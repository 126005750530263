import React from 'react';
import { OptionRenderProps } from '../FilterOption';
import { FilterSelect } from 'modules/filterControls';

const OPTIONS_0_TO_5 = new Array(6)
  .fill(0)
  .map((_, index) => ({ value: index, label: index + '' }));

export const BrightnessOption = ({
  values = [],
  onChange,
}: OptionRenderProps) => {
  const renderBrightnessLabel = (value: unknown) =>
    (value as string[])?.length
      ? `Brightness: ${(value as string[]).join(', ')}`
      : 'Brightness';
  return (
    <FilterSelect
      value={values}
      onChange={onChange}
      options={OPTIONS_0_TO_5}
      renderValue={renderBrightnessLabel}
    />
  );
};
