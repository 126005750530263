import { useEffect, useState } from 'react';
import {
  useLoadOwnedImagesByUserId,
  useLoadMyStreamingArtworks,
  useGetMyStreamingExhibitionsMap,
  useLoadPrintsOwnedByUser,
} from 'utils/requests';
import * as Yup from 'yup';
import { StreamingExhibitionsMap } from './ArtworksProvider';
import { ImageData } from 'constants/data';

export const useLoadMyStreamingExhibitions = () => {
  const [exhibitionsMap, setExhibitionsMap] =
    useState<StreamingExhibitionsMap | null>();
  const getFunc = useGetMyStreamingExhibitionsMap();

  useEffect(() => {
    const func = async () => {
      const data = await getFunc();
      setExhibitionsMap(data);
    };
    func();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    exhibitionsMap,
    isLoading: typeof exhibitionsMap === 'undefined',
  };
};

export const useLoadAllImagesAndPrintsByUserId = (userId: string) => {
  const { images: ownedImages, isLoading: isImagesLoading } =
    useLoadOwnedImagesByUserId(userId);
  const {
    prints: ownedPrints,
    isLoading: isOwnedPrintsLoading,
    setReload: setReloadPrint,
  } = useLoadPrintsOwnedByUser();
  const { images: streamingArtworks, isLoading: isStreamingArtworkLoading } =
    useLoadMyStreamingArtworks();
  const parseStreamingArtworks = (artwork: ImageData) => ({
    ...artwork,
    isStreaming: true,
  });

  return {
    images: [
      ...(Array.isArray(ownedImages) ? ownedImages : []),
      ...(Array.isArray(ownedPrints) ? ownedPrints : []),
      ...(Array.isArray(streamingArtworks)
        ? streamingArtworks.map(parseStreamingArtworks)
        : []),
    ],
    isLoading:
      isImagesLoading || isOwnedPrintsLoading || isStreamingArtworkLoading,
    setReloadPrint,
  };
};

export const MARK_PRINT_UNAVAILABLE_FOR_SALE = 'disableSale';

export function emptyUndefinedToNumber(value: number, originalValue?: number) {
  if (typeof originalValue === 'undefined') {
    return 1;
  }
  return value;
}

export const getStreamingArtworkValidationSchema = () =>
  Yup.object().shape({
    numberOfStreamingSlots: Yup.number()
      .transform(emptyUndefinedToNumber)
      .positive('Number of streams should be a positive number'),
  });

export const getGiftSendValidationSchema = () => {
  return Yup.object().shape({
    isGiftByEmail: Yup.boolean(),
    receiverEmail: Yup.string().when('isGiftByEmail', {
      is: true,
      then: Yup.string().required('Receiver email is required'),
    }),
  });
};
