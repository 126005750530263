import { useUpdate } from './useQuery';
import { commonErrors } from 'constants/errors';
import Firebase, { useFirebaseContext } from 'shell/firebase';
import { ImageData, Print } from 'constants/data';
import { responseToArray } from 'utils/common';
import { useState, useEffect, useMemo } from 'react';
import { database } from 'firebase/app';
import useQuery2 from 'utils/hooks/useQuery';
import { useAuthContext } from 'shell/session';

interface ResellPrintInputs {
  printId: string;
  imageId: string;
  price: number;
}

interface PrintsInfo {
  totalPrintsCount: number;
  selectedBuyablePrintId: string;
  printsForSale: Print[];
  isLoading: boolean;
  printsOwnedByUser: Print[];
}

const { INVALID_PARAMS_ERROR } = commonErrors;

export const useGetPrintFromId = (id?: string) => {
  const firebase = useFirebaseContext();
  const [print, setPrint] = useState<Print | null>();

  useEffect(() => {
    if (id) {
      const query = firebase.printRef(id);
      query.once(
        'value',
        snapshot => {
          setPrint(snapshot.val());
        },
        () => {
          setPrint(null);
        }
      );
    }
  }, [firebase, id]);

  return { print, isLoading: print === undefined };
};

export const useIsAvailableForSale = () => {
  return (print: Print) => {
    const { notForSale, price } = print;

    return !notForSale && !!price;
  };
};

export const useResellPrint = () => {
  const updateFunc = useUpdate();
  const firebase = useFirebaseContext();

  return async (inputs: ResellPrintInputs) => {
    const { printId, imageId, price } = inputs;
    if (!printId || !imageId || !price || isNaN(price)) {
      throw new Error(INVALID_PARAMS_ERROR);
    }

    const printPath = firebase.print(printId);
    const artworkPrintPath = firebase.artworkPrint(imageId, printId);

    const updates = {
      [`${printPath}/price`]: price,
      [`${printPath}/notForSale`]: false,
      [`${artworkPrintPath}/price`]: price,
      [`${artworkPrintPath}/notForSale`]: false,
    };

    return await updateFunc(updates);
  };
};

export const useMarkPrintAsAvailableForSale = () => {
  const updateFunc = useUpdate();
  const firebase = useFirebaseContext();

  return async (printId: string, imageId: string) => {
    if (!printId) {
      throw new Error(INVALID_PARAMS_ERROR);
    }

    const printPath = firebase.print(printId);
    const artworkPrintPath = firebase.artworkPrint(imageId, printId);

    const updates = {
      [`${printPath}/notForSale`]: false,
      [`${artworkPrintPath}/notForSale`]: false,
    };

    return await updateFunc(updates);
  };
};

export const useMarkPrintAsUnAvailableForSale = () => {
  const updateFunc = useUpdate();
  const firebase = useFirebaseContext();

  return async (printId: string, imageId: string) => {
    if (!printId) {
      throw new Error(INVALID_PARAMS_ERROR);
    }

    const printPath = firebase.print(printId);
    const artworkPrintPath = firebase.artworkPrint(imageId, printId);

    const updates = {
      [`${printPath}/notForSale`]: true,
      [`${artworkPrintPath}/notForSale`]: true,
    };

    return await updateFunc(updates);
  };
};
const isBuyablePrint = (print: Print, userId?: string) => {
  return !print.notForSale && print.owner !== userId && !!print.price;
};

export const useLoadBuyablePrints = (imageId: string, userId?: string) => {
  const firebase = useFirebaseContext();
  const query = firebase.artworkPrintsRef(imageId);
  const queryOptions = useMemo(
    () => ({
      transform: (snapshot: database.DataSnapshot) => {
        const data = snapshot.val();

        if (data !== null) {
          return Object.entries(data).reduce(
            (result: Print[], [printId, printWithoutId]: [string, any]) => {
              const print: Print = { uid: printId, ...printWithoutId };

              if (isBuyablePrint(print, userId)) {
                return [...result, print];
              }

              return result;
            },
            []
          );
        }

        return [];
      },
    }),
    [userId]
  );

  return useQuery2<Print[]>(query, queryOptions);
};

export const useGetPrintsInfo = (
  image: ImageData | undefined | null,
  userId: string
) => {
  const [
    { isLoading, totalPrintsCount, selectedBuyablePrintId, printsForSale },
    setState,
  ] = useState<PrintsInfo>({
    totalPrintsCount: 0,
    selectedBuyablePrintId: '',
    printsForSale: [],
    isLoading: true,
    printsOwnedByUser: [],
  });
  const firebase = useFirebaseContext();

  useEffect(() => {
    if (!image) {
      return;
    }

    if (firebase !== null) {
      const query = firebase.artworkPrintsRef(image.uid);
      const callback = (snapshot: database.DataSnapshot) => {
        const data = snapshot.val();

        if (data === null) {
          setState(oldState => ({
            ...oldState,
            availablePrintsCount: 0, // TODO: should check again this field, it's not used
            buyablePrints: [], // TODO: should check again this field, it's not used
            printsForSale: [], // TODO: should check again this field, it's not used
            isLoading: false,
          }));
        } else {
          const allPrints = responseToArray<Print>(data, 'uid');
          // get all the prints owned by the user or the artist
          // TODO: check migration and recheck the condition again
          const printsOwnedByUser = allPrints.filter(
            ({ owner, price, notForSale }) =>
              owner === userId && price !== 0 && !notForSale
          );

          // get all the prints which have not been sold or rented (artist owned prints)
          // show the lowest price offered by artist (if any available) - 1st priority
          // even if there's prints available from other `owners` at lower price
          const availableArtistPrints = allPrints.filter(
            ({ owner }) => owner === image.artist
          );
          availableArtistPrints.sort((a, b) => (a.price || 0) - (b.price || 0));

          const artistPrintsForSale = availableArtistPrints.filter(
            ({ notForSale, price }) => !notForSale && price !== 0
          );

          const printsForSale = allPrints.filter(
            ({ notForSale, owner, price }) =>
              !notForSale && owner !== userId && !!price && !!image.udId
          );

          const allPrintsForSale = [...artistPrintsForSale, ...printsForSale];
          const allForSaleCount =
            artistPrintsForSale.length + printsForSale.length;
          const randomIndex = Math.floor(Math.random() * allForSaleCount);
          const selectedBuyablePrintId =
            allPrintsForSale[randomIndex]?.uid || '';

          printsForSale.sort((a, b) => (a.price || 0) - (b.price || 0));

          setState({
            selectedBuyablePrintId,
            totalPrintsCount: allPrints.length,
            printsForSale,
            isLoading: false,
            printsOwnedByUser,
          });
        }
      };

      query.on('value', callback);

      return () => query.off('value', callback);
    }
  }, [firebase, userId, image]);

  return {
    isLoading,
    selectedBuyablePrintId,
    totalPrintsCount,
    printsForSale,
  };
};

export const useLoadPrintsOwnedByUser = () => {
  const firebase: Firebase = useFirebaseContext();
  const { user } = useAuthContext();
  const [prints, setPrints] = useState<Print[]>();
  const [reload, setReload] = useState<number>(0);

  useEffect(() => {
    // TODO: Refactor getting data from firebase to use on('value')
    if (user && user.editions && Object.keys(user.editions).length !== 0) {
      const prints = user.editions;

      const callable = firebase.functions.httpsCallable('prints-getPrintsData');
      callable({ prints })
        .then(res => {
          if (res?.data?.length) {
            setPrints(res.data);
          }
        })
        .catch(() => {});

      // Comment out by story 5387
      // setTimeout(() => {
      //   const promises = Object.keys(prints).map(printId => {
      //     return firebase
      //       .artworkPrintRef(prints[printId], printId)
      //       .once('value');
      //   });
      //   Promise.all(promises).then(snapshots => {
      //     const result = snapshots.map(snapshot => ({
      //       ...snapshot.val(),
      //       uid: snapshot.key,
      //     }));
      //     setPrints(result);
      //   });
      // }, 300);
    } else {
      setPrints([]);
    }
  }, [firebase, user, reload]);

  return { prints, isLoading: prints === undefined, setReload };
};

export const useGetImageFromPrint = () => {
  const firebase: Firebase = useFirebaseContext();

  return async (print: Print) => {
    const { imageId } = print;

    const snapshot = await firebase.imageRef(imageId).once('value');
    const value = snapshot.val();

    if (!value) {
      return null;
    }

    return value as ImageData;
  };
};
