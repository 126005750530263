import { createContext, useCallback, useMemo, useContext, useRef } from 'react';
import { useCreateLoadPrintsOfImageFunc, useLoadImages } from './helpers';
import { ImageData } from 'constants/data/image.data';
interface ContextProps {
  allImages?: ImageData[] | null;
  imagesMap?: Record<string, ImageData> | null;
  getNumberOfAvailablePrints: (imageId: string) => Promise<number | undefined>;
}

/**
 * This context provides the consumers the data of all images along with some utilities
 */
const AllImagesContext = createContext<ContextProps>({
  allImages: undefined,
  imagesMap: undefined,
  getNumberOfAvailablePrints: () => Promise.resolve(undefined),
});

export const AllImagesProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const printsMapRef = useRef<Record<string, number>>({});
  const countPrintsOfImage = useCreateLoadPrintsOfImageFunc();

  const { imagesMap } = useLoadImages();
  const allImages = useMemo(
    () => (imagesMap ? Object.values(imagesMap) : imagesMap),
    [imagesMap]
  );

  const getNumberOfAvailablePrints = useCallback(
    async (imageId: string) => {
      if (typeof printsMapRef.current[imageId] === 'undefined') {
        const count = await countPrintsOfImage(imageId);
        printsMapRef.current[imageId] = count;
        return count;
      }
      return printsMapRef.current[imageId];
    },
    [countPrintsOfImage]
  );

  const contextValue = useMemo(
    () => ({
      imagesMap,
      allImages,
      getNumberOfAvailablePrints,
    }),
    [imagesMap, getNumberOfAvailablePrints, allImages]
  );

  return (
    <AllImagesContext.Provider value={contextValue}>
      {children}
    </AllImagesContext.Provider>
  );
};

export const useAllImagesContext = () => useContext(AllImagesContext);
