import {
  IPreVenue,
  IVenue,
  VenueConfigurableModule,
  EnumVenueModuleType,
  VenueAboutConfigurableModule,
  EnumVenueAboutModuleType,
  VenueArtworkFilters,
} from 'constants/data/venue.data';

export const getFormDefaultValues = (venue?: IVenue | null): IPreVenue => {
  if (!venue) {
    return {
      name: '',
      shortDescription: '',
      description: '',
      uploadImage: undefined,
      coverImage: null,
      uploadCoverMobileImage: undefined,
      coverImageMobile: null,
      uploadThumbnailImage: undefined,
      thumbnailImage: null,
      logo: null,
      email: '',
      location: '',
      website: '',
      backgroundColor: '',
      footerBackgroundColor: '',
      artworkBackgroundColor: '',
      linkColor: '',
      textColor: '',
      isPrivated: false,
      isDisplayVenueProfile25: false,
      configurableModules: getDefaultConfigurableModules(),
      configurableAboutModules: getDefaultAboutConfigurableModules(),
      artworkFilters: [],
      buyingFlowGuidanceTitle: '',
      buyingFlowGuidanceSubtitle: '',
    };
  }

  return {
    name: venue.name,
    shortDescription: venue.shortDescription || '',
    description: venue.description || '',
    uploadImage: undefined,
    coverImage: venue.coverImage,
    uploadCoverMobileImage: undefined,
    coverImageMobile: venue.coverImageMobile,
    uploadThumbnailImage: undefined,
    thumbnailImage: venue.thumbnailImage,
    logo: venue.logo,
    email: venue.email || '',
    location: venue.location || '',
    website: venue.website || '',
    backgroundColor: venue.backgroundColor || '',
    footerBackgroundColor: venue.footerBackgroundColor || '',
    artworkBackgroundColor: venue.artworkBackgroundColor || '',
    linkColor: venue.linkColor || '',
    textColor: venue.textColor || '',
    isPrivated: venue.isPrivated || false,
    isDisplayVenueProfile25: venue.isDisplayVenueProfile25 || false,
    configurableModules: venue.configurableModules
      ? mergeExistingModulesWithDefault(
          Object.values(venue.configurableModules),
          getDefaultConfigurableModules()
        )
      : getDefaultConfigurableModules(),
    configurableAboutModules: venue.configurableAboutModules
      ? mergeExistingModulesWithDefault(
          Object.values(venue.configurableAboutModules),
          getDefaultAboutConfigurableModules()
        )
      : getDefaultAboutConfigurableModules(),
    artworkFilters: venue.artworkFilters
      ? artworkFiltersToNestedArray(venue.artworkFilters)
      : [],
    buyingFlowGuidanceTitle: venue.buyingFlowGuidanceTitle,
    buyingFlowGuidanceSubtitle: venue.buyingFlowGuidanceSubtitle,
  };
};

export const mergeExistingModulesWithDefault = (
  existingModules: any[],
  defaultModules: any[]
) => {
  const missingDefaultModules: any[] = [];
  defaultModules.forEach(module => {
    if (existingModules.filter(mod => mod.uid === module.uid).length === 0) {
      missingDefaultModules.push(module);
    }
  });

  const modules = [...existingModules, ...missingDefaultModules];
  const uniqueFiltered = modules.filter(
    (m, index, arr) => arr.findIndex(v => v.uid === m.uid) === index
  );

  return reorderModules(uniqueFiltered);
};
export const reorderModules = (modules: any[]) => {
  return modules.sort((a, b) => a.order - b.order);
};

export const formatModules = (modules: any[]) => {
  return modules.reduce<Record<string, any>>((acc, module, index) => {
    acc[module.uid] = {
      ...module,
      order: index + 1,
    };
    return acc;
  }, {});
};

export const getDefaultConfigurableModules = (): VenueConfigurableModule[] => {
  return [
    {
      data: {},
      order: 0,
      type: EnumVenueModuleType.TopBar,
      uid: 'topBar',
      visible: false,
    },
    {
      data: {},
      order: 1,
      type: EnumVenueModuleType.HeroBanner,
      uid: 'heroBanner1',
      visible: false,
    },
    {
      data: {},
      order: 1,
      type: EnumVenueModuleType.SplashScreenBanner,
      uid: 'splashScreenBanner',
      visible: false,
    },
    {
      data: {},
      order: 2,
      type: EnumVenueModuleType.VenueInfo,
      uid: 'venueInfo1',
      visible: false,
    },
    {
      data: {
        exhibitionId: '',
      },
      order: 3,
      type: EnumVenueModuleType.FeaturedExhibition,
      uid: 'featuredExhibition1',
      visible: false,
    },
    {
      data: {
        exhibitionId: '',
      },
      order: 4,
      type: EnumVenueModuleType.FeaturedExhibitionOnRightSection,
      uid: 'featuredExhibitionOnRightSection1',
      visible: false,
    },
    {
      data: {
        exhibitionId: '',
      },
      order: 5,
      type: EnumVenueModuleType.FeaturedExhibition,
      uid: 'featuredExhibition2',
      visible: false,
    },
    {
      data: {
        exhibitionId: '',
      },
      order: 6,
      type: EnumVenueModuleType.FeaturedExhibitionOnRightSection,
      uid: 'featuredExhibitionOnRightSection2',
      visible: false,
    },
    {
      data: {
        exhibitionId: '',
      },
      order: 7,
      type: EnumVenueModuleType.FeaturedExhibition,
      uid: 'featuredExhibition3',
      visible: false,
    },
    {
      data: {
        exhibitionId: '',
      },
      order: 8,
      type: EnumVenueModuleType.FeaturedExhibitionOnRightSection,
      uid: 'featuredExhibitionOnRightSection3',
      visible: false,
    },
    {
      data: {},
      order: 9,
      type: EnumVenueModuleType.VenueArtworks,
      uid: 'venueArtwork',
      visible: false,
    },
    {
      order: 9,
      type: EnumVenueModuleType.BrowseExhibitions,
      uid: 'venueBrowseExhibitions',
      visible: false,
    },
    {
      order: 9,
      type: EnumVenueModuleType.FeaturedMonth,
      uid: 'venueFeaturedMonth',
      visible: false,
    },
    {
      order: 9,
      type: EnumVenueModuleType.FeaturedMonth,
      uid: 'venueFeaturedMonth2',
      visible: false,
    },
    // Temporary hide from story 5462
    // {
    //   order: 9,
    //   type: EnumVenueModuleType.TwoPicturesSection,
    //   uid: 'venueTwoPicturesSection',
    //   visible: false,
    // },
    // {
    //   order: 9,
    //   type: EnumVenueModuleType.TwoPicturesOnLeftSection,
    //   uid: 'venueTwoPicturesOnLeftSection',
    //   visible: false,
    // },
    {
      order: 9,
      type: EnumVenueModuleType.ProductIntro,
      uid: 'venueProductIntro',
      visible: false,
    },
    {
      order: 9,
      type: EnumVenueModuleType.FeaturedStyle,
      uid: 'venueFeaturedStyle',
      visible: false,
    },
    {
      data: {},
      order: 0,
      type: EnumVenueModuleType.Footer,
      uid: 'footer',
      visible: false,
    },
  ];
};

export const getDefaultAboutConfigurableModules =
  (): VenueAboutConfigurableModule[] => {
    return [
      {
        data: {},
        order: 5,
        type: EnumVenueAboutModuleType.Headline,
        uid: 'venueAboutHeadline',
        visible: false,
      },
      {
        data: {},
        order: 6,
        type: EnumVenueAboutModuleType.Banner,
        uid: 'venueAboutBanner',
        visible: false,
      },
      {
        data: {},
        order: 6,
        type: EnumVenueAboutModuleType.Intro,
        uid: 'venueAboutIntro',
        visible: false,
      },
      {
        data: {},
        order: 6,
        type: EnumVenueAboutModuleType.Timeline,
        uid: 'venueAboutTimeline',
        visible: false,
      },
      {
        data: {},
        order: 6,
        type: EnumVenueAboutModuleType.Question,
        uid: 'venueAboutQuestion',
        visible: false,
      },
      {
        data: {},
        order: 6,
        type: EnumVenueAboutModuleType.Team,
        uid: 'venueAboutTeam',
        visible: false,
      },
    ];
  };

export const formatArtworkFilters = (filters?: VenueArtworkFilters[]) => {
  let returnObject = {};
  if (typeof filters == 'undefined') {
    return returnObject;
  }
  for (let i = 0; i < filters.length; i++) {
    // eslint-disable-next-line no-control-regex
    const cleanKey = filters[i].name.replace(/[^\x00-\x7F]/g, '');
    const itemKey = cleanKey.replace(/\s/g, '');

    let optionObj = {};
    for (let j = 0; j < filters[i].options.length; j++) {
      // eslint-disable-next-line no-control-regex
      const cleanKey = filters[i].options[j].value.replace(/[^\x00-\x7F]/g, '');
      const optionKey = cleanKey.replace(/\s/g, '');
      optionObj = {
        ...optionObj,
        [optionKey]: {
          order: filters[i].options[j].order | 0,
          title: filters[i].options[j].title,
          value: filters[i].options[j].value,
        },
      };
    }

    returnObject = {
      ...returnObject,
      [itemKey]: {
        name: filters[i].name,
        order: filters[i].order | 0,
        options: optionObj,
      },
    };
  }
  return returnObject;
};

export const artworkFiltersToNestedArray = (artworkFiltersObject: any) => {
  const returnArray: any[] = [];
  Object.keys(artworkFiltersObject).forEach(key => {
    let optArray: any[] = [];
    Object.keys(artworkFiltersObject[key].options).forEach(optKey => {
      optArray.push(artworkFiltersObject[key].options[optKey]);
    });
    returnArray.push({
      options: optArray,
      name: artworkFiltersObject[key].name,
      order: artworkFiltersObject[key].order,
    });
  });
  return returnArray;
};
