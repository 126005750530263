import { mobileMediaQuery } from 'utils/common';
import {
  Box,
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogProps,
  createStyles,
  makeStyles,
  Theme,
  IconButton,
} from '@material-ui/core';
import Typography from 'modules/typography';
import CloseIcon from '@material-ui/icons/Close';

import UIButton from 'ui-components/Button';

interface Props extends DialogProps {
  onConfirm: () => void;
  onClose: (clearData: boolean) => void;
  isCancelling: boolean;
}

const CancelGiftDialog = (props: Props) => {
  const { open = false, onClose, onConfirm, isCancelling, ...rest } = props;
  const classes = useStyles();

  const handleClose = () => {
    if (onClose) {
      onClose(true);
    }
  };

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      {...rest}
      classes={{ paper: classes.paper }}>
      <DialogTitle>Cancel the Gift</DialogTitle>
      <DialogContent>
        <Box component={DialogContentText} whiteSpace="pre-line">
          <Typography variant="body1" fontColor="black" component="span">
            This gift has not been claimed yet by the recipient. Are you sure
            you want to cancel it?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions disableSpacing className={classes.actions}>
        <UIButton
          onClick={onConfirm}
          variant="primary"
          size="medium"
          disabled={isCancelling}
          text="Confirm"
        />
      </DialogActions>
      <IconButton className={classes.buttonClose} onClick={() => handleClose()}>
        <CloseIcon />
      </IconButton>
    </MuiDialog>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);

  return createStyles({
    paper: {
      padding: theme.spacing(4),
      maxWidth: 500,
    },
    actions: {
      marginTop: theme.spacing(3.5),
      paddingLeft: theme.spacing(3),
      justifyContent: 'flex-start',
    },
    buttonClose: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),

      [mobile]: {
        right: theme.spacing(2),
      },
    },
  });
});

export default CancelGiftDialog;
