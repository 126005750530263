export interface IVenueCoverImage {
  path: string;
  url: string;
}
export interface IVenue {
  uid: string;
  name: string;
  shortDescription: string;
  description: string;
  coverImage: IVenueCoverImage | null;
  coverImageMobile: IVenueCoverImage | null;
  thumbnailImage: IVenueCoverImage | null;
  logo: IVenueCoverImage | null;
  email: string;
  website: string;
  location: string;
  published: boolean;
  isPrivated?: boolean;
  displayOrder: number;
  backgroundColor?: string;
  footerBackgroundColor?: string;
  artworkBackgroundColor?: string;
  linkColor?: string;
  textColor?: string;
  isDisplayVenueProfile25?: boolean;
  configurableModules: Record<string, VenueConfigurableModule>;
  domainName?: string;
  configurableAboutModules: Record<string, VenueAboutConfigurableModule>;
  artworkFilters?: VenueArtworkFilters[];
  buyingFlowGuidanceTitle: string;
  buyingFlowGuidanceSubtitle: string;
  numberOfExhibition?: number;
  secretToken?: string;
}

export interface IPreVenue {
  name: string;
  uploadImage?: File;
  uploadCoverMobileImage?: File;
  uploadThumbnailImage?: File;
  uploadLogo?: File;
  shortDescription: string;
  description: string;
  coverImage: IVenueCoverImage | null;
  coverImageMobile: IVenueCoverImage | null;
  thumbnailImage: IVenueCoverImage | null;
  logo: IVenueCoverImage | null;
  email: string;
  website: string;
  location: string;
  backgroundColor: string;
  footerBackgroundColor: string;
  artworkBackgroundColor: string;
  linkColor?: string;
  textColor?: string;
  isDisplayVenueProfile25: boolean;
  isPrivated: boolean;
  configurableModules: VenueConfigurableModule[];
  configurableAboutModules: VenueAboutConfigurableModule[];
  artworkFilters?: VenueArtworkFilters[];
  buyingFlowGuidanceTitle: string;
  buyingFlowGuidanceSubtitle: string;
}

export enum EnumVenueModuleType {
  HeroBanner = 'heroBanner',
  SplashScreenBanner = 'splashScreenBanner',
  VenueInfo = 'venueInfo',
  FeaturedArtists = 'featuredArtists',
  FeaturedExhibition = 'featuredExhibition',
  VenueArtworks = 'venueArtwork',
  TopBar = 'topBar',
  Footer = 'footer',
  FeaturedExhibitionOnRightSection = 'featuredExhibitionOnRightSection',
  TwoPicturesSection = 'twoPicturesSection',
  TwoPicturesOnLeftSection = 'twoPicturesOnLeftSection',
  OnePictureSection = 'onePictureSection',
  OnePictureLeftSection = 'onePictureLeftSection',
  BrowseExhibitions = 'browseExhibitions',
  FeaturedMonth = 'featuredMonth',
  FeaturedStyle = 'featuredStyle',
  ProductIntro = 'productIntro',
}

export interface VenueConfigurableModule {
  uid: string;
  order: number;
  visible?: boolean;
  type: EnumVenueModuleType;
  data?: any;
}

//#region venue-about
export enum EnumVenueAboutModuleType {
  Banner = 'banner',
  Headline = 'headline',
  Intro = 'intro',
  Question = 'question',
  Team = 'team',
  Timeline = 'timeline',
}

export interface VenueAboutConfigurableModule {
  uid: string;
  order: number;
  visible?: boolean;
  type: EnumVenueAboutModuleType;
  data?: any;
}
//#endregion

//#region venue-artwork-filters
export interface VenueArtworkFilters {
  name: string;
  order: number;
  options: {
    title: string;
    value: string;
    order: number;
  }[];
}
//endregion

export type CombinedModules =
  | VenueAboutConfigurableModule
  | VenueConfigurableModule;
