import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { ProductData } from 'constants/data/product.data';
import { useAuthContext } from 'shell/session';
import { UserData } from 'constants/data/user.data';
import {
  Box,
  createStyles,
  CircularProgress,
  makeStyles,
  Theme,
  Backdrop,
} from '@material-ui/core';
import { mobileMediaQuery } from 'utils/common';
import SubscribeCard from './SubscribeCard';
import { useModal } from 'modules/modals';
import {
  MembershipNameType,
  MembershipItemInterface,
} from 'constants/membership';
import { useLoadProducts, useSubscribe } from 'utils/requests';
import { Alert } from '@material-ui/lab';
import { useGetInviter } from './helpers';
import FreeMonthBox from './FreeMonthBox';
import { isPaidPlan } from 'views/client/subscribe/helpers';
import getEnvVars from 'utils/common/envVars';

interface Props {
  successUrl?: string;
  cancelUrl?: string;
  showFreeMonth?: boolean;
}

export interface SubscribeDataInterface {
  priceId: string;
  membership: MembershipItemInterface;
}

const getAvailableProducts = (
  products: any,
  user: UserData | undefined
): string[] => {
  const membership = user?.membership || '';
  // for user not belong to any membership or admin role
  if (!membership || !isPaidPlan(membership)) {
    return products.reduce(
      (list: MembershipNameType[], current: any) => [...list, current.name],
      []
    );
  }

  let foundMembership = false;
  try {
    return products.reduce((list: MembershipNameType[], current: any) => {
      if (current.name === membership) {
        foundMembership = true;
        return list;
      }
      return foundMembership ? [...list, current.name] : list;
    }, []);
  } catch (e) {
    console.error('availableProducts error: ', e);
    return [];
  }
};

const Subscribe = ({ successUrl, cancelUrl, showFreeMonth }: Props) => {
  const { stripeKey } = getEnvVars();

  const authContext = useAuthContext();
  const user = authContext.user as UserData | undefined;
  const { isEmailVerified, showVerifyDialog } = authContext;
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showSnackbar } = useModal();
  const { products, isLoading: isLoadingProduct } = useLoadProducts();
  const subscribe = useSubscribe();
  const { inviter, isLoading: isLoadingInviter } = useGetInviter(
    user,
    showFreeMonth
  );
  const isLoading = isLoadingProduct || isLoadingInviter;

  const handleSubscribeClick = async ({
    priceId,
    membership,
  }: SubscribeDataInterface) => {
    if (!isEmailVerified) {
      return showVerifyDialog();
    }

    if (user && stripeKey) {
      setIsSubmitting(true);
      const sessionId = (await subscribe(user.uid, {
        priceUid: priceId,
        cancelUrl: cancelUrl || window.location.href,
        successUrl: successUrl || window.location.href,
        membership: membership?.name,
      }).catch(error => {
        console.error('error', error);
        setIsSubmitting(false);
        showSnackbar('Subscribe error', { severity: 'error' });

        return;
      })) as string;

      if (sessionId) {
        const stripe = await loadStripe(stripeKey);
        setIsSubmitting(false);
        stripe?.redirectToCheckout({ sessionId });
      }
    }
  };

  // if (!user) {
  //   return null;
  // }

  if (isLoading) {
    return (
      <Box className={classes.container}>
        <CircularProgress />
      </Box>
    );
  }

  const availableProducts = getAvailableProducts(products, user);

  if (!isLoadingProduct && (!products?.length || !availableProducts?.length)) {
    return <Alert className={classes.noProducts}>No products found.</Alert>;
  }

  return (
    <Box className={classes.container}>
      {!isLoadingProduct && (
        <Box className={classes.subscribe}>
          {products.map((product: ProductData, index: number) => {
            return (
              availableProducts.includes(product?.name) && (
                <Box key={product.uid} className={classes.subscribeCard}>
                  <SubscribeCard
                    productData={product}
                    onCardClick={handleSubscribeClick}
                    isComingSoon={index !== 0}
                    showFreeMonth={!!inviter && index === 0}
                  />
                </Box>
              )
            );
          })}
        </Box>
      )}
      <FreeMonthBox inviter={inviter} />
      <Backdrop
        open={!isLoadingProduct && isSubmitting}
        className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      minHeight: '100%',
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(6),
      flex: 1,
      [mobile]: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
    },
    subscribe: {
      display: 'flex',
      justifyContent: 'flex-start',
      gridGap: theme.spacing(2),
      flexDirection: 'row',
      position: 'relative',
      width: '100%',
      maxWidth: '1000px',
      zIndex: 1,
      [mobile]: {
        flexDirection: 'column',
        gridGap: theme.spacing(2),
      },
    },
    subscribeCard: {
      zIndex: 2,
      position: 'relative',
      [mobile]: {
        margin: 0,
      },
    },
    noProducts: {
      margin: theme.spacing(2, 0),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    background: {
      backgroundColor: '#F9F9F9',
      position: 'absolute',
      zIndex: 0,
      top: '-28px',
      left: '46px',
      right: '46px',
      bottom: '-28px',
      [mobile]: {
        display: 'none',
      },
    },
  });
});

export default Subscribe;
