import { CanvasData } from 'constants/data/canvas.data';

import MobileInner from 'static/images/device_frames/mobile-inner.png';
import MobileOuter from 'static/images/device_frames/mobile-outer.png';
import TabletInner from 'static/images/device_frames/tablet-inner.png';
import TabletOuter from 'static/images/device_frames/tablet-outer.png';
import TvInner from 'static/images/device_frames/tv-inner.png';
import TvOuter from 'static/images/device_frames/tv-outer.png';

export const getDimensions = (canvas: CanvasData) => {
  const dimensionsStr = canvas.meta.dimensions;
  const [width, height] = dimensionsStr
    .split(',')
    .map(str => parseInt(str.trim()));

  return { width, height };
};

export const getFrameStyles = (canvas: CanvasData) => {
  if (canvas.meta.platformTV) {
    return TV_FRAME;
  }
  if (canvas.meta.platformTablet) {
    return TABLET_FRAME;
  }
  return MOBILE_FRAME;
};

export const getFrameScale = (canvas: CanvasData) => {
  const { width: canvasWidth } = getDimensions(canvas);
  const frameWidth = getFrameStyles(canvas).innerFrameWidth;
  return frameWidth / canvasWidth;
};

const MOBILE_FRAME = {
  innerFrameImage: MobileInner,
  outerFrameImage: MobileOuter,
  innerFrameWidth: 150,
  // height: 382,
  overlayBorderRadius: 15,
  outerFramePaddingX: 20,
  outerFramePaddingY: 20,
};

const TABLET_FRAME = {
  innerFrameImage: TabletInner,
  outerFrameImage: TabletOuter,
  innerFrameWidth: 255,
  // height: 339,
  overlayBorderRadius: 3,
  outerFramePaddingX: 16,
  outerFramePaddingY: 30,
};

const TV_FRAME = {
  innerFrameImage: TvInner,
  outerFrameImage: TvOuter,
  innerFrameWidth: 411,
  // height: 229,
  overlayBorderRadius: 0,
  outerFramePaddingX: 8,
  outerFramePaddingY: 8,
};
