import React from 'react';
import {
  Dialog,
  DialogProps,
  makeStyles,
  createStyles,
  Theme,
  DialogContent,
  Box,
} from '@material-ui/core';
import Typography from 'modules/typography';
import { mobileMediaQuery } from 'utils/common';
import { COLORS_MAP } from 'constants/colors';
import { SvgIcon } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { ColorType } from 'constants/colors';

import UIButton from 'ui-components/Button';

interface MessageDialogFactoryProps {
  title: string;
  description: string;
  Icon?: typeof SvgIcon;
  iconColor?: ColorType;
}

type MessageDialogType = 'something_wrong' | 'payment_unsuccessful';

export const MessageDialogFactory: Record<
  MessageDialogType,
  MessageDialogFactoryProps
> = {
  something_wrong: {
    title: 'Something went wrong.',
    description: `An error occurred. Please try again later or contact the support team.<br/>
      <br/>If you encounter any technical issues, please include details or attach a screenshot<br/>
      <br/><a href="mailto:support@activeimage.io" style="color:#EB4424">support@activeimage.io</a>`,
    Icon: Warning,
    iconColor: 'dark red',
  },
  payment_unsuccessful: {
    title: 'Payment unsuccessful',
    description: `An error occurred at checkout. It could be due to insufficient fund. Please try again and confirm you have put in a valid payment method.`,
    Icon: Warning,
    iconColor: 'dark red',
  },
};

export interface MessageDialogHook {
  type: MessageDialogType;
}

export interface MessageDialogProps
  extends Omit<DialogProps, 'open' | 'onClose' | 'title'>,
    MessageDialogFactoryProps {
  open?: boolean;
  onClose?: () => void;
}

interface MessageDialogStyleProps {
  iconColor: string;
}

const MessageDialog = ({
  Icon,
  iconColor,
  title,
  description,
  open = false,
  ...dialogProps
}: MessageDialogProps) => {
  const classes = useStyles({ iconColor: COLORS_MAP[iconColor || 'black'] });

  const handleClickOk = () => {
    if (dialogProps.onClose) {
      dialogProps.onClose();
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullWidth
      open={open}
      {...dialogProps}
      disableBackdropClick
      disableEscapeKeyDown>
      <DialogContent className={classes.content}>
        <Box className={classes.container}>
          {Icon && <Icon className={classes.icon} />}
          <Typography className={classes.title}>{title}</Typography>
          <Typography
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <UIButton
            variant="secondary"
            size="medium"
            onClick={handleClickOk}
            className={classes.buttonOk}
            text="OK"
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles<Theme, MessageDialogStyleProps>((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    paper: {
      color: '#000',
      height: 'auto',
      maxWidth: 500,
    },
    content: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: theme.spacing(7),
      [mobile]: {
        padding: theme.spacing(6, 4.5),
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingTop: theme.spacing(3),
      [mobile]: {
        marginLeft: 0,
      },
    },
    icon: {
      marginBottom: theme.spacing(2),
      display: 'block',
      color: ({ iconColor }) => iconColor,
    },
    title: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(28),
      lineHeight: 1.2,
      marginBottom: theme.spacing(3),
      letterSpacing: '-2%',
    },
    description: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.5,
      marginBottom: theme.spacing(4),
      maxWidth: 375,
      color: '#030303',
    },
    link: {
      'fontSize': theme.typography.pxToRem(14),
      'color': '#EF5533',
      'textDecoration': 'underline',
      'marginBottom': theme.spacing(4),
      '&:hover': {
        color: '#EF5533',
      },
    },
    buttonOk: {
      alignSelf: 'flex-start',
    },
  });
});

export default MessageDialog;
