import { Chip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { TransactionData } from 'constants/data';

interface TxArtworkTypeProps {
  tx: TransactionData;
}

const TxArtworkTypeCell = (props: TxArtworkTypeProps) => {
  const { tx } = props;
  const { originalArtworkId, printId } = tx;
  const isPrint = !originalArtworkId && !!printId;
  const isInvalid = !originalArtworkId && !printId;

  if (isInvalid) {
    return <Alert severity="error" />;
  }

  if (isPrint) {
    return <Chip color="secondary" label="Edition" />;
  }

  return <Chip color="primary" label="Original" />;
};

export default TxArtworkTypeCell;
