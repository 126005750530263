import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuthContext } from 'shell/session';
import { SIGN_IN } from 'constants/routes';

const PrivateRoute = (props: RouteProps) => {
  const { user } = useAuthContext();

  // undefined means checking user status
  if (typeof user === 'undefined') {
    return null;
  }

  if (user === null) {
    return (
      <Redirect
        to={{
          pathname: SIGN_IN,
          state: { from: props.location },
        }}
      />
    );
  }

  return <Route {...props} />;
};

export default PrivateRoute;
