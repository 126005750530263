import loadable from 'utils/loadable';

const AdminUserListPage = loadable(
  () =>
    import(/* webpackChunkName: "admin.users.UserListPage" */ './UserListPage')
);
const AdminUserDetailsPage = loadable(
  () =>
    import(/* webpackChunkName: "admin.users.UserDetails" */ './UserDetails')
);

export { AdminUserListPage, AdminUserDetailsPage };
