import { Location } from 'history';
import { SIGN_UP } from 'constants/routes';

export const useGetSignUpPath = () => {
  return (from: Location) => ({
    pathname: SIGN_UP,
    search: '',
    state: { from },
    hash: '',
  });
};
export default useGetSignUpPath;
