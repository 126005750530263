import aPromiseWrapper from 'a-promise-wrapper';
import { useFirebaseContext } from 'shell/firebase';

interface Inputs {
  artworkId: string;
}

const useGetSaleHistory = () => {
  const firebase = useFirebaseContext();

  return async ({ artworkId }: Inputs) => {
    const funcName = 'blockchain-getSaleHistory';
    const callable = firebase.functions.httpsCallable(funcName);
    const { data: response, error } = await aPromiseWrapper(
      callable({ artworkId })
    );

    if (error) {
      throw error;
    }

    return response.data;
  };
};

export default useGetSaleHistory;
