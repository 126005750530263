import React from 'react';
import cn from 'classnames';

import IconPaths, { PathDescriptor } from './constants';
import styles from './styles.module.scss';

export type IconColor = 'currentColor';

export type IconId = keyof typeof IconPaths;

export type IconProps = {
  icon: IconId;
  size?: number;
  color?: IconColor;
};

const Icon = ({ icon, size = 16, color = 'currentColor' }: IconProps) => {
  const pathProps: PathDescriptor | PathDescriptor[] = IconPaths[icon];

  return (
    <svg
      className={cn(styles[`color-${color}}`], {
        [styles.spinner]: icon === 'spinner',
      })}
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 30 30">
      {Array.isArray(pathProps) ? (
        pathProps.map((p, key) => (
          <path key={key} {...p} fill={'currentColor'} />
        ))
      ) : (
        <path {...pathProps} fill={'currentColor'} />
      )}
    </svg>
  );
};

export default Icon;
