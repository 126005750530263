import { formatDate, DATE_FORMAT_DAY } from 'utils/common';
import { ExhibitionData } from 'constants/data/exhibition.data';

export const getExhibitionTimeString = (exhibition: ExhibitionData) => {
  const { startTime, endTime } = exhibition;
  const startTimeStr = startTime && formatDate(startTime, DATE_FORMAT_DAY);
  const endTimeStr = endTime && formatDate(endTime, DATE_FORMAT_DAY);

  if (startTimeStr && endTimeStr) {
    return `${startTimeStr} - ${endTimeStr}`;
  }
  if (startTimeStr) {
    return `From ${startTimeStr}`;
  }
  if (endTimeStr) {
    return `Until ${endTimeStr}`;
  }
  return null;
};
