export enum TransactionType {
  TRANSACTION_TYPE_CREATE = 'create',
  TRANSACTION_TYPE_TRANSFER = 'transfer',
  TRANSACTION_TYPE_GIFT = 'gift',
}
export interface TransactionData {
  uid: string;
  createdDate: number;
  isAnonymous?: boolean;
  price: number;
  buyerId: string; // Owner in created NFT case or buyer in transfer NFT case
  sellerId: string;
  originalArtworkId: string;
  printId: string;
  blockchainTransactionHash?: string; // The transaction hash for changing data on blockchain
  completedDate?: number;
  eventType: TransactionType;
  stripeCheckoutSessionId?: string;
  status: 'created' | 'payment-success' | 'cancelled' | 'completed';
  completedOriginalId?: string;
  completedPrintId?: string;
  giftOTP?: string;
  giftReceiverEmail?: string;
  paidToArtist?: boolean;
}

export interface ExtendedTransactionVenueData extends TransactionData {
  venueId: string;
  artistId: string;
  imageId: string;
}
