import { NavLink } from 'react-router-dom';
import OptimizedImage from 'modules/OptimizedImage';
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  List,
  ListItem,
  Link,
} from '@material-ui/core';
import cs from 'classnames';
import * as ROUTES from 'constants/routes';
import EditableImage from 'modules/editableImages/EditableImage';
import useAnalytics from 'utils/requests/useAnalytics';
import { useStyles } from 'modules/footer/Footer';
import { useStyles as footerBottomStyles } from 'modules/footer/FooterBottom';
import { useStyles as socialStyles } from 'modules/footer/SocialIconList';
import { useStyles as newsletterStyles } from 'modules/footer/NewsletterLink';
import { getFooterKey } from 'views/client/venueDetails/helpers';
import EditableText, { ExternalRouterLink } from 'modules/editableText';
import { useVenueConfig } from 'views/client/venueDetails/VenueContext';
import UIButton from 'ui-components/Button';
import { useModesContext } from 'utils/contexts/modesContext';

interface FooterProps {
  moduleId: string;
  venueId: string;
  isLandingPage?: boolean;
}

const BottomLogo = (props: any) => {
  const { imageKey } = props;
  const { isEditorMode } = useModesContext();
  const classes = useStyles();
  const footerBottomClasses = footerBottomStyles();

  if (isEditorMode) {
    return (
      <Box className={cs(footerBottomClasses.logo, classes.logo)}>
        <EditableImage
          imageKey={imageKey}
          render={({ editableImage }) => {
            return editableImage ? (
              <NavLink to={editableImage?.url || ROUTES.LANDING}>
                <OptimizedImage
                  path={editableImage.path}
                  alt={editableImage.alt}
                  className={classes.logo}
                />
              </NavLink>
            ) : null;
          }}
        />
      </Box>
    );
  }

  return (
    <EditableImage
      imageKey={imageKey}
      render={({ editableImage }) => {
        return editableImage ? (
          <Box marginRight={8} className={classes.logo}>
            <a href={editableImage?.url || ROUTES.LANDING}>
              <OptimizedImage
                path={editableImage.path}
                alt={editableImage.alt}
                className={classes.logo}
              />
            </a>
          </Box>
        ) : null;
      }}
    />
  );
};

const Footer = ({ moduleId, venueId, isLandingPage = true }: FooterProps) => {
  const { footerBackgroundColor } = useVenueConfig();
  const classes = useStyles();
  const footerClasses = useStylesFooter({
    backgroundColor: footerBackgroundColor,
  });
  const footerBottomClasses = footerBottomStyles();
  const socialClasses = socialStyles();
  const newsLetterClasses = newsletterStyles();
  const { trackSocialClick } = useAnalytics();
  const footerKeys: Record<string, string> = getFooterKey(venueId, moduleId);

  return (
    <Box
      className={cs(
        classes.container,
        isLandingPage
          ? footerClasses.containerLandingpage
          : footerClasses.container
      )}>
      <Box mx="auto" className={classes.footer}>
        <Box className={classes.footerContent}>
          <Box className={classes.logo}>
            <EditableImage
              imageKey={footerKeys.logo}
              render={({ editableImage }) => {
                return editableImage ? (
                  <NavLink to={editableImage?.url || ROUTES.LANDING}>
                    <OptimizedImage
                      path={editableImage.path}
                      alt={editableImage.alt}
                      className={classes.logo}
                    />
                  </NavLink>
                ) : null;
              }}
            />
          </Box>

          <Box className={classes.navContainer}>
            {Array.from(Array(3).keys()).map(colIndex => {
              return (
                <Box key={`footerNav${colIndex}`} className={classes.nav}>
                  <Typography className={classes.navLabel}>
                    <EditableText
                      textKey={footerKeys[`nav${colIndex}_label`]}
                      defaultText={''}
                    />
                  </Typography>
                  <List className={classes.navList}>
                    {Array.from(Array(4).keys()).map(itemIndex => {
                      return (
                        <ListItem
                          className={classes.navItem}
                          key={`footerNav${colIndex}-${itemIndex}`}>
                          <EditableText
                            textKey={
                              footerKeys[`nav${colIndex}_item${itemIndex}_text`]
                            }
                            linkKey={
                              footerKeys[`nav${colIndex}_item${itemIndex}_link`]
                            }
                            defaultText={''}
                            defaultLink={''}
                            render={({ text, link }) =>
                              !text ? null : (
                                <ExternalRouterLink
                                  to={link}
                                  className={classes.link}>
                                  {text}
                                </ExternalRouterLink>
                              )
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              );
            })}
          </Box>

          <Box className={classes.footerSocial}>
            <Box>
              <List className={socialClasses.iconList}>
                {Array.from(Array(5).keys()).map(itemIndex => (
                  <ListItem
                    key={`socialItem${itemIndex}`}
                    className={socialClasses.iconItem}>
                    <EditableImage
                      imageKey={footerKeys[`social_item${itemIndex}`]}
                      render={({ editableImage }) => {
                        return editableImage ? (
                          <a
                            href={editableImage?.url || '#'}
                            target="_blank"
                            rel="noreferrer"
                            className={socialClasses.iconLink}
                            onClick={() => {
                              trackSocialClick({ name: editableImage.alt });
                            }}>
                            <OptimizedImage
                              path={editableImage.path}
                              alt={editableImage.alt}
                              className={socialClasses.icon}
                            />
                          </a>
                        ) : null;
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box className={classes.newsletterEditMode}>
              <Box>
                <Typography className={newsLetterClasses.label}>
                  <EditableText
                    textKey={footerKeys.newsLetterText}
                    defaultText={''}
                    render={({ text }) => {
                      if (!text) {
                        return null;
                      }

                      return <span>{text}</span>;
                    }}
                  />
                </Typography>
                <EditableText
                  textKey={footerKeys.newsLetterBtnText}
                  linkKey={footerKeys.newsLetterLink}
                  defaultText={''}
                  defaultLink={''}
                  render={({ text, link }) =>
                    !text ? null : (
                      <Link
                        component="a"
                        href={link}
                        target="_blank"
                        underline="none">
                        <UIButton
                          variant="secondary-inverted"
                          size="small"
                          text={text}
                        />
                      </Link>
                    )
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={footerBottomClasses.footerBottom}>
          <Box className={footerBottomClasses.leftContent}>
            <BottomLogo imageKey={footerKeys.bottomLogo} />
            <Typography className={footerBottomClasses.leftTitle}>
              <EditableText textKey={footerKeys.bottomText} defaultText={''} />
            </Typography>
            <List className={footerBottomClasses.linkList}>
              {Array.from(Array(3).keys()).map(itemIndex => (
                <ListItem
                  className={footerBottomClasses.linkItem}
                  key={`footerBottom${itemIndex}`}>
                  <EditableText
                    textKey={footerKeys[`bottom_item${itemIndex}_text`]}
                    linkKey={footerKeys[`bottom_item${itemIndex}_link`]}
                    defaultText={''}
                    defaultLink={''}
                    render={({ text, link }) =>
                      !text ? null : (
                        <ExternalRouterLink
                          to={link}
                          className={footerBottomClasses.link}>
                          {text}
                        </ExternalRouterLink>
                      )
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

type StyleProps = {
  backgroundColor?: string;
};

const useStylesFooter = makeStyles((theme: Theme) => {
  return createStyles({
    containerLandingpage: {
      marginBottom: theme.spacing(-10),
      marginTop: theme.spacing(13),
      backgroundColor: (props: StyleProps) => props.backgroundColor,
    },
    container: {
      marginTop: 0,
    },
  });
});

export default Footer;
