import { useState, useEffect } from 'react';
import { UserData } from 'constants/data/user.data';
import { useFirebaseContext } from 'shell/firebase';
import { useIsMounted } from 'utils/hooks';

export const useLoadArtist = (artistId?: string) => {
  const firebase = useFirebaseContext();
  const [artist, setArtist] = useState<UserData | null>();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (!artistId) {
      setArtist(null);
    }
    if (firebase !== null && artistId) {
      const query = firebase.userRef(artistId);
      query.once('value', snapshot => {
        const user = snapshot.val();
        if (isMounted() === true) {
          if (!user) {
            setArtist(null);
            return;
          }
          setArtist({ ...user, uid: artistId });
        }
      });
    }
  }, [artistId, firebase, isMounted]);

  return {
    artist,
    isLoading: typeof artist === 'undefined',
  };
};
