import React, { createContext, useRef, useContext, useCallback } from 'react';
import { SnackbarCloseReason } from '@material-ui/core';
import ConfirmDialog, { ConfirmDialogProps } from './ConfirmDialog';
import MessageDialog, {
  MessageDialogHook,
  MessageDialogFactory,
  MessageDialogProps,
} from './MessageDialog';
import Snackbar, { SnackbarProps } from './Snackbar';
import { useModalState } from 'utils/hooks';

interface ContextValue {
  openDialog: (props: ConfirmDialogProps) => Promise<boolean>;
  openMessageDialog: (props: MessageDialogHook) => void;
  showSnackbar: (message: string, props?: SnackbarProps) => void;
}

const defaultContextValue = {
  openDialog: () => Promise.resolve(false),
  openMessageDialog: () => {},
  showSnackbar: () => {},
};

const ModalContext = createContext<ContextValue>(defaultContextValue);

/**
 * The provider to provide nested components the ability to open a confirm dialog on demand
 */
export const ModalProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const confirmDialog = useConfirmDialog();
  const messageDialog = useMessageDialog();
  const snackbar = useSnackbar();

  const contextValue = useRef({
    showSnackbar: snackbar.showSnackbar,
    openMessageDialog: messageDialog.openDialog,
    openDialog: confirmDialog.openDialog,
  });

  return (
    <ModalContext.Provider value={contextValue.current}>
      {children}
      {confirmDialog.render}
      {messageDialog.render}
      {snackbar.render}
    </ModalContext.Provider>
  );
};

const useConfirmDialog = () => {
  const { isOpen, data, open, close } = useModalState<ConfirmDialogProps>();
  const openDialog = useCallback(
    async (props: ConfirmDialogProps) =>
      new Promise<boolean>(resolve => {
        const { onClose, onConfirm, onCancel, ...rest } = props;
        const handleConfirm = () => {
          if (typeof onConfirm === 'function') {
            onConfirm();
          }
          resolve(true);
        };
        const handleCancel = () => {
          if (typeof onCancel === 'function') {
            onCancel();
          }
          resolve(false);
        };
        const handleOnClose = () => {
          if (typeof onClose === 'function') {
            onClose({}, 'backdropClick');
          }
          close(false);
        };
        open({
          onClose: handleOnClose,
          onConfirm: handleConfirm,
          onCancel: handleCancel,
          ...rest,
        });
      }),
    [close, open]
  );
  const render = data && <ConfirmDialog open={isOpen} {...data} />;
  return { openDialog, render };
};

const useMessageDialog = () => {
  const { isOpen, data, open, close } = useModalState<MessageDialogProps>();
  const openDialog = useCallback(
    (props: MessageDialogHook) => {
      const { type } = props;
      const messageDialogPayload = MessageDialogFactory[type];
      open({
        onClose: () => close(false),
        ...messageDialogPayload,
      });
    },
    [close, open]
  );
  const render = data && <MessageDialog open={isOpen} {...data} />;
  return { openDialog, render };
};

export const useSnackbar = () => {
  const { isOpen, data, open, close } = useModalState<SnackbarProps>();
  const showSnackbar = useCallback(
    (message: string, props: SnackbarProps = {}) => {
      const { onClose, ...rest } = props;
      const handleOnClose = (
        event: React.SyntheticEvent<any, Event>,
        reason: SnackbarCloseReason
      ) => {
        if (typeof onClose === 'function') {
          onClose(event, reason);
        }
        close(false);
      };
      open({
        onClose: handleOnClose,
        ...rest,
        message,
      });
    },
    [open, close]
  );
  const dataCy =
    data?.severity === 'error' ? 'snackbar-error' : 'snackbar-success';
  const render = <Snackbar open={isOpen} {...data} data-cy={dataCy} />;
  return { showSnackbar, render };
};

export const useModal = () => useContext(ModalContext);
