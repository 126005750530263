/**
 * Move the item from `fromIndex` to `toIndex`
 *
 * @example
 * const newList = moveItem([0, 1, 2, 3, 4, 5], 4, 1)
 * // newList => [0, 4, 1, 2, 3, 5]
 *
 * @param list
 * @param fromIndex
 * @param toIndex
 * @returns
 */
export const moveListItem = <T>(
  list: T[],
  fromIndex: number,
  toIndex: number
): T[] => {
  const result = Array.from(list);
  const [item] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, item);
  return result;
};

export default moveListItem;
