import { useState } from 'react';
import cn from 'classnames';
import { makeStyles, createStyles, Theme, Box } from '@material-ui/core';
import Typography from 'modules/typography';
import { ImageData } from 'constants/data/image.data';
import { CanvasData } from 'constants/data/canvas.data';
import { mobileMediaQuery } from 'utils/common';
import CanvasItem from './CanvasItem';
import ImageSelectionDialog, {
  ImageSelectionParamsType,
  ExtendedImageSelectionParamsType,
} from 'modules/imageSelectionDialog';
import { useModalState } from 'utils/hooks';
import { useModal } from 'modules/modals';
import { usePairingCode } from 'utils/requests';
import { getErrorMessage } from 'constants/errors';
import { useAuthContext } from 'shell/session';

interface Props {
  canvases: CanvasData[];
  image: ImageData;
  printId?: string;
  className?: any;
  onPairOtherCanvasClick: () => void;
  setSelectedCanvas?: (canvas: CanvasData) => void;
  onNext?: () => void;
  onClose?: () => void;
  isShowTitle?: boolean;
  isShowBottomSection?: boolean;
  resetHeight?: boolean;
  bodyTableStyle?: any;
}

const SelectCanvasBox = ({
  canvases,
  onPairOtherCanvasClick,
  image,
  printId,
  onNext,
  onClose,
  setSelectedCanvas,
  className = {},
  isShowTitle = true,
  isShowBottomSection = true,
  bodyTableStyle = {},
}: Props) => {
  const classes = useStyles();
  const { showSnackbar } = useModal();
  const { playArtwork } = usePairingCode();
  const {
    data,
    isOpen: isOpenSelectionDialog,
    open: openSelectionDialog,
    close: closeSelectionDialog,
  } = useModalState<ExtendedImageSelectionParamsType>();
  const { user } = useAuthContext();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [playingImageId, setImageId] = useState<string>();

  const onAssignArtwork = (canvas: CanvasData) => {
    if (onNext) {
      setSelectedCanvas && setSelectedCanvas(canvas);
      onNext();
    } else {
      playWithCroppingTool(canvas);
    }
  };

  const playWithCroppingTool = async (canvas: CanvasData) => {
    const onConfirm = async (data: ImageSelectionParamsType) => {
      const { image, printId, canvas, printTransform: transform } = data;
      const { uid: imageId } = image;
      const { uid: canvasId } = canvas;
      const isStreaming = !!(user && !printId && image.owner !== user.uid);

      setLoading(true);
      playArtwork({ imageId, printId, canvasId, transform, isStreaming })
        .then(() => {
          onSuccess(imageId);
          onClose && onClose();
        })
        .catch(error => {
          onError(error);
        });
    };

    if (image) {
      openSelectionDialog({
        image,
        printId,
        canvas,
        printTransform: null,
        onConfirm,
      });
    }
  };

  const onError = (error: Error) => {
    setLoading(false);
    showSnackbar(getErrorMessage(error.message), { severity: 'error' });
  };

  const onSuccess = (imageId: string) => {
    setLoading(false);
    showSnackbar('The artwork is now playing on your canvas');
    setImageId(imageId);
  };

  return (
    <Box className={cn(classes.borderBox, className)}>
      <Box className={classes.content}>
        {isShowTitle && (
          <Box mb={3.5}>
            <Typography fontSize={20} fontWeight={500} lineHeight={1.2}>
              Assign the artwork to play on a canvas
            </Typography>
          </Box>
        )}
        <Box className={classes.table}>
          <Box className={classes.tableHeader}>
            <Box className={classes.canvasCol}>
              <Typography fontSize={11}>CANVAS</Typography>
            </Box>
            <Box className={classes.statusCol}>
              <Typography fontSize={11}>STATUS</Typography>
            </Box>
            <Box className={classes.assignCol} />
          </Box>

          <Box className={cn(classes.tableBody, bodyTableStyle)}>
            {canvases.map(canvas => {
              return (
                <CanvasItem
                  key={canvas.uid}
                  canvas={canvas}
                  onAssignArtwork={onAssignArtwork}
                  isLoading={isLoading}
                  playingImageId={playingImageId}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
      {isShowBottomSection && (
        <Box className={classes.bottomSection}>
          <Typography fontSize={14}>
            You have another device to add?{' '}
            <strong
              className={classes.pairOtherCanvas}
              onClick={onPairOtherCanvasClick}>
              Pair with another device
            </strong>
          </Typography>
        </Box>
      )}
      {data && (
        <ImageSelectionDialog
          image={data.image}
          printId={data.printId}
          canvas={data.canvas}
          printTransform={data.printTransform}
          open={isOpenSelectionDialog}
          onClose={() => closeSelectionDialog(true)}
          onConfirm={data.onConfirm}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    borderBox: {
      minWidth: 450,
      height: 420,
      marginTop: 'auto',
      border: '1px solid #CFCFCF',
      borderRadius: '3px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: theme.spacing(4, 3),
      [mobile]: {
        minWidth: 300,
      },
    },
    content: {},
    table: {
      display: 'flex',
      flexDirection: 'column',
    },
    tableBody: {
      height: 240,
      overflowY: 'auto',
      [mobile]: {
        maxHeight: 320,
        height: 'auto',
      },
    },
    tableHeader: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: theme.spacing(1.5),
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(2.5),
      borderTop: '1px solid #D8D8D8',
      alignItems: 'center',
    },
    canvasCol: {
      flex: 1,
    },
    statusCol: {
      width: 60,
      textAlign: 'center',
    },
    assignCol: {
      width: 160,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    activeDot: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: '#2FBE34',
      display: 'inline-block',
      cursor: 'pointer',
    },
    assignImageButton: {
      fontSize: '0.75rem',
      height: 35,
      borderRadius: 30,
      padding: theme.spacing(1, 1.5),
    },
    buttonIcon: {
      width: 17,
      height: 18,
      marginRight: theme.spacing(1),
    },
    tooltip: {
      backgroundColor: 'transparent',
      padding: 0,
    },
    tooltipContent: {
      padding: theme.spacing(1.5, 2),
      maxWidth: 150,
    },
    bottomSection: {
      marginTop: theme.spacing(2),
      [mobile]: {
        marginTop: theme.spacing(10),
        marginRight: theme.spacing(6),
      },
    },
    pairOtherCanvas: {
      fontWeight: 500,
      cursor: 'pointer',
      [mobile]: {
        display: 'block',
      },
    },
  });
});

export default SelectCanvasBox;
