import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

interface Props {
  isOpen: boolean;
  toggleButton: (isOpen: boolean) => void;
}

const HamburgerIcon = (props: Props) => {
  const { isOpen, toggleButton } = props;
  const classes = useStyles();
  const activeClass = isOpen ? ' active' : '';

  return (
    <button
      className={classes.hamburger + activeClass}
      onClick={() => toggleButton && toggleButton(!isOpen)}>
      <div className={classes.hamburgerInner}>
        <div className={classes.hamburgerBox} />
      </div>
    </button>
  );
};

const useStyles = makeStyles(() => {
  return createStyles({
    hamburger: {
      'display': 'inline-block',
      'cursor': 'pointer',
      'transition': 'opacity, filter 0.15s linear',
      'font': 'inherit',
      'color': 'inherit',
      'textTransform': 'none',
      'backgroundColor': 'transparent',
      'border': 0,
      'height': '16px',
      'margin': 0,
      'width': '100%',
      'position': 'relative',
      'overflow': 'visible',
      '&:focus': {
        outline: 'none',
      },
      '&.active $hamburgerInner': {
        'transform': 'translate3d(0, 7px, 0) rotate(45deg)',
        '&::before': {
          transform: 'rotate(-45deg) translate3d(-6px, -6px, 0)',
          opacity: 0,
        },
        '&::after': {
          transform: 'translate3d(0, -14px, 0) rotate(-90deg)',
        },
      },
      '& $hamburgerInner': {
        'top': '1px',
        '&::before': {
          top: '7px',
          transition: 'transform, opacity 0.15s ease',
        },
        '&::after': {
          top: '14px',
        },
      },
    },
    hamburgerInner: {
      'display': 'block',
      'top': '50%',
      'marginTop': '-1px',
      'width': '100%',
      'height': '1px',
      'backgroundColor': '#000',
      'borderRadius': 0,
      'position': 'absolute',
      'transition': 'transform 0.15s ease',
      '&::before': {
        content: "''",
        display: 'block',
        top: '-6px',
        width: '100%',
        height: '1px',
        backgroundColor: '#000',
        borderRadius: 0,
        position: 'absolute',
        transition: 'transform 0.15s ease',
      },
      '&::after': {
        content: "''",
        display: 'block',
        bottom: '-6px',
        width: '100%',
        height: '1px',
        backgroundColor: '#000',
        borderRadius: 0,
        position: 'absolute',
        transition: 'transform 0.15s ease',
      },
    },
    hamburgerBox: {
      width: '100%',
      height: '16px',
      display: 'inline-block',
      position: 'relative',
    },
  });
});

export default HamburgerIcon;
