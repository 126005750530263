import { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import { useFirebaseContext } from 'shell/firebase';
import { ImageData } from 'constants/data';

export const useGetArtistAuthors = (
  images: ImageData[]
): { artists?: Record<string, string> | null; isLoading: boolean } => {
  const firebase = useFirebaseContext();
  const [artists, setArtists] = useState<Record<string, string> | null>();
  useEffect(() => {
    if (images) {
      const listArtist = uniqBy(images, 'artist');
      const promises = listArtist.map((image: ImageData) => {
        return firebase.userRef(image.artist).once('value', snapshot => {
          if (snapshot) {
            return snapshot;
          }
        });
      });

      Promise.all(promises)
        .then(snapshots => {
          if (snapshots && snapshots.length !== 0) {
            const artist: Record<string, string> = {};
            snapshots.forEach(snapshot => {
              if (snapshot.key) {
                artist[snapshot.key] = snapshot.val()?.fullName || '';
              }
            });
            setArtists(artist);
          }
        })
        .catch(error => {
          setArtists(null);
          console.error(error);
        });
    }
  }, [images, firebase, setArtists]);
  return { artists, isLoading: typeof artists === 'undefined' };
};
