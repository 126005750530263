import * as ROUTES from 'constants/routes';
import { IMenuItem } from './Types';
import { FeatureFlagEnum } from 'constants/common';
export const ACCOUNT_MENU: IMenuItem[] = [
  {
    label: 'Upload Artwork',
    path: ROUTES.ACCOUNT_UPLOAD,
  },
  {
    label: 'Profile',
    path: ROUTES.ACCOUNT_PROFILE,
  },
  {
    label: 'Subscription',
    path: ROUTES.ACCOUNT_MEMBER_SHIP,
  },
  {
    label: 'My Collection',
    path: ROUTES.ACCOUNT_IMAGES,
  },
  {
    label: 'Canvas',
    path: ROUTES.ACCOUNT_CANVASES,
  },
  {
    label: 'Sell Artworks',
    path: ROUTES.ACCOUNT_SELL_ARTWORKS,
  },
  {
    label: 'Manage Uploaded Images',
    path: ROUTES.ACCOUNT_UPLOADED_IMAGES,
  },
  {
    label: 'Commerce',
    path: ROUTES.ACCOUNT_COMMERCE,
  },
  {
    label: 'Invite Your Friends',
    path: ROUTES.ACCOUNT_INVITE_FRIENDS,
    feature: FeatureFlagEnum.InviteFriends,
  },
];

export const ADMIN_MENU: IMenuItem[] = [
  {
    label: 'Admin: Users',
    path: ROUTES.ADMIN_USER_LIST,
  },
  {
    label: 'Admin: Images',
    path: ROUTES.ADMIN_IMAGES,
  },
  {
    label: 'Admin: Beta invites',
    path: ROUTES.ADMIN_BETA_INVITES,
  },
  {
    label: 'Admin: Featured Images',
    path: ROUTES.ADMIN_FEATURED_IMAGES,
  },
  {
    label: 'Admin: Homepage Config',
    path: ROUTES.ADMIN_HOMEPAGE_CONFIG,
  },
  {
    label: 'Admin: Exhibitions',
    path: ROUTES.ADMIN_EXHIBITION_LIST,
  },
  {
    label: 'Admin: Venues',
    path: ROUTES.ADMIN_VENUES,
  },
  {
    label: 'Admin: Hosted Venues',
    path: ROUTES.ADMIN_HOSTED_VENUES,
  },
  {
    label: 'Admin: Manage gifts',
    path: ROUTES.ADMIN_GIFT,
  },
  {
    label: 'Admin: Partners Config',
    path: ROUTES.ADMIN_PARTNERS,
  },
  {
    label: 'Admin: Query tool',
    path: ROUTES.ADMIN_QUERY_TOOL,
  },
];
