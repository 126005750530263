import { commonErrors, getErrorMessage } from 'constants/errors';
import * as ROUTES from 'constants/routes';
import pw from 'a-promise-wrapper';
import { UserData } from 'constants/data';
import { useStripeConnect } from 'utils/requests';
import { useAuthContext } from 'shell/session';

export interface ConnectToStripeProps {
  success: boolean;
  message: string;
  value?: string;
}

export const useConnectToStripe = () => {
  const auth = useAuthContext();
  const doStripeConnect = useStripeConnect();

  return async (payload?: any): Promise<ConnectToStripeProps> => {
    const user = auth.user as UserData;
    const { stripeConnect } = user;
    const { id: stripeConnectId } = stripeConnect || {};
    const { country } = payload || {};
    if (!stripeConnectId && !country) {
      return {
        success: false,
        message: getErrorMessage(commonErrors.INVALID_PARAMS_ERROR),
      };
    }

    const refreshUrl = `${window.location.origin}${ROUTES.ACCOUNT_SELL_ARTWORKS}`;
    const { data, error } = await pw(
      doStripeConnect({
        country: stripeConnectId ? '' : country || '',
        refreshUrl,
        returnUrl: refreshUrl,
      })
    );
    if (error) {
      return { success: false, message: getErrorMessage(error.message) };
    }

    if (!data?.data?.url) {
      return { success: false, message: getErrorMessage('E_003') };
    }
    return { success: true, value: data.data.url, message: '' };
  };
};
