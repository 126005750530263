import React, { useEffect, useState } from 'react';
import { useLoadHeroMedias } from 'utils/requests';
import ImageBox from 'modules/imageBox';
import { makeStyles, createStyles, Theme, Box } from '@material-ui/core';
import Loader from 'modules/loader/Loader';
import { mobileMediaQuery } from 'utils/common';
import { useIsMobile } from 'utils/hooks';

const HeroMedia = () => {
  const { images, isLoading } = useLoadHeroMedias();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const [image, setImage] = useState<string>();

  useEffect(() => {
    const getRandomInt = (max: number) => {
      return Math.floor(Math.random() * max);
    };
    if (!isLoading && images && images.length > 0) {
      const randomIndex = getRandomInt(images.length);
      setImage(images[randomIndex]);
    }
  }, [isLoading, images]);

  const ratio = isMobile ? '4:3' : '2:3';

  return (
    <Box className={classes.heroMedia}>
      {isLoading ? (
        <Loader />
      ) : (
        image && <ImageBox src={image} alt={image} key={image} ratio={ratio} />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    heroMedia: {
      width: '385px',
      minWidth: '385px',
      height: '534px',
      overflow: 'hidden',
      [mobile]: {
        width: '100%',
        minWidth: 'auto',
        height: '250px',
        marginBottom: '20px',
      },
    },
  });
});

export default HeroMedia;
