import React, { useState } from 'react';
import cs from 'classnames';
import {
  Dialog,
  DialogProps,
  makeStyles,
  createStyles,
  Theme,
  DialogContent,
  IconButton,
  Box,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Typography from 'modules/typography';
import { useIsMobile } from 'utils/hooks';
import { mobileMediaQuery } from 'utils/common';
import { ImageData } from 'constants/data/image.data';
import CloseIcon from '@material-ui/icons/Close';
import { useBuyImage } from 'utils/requests';
import { useModal } from 'modules/modals';
import OptimizedImage from 'modules/OptimizedImage';
import { useLoadTransferOriginalGasFee } from './helpers';
import { getErrorMessage } from 'constants/errors';
import { useLocation } from 'react-router-dom';
import {
  Agreement,
  AgreementNote,
  AgreementError,
} from 'modules/dialogs/ConfirmPurchasePrintDialog';

import UIButton from 'ui-components/Button';
import { useLayoutState } from 'utils/contexts/LayoutState';
import AIPoweredBy from 'modules/aIPoweredBy';

interface Props extends Omit<DialogProps, 'onClose'> {
  image: ImageData;
  isGift?: boolean;
  onClose: () => void;
}

const ConfirmPurchaseOriginalDialog = ({
  image,
  isGift = false,
  onClose,
  ...dialogProps
}: Props) => {
  const { showSnackbar } = useModal();
  const { priceAmount: price, name, path: imagePath } = image;
  const buyImage = useBuyImage();
  const isMobile = useIsMobile();
  const classes = useStyles();
  const { pathname } = useLocation();
  const [isCheckingOut, setIsCheckingOut] = useState<boolean>(false);
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const [agreedError, setAgreedError] = useState<string>('');

  const { gasFee } = useLoadTransferOriginalGasFee(image.uid);
  const { isHostedVenue } = useLayoutState();

  const handleClose = (reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (!reason) {
      onClose();
    }
  };

  const handleClickBuy = () => {
    setAgreedError('');
    if (!isAgreed) {
      setAgreedError(
        'This checkbox is required to continue with your purchase'
      );
      return;
    }
    setIsCheckingOut(true);
    buyImage({ imageId: image.uid, isAnonymous, pathname })
      .then(redirectUrl => {
        setIsCheckingOut(false);
        window.location.href = redirectUrl;
      })
      .catch(error => {
        const message = getErrorMessage(error.message);

        setIsCheckingOut(false);
        showSnackbar(message, {
          severity: 'error',
        });
      });
  };

  const title = isGift ? 'Buy as a Gift' : 'Buy Artwork';

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullWidth
      {...dialogProps}
      onClose={(event, reason) => handleClose(reason)}
      fullScreen={isMobile}>
      <DialogContent className={classes.content}>
        <Box className={classes.leftColumn}>
          <Typography className={classes.buyArtwork}>{title}</Typography>
          <Typography className={classes.imageName}>{name}</Typography>
          <Typography className={classes.description}>
            Purchase this artwork to receive a unique visual collectible that
            you can trade on our blockchain-based marketplace. Ownership also
            allows you to display the work on your screens and devices using our
            Digital Canvas app.
          </Typography>
          <Box className={classes.infoRow}>
            <Typography className={classes.label}>Buy</Typography>
            <Typography className={classes.value}>Original</Typography>
          </Box>
          <Box className={classes.infoRow}>
            <Typography className={classes.label}>Price</Typography>
            <Typography className={classes.value}>€{price}</Typography>
          </Box>
          <Box className={classes.infoRow}>
            <Typography className={classes.label}>Gas fee</Typography>
            <Typography className={classes.value}>€{gasFee}</Typography>
          </Box>
          <Box mb={2}>
            <Divider />
          </Box>
          <Box className={cs(classes.infoRow, classes.discount)}>
            <Typography className={classes.label}>Discount</Typography>
            <Typography className={classes.value}>-€{gasFee}</Typography>
          </Box>
          <Box className={classes.infoRow}>
            <Typography className={classes.label}>Total</Typography>
            <Typography className={cs(classes.label, classes.value)}>
              €{price}
            </Typography>
          </Box>
          <Typography className={classes.totalDescription}>
            Order totals include VAT
          </Typography>
          <FormControlLabel
            classes={{
              root: classes.checkboxRoot,
              label: classes.checkboxLabel,
            }}
            control={
              <Checkbox
                checked={isAnonymous}
                onChange={e => {
                  setIsAnonymous(e.target.checked);
                }}
              />
            }
            label="Don't show my name in the record of this purchase."
            labelPlacement="end"
          />
          <FormControlLabel
            classes={{
              root: cs(classes.checkboxRoot, {
                [classes.checkboxRootError]: !!agreedError,
              }),
              label: classes.checkboxLabel,
            }}
            control={
              <Checkbox
                checked={isAgreed}
                onChange={e => {
                  if (e.target.checked) {
                    setAgreedError('');
                  }
                  setIsAgreed(e.target.checked);
                }}
              />
            }
            label={<Agreement />}
            labelPlacement="end"
          />
          {agreedError && <AgreementError agreedError={agreedError} />}
          <AgreementNote />
          <UIButton
            variant="primary"
            size="medium"
            onClick={handleClickBuy}
            disabled={isCheckingOut}
            className={classes.buttonBuy}
            loading={isCheckingOut}
            text="Buy Artwork"
          />
        </Box>
        <Box className={classes.rightColumn}>
          <IconButton
            className={classes.buttonClose}
            onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
          <OptimizedImage path={imagePath} className={classes.imagePreview} />
        </Box>
      </DialogContent>
      {isHostedVenue && (
        <Box mr={isMobile ? 3 : 5} my={1.5} textAlign="right">
          <AIPoweredBy />
        </Box>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    root: {},
    paper: {
      color: '#000',
      minHeight: 600,
      height: 'auto',
      maxWidth: 1000,
      [mobile]: {
        height: '100%',
        minHeight: '100%',
      },
    },
    content: {
      'display': 'flex',
      'flexDirection': 'row',
      'padding': 0,

      '&:first-child': {
        paddingTop: 0,
      },
      [mobile]: {
        flexDirection: 'column-reverse',
      },
    },
    leftColumn: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: theme.spacing(6, 2, 6, 7),
      width: 500,
      [mobile]: {
        padding: theme.spacing(0, 2, 4),
      },
    },
    rightColumn: {
      position: 'relative',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(12, 7),
      [mobile]: {
        width: '100%',
      },
    },
    buyArtwork: {
      fontFamily: ['Fraunces', 'Times', 'Times New Roman', 'serif'].join(', '),
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1.25,
      fontWeight: 300,
      marginBottom: theme.spacing(1),
    },
    imageName: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(36),
      lineHeight: 1.2,
      marginBottom: theme.spacing(2),
    },
    description: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.5,
      marginBottom: theme.spacing(2.5),
      maxWidth: 365,
    },
    buttonClose: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),

      [mobile]: {
        right: theme.spacing(2),
      },
    },
    infoRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      gap: theme.spacing(2),
    },
    label: {
      fontSize: theme.typography.pxToRem(11),
      lineHeight: 1.2,
      textTransform: 'uppercase',
      width: 90,
      letterSpacing: '12%',
    },
    value: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.5,
    },
    discount: {
      color: '#129817',
    },
    totalDescription: {
      fontSize: theme.typography.pxToRem(11),
      color: '#777',
    },
    checkboxRoot: {
      marginTop: 'auto',
      marginBottom: theme.spacing(1),
    },
    checkboxRootError: {
      '& svg': {
        color: '#B60404',
      },
    },
    checkboxLabel: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.6,
      color: 'rgba(3, 3, 3, 0.8)',
    },
    buttonBuy: {
      alignSelf: 'flex-start',
    },
    imagePreview: {
      maxWidth: '100%',
      maxHeight: 400,
    },
  });
});

export default ConfirmPurchaseOriginalDialog;
