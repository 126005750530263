import { useMemo, useState } from 'react';
import { Box, Paper, CircularProgress } from '@material-ui/core';
import Typography from 'modules/typography';
import { FilterValues } from 'modules/filter';
import ImagesFilter from './ImagesFilter';
import { filterImages } from './helpers';
import AccountLayout from 'views/account/AccountLayout';
import {
  AllImagesProvider,
  useAllImagesContext,
} from 'utils/contexts/allImagesContext';
import { ArtistsProvider } from 'utils/contexts/artistsContext';
import {
  ImageFieldsProvider,
  useImageFieldsContext,
} from 'utils/contexts/imageFieldsContext';
import UploadedImagesTable from './UploadedImagesTable';
import * as ROUTES from 'constants/routes';
import { useAuthContext } from 'shell/session';
import { Redirect } from 'react-router-dom';
import { UserData } from 'constants/data/user.data';

interface ImageListProps {
  user: UserData;
}

const defaultFilterValues = {
  colorArr: [],
  brightnessArr: [],
  abstractionArr: [],
  catalogArr: [],
  associationArr: [],
  isPublished: -1,
};

const ImageList = ({ user }: ImageListProps) => {
  const [activeFilter, setActiveFilter] = useState<FilterValues>();
  const { checkIsPublishable } = useImageFieldsContext();
  const { allImages } = useAllImagesContext();
  const userId = user?.uid;

  const userImages = useMemo(
    () =>
      allImages?.filter(image => image.artist === userId && !image.archived),
    [allImages, userId]
  );

  const filteredImages = useMemo(() => {
    if (activeFilter && userImages) {
      return filterImages(userImages, activeFilter, { checkIsPublishable });
    }
    return [];
  }, [activeFilter, userImages, checkIsPublishable]);

  return (
    <AccountLayout>
      <Box>
        <Box mb={4}>
          <Typography variant="h5" fontWeight={500} fontSize="1.75rem">
            Uploaded images
          </Typography>
        </Box>
        <Paper>
          <ImagesFilter
            originalList={userImages || []}
            onChange={setActiveFilter}
            defaultValues={defaultFilterValues}
          />
          <UploadedImagesTable data={filteredImages} />
        </Paper>
      </Box>
    </AccountLayout>
  );
};

type UploadedImagesPageProps = {};
const UploadedImagesPage = (props: UploadedImagesPageProps) => {
  const authContext = useAuthContext();
  const user = authContext.user as UserData | undefined | null;

  if (user === null || (user !== undefined && !user.canUpload)) {
    return <Redirect to={ROUTES.NOT_FOUND} />;
  }
  if (user === undefined) {
    return <CircularProgress />;
  }
  return (
    <AllImagesProvider>
      <ArtistsProvider>
        <ImageFieldsProvider>
          <ImageList {...props} user={user} />
        </ImageFieldsProvider>
      </ArtistsProvider>
    </AllImagesProvider>
  );
};

export default UploadedImagesPage;
