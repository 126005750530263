import React, { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';

import Icon from 'ui-components/Icon';
import type { IconId } from 'ui-components/Icon';

import styles from './styles.module.scss';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'secondary-inverted'
  | 'tertiary'
  | 'tertiary-outline'
  | 'text'
  | 'minimal';
type ButtonSize = 'small' | 'medium' | 'large';

type BaseButtonProps = {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  size: ButtonSize;
  rounded?: boolean;
  fullWidth?: boolean;
  onClick?: React.MouseEventHandler;
};

type MinimalButtonProps = {
  variant: 'minimal';
  icon: IconId;
  text?: undefined;
};

type TextButtonProps = {
  variant: Exclude<ButtonVariant, 'minimal'>;
  icon?: IconId;
  text: string;
};

type ButtonProps = (MinimalButtonProps | TextButtonProps) &
  BaseButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonIconProps = Pick<ButtonProps, 'loading' | 'icon'>;

const ButtonIcon = ({ loading, icon }: ButtonIconProps) => {
  if (loading) {
    return <Icon icon={'spinner'} size={20} />;
  }

  if (icon) {
    return <Icon icon={icon} size={20} />;
  }

  return null;
};

const Button = ({
  className,
  disabled,
  text,
  onClick,
  loading,
  size,
  variant,
  rounded,
  icon,
  fullWidth,
  ...buttonProps
}: ButtonProps) => {
  const iconOnly = variant === 'minimal';

  return (
    <button
      className={cn(
        className,
        styles.button,
        styles[`button--v-${variant}`],
        styles[`button--s-${size}`],
        {
          [styles['button--iconOnly']]: iconOnly,
          [styles['button--shape-rounded']]: rounded,
          [styles['button--fullWidth']]: fullWidth,
        }
      )}
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}>
      <ButtonIcon loading={loading} icon={icon} />
      {iconOnly || loading ? null : <span>{text}</span>}
    </button>
  );
};

export default Button;
