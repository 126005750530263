import React from 'react';
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  List,
  ListItem,
} from '@material-ui/core';
import * as ROUTES from 'constants/routes';
import { Link as RouterLink } from 'react-router-dom';
import UILink from 'ui-components/Link';
import { mobileMediaQuery } from 'utils/common';

const STATIC_PAGES = [
  {
    id: 'Privacy',
    title: 'Privacy',
    path: ROUTES.PRIVACY,
  },
  {
    id: 'Terms',
    title: 'Terms',
    path: ROUTES.TERMS,
  },
  {
    id: 'Imprint',
    title: 'Imprint',
    path: ROUTES.IMPRINT,
  },
];

const FooterBottom = () => {
  const classes = useStyles();

  return (
    <Box className={classes.footerBottom}>
      <Box className={classes.leftContent}>
        <Typography className={classes.leftTitle}>
          {`© ${new Date().getFullYear()} Active Image GmbH`}
        </Typography>
        <List className={classes.linkList}>
          {STATIC_PAGES.map(page => {
            return (
              <ListItem className={classes.linkItem} key={page.id}>
                <UILink
                  component={RouterLink}
                  href={page.path}
                  className={classes.link}>
                  {page.title}
                </UILink>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

export const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    footerBottom: {
      'display': 'flex',
      'flexDirection': 'row',
      'alignItems': 'center',
      'justifyContent': 'space-between',
      [mobile]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      '&$homepageFooter': {
        '@media (min-width:1260px)': {
          maxWidth: 1920,
          paddingLeft: '130px',
          paddingRight: '130px',
        },
        '@media (max-width:1259px)': {
          maxWidth: 1040,
          paddingLeft: '20px',
          paddingRight: '20px',
        },
      },
    },
    homepageFooter: {},
    leftContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [mobile]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    logo: {
      minHeight: '50px',
      marginRight: theme.spacing(8),
    },
    leftTitle: {
      fontSize: theme.typography.pxToRem(12),
      marginRight: theme.spacing(8),
      [mobile]: {
        marginRight: theme.spacing(3),
      },
    },
    linkList: {
      padding: 0,
      margin: 0,
    },
    linkItem: {
      'marginRight': theme.spacing(8),
      'display': 'inline',
      'padding': 0,
      [mobile]: {
        'marginRight': theme.spacing(3),
        '&:last-child': {
          marginRight: 0,
        },
      },
      '&:last-child': {
        marginRight: 0,
      },
      '&:empty': {
        display: 'none',
      },
    },
    link: {
      'fontSize': theme.typography.pxToRem(12),
      'color': '#FFF',
      '&:hover': {
        color: '#FFF',
      },
    },
  });
});

export default FooterBottom;
