import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
  Box,
  makeStyles,
  createStyles,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { useLoadCanvasesByUser } from 'utils/requests';
import Typography from 'modules/typography';
import EmptyCanvasMessageBox from '../canvases/EmptyCanvasMessageBox';
import PairingCanvasBox from 'modules/pairingCode/PairingCanvasBox';
import { mobileMediaQuery } from 'utils/common';
import { ReactComponent as CloseIcon } from 'static/images/close-icon.svg';
import { useIsMobile } from 'utils/hooks';
import SelectCanvasBox from 'modules/selectCanvases';
import { ImageData, UserData, CanvasData } from 'constants/data';
import { useAuthContext } from 'shell/session';

interface Props extends DialogProps {
  onSelectCanvas: (canvas: CanvasData) => void;
  image: ImageData;
  printId: string;
}

const CanvasesDialog = ({
  image,
  printId,
  onSelectCanvas,
  onClose,
  ...dialogProps
}: Props) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const authContext = useAuthContext();
  const user = authContext.user as UserData;
  const { canvases, isLoading } = useLoadCanvasesByUser(user);
  const hasNoCanvas = !canvases || (canvases && canvases.length === 0);
  const [showCanvasBox, setShowCanvasBox] = useState<boolean>(hasNoCanvas);

  const handleClose = () => {
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  };

  return (
    <Dialog
      {...dialogProps}
      fullScreen={isMobile}
      classes={{ paper: classes.dialogPaper }}>
      <DialogTitle>
        <Typography
          fontSize={'1.75rem'}
          fontWeight={500}
          lineHeight={1.2}
          letterSpacing={'-0.02em'}>
          Select a canvas to play artwork
        </Typography>
      </DialogTitle>

      <IconButton onClick={handleClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {hasNoCanvas && (
              <Box>
                <Box mb={4}>
                  <EmptyCanvasMessageBox />
                </Box>
              </Box>
            )}
            {!hasNoCanvas && showCanvasBox && (
              <Box>
                <SelectCanvasBox
                  canvases={canvases}
                  image={image}
                  printId={printId}
                  onPairOtherCanvasClick={() => setShowCanvasBox(false)}
                  onClose={handleClose}
                  isShowBottomSection={false}
                  isShowTitle={false}
                  className={classes.selectCanvasBox}
                  bodyTableStyle={classes.selectCanvasBoxTableBody}
                />
                <Box className={classes.bottomSection}>
                  <Typography fontSize={14}>
                    Do you have another device to add?{' '}
                    <strong
                      className={classes.pairOtherCanvas}
                      onClick={() => setShowCanvasBox(false)}>
                      Pair with another device
                    </strong>
                  </Typography>
                </Box>
              </Box>
            )}
            <Box display={hasNoCanvas || !showCanvasBox ? 'block' : 'none'}>
              <PairingCanvasBox
                image={image}
                printId={printId}
                onNext={handleClose}
              />
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => {
  const mobile = mobileMediaQuery(theme);

  return createStyles({
    dialogPaper: {
      maxWidth: 500,
      padding: theme.spacing(3.5),
      [mobile]: {
        padding: theme.spacing(1),
        maxWidth: '100%',
        height: 'auto',
        maxHeight: '80vh',
      },
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(3.5),
      right: theme.spacing(3.5),
      zIndex: 2,
      [mobile]: {
        top: theme.spacing(3),
        right: theme.spacing(1),
      },
    },
    selectCanvasBox: {
      'marginTop': theme.spacing(2),
      'paddingTop': theme.spacing(2),
      'paddingBottom': theme.spacing(2),
      'border': 'none',
      'minWidth': 0,
      '&tableBody': {
        height: '100%',
      },
    },
    selectCanvasBoxTableBody: {
      height: '100%',
      maxHeight: 240,
    },
    pairOtherCanvas: {
      fontWeight: 500,
      cursor: 'pointer',
    },
    bottomSection: {
      marginTop: theme.spacing(2),
      [mobile]: {
        marginTop: theme.spacing(1),
      },
    },
  });
});

export default CanvasesDialog;
