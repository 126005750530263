import * as ROUTES from 'constants/routes';
import { IMenuItem } from './Types';

export const MAIN_MENU: IMenuItem[] = [
  {
    label: 'Verify',
    path: ROUTES.WATERMARK_VERIFICATION,
    isButton: true,
  },
  {
    label: 'Exhibitions',
    path: ROUTES.EXHIBITIONS,
  },
  {
    label: 'Venues',
    path: ROUTES.VENUES,
  },
  {
    label: 'About',
    path: ROUTES.ABOUT,
  },
];
