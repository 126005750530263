import React from 'react';

import FirebaseContext, { firebase } from './context';

type FirebaseProviderProps = React.PropsWithChildren<{}>;

const FirebaseProvider = ({ children }: FirebaseProviderProps) => {
  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
