import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Location } from 'history';
import { useAuthContext } from 'shell/session';
import { createStyles, makeStyles, Theme, List, Box } from '@material-ui/core';
import { mobileMediaQuery } from 'utils/common';
import { UserData } from 'constants/data/user.data';
import EditableText from 'modules/editableText';
import { SIGN_UP, SIGN_IN, ACCOUNT_IMAGES } from 'constants/routes';
import AnimatedListItem from 'modules/topBar/AnimatedListItem';
import { useGetSignUpPath } from 'utils/hooks';
import { getTopBarKey } from '../helpers';

const MENU_1_TO_5 = new Array(5).fill(1).map((_, index) => index + 1);

const NavMain = ({
  moduleId,
  venueId,
}: {
  moduleId: string;
  venueId: string;
}) => {
  const authContext = useAuthContext();
  const user = authContext.user as UserData | undefined | null;
  const classes = useStyles();

  return (
    <List className={classes.navMain}>
      {MENU_1_TO_5.map((item: number) => {
        const key = getTopBarKey(venueId, moduleId, item);
        return (
          <EditableText
            key={key.menuItemTextKey}
            textKey={key.menuItemTextKey}
            linkKey={key.menuItemLinkKey}
            defaultText={''}
            defaultLink={'#'}
            render={({ text, link }) => {
              if (text) {
                return <AnimatedListItem to={link} label={text} />;
              }
              return null;
            }}
          />
        );
      })}
      {user && (
        <Box className={classes.myArtworksItem}>
          <AnimatedListItem to={ACCOUNT_IMAGES} label="My Collection" />
        </Box>
      )}
      {!user && <GuestMenuItems />}
    </List>
  );
};

const GuestMenuItems = () => {
  const getSignUpPath = useGetSignUpPath();
  const location = useLocation<{ from: Location }>();
  const isSignUpPage = matchPath(location.pathname, { path: SIGN_UP });
  const isSignInPage = matchPath(location.pathname, { path: SIGN_IN });

  let previousLocation: any = location;
  if (isSignInPage || isSignUpPage) {
    // If the current page is Sign In/Sign Up, forward the previous location
    previousLocation = location.state?.from;
  }

  const signInLink = (
    <AnimatedListItem
      to={{
        pathname: SIGN_IN,
        state: {
          from: previousLocation,
        },
      }}
      label="Sign In"
    />
  );

  const signUpLink = (
    <AnimatedListItem
      to={getSignUpPath(previousLocation)}
      label="Create Account"
    />
  );

  return (
    <>
      {!isSignInPage && signInLink}
      {!isSignUpPage && signUpLink}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    navMain: {
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
      [mobile]: {
        width: '100%',
        flexDirection: 'column',
      },
    },
    myArtworksItem: {
      borderLeft: '1px solid #CFCFCF',
    },
  });
});

export default NavMain;
