import { Badge, createStyles, IconButton, makeStyles } from '@material-ui/core';
import { ReactComponent as GiftIcon } from 'static/images/giftIcon.svg';
import { useGiftIndicator } from 'utils/hooks/useLocalStorage';

const getUrl = (id: string, otp?: string) => {
  if (!otp) {
    return `/gift/${id}`;
  }

  return `/gift/${id}/${otp}`;
};

const GiftIndicator = () => {
  const classes = useStyles();
  const [giftIndicator] = useGiftIndicator();

  if (!giftIndicator) {
    return null;
  }

  const url = getUrl(giftIndicator.id, giftIndicator.otp);

  return (
    <IconButton component="a" href={url}>
      <Badge
        overlap="rectangular"
        color="secondary"
        variant="dot"
        invisible={false}
        classes={{
          dot: classes.dot,
        }}>
        <GiftIcon />
      </Badge>
    </IconButton>
  );
};

const useStyles = makeStyles(() => {
  return createStyles({
    dot: {
      backgroundColor: '#16CE13',
      minWidth: '6px',
      width: '6px',
      height: '6px',
      top: '-3px',
      right: '-4px',
    },
  });
});

export default GiftIndicator;
