import { useState, useEffect } from 'react';
import { useFirebaseContext } from 'shell/firebase';
import { Print as PrintData } from 'constants/data/print.data';

interface PrintsState {
  allPrint?: PrintData[] | null;
  availablePrints?: PrintData[] | null;
}

/**
 * TODO
 *
 * This is a bug, need to update logic at `/admin/users/${userId}`
 * If the `userId` is an artist, we will show list of images
 * (`src/views/admin/users/ImageList.tsx`).
 * Need tp update the logic to streaming original
 */
export const useLoadPrintsOfImage = (imageId: string) => {
  const firebase = useFirebaseContext();
  const [prints, setPrints] = useState<PrintsState>({});

  useEffect(() => {
    if (firebase !== null) {
      const query = firebase
        .printsRef()
        .orderByChild('imageId')
        .equalTo(imageId);
      const stopListener = query.on('value', snapshot => {
        const value = snapshot.val();
        if (value === null) {
          setPrints({
            allPrint: null,
            availablePrints: null,
          });
          return;
        }

        const result = Object.values<PrintData>(value).reduce<PrintsState>(
          (acc, print) => {
            if (!print.tenant) {
              acc.availablePrints?.push(print);
            }
            acc.allPrint?.push(print);
            return acc;
          },
          {
            allPrint: [],
            availablePrints: [],
          }
        );

        setPrints(result);
      });
      return () => query.off('value', stopListener);
    }
  }, [firebase, imageId]);

  return { ...prints };
};
