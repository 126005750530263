import { IVenue } from 'constants/data/venue.data';
import { UserData } from 'constants/data/user.data';

export const userCanViewVenue = (
  venue?: IVenue | null,
  user?: UserData | null
) => {
  if (user?.isAdmin) {
    return true;
  }
  return isAvalableVenue(venue);
};

export const isAvalableVenue = (venue?: IVenue | null) => {
  return venue?.published === true;
};

/**
 * Check if the URL contains specific token for specific venue
 * To allow access in case when venue isn't published
 */
export const isVenueWithSecretToken = (venue?: IVenue | null) => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  return venue?.secretToken === token;
};
