import {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useAuthContext } from 'shell/session';
import { UserData } from 'constants/data/user.data';
export interface ModesContextProps {
  isEditorMode?: boolean;
  exitEditorMode: () => void;
}

/**
 * This context provides the consumers with the ability to enable/disable system modes
 */
const ModesContext = createContext<ModesContextProps>({
  isEditorMode: false,
  exitEditorMode: () => {},
});

export const ModesProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [isEditorMode, setIsEditorMode] = useState<boolean>(false);
  const { user, isSignedIn } = useAuthContext();
  const isAdmin = !!(user as UserData | undefined)?.isAdmin;

  useHotkeys(
    'ctrl+c, ctrl+alt+c',
    () => {
      setIsEditorMode(v => !v);
    },
    { enabled: isAdmin }
  );

  useEffect(() => {
    if (!isSignedIn && isEditorMode) {
      setIsEditorMode(false);
    }
  }, [isSignedIn, isEditorMode]);

  const exitEditorMode = useCallback(() => setIsEditorMode(false), []);

  const contextValue = useMemo(
    () => ({
      isEditorMode,
      exitEditorMode,
    }),
    [isEditorMode, exitEditorMode]
  );

  return (
    <ModesContext.Provider value={contextValue}>
      {children}
    </ModesContext.Provider>
  );
};

export const useModesContext = () => useContext(ModesContext);
