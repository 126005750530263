import {
  Box,
  createStyles,
  makeStyles,
  CircularProgress,
  Theme,
} from '@material-ui/core';
import { useState, useCallback } from 'react';
import { UserData } from 'constants/data';
import Typography from 'modules/typography';
import { useAuthContext } from 'shell/session';
import AccountLayout from 'views/account/AccountLayout';
import { SelectCountriesDialog } from 'modules/dialogs';
import { useSnackbar } from 'modules/modals';
import { useModalState } from 'utils/hooks';
import { StripeAccountPayoutStatus } from 'constants/payment';
import SellArtworksAccount from 'views/account/sellArtworks/Account';
import { useConnectToStripe, ConnectToStripeProps } from './helpers';
import ResellDialog from 'views/account/images/new-ui/ResellDialog';

import UIButton from 'ui-components/Button';

interface ActionButtonsProps {
  disabled?: boolean;
  onClickConnect?: () => void;
}

const ActionButtons = (props: ActionButtonsProps) => {
  const { disabled, onClickConnect } = props;
  const classes = useStyles();
  const {
    isOpen: isOpenResellDialog,
    open: openResellDialog,
    close: closeResellDialog,
  } = useModalState();

  return (
    <Box>
      <UIButton
        disabled={disabled}
        className={classes.button}
        variant="primary"
        size="medium"
        onClick={onClickConnect}
        text="Connect Bank Account"
      />
      <UIButton
        className={classes.button}
        variant="secondary"
        size="medium"
        onClick={() => openResellDialog()}
        text="Learn about Reselling"
      />
      {isOpenResellDialog && (
        <ResellDialog
          isPrint={false}
          printId={''}
          imageId={''}
          viewGuidanceOnly
          open={isOpenResellDialog}
          onClose={closeResellDialog}
          lastSoldPrice={0}
        />
      )}
    </Box>
  );
};

const RestrictedButtons = (props: ActionButtonsProps) => {
  const { disabled, onClickConnect } = props;
  const classes = useStyles();

  return (
    <Box>
      <Typography>
        Something went wrong while connect your bank account
      </Typography>
      <UIButton
        disabled={disabled}
        className={classes.button}
        variant="primary"
        size="medium"
        onClick={onClickConnect}
        text="Re-connect Bank Account"
      />
    </Box>
  );
};

const SellArtworkPage = () => {
  const auth = useAuthContext();
  const connectToStripe = useConnectToStripe();
  const user = auth.user as UserData;
  const { stripeConnect } = user;
  const { id: stripeConnectId, status: stripeConnectStatus } =
    stripeConnect || {};
  const {
    isOpen: isOpenDialog,
    open: openDialog,
    close: closeDialog,
  } = useModalState();
  const { showSnackbar, render: snackbarRendering } = useSnackbar();
  const [isConnecting, setIsConnecting] = useState<boolean>(false);

  const handleConnectBankAccount = useCallback(
    async (payload?: any) => {
      setIsConnecting(true);
      const result: ConnectToStripeProps = await connectToStripe(payload);
      if (result.success && result.value) {
        return (window.location.href = result.value);
      }
      setIsConnecting(false);
      showSnackbar(result.message, { severity: 'error' });
    },
    [showSnackbar, connectToStripe]
  );

  const renderStripeConnected = useCallback(() => {
    switch (stripeConnectStatus) {
      case StripeAccountPayoutStatus.Completed:
        return (
          <Typography>
            You have already connected account with Stripe
          </Typography>
        );
      case StripeAccountPayoutStatus.Restricted:
      default:
        return (
          <RestrictedButtons
            onClickConnect={handleConnectBankAccount}
            disabled={isConnecting}
          />
        );
    }
  }, [stripeConnectStatus, isConnecting, handleConnectBankAccount]);

  return (
    <AccountLayout>
      <Box mb={2}>
        <Typography variant="h5" fontWeight={500} fontSize="1.75rem">
          Sell Artworks
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body2">
          As an owner of artworks that you have purchased or created, you can
          sell them to other collectors. In order to collect payments for the
          amount sold, connect your bank account with Stripe, and the amount
          will be transferred to your account.
        </Typography>
      </Box>
      {stripeConnectId ? (
        isConnecting ? (
          <CircularProgress />
        ) : (
          renderStripeConnected()
        )
      ) : (
        <ActionButtons onClickConnect={openDialog} disabled={isConnecting} />
      )}
      <SellArtworksAccount />
      <SelectCountriesDialog
        open={isOpenDialog}
        isLoading={isConnecting}
        onClose={() => closeDialog(true)}
        onNext={handleConnectBankAccount}
      />
      {snackbarRendering}
    </AccountLayout>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(2),
    },
  })
);

export default SellArtworkPage;
