export interface ModuleKeyInputs {
  venueId: string;
  moduleId: string;
}

export interface ConfigurableModuleProps {
  keys: {
    [key: string]: {
      textKey: string;
      defaultText: string;
    };
  };
}

export enum EnumHomePageConfigTitle {
  FeaturedImagesTitle = 'homepage2_featuredImagesSection_title',
  FeaturedStyleTitle = 'homepage2_featuredStylesSection_name',
  FeaturedThemeTitle = 'homepage2_featuredThemesSection_name',
  HowItWorksTitle = 'homepage2_howItWorksSection_supertitle',
  AppIntroduceTitle = 'homepage2_appIntroSection_superTitle',
  BrowseArtworksTitle1 = 'homepage2_explorMore_title_1',
  BrowseArtworksTitle2 = 'homepage2_explorMore_title_2',
  ComingSoonTitle = 'homepage2_ComingSoonSection_title',
  FeaturedArtistsTitle = 'homepage2_artistsSection_subtitle',
  FeaturedExhibitionTitle = 'homepage2_featuredExhibitionSection_title',
  TwoPicturesSection1Title = 'homepage_twoPicturesSection1_title',
  TwoPicturesSection1TitleLink = 'homepage_twoPicturesSection1_title_link',
  TwoPicturesSection2Title = 'homepage_twoPicturesSection2_title',
  TwoPicturesSection2TitleLink = 'homepage_twoPicturesSection2_title_link',
  TwoPicturesSection3Title = 'homepage_twoPicturesSection3_title',
  TwoPicturesSection3TitleLink = 'homepage_twoPicturesSection3_title_link',
  TwoPicturesSection4Title = 'homepage_twoPicturesSection4_title',
  TwoPicturesSection4TitleLink = 'homepage_twoPicturesSection4_title_link',
  NewArtistTitle = 'homepage2_newArtistSection_title',
  FeaturedBrowseArtworksTitle = 'homepage2_featuredBrowseArtworks_title',
  FeaturedBrowseArtworksSubTitle = 'homepage2_featuredBrowseArtworks_sub_title',
}

export const HomePageConfigDefaultTitle: Record<
  EnumHomePageConfigTitle,
  string
> = {
  [EnumHomePageConfigTitle.FeaturedImagesTitle]: 'Showroom',
  [EnumHomePageConfigTitle.FeaturedStyleTitle]: 'Style Name',
  [EnumHomePageConfigTitle.FeaturedThemeTitle]: 'Theme name',
  [EnumHomePageConfigTitle.HowItWorksTitle]: 'How it works',
  [EnumHomePageConfigTitle.AppIntroduceTitle]: 'Digital Canvas App',
  [EnumHomePageConfigTitle.BrowseArtworksTitle1]: 'Browse',
  [EnumHomePageConfigTitle.BrowseArtworksTitle2]: 'Artworks',
  [EnumHomePageConfigTitle.ComingSoonTitle]: 'Coming soon',
  [EnumHomePageConfigTitle.FeaturedArtistsTitle]: 'New Artists',
  [EnumHomePageConfigTitle.FeaturedExhibitionTitle]: 'Exhibition on view',
  [EnumHomePageConfigTitle.TwoPicturesSection1Title]: 'Two Pictures Section 1',
  [EnumHomePageConfigTitle.TwoPicturesSection1TitleLink]: '#',
  [EnumHomePageConfigTitle.TwoPicturesSection2Title]: 'Two Pictures Section 2',
  [EnumHomePageConfigTitle.TwoPicturesSection2TitleLink]: '#',
  [EnumHomePageConfigTitle.TwoPicturesSection3Title]: 'Two Pictures Section 3',
  [EnumHomePageConfigTitle.TwoPicturesSection3TitleLink]: '#',
  [EnumHomePageConfigTitle.TwoPicturesSection4Title]: 'Two Pictures Section 4',
  [EnumHomePageConfigTitle.TwoPicturesSection4TitleLink]: '#',
  [EnumHomePageConfigTitle.NewArtistTitle]: 'New Artist Feature',
  [EnumHomePageConfigTitle.FeaturedBrowseArtworksTitle]: 'Browse',
  [EnumHomePageConfigTitle.FeaturedBrowseArtworksSubTitle]: 'Artworks',
};
