import { useEffect } from 'react';
import { createStyles, makeStyles, Theme, Box } from '@material-ui/core';
import { mobileMediaQuery } from 'utils/common';
import { HeroMedia } from 'modules/media';
import { useAuthContext } from 'shell/session';
import { Location } from 'history';
import * as ROUTES from 'constants/routes';
import { useLocation, useHistory, matchPath } from 'react-router-dom';

const NotRedirectRoutes = [
  ROUTES.SIGN_UP,
  ROUTES.VERIFICATION,
  ROUTES.AUTH_ACTION,
];

const RoutesUseStaticImage = [ROUTES.VERIFICATION, ROUTES.SIGN_UP];

const checkNotRedirectRoute = (pathname: string): boolean =>
  NotRedirectRoutes.some(route => matchPath(pathname, { path: route }));

type AuthLayoutProps = React.PropsWithChildren<{}>;
const AuthLayout = ({ children }: AuthLayoutProps) => {
  const classes = useStyles();
  const { pathname, state: locationState } = useLocation<{ from: Location }>();
  const history = useHistory();
  const { user } = useAuthContext();
  const isLoggedIn = !!user;
  const notRedirectRoute = checkNotRedirectRoute(pathname);
  const isShow = user === null || notRedirectRoute;

  useEffect(() => {
    // Sign up page has a different way to handle this logic
    if (isLoggedIn && !notRedirectRoute) {
      if (locationState) {
        const location = locationState.from;
        history.push(location);
        return;
      }

      history.replace(ROUTES.LANDING);
    }
  }, [isLoggedIn, history, locationState, notRedirectRoute]);

  return (
    <Box className={classes.container}>
      {isShow && (
        <Box className={classes.content}>
          {!RoutesUseStaticImage.includes(pathname) && <HeroMedia />}
          {children}
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: theme.spacing(17, 4, 4),
      minHeight: '100%',
      [mobile]: {
        padding: theme.spacing(17, 2, 2),
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      maxWidth: '1300px',
      [mobile]: {
        maxWidth: '385px',
        flexDirection: 'column-reverse',
        gap: theme.spacing(6),
      },
    },
  });
});

export default AuthLayout;
