import { useEffect, useState } from 'react';
import { useFirebaseContext } from 'shell/firebase';
import { ExhibitionData } from 'constants/data/exhibition.data';

export const useLoadExhibition = (exhibitionId?: string) => {
  const firebase = useFirebaseContext();
  const [exhibition, setExhibition] = useState<ExhibitionData | null>();
  useEffect(() => {
    if (!exhibitionId) {
      setExhibition(null);
      return;
    }

    const query = firebase.exhibitionRef(exhibitionId);
    const stopListener = query.on('value', snapshot => {
      const data = snapshot.val();
      setExhibition(data);
    });
    return () => query.off('value', stopListener);
  }, [firebase, exhibitionId]);

  return { exhibition, isLoading: typeof exhibition === 'undefined' };
};

export default useLoadExhibition;
