import {
  MEMBERSHIP,
  MembershipInterface,
  MembershipItemInterface,
} from 'constants/membership';
import { getPriceFromCents } from 'utils/common/currency';
import { Stripe } from 'stripe';
import { useFirebaseContext } from 'shell/firebase';
import { useState, useEffect } from 'react';
import { IResponseInviter } from 'constants/data/betaInvite.data';
import { UserData } from 'constants/data/user.data';

export const getPriceLabel = (price: Stripe.Price): string => {
  if (!price) {
    return '';
  }

  const amount = getPriceFromCents(price);
  const { recurring } = price;
  const displayedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: price.currency,
  }).format(amount);

  if (recurring) {
    const { interval } = recurring;

    return `${displayedAmount} per ${interval}`;
  }

  return displayedAmount;
};

export const getBenefits = (description?: string): string[] => {
  if (description) {
    return description.split(',');
  }
  return [];
};

export const getListUnpaidPlans = () => {
  return Object.keys(MEMBERSHIP).filter(key => !MEMBERSHIP[key].isPaid);
};

export const getListMembership = () => {
  return Object.keys(MEMBERSHIP);
};

export const getMembership = (productName: string): MembershipItemInterface => {
  if (!productName) {
    return {
      maxPrints: -1,
      name: 'Unsubscribed',
      isPaid: false,
    };
  }

  const name = productName.toLowerCase();

  if (name.indexOf('unlimited') > -1) {
    return MEMBERSHIP['unlimited'];
  }

  if (name.indexOf('prime') > -1) {
    return MEMBERSHIP['prime'];
  }

  if (name.indexOf('plus') > -1) {
    return MEMBERSHIP['plus'];
  }

  return MEMBERSHIP['basic'];
};

export const getProductFromSubscription = (subscription: any) => {
  const product = subscription?.items?.[0]?.price?.product;

  return product;
};

export const getMaxPrintsFromSubscription = (subscription: any) => {
  const { name } = getProductFromSubscription(subscription);

  return getMembership(name);
};

export const getIdFromSubscription = (subscription: any) => {
  return subscription?.items[0]?.subscription;
};

export const isPaidPlan = (planName: keyof MembershipInterface) => {
  const membershipObject = MEMBERSHIP[planName.toString().toLowerCase()];

  if (membershipObject) {
    return membershipObject.isPaid;
  }

  return false;
};

export const useGetInviter = (user?: UserData, showFreeMonth?: boolean) => {
  const firebase = useFirebaseContext();
  const [inviter, setInviter] = useState<IResponseInviter | null>();

  useEffect(() => {
    if (!showFreeMonth) {
      setInviter(null);
      return;
    }
    if (firebase && user) {
      firebase.functions
        .httpsCallable('getInviter')()
        .then(response => {
          const data = response.data.data;
          if (data) {
            setInviter(data);
            return;
          }
          setInviter(null);
        })
        .catch(() => setInviter(null));
    }
  }, [firebase, showFreeMonth, user]);

  return { inviter, isLoading: inviter === undefined };
};
