import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { mobileMediaQuery } from 'utils/common';
import React from 'react';

type Props = React.PropsWithChildren<{
  lineHeight?: any;
  mobileFontSize?: string | number;
}>;

const H1 = ({ children, lineHeight, mobileFontSize }: Props) => {
  const classes = useStyles({ lineHeight, mobileFontSize });
  return <h1 className={classes.h1}>{children}</h1>;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    h1: {
      fontFamily: '"Inter", sans-serif',
      fontSize: '3rem',
      lineHeight: ({ lineHeight }) => lineHeight || 1.5,
      fontWeight: 500,
      color: '#000',
      margin: 0,
      [mobile]: {
        fontSize: ({ mobileFontSize }) => mobileFontSize || '1.75rem',
      },
    },
  });
});

export default H1;
