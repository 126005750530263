import { useEffect, useState } from 'react';
import { IVenue } from 'constants/data/venue.data';
import { useFirebaseContext } from 'shell/firebase';
import { responseToArray } from 'utils/common';

export const useLoadVenues = () => {
  const [venues, setVenues] = useState<IVenue[] | null>();
  const firebase = useFirebaseContext();

  useEffect(() => {
    if (firebase) {
      const query = firebase.venuesRef();

      const callback = query.on('value', async snapshot => {
        const venuesObj = snapshot.val();

        if (venuesObj === null) {
          setVenues(null);
          return;
        }

        const allVenues = Object.values<IVenue>(venuesObj);
        setVenues(allVenues);
      });

      return () => query.off('value', callback);
    }
  }, [firebase]);

  return { venues, isLoading: typeof venues === 'undefined' };
};

export const useLoadPublishedVenues = () => {
  const [venues, setVenues] = useState<IVenue[] | null>();
  const firebase = useFirebaseContext();

  useEffect(() => {
    if (firebase) {
      const query = firebase
        .venuesRef()
        .orderByChild('published')
        .equalTo(true);

      const callback = query.on('value', async snapshot => {
        const venuesObj = snapshot.val();

        if (venuesObj === null) {
          setVenues(null);
          return;
        }

        const allVenues = Object.values<IVenue>(venuesObj);
        const publicVenues = allVenues.filter(v => !v.isPrivated);
        setVenues(publicVenues);
      });

      return () => query.off('value', callback);
    }
  }, [firebase]);

  return { venues, isLoading: typeof venues === 'undefined' };
};

export const useLoadPrivateVenues = () => {
  const firebase = useFirebaseContext();
  const [venues, setVenues] = useState<IVenue[]>([]);

  useEffect(() => {
    const query = firebase.venuesRef().orderByChild('isPrivated').equalTo(true);

    const callback = (snapshot: firebase.database.DataSnapshot) => {
      const val = snapshot.val();

      if (val) {
        setVenues(responseToArray(val, 'uid'));
      }
    };

    query.on('value', callback);

    return () => {
      query.off('value', callback);
    };
  }, [firebase]);

  return venues;
};
