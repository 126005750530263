import { useObjectVal } from 'react-firebase-hooks/database';
import { useFirebaseContext } from 'shell/firebase';
import { useUpdate } from 'utils/requests/useQuery';
import { IEditableImage, EditableImageLog, Logs } from 'constants/data';
import { getMetadata } from 'video-metadata-thumbnails';
import { getImageFileDimensions } from 'utils/common';
import {
  IFILE_METADATA,
  DEFAULT_FILE_METADATA,
  FileType,
} from 'constants/common';
import Firebase from 'shell/firebase';
import { useAuthContext } from 'shell/session';
import { useIsMobile } from 'utils/hooks';

export const useLoadEditableImage = (imageKey: string) => {
  const firebase = useFirebaseContext();
  const [editableImage, isLoading, error] = useObjectVal<IEditableImage>(
    firebase.editableImagesRef().child(imageKey)
  );
  return {
    isLoading,
    editableImage,
    error,
  };
};

export const useLogEditableImageChange = () => {
  const updateDB = useUpdate();
  const firebase = useFirebaseContext();
  const { user } = useAuthContext();
  return (data: EditableImageLog) => {
    const key = firebase.editableImageLogRef().push().key as string;
    const updateObj: Record<string, Logs> = {
      [`${firebase.editableImageLog()}/${key}`]: {
        userId: user?.uid || '',
        createdDate: Date.now(),
        data,
      },
    };
    return updateDB(updateObj);
  };
};

export interface IEditableImageFormValues {
  imageFile?: File;
  videoFile?: File;
  mobileVideoFile?: File;
  alt: string;
  url?: string;
}
interface ISubmitFuncParams {
  values: IEditableImageFormValues;
  imageKey: string;
  oldImagePath?: string;
  oldVideoPath?: string;
  oldMobileVideoPath?: string;
}

const createFileStorage = async (
  firebase: Firebase,
  file: File
): Promise<string> => {
  if (!file) {
    return '';
  }
  try {
    const fileSnapshot = await firebase.imagesStorageCreate(file);
    return fileSnapshot.ref.fullPath;
  } catch (e) {
    console.error('createFileStorage error: ', e);
    return '';
  }
};

const getFileMetadataFactory: Record<FileType, any> = {
  image: getImageFileDimensions,
  video: getMetadata,
};

const getFileMetadata = async (
  type: FileType,
  firebase: Firebase,
  file: File | null | undefined
): Promise<IFILE_METADATA> => {
  if (!file) {
    return DEFAULT_FILE_METADATA;
  }

  const getFileMetadataInstance = getFileMetadataFactory[type];
  const metadata = await getFileMetadataInstance(file);
  const filePath = await createFileStorage(firebase, file);

  return {
    width: metadata?.width || 0,
    height: metadata?.height || 0,
    path: filePath,
  };
};

export const useSubmitEditableImage = () => {
  const updateFunc = useUpdate();
  const firebase = useFirebaseContext();
  return async ({ values, imageKey }: ISubmitFuncParams) => {
    const { imageFile, videoFile, mobileVideoFile, alt, url } = values;
    const updates: { [p: string]: string | number | boolean | undefined } = {
      [`${firebase.editableImagesPath()}/${imageKey}/alt`]: alt,
      [`${firebase.editableImagesPath()}/${imageKey}/url`]: url,
    };
    let imagePath: string;
    let videoPath: string;
    let mobileVideoPath: string;

    if (imageFile || videoFile || mobileVideoFile) {
      // Get image metadata
      const imageMetadata = await getFileMetadata('image', firebase, imageFile);
      updates[`${firebase.editableImagesPath()}/${imageKey}/width`] =
        imageMetadata?.width || 0;
      updates[`${firebase.editableImagesPath()}/${imageKey}/height`] =
        imageMetadata?.height || 0;
      updates[`${firebase.editableImagesPath()}/${imageKey}/path`] = imagePath =
        imageMetadata.path;

      // Get video metadata
      const videoMetadata = await getFileMetadata('video', firebase, videoFile);
      updates[`${firebase.editableImagesPath()}/${imageKey}/videoWidth`] =
        videoMetadata.width || 0;
      updates[`${firebase.editableImagesPath()}/${imageKey}/videoHeight`] =
        videoMetadata.height || 0;
      updates[`${firebase.editableImagesPath()}/${imageKey}/videoPath`] =
        videoPath = videoMetadata.path;

      // Get video metadata
      const mobileVideoMetadata = await getFileMetadata(
        'video',
        firebase,
        mobileVideoFile
      );
      updates[`${firebase.editableImagesPath()}/${imageKey}/mobileVideoWidth`] =
        mobileVideoMetadata.width || 0;
      updates[
        `${firebase.editableImagesPath()}/${imageKey}/mobileVideoHeight`
      ] = mobileVideoMetadata.height || 0;
      updates[`${firebase.editableImagesPath()}/${imageKey}/mobileVideoPath`] =
        videoPath = mobileVideoMetadata.path;
    }

    return updateFunc(updates)
      .then(() => {
        /**
         * sc-5384 Temporarily remove this logic
         */
        /* if (oldImagePath) {
          // delete the old image silently
          firebase.storageDelete(oldImagePath).catch(() => {});
        }
        if (oldVideoPath) {
          // delete the old video silently
          firebase.storageDelete(oldVideoPath).catch(() => {});
        }
        if (oldMobileVideoPath) {
          // delete the old video silently
          firebase.storageDelete(oldMobileVideoPath).catch(() => {});
        } */
        return {
          newImagePath: imagePath,
          newVideoPath: videoPath,
          newMobileVideoPath: mobileVideoPath,
        };
      })
      .catch(error => {
        /**
         * sc-5384 Temporarily remove this logic
         */
        /* if (imagePath) {
          // if saving to db failed, delete the uploaded image silently
          firebase.storageDelete(imagePath).catch(() => {});
        }
        if (videoPath) {
          // if saving to db failed, delete the uploaded video silently
          firebase.storageDelete(videoPath).catch(() => {});
        }

        if (mobileVideoPath) {
          // if saving to db failed, delete the uploaded video silently
          firebase.storageDelete(mobileVideoPath).catch(() => {});
        } */
        throw error;
      });
  };
};

export const isVideoContent = (
  editableImage: IEditableImage | undefined | null
): boolean => {
  return (
    (editableImage?.videoPath ? true : false) ||
    (editableImage?.mobileVideoPath ? true : false)
  );
};

export const useGetVideoSrc = () => {
  const isMobile = useIsMobile();

  return (editableImage: IEditableImage | undefined | null) => {
    if (!editableImage) {
      return '';
    }

    const { videoPath, mobileVideoPath } = editableImage;

    // case desktop
    if (!isMobile) {
      return videoPath || '';
    } else {
      // case mobile and empty both desktop and mobile video
      if (!videoPath && !mobileVideoPath) {
        return '';
      }

      return mobileVideoPath || videoPath;
    }
  };
};
