import { useState, useEffect } from 'react';
import { UserData } from 'constants/data/user.data';
import { useFirebaseContext } from 'shell/firebase';
import { useIsMounted } from 'utils/hooks';

export const useLoadUser = (userId: string, loadOnce?: boolean) => {
  const firebase = useFirebaseContext();
  const [user, setUser] = useState<UserData>();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (!loadOnce) {
      const query = firebase.userRef(userId);
      const stopListener = query.on('value', snapshot => {
        setUser(snapshot.val());
      });
      return () => query.off('value', stopListener);
    }
  }, [userId, firebase, loadOnce]);

  useEffect(() => {
    if (loadOnce) {
      const query = firebase.userRef(userId);
      query.once('value', snapshot => {
        const data = snapshot.val();
        if (isMounted() === true) {
          setUser(data);
        }
      });
    }
  }, [userId, firebase, isMounted, loadOnce]);

  return {
    user,
    isLoading: typeof user === 'undefined',
  };
};

export const useCheckEmailExisted = () => {
  const firebase = useFirebaseContext();

  return (email: string) => firebase.auth.fetchSignInMethodsForEmail(email);
};
