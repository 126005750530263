import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogProps,
  makeStyles,
  createStyles,
  Theme,
  Box,
  IconButton,
} from '@material-ui/core';
import promiseWrapper from 'a-promise-wrapper';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from 'modules/formFields';
import Typography from 'modules/typography';
import { useResellPrint } from 'utils/requests';
import { useModal } from 'modules/modals';
import { useResellOriginalArtwork } from 'utils/requests/useOriginalArtwork';
import { ReactComponent as CloseIcon } from 'static/images/close-icon.svg';
import { mobileMediaQuery } from 'utils/common';
import { useIsMobile } from 'utils/hooks';
import { useAuthContext } from 'shell/session';
import ResellGuidanceSteps from 'views/common/ResellGuidanceSteps';
import { useConnectToStripe } from 'views/account/sellArtworks/helpers';
import { useClickConnectBank } from 'utils/requests/usePayment';
import { SelectCountriesDialog } from 'modules/dialogs';
import { useModalState } from 'utils/hooks';
import { StripeAccountPayoutStatus } from 'constants/payment';

import UIButton from 'ui-components/Button';

type Props = {
  imageId: string;
  printId: string;
  isPrint: boolean;
  lastSoldPrice?: number;
  viewGuidanceOnly?: boolean;
  onSuccess?: () => void;
} & DialogProps;

const ResellDialog = ({
  imageId,
  printId,
  isPrint,
  lastSoldPrice,
  viewGuidanceOnly,
  onSuccess,
  ...dialogProps
}: Props) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const styles = useStyles();
  const resellPrint = useResellPrint();
  const resellOriginalArtwork = useResellOriginalArtwork();
  const { showSnackbar } = useModal();
  const isMobile = useIsMobile();
  const { user } = useAuthContext();
  const connectToBank = useConnectToStripe();
  const clickConnectBank = useClickConnectBank();
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const {
    isOpen: isOpenDialog,
    open: openDialog,
    close: closeSelectCountryDialog,
  } = useModalState();

  const handleConnectToStripe = async (payload?: any) => {
    setIsConnecting(true);
    const result = await connectToBank(payload);
    setIsConnecting(false);
    if (result.success && result.value) {
      return (window.location.href = result.value);
    }
    showSnackbar(result.message, { severity: 'error' });
  };

  const onSubmit = async (values: any) => {
    const { price } = values;
    const handler = isPrint ? resellPrint : resellOriginalArtwork;

    const { error } = await promiseWrapper(
      handler({ printId, imageId, price })
    );
    const itemName = isPrint ? 'print' : 'original artwork';
    const errorMessage = `Failed to resell ${itemName}`;
    const successMessage = `Update successfully`;

    if (error) {
      showSnackbar(errorMessage, {
        severity: 'error',
      });
    } else {
      showSnackbar(successMessage);
      onSuccess && onSuccess();
    }

    closeDialog();
  };

  const closeDialog = () => {
    if (dialogProps.onClose) {
      dialogProps.onClose({}, 'backdropClick');
    }
  };

  const isUserConnectedToStripeSuccess =
    user &&
    user.stripeConnect &&
    user.stripeConnect.status &&
    user.stripeConnect.status === StripeAccountPayoutStatus.Completed;

  return (
    <Dialog
      fullScreen={isMobile}
      {...dialogProps}
      classes={{ paper: styles.paper }}>
      <DialogContent className={styles.content}>
        <IconButton onClick={closeDialog} className={styles.closeButton}>
          <CloseIcon />
        </IconButton>
        <Typography className={styles.heading}>Resell artwork</Typography>
        <Typography className={styles.description}>
          Set the price for sale, to put the artwork back in the market for
          other collectors to buy
        </Typography>
        {isUserConnectedToStripeSuccess && !viewGuidanceOnly ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className={styles.priceWrapper}>
              <Box mb={2}>
                <Typography
                  fontSize={'1.25rem'}
                  letterSpacing={'-0.02em'}
                  lineHeight={'135%'}
                  fontWeight={500}>
                  Set Price
                </Typography>
              </Box>
              <Typography className={styles.price}>price</Typography>
              <Controller
                name="price"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Box mt={1} mb={1.5}>
                    <TextField
                      {...field}
                      type={'number'}
                      className={{ root: styles.textField }}
                      placeholder="Set price"
                      InputProps={{
                        startAdornment: (
                          <Typography className={styles.currencyPlaceholder}>
                            €
                          </Typography>
                        ),
                      }}
                    />
                  </Box>
                )}
              />
              {lastSoldPrice && (
                <Typography className={styles.note}>
                  The artwork last sold for €{lastSoldPrice}
                </Typography>
              )}
            </Box>
            <Box className={styles.buttonWrapper}>
              <UIButton
                fullWidth={isMobile}
                variant="primary"
                size="medium"
                type="submit"
                disabled={isSubmitting}
                text="Resell Artwork"
              />
            </Box>
          </form>
        ) : (
          <Box>
            <Typography
              fontWeight={500}
              fontSize={'1.25rem'}
              lineHeight={'135%'}
              letterSpacing={'-0.02em'}>
              How it works
            </Typography>
            <Box className={styles.howItWorks}>
              <Box mb={3.5}>
                <Typography fontSize={'0.875rem'} lineHeight={'150%'}>
                  Connect your bank account, and set the price desired for
                  resell. Once it’s sold, you’ll get the amount sold transferred
                  to your account.
                </Typography>
              </Box>
              <ResellGuidanceSteps containerClassName={styles.guidanceSteps} />
            </Box>
            <UIButton
              fullWidth={isMobile}
              variant="primary"
              size="medium"
              text="Connect Bank Account"
              disabled={isConnecting}
              loading={isConnecting}
              onClick={() =>
                clickConnectBank({
                  openDialog: openDialog,
                  connectStripe: handleConnectToStripe,
                })
              }
            />
          </Box>
        )}
        {isOpenDialog && (
          <SelectCountriesDialog
            open={isOpenDialog}
            isLoading={isConnecting}
            onClose={() => closeSelectCountryDialog(true)}
            onNext={handleConnectToStripe}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    paper: {
      maxWidth: 500,
      maxHeight: 700,
      [mobile]: {
        maxWidth: '100%',
        maxHeight: '80%',
      },
    },
    content: {
      'padding': theme.spacing(6.25),
      'position': 'relative',
      '&:first-child': {
        paddingTop: theme.spacing(6.25),
      },
      [mobile]: {
        padding: theme.spacing(2),
      },
    },
    heading: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(28),
      lineHeight: 1.2,
      letterSpacing: '-0.02em',
      color: '#000000',
      paddingBottom: theme.spacing(2),
    },
    description: {
      lineHeight: 1.625,
      color: '#000000',
      opacity: 0.8,
      paddingBottom: theme.spacing(4),
    },
    priceWrapper: {
      width: '70%',
      [mobile]: {
        width: '100%',
      },
    },
    price: {
      fontSize: theme.typography.pxToRem(11),
      lineHeight: 1.2,
      letterSpacing: '0.12em',
      textTransform: 'uppercase',
    },
    textField: {
      maxWidth: 272,
      width: '100%',
      padding: theme.spacing(0),
    },
    currencyPlaceholder: {
      paddingLeft: theme.spacing(0.75),
      paddingRight: theme.spacing(1),
    },
    note: {
      fontSize: theme.typography.pxToRem(11),
      lineHeight: 1.45,
      color: '#777777',
    },
    buttonWrapper: {
      paddingTop: theme.spacing(17),
      width: 175,
      [mobile]: {
        paddingTop: theme.spacing(12),
        width: '100%',
      },
    },
    closeButton: {
      position: 'absolute',
      top: 12,
      right: 20,
      [mobile]: {
        right: 10,
      },
    },
    howItWorks: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(4),
      border: '1px solid #F0F0F0',
      borderRadius: 3,
      padding: theme.spacing(3),
    },
    guidanceSteps: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2.5),
      marginBottom: theme.spacing(1),
    },
  });
});

export default ResellDialog;
