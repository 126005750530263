import { useState, useEffect } from 'react';
import { useFirebaseContext } from 'shell/firebase';
import { TransactionData } from 'constants/data';
import { sortBy, last } from 'lodash';
import { useAuthContext } from 'shell/session';
import { responseToArray } from '../common';

interface TransactionDataWithKey extends TransactionData {
  key: string;
}
interface Props {
  value?: string | null;
  isPrint: boolean;
  transactionId?: string;
}

const useGetTransactionById = ({ isPrint, value, transactionId }: Props) => {
  const firebase = useFirebaseContext();
  const { user } = useAuthContext();
  const [latestTransaction, setLatestTransaction] =
    useState<TransactionDataWithKey | null>();

  useEffect(() => {
    if (transactionId) {
      firebase.transactionsRefByKey(transactionId).once(
        'value',
        snapshot => {
          if (snapshot.exists()) {
            setLatestTransaction({ ...snapshot.val(), key: snapshot.key });
          }
        },
        error => {
          console.error(error);
          setLatestTransaction(null);
        }
      );
    }
    if (value && user && !transactionId) {
      firebase
        .transactionsRef()
        .orderByChild(isPrint ? 'printId' : 'originalArtworkId')
        .equalTo(value)
        .once(
          'value',
          snapshot => {
            const data = snapshot.val();
            if (!data) {
              setLatestTransaction(null);
              return;
            }
            const transactions: TransactionDataWithKey[] = responseToArray(
              data,
              'key'
            );

            const transactionsBelongToUser = transactions.filter(
              (trans: TransactionDataWithKey) =>
                trans.buyerId === user.uid && trans.status === 'completed'
            );
            const latestTransaction = last(
              sortBy(transactionsBelongToUser, ['createdDate'])
            );

            setLatestTransaction(latestTransaction);
          },
          error => {
            console.error(error);
            setLatestTransaction(null);
          }
        );
    }
  }, [value, isPrint, user, firebase, transactionId]);
  return { latestTransaction, isLoading: latestTransaction === undefined };
};

export default useGetTransactionById;
