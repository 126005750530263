export const FEATURED_IMAGES_COUNT = 8;

export const APP_STORE_APP_URL =
  'https://apps.apple.com/vn/app/digitalcanvas/id1538110096';
export const GOOGLE_PLAY_APP_URL =
  'https://play.google.com/store/apps/details?id=io.activeimage.digitalcanvas';

export const zeroDecimalCurrencies = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];
export const DEFAULT_CURRENCY = 'eur';

export enum FeatureFlagEnum {
  InviteFriends = 'inviteFriends',
  VenueProfile25 = 'venueProfile25',
  Timeline = 'timeline',
  ReGifting = 'regifting',
  IrisDigitalSignature = 'irisDigitalSignature',
  VerifyEmailByReWriteEmail = 'verifyEmailByReWriteEmail',
}

export const FEATURE_FLAGS = {
  [FeatureFlagEnum.InviteFriends]: {
    name: 'Invite Friends',
  },
  [FeatureFlagEnum.VenueProfile25]: {
    name: 'Venue Profile 2.5',
  },
  [FeatureFlagEnum.Timeline]: {
    name: 'About - Timeline',
  },
  [FeatureFlagEnum.ReGifting]: {
    name: 'Regifting',
  },
  [FeatureFlagEnum.IrisDigitalSignature]: {
    name: 'IrisDigitalSignature',
  },
  [FeatureFlagEnum.VerifyEmailByReWriteEmail]: {
    name: 'Verify Email by re-writing email second time',
  },
};

export type IFILE_METADATA = {
  path: string;
  width: number;
  height: number;
};

export const DEFAULT_FILE_METADATA: IFILE_METADATA = {
  width: 0,
  height: 0,
  path: '',
};

export type FileType = 'image' | 'video';

export const ALLOWED_VIDEO_FORMAT: string[] = ['video/mp4'];
export const ALLOWED_IMAGE_FORMAT: string[] = [
  'image/jpeg',
  'image/png',
  'image/svg+xml',
];

export const specialGiftingTypes = ['hz'];

/**
 * HARDCODED for HZ Livestore gifting
 * Story: https://app.shortcut.com/active-image/story/5512/replace-the-wallpaper-in-the-hz-gift-receive-page
 */
// export const specialGiftingBg = '#f9b120';
export const specialGiftingBg = `url("//cdn.shopify.com/s/files/1/0606/7974/9812/t/2/assets/site-background-image-file.jpg?v=139179247623423685001644839301")`;
