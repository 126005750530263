import { getSlug } from 'utils/common';

export const LANDING = '/';
export const SIGN_UP = '/signup';
export const VERIFICATION = '/verification';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';
export const ACCOUNT_UPLOAD = '/account/upload';
export const ACCOUNT_PROFILE = '/account/profile';
export const ACCOUNT_MEMBER_SHIP = '/account/membership';
export const ACCOUNT_IMAGES = '/account/images';
export const ACCOUNT_CANVASES = '/account/canvases';
export const ACCOUNT_UPLOADED_IMAGES = '/account/uploaded-images';
export const ACCOUNT_COMMERCE = '/account/commerce';
export const ACCOUNT_INVITE_FRIENDS = '/account/invite-friends';
export const ACCOUNT_SELL_ARTWORKS = '/account/sell-artworks';
export const ADMIN_EXHIBITION_LIST = '/admin/exhibitions';
export const ADMIN_BETA_INVITES = '/admin/beta-invites';
export const NOT_FOUND = '/404';
export const CATALOGS = '/collections';
export const ARTIST_CATALOG = '/collection/:artistId/:catalog';
export const ARTISTS = '/artists';
export const ARTIST = '/artist/:id';
export const RESET_PASSWORD = '/reset-password';
export const AUTH_ACTION = '/auth/action';
export const YOU_ARE_SET = '/you-are-set';
export const ADMIN_USER_LIST = '/admin/users';
export const ADMIN_USER_DETAILS = '/admin/users/:id';
export const ADMIN_IMAGES = '/admin/images';
export const ADMIN_FEATURED_IMAGES = '/admin/featured-images';
export const ADMIN_FEATURED_FLAG = '/admin/feature-configuration';
export const ADMIN_HOMEPAGE_CONFIG = '/admin/homepage-configuration';
export const ADMIN_VENUES = '/admin/venues';
export const ADMIN_HOSTED_VENUES = '/admin/hosted-venues';

export const KEYWORD = '/keyword/:keyword';
export const PRIVACY = '/privacy-policy';
export const PURCHASE_SUBSCRIPTION_AGREEMENT =
  '/purchase-subscription-agreement';
export const ARTIST_AGREEMENT = '/artist-agreement';
export const TERMS = '/terms-of-use';
export const IMPRINT = '/imprint';
export const EXPLORE = '/explore';
export const EXHIBITIONS = '/exhibitions';
export const EXHIBITION = '/exhibitions/:id/:name';
export const EXHIBITION_IMAGE_DETAILS =
  '/exhibitions/:exhibitionId/images/:imageId/:imageName';
export const ADMIN_EXHIBITION_EDIT = '/admin/exhibitions/edit/:id';
export const ADMIN_EXHIBITION_CREATE = '/admin/exhibitions/create';
export const VENUES = '/venues';
export const ADMIN_VENUE_CREATE = '/admin/venues/create';
export const ADMIN_VENUE_EDIT = '/admin/venues/edit/:id';
export const VENUE_DETAILS = '/venues/:id/:name';
export const VENUE_ABOUT = '/venues/:id/:venueName/about';
export const VENUE_ARTWORK = '/venues/:id/:venueName/artworks';
export const VENUE_EXHIBITIONS = '/venues/:id/:venueName/exhibitions';
export const ARTWORKS = '/artworks';
export const HOW_IT_WORKS = '/how-it-works';
export const ABOUT = '/about';
export const CONTACT = '/contact';
export const OUR_PARTNERS = '/our-partners';
export const PRICING = '/subscription-plans';
export const DOWNLOADS = '/downloads';
export const RESET_PASSWORD_CONFIRMATION = '/reset-password-confirmation';
export const RESET_PASSWORD_COMPLETE = '/reset-password-complete';
export const ADMIN_QUERY_TOOL = '/admin/query-tool';
export const ADMIN_REFUND_TOOL = '/admin/refund-tool';
export const ADMIN_GIFT = '/admin/manage-gift';
export const WATERMARK_VERIFICATION = '/verify';
export const PRODUCT_OVERVIEW_DISPLAY = '/product-overview/display';
export const PRODUCT_OVERVIEW_COLLECT = '/product-overview/collect';
export const RECEIVE_GIFT = '/gift/:id/:otp?';
export const ADMIN_PARTNERS = '/admin/partners';
export const ADMIN_SALES_REPORT = '/admin/sales-report';
export const SAMSUNG_PROMOTION = '/campaigns/apr23/samsung';

interface ExhibitionArtworkDetailUrlInput {
  exhibitionId: string;
  imageId: string;
  imageName: string;
}

export const artistDetailsUrl = ({
  id,
  name = '',
}: {
  id: string;
  name: string;
}) => `/artist/${id}/${getSlug(name)}`;
export const ARTWORK_DETAILS = '/images/:imageId/:name';
export const artworkDetailsUrl = ({
  imageId,
  name = '',
}: {
  imageId: string;
  name: string;
}) => `/images/${imageId}/${getSlug(name)}`;
export const exhibitionArtworkDetailUrl = ({
  exhibitionId,
  imageId,
  imageName,
}: ExhibitionArtworkDetailUrlInput) =>
  `/exhibitions/${exhibitionId}/images/${imageId}/${getSlug(imageName)}`;
