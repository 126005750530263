import { Box, CircularProgress, Grid } from '@material-ui/core';
import pw from 'a-promise-wrapper';
import { TransactionData } from 'constants/data';
import Typography from 'modules/typography';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGiftIndicator } from 'utils/hooks/useLocalStorage';
import { useValidateOTPGift } from 'utils/requests';
import { InvalidState, ValidState } from './components';
import { useSpecialGifting } from 'utils/hooks';

const ReceiveGift = () => {
  const specialGifting = useSpecialGifting();
  // const location = useLocation();
  // const history = useHistory();
  const validateFunc = useValidateOTPGift();

  // Loading state for validate OTP request
  const [isChecking, setIsChecking] = useState(true);
  // Result after checking OTP
  const [isValidGift, setIsValidGift] = useState(false);
  // Valid transaction after checking
  const [tx, setTx] = useState<TransactionData>();

  // Show gift indicator on topbar menu
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_giftData, setGiftIndicatorData] = useGiftIndicator();

  /**
   * searchParams should be `/gift/${txId}/${otp?}`
   */
  const { id } = useParams<{ id: string }>();
  const { otp } = useParams<{ otp: string }>();

  useEffect(() => {
    /**
     * Validate the OTP
     */
    const validate = async () => {
      if (!id) {
        setIsChecking(false);

        return;
      }

      const { data, error } = await pw(validateFunc(id, otp));

      if (error) {
        setIsValidGift(false);
        setIsChecking(false);
        setGiftIndicatorData(null);

        // history.replace({ search: '' });

        return;
      }

      setIsValidGift(true);
      setIsChecking(false);
      setTx(data.data as TransactionData);
      setGiftIndicatorData({
        id,
        otp,
      });

      // history.replace({ search: '' });
    };

    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, otp]);

  if (isChecking) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        style={{ minHeight: '100vh' }}>
        <CircularProgress />
        <Box mt={2}>
          <Typography>
            Checking your awesome {specialGifting ? 'artwork' : 'gift'}
          </Typography>
        </Box>
      </Grid>
    );
  }

  if (!isValidGift || !tx) {
    // TODO
    return <InvalidState isSpecialGifting={specialGifting} />;
  }

  return <ValidState tx={tx} />;
};

export default ReceiveGift;
