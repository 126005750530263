import loadable from 'utils/loadable';

export const AdminCreateVenuePage = loadable(
  () =>
    import(/* webpackChunkName: "admin.CreateVenuePage" */ './CreateVenuePage')
);
export const AdminEditVenuePage = loadable(
  () => import(/* webpackChunkName: "admin.EditVenuePage" */ './EditVenuePage')
);

export default loadable(
  () => import(/* webpackChunkName: "admin.VenueListPage" */ './VenueListPage')
);
