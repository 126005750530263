import { useState, useRef, useEffect } from 'react';
import {
  withRouter,
  NavLink,
  useLocation,
  Redirect,
  generatePath,
} from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { useIsMobile } from 'utils/hooks';
import { createStyles, makeStyles, Theme, Box } from '@material-ui/core';
import { mobileMediaQuery } from 'utils/common';
import HamburgerButton from 'modules/topBar/HamburgerButton';
import { motion } from 'framer-motion';
import NavUser from './NavUser';
import NavMain from './NavMain';
import { ReactComponent as Logo } from 'static/images/logo.svg';
import { useAuthContext } from 'shell/session';
import { UserData } from 'constants/data/user.data';
import { useGiftIndicator } from 'utils/hooks/useLocalStorage';
import GiftIndicator from './GiftIndicator';
import { useRouteMatch } from 'react-router';
import { useSpecialGifting } from 'utils/hooks';
import CloseIcon from '@material-ui/icons/Close';
import HzLogo from 'static/images/hz-logo.png';

const TopBar = () => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const location = useLocation();
  const authContext = useAuthContext();
  const user = authContext.user as UserData | undefined | null;
  const isLoading = user === undefined;
  const showHamburger = isMobile && !isLoading;

  const mobileNavInner = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [giftIndicator] = useGiftIndicator();
  const specialGifting = useSpecialGifting();
  const isGiftingRoute = useRouteMatch(ROUTES.RECEIVE_GIFT);

  useEffect(() => {
    if (isMobile) {
      setIsOpen(false);
    }
  }, [location.pathname, isMobile]);
  const mobileNavVariants = {
    slide: () => ({
      height: isOpen ? mobileNavInner?.current?.clientHeight : 0,
      transition: {
        duration: 0.3,
      },
    }),
  };

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  if (query.get('visitorPass') === 'xWnzZTaNtwxYBFBqD2CMeJCVPGyY68gRqP') {
    return (
      <Redirect
        to={generatePath(ROUTES.EXHIBITION, {
          id: '-Mnj1qZArsq_ZQ4NwA22',
          name: 'Alfonso%20Granati%20at%20the%20Biennale',
        })}
      />
    );
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.topBar}>
        {/* Disable hyperlink from story 5475 */}
        {specialGifting && isGiftingRoute ? (
          <Box display="flex" alignItems="center">
            <Logo className={classes.logo} />
            <CloseIcon className={classes.icon} />
            <img src={HzLogo} alt="Hans Zimmer" className={classes.HzLogo} />
          </Box>
        ) : (
          <NavLink to={ROUTES.LANDING} className={classes.topBarLogo}>
            <Logo className={classes.logo} />
          </NavLink>
        )}
        {/* Hide top nav from story 5475 */}
        {specialGifting && isGiftingRoute ? null : (
          <Box className={classes.topBarRight}>
            {isMobile && !!giftIndicator && <GiftIndicator />}
            {showHamburger && (
              <Box className={classes.hamburgerIcon}>
                <HamburgerButton
                  isOpen={isOpen}
                  toggleButton={isOpen => setIsOpen(isOpen)}
                />
              </Box>
            )}
            {!isMobile && !isLoading && <NavMain />}
            <NavUser />
          </Box>
        )}
      </Box>
      {showHamburger && (
        <motion.div
          className={classes.mobileNav}
          variants={mobileNavVariants}
          animate="slide">
          <div ref={mobileNavInner}>
            <NavMain />
          </div>
        </motion.div>
      )}
    </Box>
  );
};

export const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    container: {
      position: 'absolute',
      zIndex: 10,
      width: '100%',
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    topBar: {
      height: '100px',
      width: '100%',
      maxWidth: '1440px',
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      [mobile]: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
      },
    },
    topBarLogo: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      height: '68px',
      width: '135px',
    },
    logoText: {
      fontSize: '1rem',
      lineHeight: '1rem',
      marginLeft: '10px',
      color: '#000',
    },
    topBarRight: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    hamburgerIcon: {
      display: 'block',
      width: '20px',
      height: '30px',
    },
    mobileNav: {
      backgroundColor: '#FFF',
      overflow: 'hidden',
      width: '100%',
    },
    giftDot: {
      backgroundColor: '#16CE13',
      minWidth: '6px',
      width: '6px',
      height: '6px',
      top: '-3px',
      right: '-4px',
    },
    icon: {
      marginLeft: '10px',
      marginRight: '10px',
      fontSize: '14px',
    },
    HzLogo: {
      height: '68px',
      width: 'auto',
    },
  });
});

export default withRouter(TopBar);
