import { useState, useEffect } from 'react';
import { camelCase } from 'change-case';
import Firebase, { useFirebaseContext } from 'shell/firebase';
import { checkIsInternalLink } from 'utils/common';
import { useUpdate } from 'utils/requests/useQuery';
import { useAuthContext } from 'shell/session';
import { EditableTextLog, Logs } from 'constants/data';

export type EditableTextType = 'static-text' | 'keyword-description';

export const useLoadLink = ({ linkKey }: { linkKey?: string }) => {
  const { text, ...rest } = useLoadText({
    textKey: linkKey,
    textType: 'static-text',
  });
  return {
    ...rest,
    link: text,
  };
};

export const useLoadText = ({
  textKey,
  textType,
}: {
  textKey?: string;
  textType: EditableTextType;
}) => {
  const [text, setText] = useState<string | null>();
  const firebase = useFirebaseContext();

  useEffect(() => {
    if (!textKey) {
      setText(null);
      return;
    }
    if (firebase !== null && textKey) {
      const query = getQuery({
        firebase,
        textKey,
        textType,
      });
      const stopListener = query.on('value', snapshot => {
        setText(snapshot.val());
      });
      return () => query.off('value', stopListener);
    }
  }, [textKey, firebase, textType]);

  return { text };
};

export const useLoadStaticTexts = (refs: string[]) => {
  const [texts, setTexts] = useState<string[] | void[] | unknown[]>();
  const firebase = useFirebaseContext();

  useEffect(() => {
    if (refs.length !== 0) {
      const query = firebase.staticTextsRef();
      const stopListener = query.on('value', snapshot => {
        const staticTexts = snapshot.val();
        setTexts(refs.map(ref => staticTexts[ref]));
      });
      return () => query.off('value', stopListener);
    }
  }, [refs, firebase]);

  return { texts };
};

export const useUpdateText = ({
  textKey,
  linkKey,
  textType,
}: {
  textKey: string;
  linkKey?: string;
  textType: EditableTextType;
}) => {
  const firebase = useFirebaseContext();
  return ({ text, link }: { text: string; link?: string }) => {
    const linkChanges =
      linkKey && link ? getLinkChanges({ link, linkKey, firebase }) : {};
    const textChanges = getTextChanges({
      text,
      textKey,
      textType,
      firebase,
    });
    return firebase.db.ref().update({
      ...linkChanges,
      ...textChanges,
    });
  };
};

export const useLogEditableTextChange = () => {
  const updateDB = useUpdate();
  const firebase = useFirebaseContext();
  const { user } = useAuthContext();
  return (data: EditableTextLog) => {
    const key = firebase.staticTextLogRef().push().key as string;
    const updateObj: Record<string, Logs> = {
      [`${firebase.staticTextLog()}/${key}`]: {
        userId: user?.uid || '',
        createdDate: Date.now(),
        data,
      },
    };
    return updateDB(updateObj);
  };
};

const getQuery = ({
  firebase,
  textKey,
  textType,
}: {
  firebase: Firebase;
  textKey: string;
  textType: EditableTextType;
}) => {
  switch (textType) {
    case 'static-text':
      return firebase.staticTextsRef().child(textKey);
    case 'keyword-description':
      return firebase
        .keywordsRef()
        .child(camelCase(textKey))
        .child('description');
    default:
      throw new Error('Invalid Editable Text Type');
  }
};

const getTextChanges = ({
  firebase,
  textKey,
  textType,
  text,
}: {
  firebase: Firebase;
  textKey: string;
  textType: EditableTextType;
  text: string;
}) => {
  switch (textType) {
    case 'static-text':
      return { [`${firebase.staticTextsPath()}/${textKey}`]: text };
    case 'keyword-description':
      return {
        [`${firebase.keywordsPath}/${camelCase(textKey)}/description`]: text,
      };
    default:
      throw new Error('Invalid Editable Text Type');
  }
};

const getLinkChanges = ({
  link,
  linkKey,
  firebase,
}: {
  link: string;
  linkKey: string;
  firebase: Firebase;
}) => ({ [`${firebase.staticTextsPath()}/${linkKey}`]: link });

/**
 * If the `link` is external link, save the full url
 * If the `link` is internal link, save the pathname only
 * @param link
 * @returns
 */
export const getLinkToSave = (link: string) => {
  const isInternalLink = checkIsInternalLink(link);
  if (!isInternalLink) {
    return link;
  }
  if (link.startsWith('http')) {
    return new URL(link).pathname;
  }
  return link;
};
