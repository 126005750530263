import { specialGiftingTypes } from 'constants/common';

export const getGiftExpireDays = (createdDate: number | undefined) => {
  if (!createdDate) {
    return '';
  }

  const now = Date.now() / 1000;
  const seconds = createdDate / 1000;
  const expectedExpire = seconds + 24 * 3600 * 30;
  const isValid = expectedExpire >= now;

  if (!isValid) {
    return '';
  }

  let days = Math.floor((expectedExpire - now) / 3600 / 24);
  if (days <= 1) {
    days = 1;
  }

  return days;
};

export const isSpecialGifting = (type?: string | null) => {
  if (!type) {
    return false;
  }
  return specialGiftingTypes.includes(type);
};
