import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  CircularProgress,
} from '@material-ui/core';
import cs from 'classnames';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { useState, useEffect } from 'react';
import Typography from 'modules/typography';
import { useAuthContext } from 'shell/session';
import { UserData } from 'constants/data';
import { useGetStripeBankAccounts } from 'utils/requests/usePayment';
import { useSnackbar } from 'modules/modals';
import { getErrorMessage } from 'constants/errors';
import { StripeAccountPayoutStatus } from 'constants/payment';
import { Stripe } from 'stripe';
import UILink from 'ui-components/Link';

interface StripeBankAccountProps {
  // For now we only use BankAccount because we use pre-built Stripe Onboarding form
  bankAccounts: Stripe.BankAccount[];
  loginLink: Stripe.LoginLink | null;
}

const StripeBankAccount = ({
  bankAccounts,
  loginLink,
}: StripeBankAccountProps) => {
  const classes = useStyles();

  return (
    <>
      {bankAccounts.map(item => {
        const { account_holder_name, bank_name, last4 } = item;

        return (
          <Box key={item.id} className={classes.paymentMethod} mb={2}>
            <Box>
              <Typography className={classes.name}>
                {account_holder_name || bank_name || ''}
              </Typography>
              <Typography className={classes.account}>
                <AccountBalanceIcon className={classes.icon} />
                Ending in {last4 || ''}
              </Typography>
            </Box>
            {loginLink && (
              <UILink
                href={loginLink.url}
                size={'large'}
                variant={'underline'}
                target="_blank">
                Edit
              </UILink>
            )}
          </Box>
        );
      })}
    </>
  );
};

const SellArtworksAccount = () => {
  const classes = useStyles();
  const auth = useAuthContext();
  const getStripeBankAccounts = useGetStripeBankAccounts();
  const user = auth.user as UserData;
  const { stripeConnect } = user;
  const { id: stripeConnectId, status: stripeConnectStatus } =
    stripeConnect || {};
  const { showSnackbar, render: snackbarRendering } = useSnackbar();
  const [stripeBankAccounts, setStripeBankAccounts] = useState<
    Stripe.ApiList<Stripe.BankAccount> | undefined | null
  >(undefined);
  const [stripeLoginLink, setStripeLoginLink] =
    useState<Stripe.LoginLink | null>(null);

  useEffect(() => {
    if (
      stripeConnectId &&
      stripeConnectStatus === StripeAccountPayoutStatus.Completed
    ) {
      getStripeBankAccounts()
        .then(res => {
          setStripeBankAccounts(res?.data?.bankAccounts || null);
          setStripeLoginLink(res?.data?.loginLink || null);
        })
        .catch(error =>
          showSnackbar(getErrorMessage(error.message), {
            severity: 'error',
          })
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeConnectId, stripeConnectStatus]);

  return (
    <Box mt={6}>
      <Typography className={classes.title}>Account</Typography>
      {!stripeConnectId ||
      stripeConnectStatus !== StripeAccountPayoutStatus.Completed ? (
        <Box className={cs(classes.paymentMethod, classes.paymentMethodEmpty)}>
          No account connected yet
        </Box>
      ) : stripeBankAccounts === undefined ? (
        <CircularProgress />
      ) : !stripeBankAccounts ? null : (
        <StripeBankAccount
          bankAccounts={stripeBankAccounts?.data || []}
          loginLink={stripeLoginLink}
        />
      )}

      {snackbarRendering}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: theme.typography.pxToRem(11),
      letterSpacing: '0.12em',
      textTransform: 'uppercase',
      marginBottom: theme.spacing(1.5),
    },
    paymentMethod: {
      display: 'flex',
      border: '1px solid #D8D8D8',
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(2, 4, 2, 3),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paymentMethodEmpty: {
      color: '#7f7f7f',
    },
    name: {
      marginBottom: theme.spacing(0.5),
    },
    account: {
      display: 'flex',
      color: '#616161',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    edit: {
      fontSize: theme.typography.pxToRem(16),
    },
  })
);

export default SellArtworksAccount;
