import { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from 'modules/formFields';
import { useModal } from 'modules/modals';
import { ImageData } from 'constants/data';
import { usePairingCode, useGetCanvasFromOTP } from 'utils/requests';
import { getErrorMessage } from 'constants/errors';
import ImageSelectionDialog, {
  ExtendedImageSelectionParamsType,
  ImageSelectionParamsType,
} from 'modules/imageSelectionDialog';
import { useModalState } from 'utils/hooks';
import promiseWrapper from 'a-promise-wrapper';
import { useAuthContext } from 'shell/session';

import UIButton from 'ui-components/Button';

type Props = {
  image?: ImageData;
  printId?: string;
  onNext?: () => void;
};

const PairingCode = (props: Props) => {
  const { image, printId, onNext } = props;
  const classes = useStyles();
  const { signInByOTP, playArtwork } = usePairingCode();
  const { showSnackbar } = useModal();
  const {
    data,
    isOpen: isOpenSelectionDialog,
    open: openSelectionDialog,
    close: closeSelectionDialog,
  } = useModalState<ExtendedImageSelectionParamsType>();
  const getCanvasIdFromOTP = useGetCanvasFromOTP();
  const { user } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ otp: string }>();

  const handleNext = () => {
    if (onNext) {
      onNext();
    }
  };

  const onError = (error: Error) => {
    setIsSubmitting(false);
    showSnackbar(getErrorMessage(error.message), { severity: 'error' });
  };

  const onSuccess = (message: string) => {
    setIsSubmitting(false);
    showSnackbar(message);
    reset({ otp: '' });
  };

  const playWithCroppingtool = async (otp: string) => {
    const onConfirm = async (data: ImageSelectionParamsType) => {
      const { image, printId, canvas, printTransform: transform } = data;
      const { uid: imageId } = image;
      const { uid: canvasId } = canvas;
      const isStreaming = !!(
        user &&
        image &&
        !printId &&
        image.owner !== user.uid
      );

      setIsSubmitting(true);
      playArtwork({ imageId, printId, canvasId, transform, isStreaming })
        .then(() => {
          onSuccess('The artwork is now playing on your canvas');
          handleNext();
        })
        .catch(error => {
          onError(error);
        });
    };

    if (image) {
      setIsSubmitting(true);
      const { data: canvas, error } = await promiseWrapper(
        getCanvasIdFromOTP(otp)
      );

      if (error) {
        onError(error);

        return;
      }

      setIsSubmitting(false);
      openSelectionDialog({
        image,
        printId,
        canvas,
        printTransform: null,
        onConfirm,
      });
    }
  };

  const onSubmit = (values: { otp: string }) => {
    if (image) {
      playWithCroppingtool(values.otp);
      return;
    }

    setIsSubmitting(true);
    signInByOTP({ otp: values.otp })
      .then(() => {
        onSuccess('Device successfully paired');
        handleNext();
      })
      .catch(error => {
        onError(error);
      });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="otp"
        defaultValue=""
        control={control}
        rules={{
          required: 'Please enter 6 digits appearing on your device',
        }}
        render={({ field: { onChange, value } }) => (
          <TextField
            value={value}
            onChange={onChange}
            className={classes.textField}
            placeholder="6-digit pairing code"
            error={!!errors.otp}
            helperText={errors.otp?.message}
            type="otp"
          />
        )}
      />
      <UIButton
        type="submit"
        variant="text"
        size="medium"
        className={classes.submitButton}
        disabled={isSubmitting}
        loading={isSubmitting}
        text="Submit"
      />
      {data && (
        <ImageSelectionDialog
          image={data.image}
          printId={data.printId}
          canvas={data.canvas}
          printTransform={data.printTransform}
          open={isOpenSelectionDialog}
          onClose={() => closeSelectionDialog(true)}
          onConfirm={data.onConfirm}
        />
      )}
    </form>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {},
    form: {
      position: 'relative',
      marginBottom: theme.spacing(1),
    },
    textField: {
      width: '100%',
    },
    submitButton: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  });
});

export default PairingCode;
