import {
  Box,
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogProps,
} from '@material-ui/core';
import Typography from 'modules/typography';

import UIButton from 'ui-components/Button';

export interface ConfirmDialogProps extends Omit<DialogProps, 'open'> {
  message: string;
  labelConfirm?: string;
  labelCancel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  hideCancel?: boolean;
  open?: boolean;
}

/**
 * A reusable confirmation dialog with customizable title, message, button labels
 * @param {object} props
 * @param {boolean} props.open if true, the dialog is open
 * @param {function} props.onClose Callback fired when the component requests to be closed.
 * @param {string} props.message The body message
 * @param {string} props.title The title
 * @param {string} props.labelConfirm Custom label for the Confirm button
 * @param {string} props.labelCancel Custom label for the Cancel button
 * @param {function} props.onConfirm Callback fired when the Confirm button is clicked
 * @param {function} props.onCancel Callabek fired when the Cancel button is clicked
 * @param {boolean} props.hideCancel whether display the Cancel button or not
 * @returns
 */
const ConfirmDialog = ({
  open = false,
  onClose,
  message,
  labelConfirm = 'Confirm',
  labelCancel = 'Cancel',
  title,
  onConfirm,
  onCancel,
  hideCancel,
  ...props
}: ConfirmDialogProps) => {
  const handleClickCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
    if (typeof onClose === 'function') {
      onClose({}, 'backdropClick');
    }
  };
  const handleClickConfirm = () => {
    if (typeof onConfirm === 'function') {
      onConfirm();
    }
    if (typeof onClose === 'function') {
      onClose({}, 'backdropClick');
    }
  };
  return (
    <MuiDialog open={open} onClose={onClose} disableBackdropClick {...props}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <Box component={DialogContentText} whiteSpace="pre-line">
          <Typography variant="body1" fontColor="black" component="span">
            {message}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        {!hideCancel && (
          <UIButton
            variant="text"
            size="medium"
            onClick={handleClickCancel}
            text={labelCancel}
          />
        )}
        <UIButton
          variant="primary"
          size="medium"
          onClick={handleClickConfirm}
          text={labelConfirm}
        />
      </DialogActions>
    </MuiDialog>
  );
};

export default ConfirmDialog;
