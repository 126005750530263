import { useEffect } from 'react';
import { useVisible } from 'react-hooks-visible';

interface InfiniteScrollProps {
  loadMore: () => void;
  hasNextPage: boolean;
  currentPage: number;
}

export function useInfiniteScroll(props: InfiniteScrollProps) {
  const { loadMore, hasNextPage, currentPage } = props;
  const [loadingRef, isVisible] = useVisible();

  const shouldLoadMore = isVisible > 0.2 && hasNextPage;
  useEffect(() => {
    if (shouldLoadMore && currentPage >= 1) {
      const timer = setTimeout(() => {
        loadMore();
      }, 100);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [shouldLoadMore, loadMore, currentPage]);

  return loadingRef;
}
