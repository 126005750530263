import {
  Streaming,
  StreamingLogActionEnum,
  StreamingLogs,
  UserData,
  ImageData,
  ExhibitionData,
  StreamingArtworkSlotsInfo,
  STREAMING_SLOT_UNLIMITED,
  IPrintTransform,
  CanvasData,
} from 'constants/data';
import { commonErrors } from 'constants/errors';
import { useFirebaseContext } from 'shell/firebase';
import { useAuthContext } from 'shell/session';
import { responseToArray, getMembershipItem } from 'utils/common';
import { useUpdate } from './useQuery';
import { useSnackbar } from 'modules/modals';
import { useEffect, useState } from 'react';
import promiseWrapper from 'a-promise-wrapper';
import { useGetPlayingPrintsCommand } from './usePlayImageOnCanvas';
import { isStreamingUnlimited } from 'views/account/upload/helpers';

interface StreamingInput {
  artworkId: string;
  exhibitionId?: string;
}

interface UserJoinStreamingInput extends StreamingInput {
  canvasId?: string;
  transform: IPrintTransform | null;
  allowUserPlayCanVasWithoutVerifyingEmail?: boolean;
}

export interface StreamingCollections {
  [userId: string]: Streaming;
}

interface StreamingLogsCollections {
  [key: string]: StreamingLogs;
}

/**
 * Get current streaming item of current signed in user of given artworkId
 */
const useGetMyJoinedStreamingItem = () => {
  const fb = useFirebaseContext();
  const authContext = useAuthContext();
  const user = authContext.user as unknown as UserData;

  return async (artworkId: string): Promise<Streaming | null> => {
    const { uid } = user;
    if (!artworkId || !uid) {
      return null;
    }

    const ref = fb.streamingRefByArtworkIdAndUserId(artworkId, uid);
    const snapshot = await ref.once('value');
    const value = snapshot.val() as Streaming;

    return value ? { ...value, artworkId } : null;
  };
};

/**
 * Determine current signed in user is already stream given artwork or not
 */
const useIsJoinedStreaming = () => {
  const authContext = useAuthContext();
  const getStreaming = useGetMyJoinedStreamingItem();
  const user = authContext.user as unknown as UserData;

  return async (artworkId: string) => {
    if (!artworkId || !user || !user.uid) {
      return true; // Required signed in user to join
    }
    const value = await getStreaming(artworkId);

    if (!value) {
      return false;
    }

    return true;
  };
};

/**
 * The artwork's owner calls this function to enable streaming
 */
const useOwnerEnableStreaming = () => {
  const fb = useFirebaseContext();
  const updateFunc = useUpdate();
  const authContext = useAuthContext();
  const user = authContext.user as unknown as UserData;

  return async (artworkId: string) => {
    if (!artworkId || !user) {
      return;
    }
    const { uid } = user;

    const newLogKey = fb.streamingLogsRef().push().key as string;
    const updateObj = {
      [`images/${artworkId}/enableStreaming`]: true,
      [`streamingLogs/${artworkId}/${newLogKey}`]: {
        userId: uid,
        timestamp: Date.now(),
        action: StreamingLogActionEnum.OWNER_ENABLE_STREAMING,
      },
    };

    await updateFunc(updateObj);
  };
};

/**
 * The artwork's owner calls this function to disable streaming
 */
const useOwnerDisableStreaming = () => {
  const fb = useFirebaseContext();
  const updateFunc = useUpdate();
  const authContext = useAuthContext();
  const user = authContext.user as unknown as UserData;

  return async (artworkId: string) => {
    if (!artworkId || !user) {
      return;
    }
    const { uid } = user;

    const newLogKey = fb.streamingLogsRef().push().key as string;
    const updateObj = {
      [`images/${artworkId}/enableStreaming`]: false,
      [`streamingLogs/${artworkId}/${newLogKey}`]: {
        userId: uid,
        timestamp: Date.now(),
        action: StreamingLogActionEnum.OWNER_DISABLE_STREAMING,
      },
    };

    await updateFunc(updateObj);
  };
};

/**
 * The artwork's owner calls this function to set number of TOTAL streaming slots
 */
const useOwnerSetSlots = () => {
  const fb = useFirebaseContext();
  const updateFunc = useUpdate();
  const authContext = useAuthContext();
  const user = authContext.user as unknown as UserData;

  return async (artworkId: string, numberOfStreamings: number) => {
    if (!artworkId || !user) {
      return;
    }
    const { uid } = user;

    const timestamp = Date.now();
    const newLogKey = fb.streamingLogsRef().push().key as string;
    const updateObj = {
      [`images/${artworkId}/numberOfStreamings`]: numberOfStreamings,
      [`streamingLogs/${artworkId}/${newLogKey}`]: {
        userId: uid,
        timestamp,
        action: StreamingLogActionEnum.OWNER_CHANGE_NUMBER,
      },
    };

    await updateFunc(updateObj);
  };
};

/**
 * Current signed in user calls this function to join a streaming
 * After joined, the given artwork will be displayed in "My Collection" page
 * This is similar to old logic of renting ("userSelectImage")
 */

export const getMembershipPackageSlotRemain = (
  membership: string,
  streamings: Record<string, boolean>
): number => {
  const { maxPrints } = getMembershipItem(membership) || {};
  if (maxPrints) {
    return maxPrints - Object.keys(streamings).length;
  }
  return 0;
};

const validateMembership = (
  membership: string,
  streamings: Record<string, boolean>
) => {
  // if user doesn't subscribe any package
  if (!membership) {
    return true;
  }
  // if user subscribe but there are slots remain in membership package
  if (
    membership &&
    getMembershipPackageSlotRemain(membership, streamings) === 0
  ) {
    return true;
  }

  return false;
};

const useFindCanvasStreamArtworkId = () => {
  const fb = useFirebaseContext();
  const authContext = useAuthContext();
  const user = authContext.user as unknown as UserData;

  return async (artworkId: string) => {
    const { canvases } = user;
    if (!canvases) {
      return undefined;
    }
    const canvasIds = Object.keys(canvases).map(canvasId => {
      return fb.canvasRef(canvasId).once('value');
    });

    return Promise.all(canvasIds)
      .then(snapshots => {
        const canvases = snapshots.map(snapshot => ({
          ...snapshot.val(),
          uid: snapshot.key,
        }));
        return canvases.find(canvas => canvas.originalArtwork === artworkId);
      })
      .catch(error => {
        console.error(error);
        return undefined;
      });
  };
};

const useMemberStreamArtwork = () => {
  const fb = useFirebaseContext();
  const updateFunc = useUpdate();
  const getPlayingPrints = useGetPlayingPrintsCommand();
  const getCanvas = useFindCanvasStreamArtworkId();
  const getListStreamingArtworksData = useGetListStreamingArtworksData();
  const authContext = useAuthContext();
  const user = authContext.user as unknown as UserData;
  const { isEmailVerified } = authContext;

  return async (input: UserJoinStreamingInput) => {
    const {
      artworkId,
      canvasId,
      allowUserPlayCanVasWithoutVerifyingEmail,
      transform,
    } = input;
    if (!artworkId || !user || !user.uid || !canvasId) {
      throw new Error(commonErrors.INVALID_PARAMS_ERROR);
    }
    const { uid } = user;

    if (!allowUserPlayCanVasWithoutVerifyingEmail && !isEmailVerified) {
      throw new Error(commonErrors.NEED_VERIFY_EMAIL_ERROR);
    }

    // find any print is playing on this canvas and replace that print with current artwork it
    const { data: playingPrintUpdates, error: fetchPlayingPrintError } =
      await promiseWrapper(getPlayingPrints(canvasId));

    if (fetchPlayingPrintError) {
      throw fetchPlayingPrintError;
    }

    // find a canvas in list canvas of user are playing this artwork and remove current artwork
    const canvas: CanvasData | undefined = await getCanvas(artworkId);

    const timestamp = Date.now();
    let updateObj: any = {
      [`${fb.canvases()}/${canvasId}/transform`]: transform,
      [`${fb.canvases()}/${canvasId}/originalArtwork`]: artworkId,
      [`${fb.canvases()}/${canvasId}/media_last_changed`]: timestamp,
      [`${fb.canvases()}/${canvasId}/userId`]: uid,
      [`${fb.streamings()}/${artworkId}/${user.uid}/canvasId`]: canvasId,
    };

    // if this artwork was streaming on another canvas device,
    // we should remove the artwork info in that canvas
    // in order to streaming the artwork in the new selected canvas
    const isDifferentCanvas = canvas && canvas.uid !== canvasId;
    if (isDifferentCanvas) {
      updateObj = {
        ...updateObj,
        [`${fb.canvases()}/${canvas.uid}/originalArtwork`]: null,
        [`${fb.canvases()}/${canvas.uid}/media_last_changed`]: Date.now(),
      };
    }

    // if current canvas is play another original artwork
    // check if user own the artwork or user stream the artwork
    // if user own the artwork, no action need
    // if user stream the artwork, update data below
    if (canvas && canvas.originalArtwork) {
      const { data: artworkSnapshot, error: loadArtworkError } =
        await promiseWrapper(fb.imageRef(canvas.originalArtwork).once('value'));
      const originalArtworkPlayOnCanvas: ImageData = artworkSnapshot.val();
      if (loadArtworkError) {
        throw new Error(commonErrors.ARTWORK_PLAY_ON_CANVAS_NOT_FOUND);
      }
      const isArtworkOwner = originalArtworkPlayOnCanvas.owner === user.uid;
      const isTheSameArtwork = canvas.originalArtwork === artworkId;
      if (!isArtworkOwner && !isTheSameArtwork) {
        updateObj = {
          ...updateObj,
          [`users/${user.uid}/streamings/${canvas.originalArtwork}`]: false,
          [`streamings/${canvas.originalArtwork}/${user.uid}/canvasId`]: null,
        };
      }
    }

    // For case: user streaming different artwork on the same canvasId,
    // Remove canvasId from other artwork of same user
    const { data: streamingArtworksData, error: streamingArtworksError } =
      await promiseWrapper(getListStreamingArtworksData());

    if (streamingArtworksError) {
      throw new Error(streamingArtworksError);
    }

    if (streamingArtworksData?.length) {
      streamingArtworksData.forEach(artwork => {
        if (
          artwork &&
          artwork.artworkId !== artworkId &&
          artwork.canvasId === canvasId
        ) {
          updateObj[`streamings/${artwork.artworkId}/${uid}/canvasId`] = null;
        }
      });
    }

    await updateFunc({ ...updateObj, ...playingPrintUpdates });
  };
};

const useMemberJoinStreaming = () => {
  const fb = useFirebaseContext();
  const updateFunc = useUpdate();
  const authContext = useAuthContext();
  const isJoinedFunc = useIsJoinedStreaming();
  const streamingArtwork = useMemberStreamArtwork();
  const user = authContext.user as unknown as UserData;
  const { isEmailVerified } = authContext;

  return async (input: UserJoinStreamingInput) => {
    const {
      artworkId,
      exhibitionId,
      canvasId,
      allowUserPlayCanVasWithoutVerifyingEmail,
    } = input;
    if (!artworkId || !user || !user.uid) {
      throw new Error(commonErrors.INVALID_PARAMS_ERROR);
    }
    const { uid, freeSlot, membership, streamings } = user;

    if (!allowUserPlayCanVasWithoutVerifyingEmail && !isEmailVerified) {
      throw new Error(commonErrors.NEED_VERIFY_EMAIL_ERROR);
    }

    const useFreeSlot =
      (!freeSlot || !freeSlot.current) &&
      exhibitionId &&
      validateMembership(membership, streamings || {});
    const isJoined = await isJoinedFunc(artworkId);

    if (isJoined) {
      return streamingArtwork(input);
    }

    const timestamp = Date.now();
    const newLogKey = fb.streamingLogsRef().push().key as string;
    let updateObj: any = {
      [`${fb.streamings()}/${artworkId}/${uid}`]: {
        timestamp,
        exhibitionId: exhibitionId || null,
        isFreeSlot: useFreeSlot || null,
        canvasId,
      },
      [`${fb.streamingLogs()}/${artworkId}/${newLogKey}`]: {
        userId: uid,
        timestamp,
        action: StreamingLogActionEnum.MEMBER_JOIN,
      },
    };

    if (useFreeSlot) {
      updateObj = {
        ...updateObj,
        [`users/${uid}/freeSlot/current`]: {
          artworkId,
          exhibitionId,
        },
      };
    } else {
      updateObj = {
        ...updateObj,
        [`users/${uid}/streamings/${artworkId}`]: true,
      };
    }

    await updateFunc(updateObj);
    await streamingArtwork(input);
  };
};

/**
 * Current signed in user calls this function to join a streaming
 * After leave, the given artwork will be removed in "My Collection" page
 * This is similar to old logic of remove a rented artwork
 */
const useMemberLeaveStreaming = () => {
  const fb = useFirebaseContext();
  const updateFunc = useUpdate();
  const authContext = useAuthContext();
  const getStreaming = useGetMyJoinedStreamingItem();
  const getCanvas = useFindCanvasStreamArtworkId();
  const user = authContext.user as unknown as UserData;

  return async (input: StreamingInput) => {
    const { artworkId, exhibitionId } = input;
    const { uid, freeSlot } = user;
    const canvas: CanvasData = await getCanvas(artworkId);
    const isFreeSlot =
      freeSlot &&
      freeSlot.current?.artworkId === artworkId &&
      freeSlot.current?.exhibitionId === exhibitionId;
    if (!artworkId || !user) {
      return;
    }

    const streamingValue = await getStreaming(artworkId);

    if (!streamingValue) {
      return;
    }

    const newLogKey = fb.streamingLogsRef().push().key as string;

    let updateObj: { [key: string]: any } = {
      [`streamingLogs/${artworkId}/${newLogKey}`]: {
        userId: uid,
        exhibitionId: exhibitionId || null,
        timestamp: Date.now(),
        action: StreamingLogActionEnum.MEMBER_LEAVE,
      },
      [`streamings/${artworkId}/${uid}`]: null,
    };

    if (canvas) {
      updateObj = {
        ...updateObj,
        [`canvases/${canvas.uid}/originalArtwork`]: null,
        [`canvases/${canvas.uid}/media_last_changed`]: Date.now(),
      };
    }

    if (isFreeSlot) {
      updateObj = {
        ...updateObj,
        [`users/${uid}/freeSlot/current`]: null,
      };
    } else {
      updateObj = {
        ...updateObj,
        [`users/${uid}/streamings/${artworkId}`]: null,
      };
    }

    await updateFunc(updateObj);
  };
};

/**
 *
 **/
const useGetListStreamingArtworksData = () => {
  const authContext = useAuthContext();
  const getStreaming = useGetMyJoinedStreamingItem();
  const user = authContext.user as unknown as UserData;

  return async () => {
    if (!user || !user.uid) {
      throw new Error(commonErrors.INVALID_PARAMS_ERROR);
    }
    const { streamings } = user;

    const artworks = streamings ? Object.keys(streamings) : [];
    if (artworks.length) {
      const { data: streamingArtworks, error: streamingsError } =
        await promiseWrapper(
          Promise.all(
            artworks.map(async artworkId => await getStreaming(artworkId))
          )
        );
      if (streamingsError) {
        throw new Error(streamingsError);
      }
      return streamingArtworks || [];
    }
    return [];
  };
};

/**
 * Get list ALL who currently streaming the given artwork
 * No matter what in exhibition or in marketplace
 */
const useGetListStreamings = () => {
  const fb = useFirebaseContext();

  return async (artworkId: string) => {
    const ref = fb.streamingRefByArtworkId(artworkId);
    const snapshot = await ref.once('value');
    const value = snapshot.val() as StreamingCollections;

    if (!value) {
      return [];
    }

    return responseToArray(value);
  };
};

/**
 * Get list ALL who currently streaming the given artwork
 * In given exhibition or marketplace
 */
const useGetStreamsInExhibition = () => {
  const fb = useFirebaseContext();

  return async (artworkId: string, exhibitionId: string | null) => {
    const ref = fb.streamingRefByArtworkId(artworkId);
    // NOTE: To get current user streaming in marketplace, Currently we use query equalTo(null)
    // This query does not include exhibitionId="" (empty string)
    const query = ref.orderByChild('exhibitionId').equalTo(exhibitionId);
    const snapshot = await query.once('value');
    const value = snapshot.val() as StreamingCollections;

    if (!value) {
      return [];
    }

    return responseToArray(value);
  };
};

/**
 * Get list ALL who currently streaming the given artwork in ALL exhibitions
 */
const useGetStreamsInAllExhibitions = () => {
  const fb = useFirebaseContext();

  return async (artworkId: string) => {
    const ref = fb.streamingRefByArtworkId(artworkId);
    const query = ref.orderByChild('exhibitionId').startAt(false);
    const snapshot = await query.once('value');
    const value = snapshot.val() as StreamingCollections;

    if (!value) {
      return [];
    }

    return responseToArray(value);
  };
};

/**
 * Get streamed artworks of current signed in user
 * Use to display my streams in "My Collection" page
 */
// TODO
// This is no longer good logic since we can always get from AuthContext
const useGetMyStreamedImageIds = () => {
  const fb = useFirebaseContext();
  const authContext = useAuthContext();
  const user = authContext.user as unknown as UserData;

  return async () => {
    if (!user) {
      return [];
    }
    const { uid } = user;
    const ref = fb.userRef(uid);
    const snapshot = await ref.once('value');
    const value = snapshot.val() as UserData;

    if (!value) {
      return [];
    }

    const { streamings } = value;
    return streamings ? Object.keys(streamings) : [];
  };
};

/**
 * Get streaming exhibitions of current signed in user
 * Use in "My Collection" page
 */
const useGetMyStreamingExhibitionsMap = () => {
  const fb = useFirebaseContext();
  const authContext = useAuthContext();
  const user = authContext.user as unknown as UserData;

  return async () => {
    if (!user || (!user.streamings && !user.freeSlot?.current)) {
      return null;
    }

    const { uid, freeSlot } = user;
    const regularImageIds = Object.keys(user.streamings || {});
    const currentfreeSlot = freeSlot?.current;
    const hasFreeSlot = currentfreeSlot && currentfreeSlot.artworkId;
    const freeImageId = hasFreeSlot ? [currentfreeSlot.artworkId] : [];
    const imageIds = [...regularImageIds, ...freeImageId];

    const streamingQuery = (imageId: string) => {
      const ref = fb.streamingRefByArtworkIdAndUserId(imageId, uid);
      return ref;
    };
    const promises = imageIds.map(imageId =>
      streamingQuery(imageId).once('value')
    );
    const streamingSnapshots = await Promise.all(promises);
    const streamings = streamingSnapshots
      .map(sn => sn.val() as Streaming)
      .filter(v => !!v);
    const exhibitionIds = streamings.map(s => s.exhibitionId);
    const exhibitionPromises = exhibitionIds.map(id =>
      id ? fb.exhibitionRef(id).once('value') : Promise.resolve(null)
    );
    const exhibitions = await Promise.all(exhibitionPromises);

    const result: { [imageId: string]: ExhibitionData } = imageIds.reduce(
      (res, imageId, index) => {
        const value = exhibitions[index];
        const exhibition = value ? (value.val() as ExhibitionData) : null;

        return {
          ...res,
          [imageId]: exhibition,
        };
      },
      {}
    );

    return result;
  };
};

/**
 * Get all history of an artwork for both owner and members
 */
const useGetFullStreamingHistory = () => {
  const fb = useFirebaseContext();

  return async (artworkId: string) => {
    if (!artworkId) {
      return [];
    }

    const ref = fb.streamingLogsRefByArtworkId(artworkId);
    const snapshot = await ref.once('value');
    const value = snapshot.val() as StreamingLogsCollections;

    if (!value) {
      return [];
    }

    return responseToArray(value);
  };
};

/**
 * Get all history of an artwork of the owner
 */
const useGetOwnerStreamingHistory = () => {
  const fb = useFirebaseContext();

  return async (artworkId: string, ownerId: string) => {
    if (!artworkId || !ownerId) {
      return [];
    }

    const ref = fb.streamingLogsRefByArtworkId(artworkId);
    const query = ref.orderByChild('userId').equalTo(ownerId);
    const snapshot = await query.once('value');
    const value = snapshot.val() as StreamingLogsCollections;

    if (!value) {
      return [];
    }

    return responseToArray(value);
  };
};

/**
 * Get all history of an artwork of the member
 */
const useGetMemberStreamingHistory = () => {
  const fb = useFirebaseContext();

  return async (artworkId: string, userId: string) => {
    if (!artworkId || !userId) {
      return [];
    }

    const ref = fb.streamingLogsRefByArtworkId(artworkId);
    const query = ref.orderByChild('userId').equalTo(userId);
    const snapshot = await query.once('value');
    const value = snapshot.val() as StreamingLogsCollections;

    if (!value) {
      return [];
    }

    return responseToArray(value);
  };
};

type streamingArtworkProps = {
  enableStreaming: boolean;
  isUnlimitedStreamingSlots: boolean;
  numberOfStreamingSlots: number;
  image: ImageData;
};

export const useUpdateStreamingArtwork = () => {
  const fb = useFirebaseContext();
  const updateDatabase = useUpdate();
  const authContext = useAuthContext();
  const user = authContext.user as unknown as UserData;

  return ({
    enableStreaming,
    isUnlimitedStreamingSlots,
    numberOfStreamingSlots,
    image,
  }: streamingArtworkProps) => {
    const { uid } = user;
    const imageId = image.uid;
    const newLogKey = fb.streamingLogsRef().push().key as string;
    const timestamp = Date.now();
    let numberOfStreamings = image.numberOfStreamings;
    if (enableStreaming) {
      numberOfStreamings = isUnlimitedStreamingSlots
        ? STREAMING_SLOT_UNLIMITED
        : numberOfStreamingSlots;
    }
    const update: Record<string, any> = {
      [`images/${imageId}/enableStreaming`]: enableStreaming,
      [`images/${imageId}/numberOfStreamings`]: numberOfStreamings,
      [`streamingLogs/${imageId}/${newLogKey}`]: {
        userId: uid,
        timestamp,
        action: StreamingLogActionEnum.OWNER_UPDATE,
        metadataUpdate: {
          enableStreaming: enableStreaming,
          numberOfStreamings: numberOfStreamings,
        },
      },
    };
    return updateDatabase(update);
  };
};

/**
 * Get total streaming slots of all exhibitons of given artwork Id
 **/
const useGetAllExhibitionsStreamSlots = () => {
  const fb = useFirebaseContext();

  return async (artworkId: string) => {
    const query = fb.streamingExhibitionsRefByArtworkId(artworkId);
    const snapshot = await query.once('value');
    const listExhibitions = snapshot.val();
    if (!listExhibitions) {
      return 0;
    }
    return Object.values(listExhibitions).reduce(
      (total: number, numberSlots: any) =>
        total +
        (typeof numberSlots === 'string' ? parseInt(numberSlots) : numberSlots),
      0
    );
  };
};

/**
 * Get Streaming info of given exhibition Id or in marketplace
 **/
const useGetExhibitionArtworkStreaming = () => {
  const { user } = useAuthContext();
  const getCurrentStreamingSlots = useGetStreamsInExhibition();
  const getAllExhibitionsStreamSlots = useGetAllExhibitionsStreamSlots();
  const isJoinedFunc = useIsJoinedStreaming();

  return async (artwork: ImageData, exhibition?: ExhibitionData | null) => {
    const artworkId = artwork?.uid || null;
    if (!artworkId) {
      return null;
    }
    const exhibitionId = exhibition?.uid || null;
    const streamingInfo: StreamingArtworkSlotsInfo = {
      exhibitionId,
      artworkId,
      availableSlots: 0,
      totalSlots: 0,
      currentUsersCount: 0,
    };

    let totalSlots = 0;
    if (exhibitionId) {
      // total slots in exhibition
      totalSlots =
        (exhibition?.streamings && exhibition.streamings[artworkId]) || 0;
    } else {
      // total slots in marketplace
      totalSlots = artwork?.numberOfStreamings || 0;
    }
    streamingInfo.totalSlots = totalSlots;

    // Calculate remaining available slots
    let remainingSlots = totalSlots;
    if (!isStreamingUnlimited(totalSlots)) {
      // If is in exhibition then get current streaming of given exhibition
      // If is in marketplace then get current streaming of marketplace only
      const userList = await getCurrentStreamingSlots(artworkId, exhibitionId);
      if (userList.length > 0) {
        streamingInfo.currentUsersCount = userList.length;
        remainingSlots = streamingInfo.totalSlots - userList.length;
      }
      // If is in marketplace remainingSlots should exclude all slots from exhibitions
      if (!exhibitionId) {
        const totalExhibitionsStreamSlots = await getAllExhibitionsStreamSlots(
          artworkId
        );
        remainingSlots -= totalExhibitionsStreamSlots;
      }
    }
    streamingInfo.availableSlots = remainingSlots;

    if (user?.uid) {
      const isJoined = await isJoinedFunc(artworkId);
      streamingInfo.isUserJoined = isJoined;
    }

    return streamingInfo;
  };
};

const useLoadMyStreamingArtworks = () => {
  const firebase = useFirebaseContext();
  const [images, setImages] = useState<ImageData[] | null>();
  const authContext = useAuthContext();
  const user = authContext.user as unknown as UserData;
  useEffect(() => {
    if (user) {
      const { streamings, freeSlot } = user;
      if (!streamings && !freeSlot) {
        setImages(null);
        return;
      }
      const artworkIds = Object.keys(streamings || {});
      let promises = artworkIds.map(imageId =>
        firebase.imageRef(imageId).once('value')
      );
      if (freeSlot && freeSlot.current) {
        promises = promises.concat(
          firebase.imageRef(freeSlot.current.artworkId).once('value')
        );
      }
      Promise.all(promises)
        .then(snapshots => {
          const images = snapshots
            .filter(snapshot => !!snapshot)
            .map(snapshot => snapshot.val());
          setImages(images);
        })
        .catch(error => {
          console.error(error);
          setImages(null);
        });
    }
  }, [firebase, user]);

  return { images, isLoading: typeof images === 'undefined' };
};

interface TransferArtwork {
  artworkId: string;
  userId: string;
  onSuccess: () => void;
  onFail: () => void;
}

const useTransferArtwork = () => {
  const fb = useFirebaseContext();
  const { showSnackbar } = useSnackbar();

  return ({ artworkId, userId, onSuccess, onFail }: TransferArtwork) => {
    const query = fb.db.ref(`streamings/${artworkId}/${userId}`);

    query.once(
      'value',
      snapshot => {
        if (!snapshot) {
          throw new Error('Can not find record');
        }
        const value = snapshot.val() as Streaming;
        const callable = fb.functions.httpsCallable(
          'streaming-transferArtwork'
        );
        callable({
          artworkId: artworkId,
          canvasId: value.canvasId,
          exhibitionId: value.exhibitionId,
          userId: userId,
        })
          .then(() => {
            console.info(
              `Transfer artwork -${artworkId} successfully to user ${userId}`
            );
            onSuccess();
          })
          .catch(error => {
            console.error(error);
            showSnackbar(`Transfer artwork - ${error.message}`, {
              severity: 'error',
            });
            onFail();
          });
      },
      error => {
        console.error(error);
        showSnackbar(`Query streaming exhibition - ${error.message}`, {
          severity: 'error',
        });
      }
    );
  };
};

export {
  useOwnerEnableStreaming,
  useOwnerDisableStreaming,
  useOwnerSetSlots,
  useMemberJoinStreaming,
  useMemberLeaveStreaming,
  useGetListStreamings,
  useGetStreamsInExhibition,
  useGetStreamsInAllExhibitions,
  useGetMyStreamedImageIds,
  useGetMyStreamingExhibitionsMap,
  useGetFullStreamingHistory,
  useGetOwnerStreamingHistory,
  useGetMemberStreamingHistory,
  useGetExhibitionArtworkStreaming,
  useLoadMyStreamingArtworks,
  useTransferArtwork,
  useMemberStreamArtwork,
  useGetMyJoinedStreamingItem,
  useGetListStreamingArtworksData,
};
