import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { mobileMediaQuery } from 'utils/common';
import CalendarIcon from 'static/images/icons/calendar_icon.png';
import React from 'react';
import { IResponseInviter } from 'constants/data/betaInvite.data';

interface Props {
  inviter?: IResponseInviter | null;
}

const FreeMonthBox = ({ inviter }: Props) => {
  const classes = useStyles();
  const { name, isInvitedByAdmin } = inviter || {};

  if (!inviter) {
    return null;
  }
  return (
    <Box className={classes.container}>
      <Box className={classes.freeMonthBox}>
        <Box className={classes.invite}>
          <img
            src={CalendarIcon}
            alt="Get free month"
            className={classes.calendarIcon}
          />
          <Box>
            <Typography className={classes.inviteText}>
              {isInvitedByAdmin ? 'Admin' : name} invited you
            </Typography>
            <Typography className={classes.freeMonthText}>
              Get a free month
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" className={classes.bothText}>
          {isInvitedByAdmin ? 'You will ' : `Both you and ${name} will each `}
          get a month for free when you sign up with a subscription
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    container: {
      padding: '0 46px',
      width: '100%',
      [mobile]: {
        padding: 0,
      },
    },
    freeMonthBox: {
      backgroundColor: '#F9F9F9',
      padding: '28px 20px',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [mobile]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: 'transparent',
      },
    },
    calendarIcon: {
      width: '32px',
      objectFit: 'contain',
      marginRight: theme.spacing(2),
    },
    invite: {
      maxWidth: '250px',
      marginRight: theme.spacing(5),
      display: 'flex',
      flexDirection: 'row',
      [mobile]: {
        marginRight: 0,
        marginBottom: theme.spacing(2),
      },
    },
    inviteText: {
      textTransform: 'uppercase',
      fontSize: '0.69rem',
      lineHeight: '0.8rem',
    },
    freeMonthText: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
    },
    bothText: {
      maxWidth: '410px',
    },
  });
});

export default FreeMonthBox;
