import { useFirebaseContext } from 'shell/firebase';
import promiseWrapper from 'a-promise-wrapper';
import {
  usePlayOriginalArtwork,
  usePlayPrint,
} from 'utils/requests/usePlayImageOnCanvas';
import { pairingCodeErrors } from 'constants/errors';
import { IPrintTransform } from 'constants/data/print.data';
import { useMemberJoinStreaming } from './useStreaming';

export interface PlayArtworkParams {
  canvasId: string;
  printId?: string;
  imageId: string;
  transform: IPrintTransform | null;
  exhibitionId?: string;
  isStreaming?: boolean;
}

export const usePairingCode = () => {
  const firebase = useFirebaseContext();
  const playPrint = usePlayPrint();
  const playOriginal = usePlayOriginalArtwork();
  const joinStreaming = useMemberJoinStreaming();
  const playArtworkByOTP = async ({
    otp,
    printId,
    imageId,
  }: {
    otp: string;
    printId?: string;
    imageId: string;
  }) => {
    const callable = firebase.functions.httpsCallable('createSignInToken');

    const { data, error } = await promiseWrapper(callable({ otp }));

    if (error) {
      throw new Error(pairingCodeErrors.INVALID_OTP);
    }

    const canvasId = data.data;

    return playArtwork({
      printId,
      imageId,
      canvasId,
      transform: null,
    });
  };

  const playArtwork = async ({
    canvasId,
    printId,
    imageId,
    transform,
    exhibitionId,
    isStreaming,
  }: PlayArtworkParams) => {
    if (isStreaming) {
      return joinStreaming({
        artworkId: imageId,
        exhibitionId: exhibitionId,
        canvasId: canvasId,
        transform,
        allowUserPlayCanVasWithoutVerifyingEmail: true,
      });
    }
    if (printId) {
      return playPrint({ printId, canvasId, transform });
    } else {
      return playOriginal({ imageId, canvasId, transform });
    }
  };

  const signInByOTP = async ({ otp }: { otp: string }) => {
    const callable = firebase.functions.httpsCallable('createSignInToken');

    const { error } = await promiseWrapper(callable({ otp }));

    if (error) {
      throw new Error(pairingCodeErrors.INVALID_OTP);
    }
  };

  return {
    playArtworkByOTP, // TODO: it seems no longer used
    signInByOTP,
    playArtwork,
  };
};
