import { Box, CircularProgress } from '@material-ui/core';
import Typography from 'modules/typography';
import { useLoadUser } from 'utils/requests';

const UserCell = ({ userId }: { userId: string }) => {
  const { user, isLoading } = useLoadUser(userId);

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  if (!user) {
    return null;
  }

  return (
    <Box data-uid={userId}>
      <Typography>{user.fullName}</Typography>
      <Typography color="textSecondary">{user.email}</Typography>
    </Box>
  );
};

export default UserCell;
