import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  DialogProps,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState } from 'react';
import { useLoadCountriesList } from 'modules/dialogs/helpers';

import UIButton from 'ui-components/Button';

interface Props extends DialogProps {
  isLoading?: boolean;
  onClose: () => void;
  onNext: (payload?: any) => void;
}

interface ICountry {
  name: string;
  value: string;
}

const SelectCountriesDialog = (props: Props) => {
  const { isLoading, onClose, onNext, ...dialogProps } = props;
  const [country, setCountry] = useState<string>('');
  const [list, setList] = useState<ICountry[]>([]);
  const classes = useStyles();
  const loadCountriesList = useLoadCountriesList();

  const handleClose = (reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (!reason) {
      onClose && onClose();
    }
  };

  const handleOnNext = () => {
    onNext && onNext({ country });
  };

  useEffect(() => {
    const loadList = async () => {
      const countrieslist = await loadCountriesList();
      setList(countrieslist);
    };
    loadList();
  }, [loadCountriesList]);

  return (
    <Dialog
      {...dialogProps}
      disableEscapeKeyDown
      BackdropProps={{ invisible: true }}
      classes={{
        paper: classes.paper,
      }}
      onClose={(event, reason) => handleClose(reason)}>
      <DialogContent className={classes.dialogContent}>
        <CloseIcon
          onClick={() => handleClose()}
          className={classes.closeButton}
        />
        <Box className={classes.content}>
          <FormControl className={classes.formControl}>
            <InputLabel id="select-country-label">Select Country</InputLabel>
            <Select
              labelId="select-country-label"
              id="select-country"
              value={country}
              onChange={(e: any) => setCountry(e?.target?.value || '')}>
              {list.map(country => (
                <MenuItem key={country.value} value={country.value}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <UIButton
            className={classes.button}
            variant="primary"
            size="medium"
            onClick={handleOnNext}
            loading={isLoading}
            disabled={isLoading}
            text="Submit"
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    paper: {
      color: '#000',
      minHeight: 160,
      height: 'auto',
      maxWidth: 400,
    },
    content: {
      'display': 'flex',
      'flexDirection': 'column',
      'padding': 0,
      '&:first-child': {
        paddingTop: 0,
      },
    },
    dialogContent: {
      padding: theme.spacing(3, 8, 3, 3),
      position: 'relative',
    },
    closeButton: {
      cursor: 'pointer',
      position: 'absolute',
      right: 24,
      top: 24,
      fontSize: '1rem',
    },
    formControl: {
      marginBottom: theme.spacing(2),
      minWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    button: {
      marginRight: theme.spacing(2),
    },
  });
});

export default SelectCountriesDialog;
