/**
 * Transform the response from firebase to array, also add `id` to each object
 * @param data The response from firebase query, it is gotten from `snapshot.val()`
 * @param idKey The `id` key to be add to the object, default to `id`
 * @returns
 */
export function responseToArray<T>(data: any, idKey = 'id'): Array<T> {
  return Object.entries<T>(data).map(([id, value]) => ({
    ...value,
    [idKey]: id,
  }));
}
