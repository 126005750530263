import React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import UILink from 'ui-components/Link';

import UIButton from 'ui-components/Button';

const NewsletterLink = () => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.label}>
        Sign up for our newsletter!
      </Typography>
      <UILink
        href="https://info.activeimage.io/newsletter-sign-up/"
        target="_blank"
        className={classes.subscribeLink}>
        <UIButton variant="secondary-inverted" size="small" text="Subscribe" />
      </UILink>
    </Box>
  );
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'block',
      marginTop: theme.spacing(2),
    },
    fields: {
      padding: theme.spacing(2.5),
    },
    field: {
      position: 'relative',
      maxWidth: 260,
    },
    loadingWrapper: {
      position: 'absolute',
      right: 5,
      top: 40,
    },
    label: {
      fontSize: theme.typography.pxToRem(20),
      color: '#fff',
      marginBottom: theme.spacing(2),
    },
    input: {
      padding: theme.spacing(1),
    },
    subscribeLink: {
      'textDecoration': 'none',
      '&:hover': {
        opacity: 1,
        textDecoration: 'none',
      },
    },
  })
);

export default NewsletterLink;
