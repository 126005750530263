import { useEffect, useRef } from 'react';
import { TextField as MuiTextField } from '@material-ui/core';
import useStyles from './styles';

const FileField = ({ onChange, value, inputProps, ...props }) => {
  const classes = useStyles();
  const inputRef = useRef();
  const handleChange = event => {
    if (onChange) {
      const file = inputProps.multiple
        ? event?.target.files
        : event?.target.files[0];
      onChange(file, event);
    }
  };
  useEffect(() => {
    if (!value && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [value]);
  return (
    <MuiTextField
      {...props}
      onChange={handleChange}
      variant="outlined"
      inputProps={inputProps}
      inputRef={inputRef}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          notchedOutline: classes.inputNotchedOutline,
          error: classes.inputError,
          input: classes.inputInput,
          inputMultiline: classes.inputInputMultiline,
        },
      }}
      InputLabelProps={{
        disableAnimation: true,
        shrink: false,
        classes: {
          root: classes.labelRoot,
          formControl: classes.labelFormControl,
        },
      }}
      FormHelperTextProps={{
        classes: {
          contained: classes.formHelperTextContained,
        },
      }}
    />
  );
};

export default FileField;
