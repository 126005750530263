import { useFirebaseContext } from 'shell/firebase';
import { useAuthContext } from 'shell/session';
import { UserData } from 'constants/data';

export interface IStripeAccountLink {
  country: string;
  refreshUrl: string;
  returnUrl: string;
}

interface IClickConnectBank {
  openDialog: (modalData?: unknown) => void;
  connectStripe: (payload?: any) => Promise<string | undefined>;
}

export const useStripeConnect = () => {
  const firebase = useFirebaseContext();

  return (payload: IStripeAccountLink) => {
    if (!firebase) {
      return Promise.reject('firebase is not defined');
    }
    const callable = firebase.functions.httpsCallable(
      'payment-getStripeAccountLink'
    );
    return callable(payload);
  };
};

export const useGetStripeBankAccounts = () => {
  const firebase = useFirebaseContext();

  return () => {
    if (!firebase) {
      return Promise.reject('firebase is not defined');
    }
    const callable = firebase.functions.httpsCallable(
      'payment-getStripeBankAccounts'
    );
    return callable();
  };
};

export const useClickConnectBank = () => {
  const auth = useAuthContext();
  const user = auth.user as UserData;

  return ({ openDialog, connectStripe }: IClickConnectBank) => {
    if (!user) {
      return;
    }
    const { stripeConnect } = user;
    const { id: stripeConnectId } = stripeConnect || {};
    if (!stripeConnectId) {
      openDialog();
      return;
    }

    connectStripe();
  };
};
