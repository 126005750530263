import React from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import Today from '@material-ui/icons/Today';
import useStyles from './styles';
import cs from 'classnames';
import { DateTimePicker, DateTimePickerProps } from '@material-ui/pickers';

// These are the default min date and max date of the lib @material-ui/pickers
const defaultMinDate = new Date('1900-01-01');
const defaultMaxDate = new Date('2100-01-01');

const DateTimePickerField = (props: DateTimePickerProps) => {
  const classes = useStyles();
  const {
    InputProps,
    FormHelperTextProps,
    DialogProps,
    onChange,
    value,
    minDate,
    maxDate,
    ...rest
  } = props;
  const customClasses = InputProps?.classes;

  return (
    <DateTimePicker
      inputVariant="outlined"
      value={value || null}
      onChange={onChange}
      showTodayButton
      clearable
      minDate={minDate || defaultMinDate}
      maxDate={maxDate || defaultMaxDate}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <Today />
            </IconButton>
          </InputAdornment>
        ),
        classes: {
          root: cs(classes.inputRoot, customClasses?.root),
          error: cs(classes.inputError, customClasses?.error),
          input: cs(classes.inputInput, customClasses?.input),
          inputMultiline: cs(
            classes.inputInputMultiline,
            customClasses?.inputMultiline
          ),
          notchedOutline: classes.inputNotchedOutline,
        },
      }}
      InputLabelProps={{
        disableAnimation: true,
        shrink: true,
        classes: {
          root: classes.labelRoot,
          formControl: classes.labelFormControl,
          shrink: classes.labelShrink,
          outlined: classes.labelOutlined,
        },
      }}
      FormHelperTextProps={{
        ...FormHelperTextProps,
        classes: {
          contained: cs(
            classes.formHelperTextContained,
            FormHelperTextProps?.classes?.contained
          ),
        },
      }}
      {...rest}
    />
  );
};

export default DateTimePickerField;
