import { ImageData, ImageStatusEnum } from 'constants/data/image.data';
import { sanitizeString } from './sanitizer';
import { multiValuesStringToArray } from './multipleValuesStringToArray';
import getEnvVars from './envVars';
export const UNCATEGORIZED = '<uncategorized>';

export const IMAGE_FILTER_ALL_INCLUDE_ARCHIVED = 'AllIncludeArchived';

interface FilterFunctionFactory<T = any, P extends {} = any> {
  (filterValue: T, options?: P): FilterFunction;
}
export interface FilterFunction {
  (image: ImageData): boolean;
}

/**
 * Filter the images using set of `FilterFunction`s, an image is returned only when all `FilterFunction`s return true
 *
 * @example
 *
 * // This will return the images whose `catalog` appears in `selectedCatalogs` AND `color` appears in `selectedColors`
 * filterImages(images, isPassCatalogFilter(selectedCatalogs), isPassColorFilter(selectedColors))
 *
 * @param images
 * @param filterFuncs set of FilterFunction
 * @returns
 */
export const filterImages = (
  images: ImageData[],
  ...filterFuncs: FilterFunction[]
) => {
  return images.filter(image =>
    filterFuncs.every(filterFunc => filterFunc(image))
  );
};

/**
 * Create a FilterFunction to filter image by `catalog`
 * @param catalogArr
 * @returns FilterFunction
 */
export const isPassCatalogFilter: FilterFunctionFactory =
  (catalogArr: string[]) => (image: ImageData) => {
    if (catalogArr?.length === 0) {
      return true;
    }
    const imageCatalogArray = multiValuesStringToArray(
      image.catalog || UNCATEGORIZED
    );
    return catalogArr.some(catalog => imageCatalogArray.includes(catalog));
  };

/**
 * Create a FilterFunction to filter image by `association`
 * @param associationArr
 * @returns FilterFunction
 */
export const isPassAssociationFilter: FilterFunctionFactory =
  (associationArr: string[]) => (image: ImageData) => {
    if (associationArr?.length === 0) {
      return true;
    }
    if (!image.associations) return false;
    const imageAssociationArray = multiValuesStringToArray(image.associations);
    return associationArr.some(association =>
      imageAssociationArray.includes(sanitizeString(association))
    );
  };

/**
 * Create a FilterFunction to filter image by `color`
 * @param colorArr
 * @returns FilterFunction
 */
export const isPassColorFilter: FilterFunctionFactory =
  (colorArr: string[]) => (image: ImageData) => {
    if (colorArr?.length === 0) {
      return true;
    }
    const imageColorArray = multiValuesStringToArray(image.color);
    return colorArr.some(color => imageColorArray.includes(color));
  };

/**
 * Create a FilterFunction to filter image by `brightness`
 * @param brightnessArr
 * @returns FilterFunction
 */
export const isPassBrightnessFilter: FilterFunctionFactory =
  (brightnessArr: number[]) => (image: ImageData) => {
    if (brightnessArr?.length === 0) {
      return true;
    }
    return brightnessArr.includes(image.brightness);
  };

/**
 * Create a FilterFunction to filter image by `abstraction`
 * @param abstractionArr
 * @returns FilterFunction
 */
export const isPassAbstractionFilter: FilterFunctionFactory =
  (abstractionArr: number[]) => (image: ImageData) => {
    if (abstractionArr?.length === 0) {
      return true;
    }
    return abstractionArr.includes(image.abstraction);
  };

/**
 * Create a FilterFunction to filter image by `artist`
 * @param artistArr
 * @returns FilterFunction
 */
export const isPassArtistFilter: FilterFunctionFactory =
  (artistArr: string[]) => (image: ImageData) => {
    if (artistArr?.length === 0) {
      return true;
    }
    return artistArr.includes(image.artist);
  };

/**
 * Create a FilterFunction to filter image by Status
 * There are available status for now
 * * Published: is checked by the image's `published` field
 * * Ready to publish: is checked by the method: `checkIsPublishable`
 * * Incomplete: is checked by the method: `checkIsPublishable`
 */
export const isPassStatusFilter: FilterFunctionFactory =
  (status: ImageStatusEnum | string, { checkIsPublishable }) =>
  (image: ImageData) => {
    switch (status) {
      case IMAGE_FILTER_ALL_INCLUDE_ARCHIVED:
        return true;
      case ImageStatusEnum.Published:
        return image.published && !image.archived;
      case ImageStatusEnum.ReadyToPublish:
      case ImageStatusEnum.Incomplete:
        const { isPublishable } = checkIsPublishable(image);
        if (status === ImageStatusEnum.ReadyToPublish) {
          return isPublishable && !image.published && !image.archived;
        }
        return !isPublishable && !image.published && !image.archived;
      default:
        return !image.archived;
    }
  };

export const getFullImagePath = (path: string): string => {
  const { imageDomain } = getEnvVars();
  return path ? `${imageDomain}/${path}` : path;
};
