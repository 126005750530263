import { createContext, useCallback, useMemo, useContext } from 'react';
import { useLoadArtists } from './helpers';
import { UserData } from 'constants/data/user.data';

export interface ArtistsContextProps {
  artistsMap?: Record<string, any> | null;
  getArtistInfo: (artistId: string) => UserData | null | undefined;
}

/**
 * This context provides the consumers with the data of all artists
 */
const ArtistsContext = createContext<ArtistsContextProps>({
  artistsMap: undefined,
  getArtistInfo: () => undefined,
});

export const ArtistsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { artistsMap } = useLoadArtists();

  const getArtistInfo = useCallback(
    (artistId: string) => {
      if (typeof artistsMap === 'undefined') {
        return undefined;
      }
      if (artistsMap === null) {
        return null;
      }
      return artistsMap[artistId] || null;
    },
    [artistsMap]
  );

  const contextValue = useMemo(
    () => ({
      getArtistInfo,
      artistsMap,
    }),
    [getArtistInfo, artistsMap]
  );

  return (
    <ArtistsContext.Provider value={contextValue}>
      {children}
    </ArtistsContext.Provider>
  );
};

export const useArtistsContext = () => useContext(ArtistsContext);
