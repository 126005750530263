import { MEMBERSHIP, MembershipItemInterface } from 'constants/membership';

export const getMembershipItem = (
  membership: string
): MembershipItemInterface | null => {
  return MEMBERSHIP[membership?.toLowerCase()] || null;
};

export const getMembershipKey = (membership: string): string | null => {
  const key = membership?.toLowerCase();
  return MEMBERSHIP[key] ? key : null;
};
