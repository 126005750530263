import { useState, useEffect } from 'react';
import { useFirebaseContext } from 'shell/firebase';
import { UserData } from 'constants/data/user.data';

export const useLoadArtists = () => {
  const firebase = useFirebaseContext();
  const [artistsMap, setArtistsMap] = useState<Record<
    string,
    UserData
  > | null>();

  useEffect(() => {
    if (firebase !== null) {
      const query = firebase.db.ref('artists');
      const stopListener = query.on('value', snapshot => {
        const newArtistsMap = snapshot.val();
        if (newArtistsMap) {
          const ids = Object.keys(newArtistsMap);
          const promises = ids.map(artistId =>
            firebase.userRef(artistId).once('value')
          );
          Promise.all(promises).then(snapshots => {
            const map = snapshots.reduce(
              (acc: Record<string, UserData>, snapshot) => {
                const key = snapshot.key;
                const user = snapshot.val();
                if (user !== null && key !== null) {
                  acc[key] = user;
                  acc[key].featuredImage = newArtistsMap[key].featuredImage;
                }
                return acc;
              },
              {}
            );
            setArtistsMap(map);
          });
          return;
        }
        setArtistsMap(null);
      });
      return () => query.off('value', stopListener);
    }
  }, [firebase]);
  return { artistsMap };
};

export const useUpdateFeaturedImage = () => {
  const firebase = useFirebaseContext();
  return (imageId: string, artistId: string) => {
    if (!firebase) {
      return Promise.reject('firebase is not defined');
    }
    const updates = {
      [`artists/${artistId}/featuredImage`]: imageId,
    };
    return firebase.db.ref().update(updates);
  };
};
