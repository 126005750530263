import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import WhiteInfoIcon from 'static/images/white-info-icon.svg';
import Typography from 'modules/typography';
import { useState } from 'react';
import { ContentWrapper } from './ContentWrapper';
import { mobileMediaQuery } from 'utils/common';
import { useIsMobile } from 'utils/hooks';
import ResellGuidanceSteps from 'views/common/ResellGuidanceSteps';
import { SelectCountriesDialog } from '../dialogs';
import { useModalState } from 'utils/hooks';
import { useConnectToStripe } from 'views/account/sellArtworks/helpers';
import { useClickConnectBank } from 'utils/requests/usePayment';
import { useModal } from 'modules/modals';

import UIButton from 'ui-components/Button';

export const ResellOffer = () => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const connectToBank = useConnectToStripe();
  const clickConnectBank = useClickConnectBank();
  const { showSnackbar } = useModal();
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(true);

  const handleConnectToStripe = async (payload?: any) => {
    setIsConnecting(true);
    const result = await connectToBank(payload);
    setIsConnecting(false);
    if (result.success && result.value) {
      return (window.location.href = result.value);
    }
    showSnackbar(result.message, { severity: 'error' });
  };

  const {
    isOpen: isOpenDialog,
    open: openDialog,
    close: closeSelectCountryDialog,
  } = useModalState();
  return (
    <ContentWrapper onClose={() => setOpen(false)} isOpen={isOpen}>
      <Box className={classes.content}>
        <Box display={'flex'} mb={isMobile ? 5 : 0}>
          <Box>
            <img src={WhiteInfoIcon} alt="info" className={classes.infoIcon} />
          </Box>
          <Box ml={1.75} maxWidth={650}>
            <Typography fontWeight={500} lineHeight={'160%'}>
              You Can Resell Your Artworks
            </Typography>
            <Typography fontSize={'0.875rem'} lineHeight={'150%'}>
              Connect your bank account, and set the price desired for resell.
              Once it’s sold, you’ll get the amount sold transferred to your
              account.
            </Typography>
            <Box mt={2}>
              <ResellGuidanceSteps containerClassName={classes.guidanceSteps} />
            </Box>
            <Box mb={1}>
              <UIButton
                fullWidth={isMobile}
                variant="primary"
                size="medium"
                text="Connect Bank Account"
                className={classes.connectBankAccountBtn}
                loading={isConnecting}
                disabled={isConnecting}
                onClick={() =>
                  clickConnectBank({
                    openDialog: openDialog,
                    connectStripe: handleConnectToStripe,
                  })
                }
              />
              <UIButton
                fullWidth={isMobile}
                variant="secondary"
                size="medium"
                onClick={() => setOpen(false)}
                text="Maybe Later"
              />
            </Box>
          </Box>
        </Box>
        {isOpenDialog && (
          <SelectCountriesDialog
            open={isOpenDialog}
            isLoading={isConnecting}
            onClose={() => closeSelectCountryDialog(true)}
            onNext={handleConnectToStripe}
          />
        )}
      </Box>
    </ContentWrapper>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    content: {
      display: 'flex',
      flexDirection: 'row',
      [mobile]: {
        flexDirection: 'column',
      },
    },
    infoIcon: {
      width: 24,
      height: 24,
    },
    guidanceSteps: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      columnGap: theme.spacing(9),
      rowGap: theme.spacing(2.5),
      marginBottom: theme.spacing(4.5),
    },
    connectBankAccountBtn: {
      marginRight: theme.spacing(2),
      [mobile]: {
        marginRight: 0,
        marginBottom: theme.spacing(2),
      },
    },
  });
});

export default ResellOffer;
