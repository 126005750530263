import React, { useState } from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
} from '@material-ui/core';
import { useIsMobile } from 'utils/hooks';
import { ReactComponent as CloseIcon } from 'static/images/close-icon.svg';
import EmailImg from './images/verification_email_sent.png';
import Typography from 'modules/typography';
import { mobileMediaQuery } from 'utils/common';
import { useResendVerifyEmail } from 'utils/requests';
import aPromiseWrapper from 'a-promise-wrapper';
import { useSnackbar } from 'modules/modals';
import {
  sendingEmailVerificationErrors,
  getErrorMessage,
} from 'constants/errors';

import UIButton from 'ui-components/Button';

const EMAIL_RESEND_UNAVAILABLE_DURATION = 120000;
const Description = ({ children }: React.PropsWithChildren<{}>) => {
  const classes = useStyles();

  return <div className={classes.description}>{children}</div>;
};

interface Props extends DialogProps {
  email?: string;
  onClose: () => void;
}

const VerificationEmailDialog = (props: Props) => {
  const [canResendEmail, setResendEmailStatus] = useState(true);
  const isMobile = useIsMobile();
  const classes = useStyles();
  const { showSnackbar, render: snackbarRendering } = useSnackbar();
  const sendVerificationEmail = useResendVerifyEmail();
  const { email, onClose, ...dialogProps } = props;

  const [isLoading, setLoading] = useState<boolean>(false);

  const handleClose = (reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (!reason) {
      onClose();
    }
  };

  const handleSendEmail = async () => {
    if (!email || isLoading) {
      return;
    }

    if (!canResendEmail) {
      return showSnackbar(
        getErrorMessage(
          sendingEmailVerificationErrors.GENERATE_VERIFY_LINK_ERROR
        ),
        { severity: 'warning' }
      );
    }

    setLoading(true);
    const { error } = await aPromiseWrapper(sendVerificationEmail({ email }));
    setLoading(false);

    if (error) {
      const message = error.message;
      if (
        message === sendingEmailVerificationErrors.GENERATE_VERIFY_LINK_ERROR
      ) {
        return showSnackbar(
          getErrorMessage(
            sendingEmailVerificationErrors.GENERATE_VERIFY_LINK_ERROR
          ),
          { severity: 'warning' }
        );
      }
      return showSnackbar(message, { severity: 'error' });
    }

    setResendEmailStatus(false);
    setTimeout(() => {
      setResendEmailStatus(true);
    }, EMAIL_RESEND_UNAVAILABLE_DURATION);
    showSnackbar(
      "OK, we've sent a new email requesting you to verify. It may take a few minutes to show up."
    );
  };

  return (
    <Dialog
      {...dialogProps}
      fullScreen={isMobile}
      maxWidth="md"
      onClose={(event, reason) => handleClose(reason)}>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.closeButton}>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box className={classes.content}>
          <Box className={classes.leftContent}>
            <Typography
              fontFamily="Fraunces, serif"
              fontSize="1.5rem"
              fontWeight={300}>
              Account setup
            </Typography>
            <Box mt={2}>
              <Typography fontSize="2.25rem" lineHeight={1.2}>
                Verify your email
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography component={Description}>
                A verification link was sent to your email address upon account
                creation. Click on the link in the email to verify your account.
              </Typography>
            </Box>
            <Box mt={3.5}>
              <Typography component={Description} fontSize="0.875rem">
                Can’t find the email? Check your spam folder or
              </Typography>
            </Box>
            {email && (
              <UIButton
                variant="primary"
                size="medium"
                text="Send the verification email again"
                onClick={handleSendEmail}
                disabled={isLoading}
                loading={isLoading}
                className={classes.sendEmailBtn}
              />
            )}
            {snackbarRendering}
          </Box>
          <Box>
            <img
              src={EmailImg}
              alt="Verification email"
              className={classes.emailImg}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);

  return createStyles({
    closeButton: {
      textAlign: 'right',
      paddingRight: theme.spacing(2),
    },
    dialogContent: {
      padding: 0,
    },
    availableText: {
      color: '#30A035',
      fontSize: '0.75rem',
    },
    textBold: {
      fontWeight: 500,
      marginTop: theme.spacing(3.5),
    },
    emailImg: {
      width: '100%',
      objectFit: 'contain',
      marginTop: theme.spacing(4),
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      [mobile]: {
        flexDirection: 'column',
      },
    },
    leftContent: {
      marginLeft: theme.spacing(7),
      marginRight: theme.spacing(9),
      minWidth: 365,
      [mobile]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    },
    sendEmailBtn: {
      marginTop: theme.spacing(1),
    },
    description: {
      color: '#030303',
      opacity: 0.8,
    },
    artistName: {
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(11),
    },
  });
});

export default VerificationEmailDialog;
