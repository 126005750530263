import { useEffect, useState } from 'react';
import Firebase, { useFirebaseContext } from 'shell/firebase';
import promiseWrapper from 'a-promise-wrapper';
import { useUpdate } from 'utils/requests/useQuery';
import { ImageData } from 'constants/data/image.data';
import { CanvasData } from 'constants/data/canvas.data';
import { UserData } from 'constants/data/user.data';
import { Print as PrintData, IPrintTransform } from 'constants/data/print.data';
import { filterPublishedImages } from 'utils/common/filterPublishedImages';

export const useLoadImages = (user: UserData) => {
  const firebase = useFirebaseContext();
  const [images, setImages] = useState<ImageData[]>();
  useEffect(() => {
    const imageIds = Object.keys(user.images || {});

    const promises = imageIds.map(imageId =>
      firebase.imageRef(imageId).once('value')
    );
    Promise.all(promises).then(snapshots => {
      const result = snapshots.map(snap => ({
        uid: snap.key,
        ...snap.val(),
      }));
      setImages(filterPublishedImages(result));
    });
  }, [firebase, user]);
  return { images };
};

export const useLoadCurrentMedia = (userId: string, canvasId?: string) => {
  const firebase = useFirebaseContext();
  const [{ media, print }, setState] = useState<{
    media?: ImageData | null;
    print?: PrintData | null;
  }>({
    media: undefined,
    print: undefined,
  });
  useEffect(() => {
    if (!canvasId) {
      return;
    }

    const fetchMedia = () => {
      firebase
        .printsRef()
        .orderByChild('canvasId')
        .equalTo(canvasId)
        .once('value', printSnapshot => {
          const value = printSnapshot.val();
          if (value === null) {
            setState({
              media: null,
              print: null,
            });
            return;
          }
          const playingPrintId = Object.keys(value)[0];
          const playingPrint = {
            ...value[playingPrintId],
            uid: playingPrintId,
          };

          firebase
            .imageRef(playingPrint.imageId)
            .once('value', imageSnapshot => {
              setState({
                media: imageSnapshot.val(),
                print: playingPrint,
              });
            });
        });
    };
    firebase.canvasToMediaLastChangedRef(canvasId).on('value', fetchMedia);

    return () =>
      firebase.canvasToMediaLastChangedRef(canvasId).off('value', fetchMedia);
  }, [firebase, userId, canvasId]);
  return {
    media: media,
    print: print,
    isLoading: typeof media === 'undefined',
  };
};

export const removeImage = ({
  canvasId,
  firebase,
}: {
  canvasId: string;
  firebase: Firebase;
}) => {
  return new Promise<void>((resolve, reject) => {
    firebase
      .printsRef()
      .orderByChild('canvasId')
      .equalTo(canvasId)
      .once('value', snapshot => {
        const value = snapshot.val();
        if (value === null) {
          return reject('There is no print playing on the canvas');
        }
        const printId = Object.keys(value)[0];

        const updates = {
          [`prints/${printId}/canvasId`]: null,
          [`canvases/${canvasId}/transform`]: null,
          [`canvases/${canvasId}/media_last_changed`]: firebase.getTimestamp(),
        };

        firebase.db.ref().update(updates, (error: Error | null) => {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        });
      });
  });
};

export const useRemoveCanvas = () => {
  const updateFunc = useUpdate();

  return async ({
    canvas,
    user,
    firebase,
  }: {
    canvas: CanvasData;
    user: UserData;
    firebase: Firebase;
  }) => {
    const { uid: userId } = user;
    const { uid: canvasId, originalArtwork } = canvas;
    const updates = {
      [`canvases/${canvasId}`]: null,
      [`users/${userId}/canvases/${canvasId}`]: null,
    };

    // remove canvasId from streamings collection
    if (originalArtwork) {
      updates[`streamings/${originalArtwork}/${userId}/canvasId`] = null;
    }

    // remove canvaId from print
    const { data: prints } = await promiseWrapper(
      firebase
        .printsRef()
        .orderByChild('canvasId')
        .equalTo(canvasId)
        .once('value')
        .then((snapshot: any) => snapshot.val())
    );

    if (prints) {
      const printId = Object.keys(prints)[0];
      updates[`prints/${printId}/canvasId`] = null;
    }

    const { error } = await promiseWrapper(updateFunc(updates));

    if (error) {
      throw error;
    }
    return;
  };
};

export const useUpdatePrintTransform = () => {
  const firebase = useFirebaseContext();
  return function updatePrintTransfrom(
    print: PrintData,
    newPrintTransform: IPrintTransform | null
  ) {
    const updates = {
      [`prints/${print.uid}/transform`]: newPrintTransform,
      [`canvases/${print.canvasId}/transform`]: newPrintTransform,
      [`canvases/${print.canvasId}/media_last_changed`]:
        firebase.getTimestamp(),
    };

    return firebase.db.ref().update(updates);
  };
};
