import React from 'react';
import { OptionRenderProps } from '../FilterOption';
import { SingleFilterSelect } from 'modules/filterControls';
import { ImageStatusEnum } from 'constants/data/image.data';
import { IMAGE_FILTER_ALL_INCLUDE_ARCHIVED } from 'utils/common/filterImages';

const STATUS_OPTIONS = [
  {
    value: -1,
    label: 'All',
  },
  {
    value: IMAGE_FILTER_ALL_INCLUDE_ARCHIVED,
    label: 'All (include archived)',
  },
  {
    value: ImageStatusEnum.Published,
    label: 'Published',
  },
  {
    value: ImageStatusEnum.ReadyToPublish,
    label: 'Ready to publish',
  },
  {
    value: ImageStatusEnum.Incomplete,
    label: 'Incomplete',
  },
];

export const ImageStatusOption = ({
  values = [],
  onChange,
}: OptionRenderProps) => {
  return (
    <SingleFilterSelect
      value={values}
      onChange={onChange}
      options={STATUS_OPTIONS}
      renderValue={renderLabel}
    />
  );
};

const renderLabel = (value: unknown) => {
  const foundOption = STATUS_OPTIONS.find(option => option.value === value);
  const status = foundOption?.label || '';
  return `Status: ${status}`;
};
