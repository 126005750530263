export interface ImageDimensions {
  width: number;
  height: number;
}

export const getImageFileDimensions = (file: Blob) =>
  new Promise<ImageDimensions | null>(resolve => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const image = new Image();
      image.onload = () => {
        resolve({
          width: image.width,
          height: image.height,
        });
      };
      image.onerror = () => {
        resolve(null);
      };

      if (fileReader.result !== null) {
        image.src = fileReader.result as string;
      }
    };
    fileReader.readAsDataURL(file);
    fileReader.onerror = () => {
      resolve(null);
    };
  });
