import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useAuthContext } from 'shell/session';
import * as ROUTES from 'constants/routes';
import { useIsMobile } from 'utils/hooks';
import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Popper,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Typography,
} from '@material-ui/core';
import { ExpandMore, AccountCircle } from '@material-ui/icons';
import { UserData } from 'constants/data/user.data';
import { useFirebaseContext } from 'shell/firebase';
import { ACCOUNT_MENU, ADMIN_MENU } from 'constants/menu';
import FeatureFlag from 'modules/featureFlag';
import cs from 'classnames';
import { useFeatureFlagContext } from 'utils/contexts/featureFlagContext';

const NavUser = () => {
  const firebase = useFirebaseContext();
  const authContext = useAuthContext();
  const user = authContext.user as UserData | undefined | null;
  const classes = useStyles();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const isMobile = useIsMobile();
  const { enabled: enableAdminQueryTool } =
    useFeatureFlagContext('queryDatabases');

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLDivElement)
    ) {
      return;
    }
    setOpen(false);
  };
  const onItemClick = (path: string) => {
    history.push(path);
    setOpen(false);
  };
  const handleClickSignout = () => {
    if (firebase !== null) {
      (firebase as any).doSignOut();
      setOpen(false);
    }
  };
  const hideMenuItem = (path: string): boolean => {
    if (user) {
      return (
        !user.canUpload &&
        (path === ROUTES.ACCOUNT_UPLOAD ||
          path === ROUTES.ACCOUNT_UPLOADED_IMAGES ||
          path === ROUTES.ACCOUNT_COMMERCE)
      );
    }

    return true;
  };

  if (!user) {
    return null;
  }

  const { membership } = user;
  const filteredMenus = ACCOUNT_MENU.filter(m => {
    if (m.path === ROUTES.ACCOUNT_MEMBER_SHIP) {
      return !!membership ? true : false;
    }

    return true;
  });
  const filteredAdminMenu = ADMIN_MENU.filter(m => {
    if (m.path === ROUTES.ADMIN_QUERY_TOOL) {
      return !!enableAdminQueryTool;
    }

    return true;
  });

  return (
    <Box className={classes.navUser}>
      <NavLink to={ROUTES.ACCOUNT_PROFILE} className={classes.userLink}>
        <AccountCircle className={classes.userIcon} />
      </NavLink>
      {!isMobile && (
        <>
          <div
            ref={anchorRef}
            className={classes.downIcon}
            onClick={handleToggle}>
            <ExpandMore />
          </div>
          <Popper
            open={open}
            anchorEl={anchorRef ? anchorRef.current : null}
            placement="bottom-end"
            transition
            className={classes.navUserPopper}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow">
                  {filteredMenus.map(menu => {
                    const label = menu.label;
                    const path = menu.path;
                    if (hideMenuItem(path)) {
                      return null;
                    }
                    if (menu.feature) {
                      return (
                        <FeatureFlag key={path} feature={menu.feature}>
                          <MenuItem
                            key={path}
                            onClick={() => onItemClick(path)}>
                            {label}
                          </MenuItem>
                        </FeatureFlag>
                      );
                    }
                    return (
                      <MenuItem key={path} onClick={() => onItemClick(path)}>
                        <Typography
                          className={cs({
                            [classes.uploadItem]:
                              menu.path === ROUTES.ACCOUNT_UPLOAD,
                          })}>
                          {label}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                  {user.isAdmin &&
                    filteredAdminMenu.map(menu => {
                      const label = menu.label;
                      const path = menu.path;
                      if (menu.feature) {
                        return (
                          <FeatureFlag feature={menu.feature} key={path}>
                            <MenuItem
                              key={path}
                              onClick={() => onItemClick(path)}>
                              {label}
                            </MenuItem>
                          </FeatureFlag>
                        );
                      }
                      return (
                        <MenuItem key={path} onClick={() => onItemClick(path)}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  <MenuItem onClick={handleClickSignout}>Sign out</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    navUser: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: '20px',
      position: 'relative',
    },
    navUserPopper: {
      zIndex: theme.zIndex.tooltip,
    },
    userLink: {
      lineHeight: 1,
    },
    userIcon: {
      color: '#000',
      width: '30px',
      height: '30px',
    },
    downIcon: {
      cursor: 'pointer',
      marginLeft: '5px',
    },
    uploadItem: {
      width: '100%',
      padding: theme.spacing(1.6),
      backgroundColor: '#242424',
      color: '#fff',
      textAlign: 'center',
      borderRadius: theme.shape.borderRadius,
    },
  });
});

export default NavUser;
