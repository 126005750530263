import {
  createContext,
  PropsWithChildren,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useLoadVenueDetails } from 'utils/requests';
import { IVenue } from 'constants/data/venue.data';
import { useLayoutState } from 'utils/contexts/LayoutState';

type VenueContextType = {
  backgroundColor: string;
  setBackgroundColor: (hexColor: string) => void;
  footerBackgroundColor: string;
  setFooterBackgroundColor: (hexColor: string) => void;
  artworkBackgroundColor: string;
  setArtworkBackgroundColor: (hexColor: string) => void;
  venue?: IVenue | null;
  isLoadingVenue: boolean;
};

const venueContextDefaultValues: VenueContextType = {
  backgroundColor: '#fff',
  setBackgroundColor: () => {},
  footerBackgroundColor: '#000',
  setFooterBackgroundColor: () => {},
  artworkBackgroundColor: '#F6F6F6',
  setArtworkBackgroundColor: () => {},
  venue: null,
  isLoadingVenue: false,
};

const VenueContext = createContext<VenueContextType | null>(
  venueContextDefaultValues
);

type VenueProviderProps = PropsWithChildren<{
  venueId?: string;
}>;

const VenueProvider = ({ children, venueId }: VenueProviderProps) => {
  const { isHostedVenue, setShowTopBar } = useLayoutState();
  const { venue, isLoading: isLoadingVenue } = useLoadVenueDetails(venueId);
  const [backgroundColor, setBackgroundColor] = useState<string>(
    venueContextDefaultValues.backgroundColor
  );
  const [footerBackgroundColor, setFooterBackgroundColor] = useState<string>(
    venueContextDefaultValues.backgroundColor
  );
  const [artworkBackgroundColor, setArtworkBackgroundColor] = useState<string>(
    venueContextDefaultValues.artworkBackgroundColor
  );

  useEffect(() => {
    if (venue?.artworkBackgroundColor && venue?.isDisplayVenueProfile25) {
      setArtworkBackgroundColor(venue.artworkBackgroundColor);
    } else {
      setArtworkBackgroundColor(
        venueContextDefaultValues.artworkBackgroundColor
      );
    }
  }, [venue?.isDisplayVenueProfile25, venue?.artworkBackgroundColor]);

  useEffect(() => {
    if (venue?.backgroundColor && venue?.isDisplayVenueProfile25) {
      setBackgroundColor(venue.backgroundColor);
    } else {
      setBackgroundColor(venueContextDefaultValues.backgroundColor);
    }
  }, [venue?.isDisplayVenueProfile25, venue?.backgroundColor]);

  useEffect(() => {
    if (venue?.footerBackgroundColor && venue?.isDisplayVenueProfile25) {
      setFooterBackgroundColor(venue.footerBackgroundColor);
    } else {
      setFooterBackgroundColor(venueContextDefaultValues.footerBackgroundColor);
    }
  }, [venue?.isDisplayVenueProfile25, venue?.footerBackgroundColor]);

  useEffect(() => {
    if (isHostedVenue) {
      return;
    }
    if (venue?.isDisplayVenueProfile25 && venue?.configurableModules) {
      setShowTopBar(false);
    }
  }, [
    isHostedVenue,
    venue?.isDisplayVenueProfile25,
    venue?.configurableModules,
    setShowTopBar,
  ]);

  const contextValue = useMemo(
    () => ({
      backgroundColor,
      setBackgroundColor,
      footerBackgroundColor,
      setFooterBackgroundColor,
      artworkBackgroundColor,
      setArtworkBackgroundColor,
      venue,
      isLoadingVenue,
    }),
    [
      backgroundColor,
      setBackgroundColor,
      footerBackgroundColor,
      setFooterBackgroundColor,
      artworkBackgroundColor,
      setArtworkBackgroundColor,
      venue,
      isLoadingVenue,
    ]
  );
  return (
    <VenueContext.Provider value={contextValue}>
      {children}
    </VenueContext.Provider>
  );
};

export const useVenueConfig = (): VenueContextType => {
  const ctx = useContext(VenueContext);

  if (!ctx) {
    throw new Error('VenueContext not found');
  }

  return ctx;
};

export default VenueProvider;
