import { useFirebaseContext } from 'shell/firebase';
import promiseWrapper from 'a-promise-wrapper';
import { commonErrors } from 'constants/errors';
import { CanvasData } from 'constants/data';

const { FETCH_CANVAS_ERROR, CANVAS_NOT_FOUND_ERROR } = commonErrors;

export const useGetCanvasFromOTP = () => {
  const firebase = useFirebaseContext();
  const callable = firebase.functions.httpsCallable('createSignInToken');

  return async (otp: string) => {
    const { data: response, error } = await promiseWrapper(callable({ otp }));

    if (error) {
      throw new Error(error.message);
    }

    const { data: canvasId } = response;

    if (!canvasId) {
      throw new Error(CANVAS_NOT_FOUND_ERROR);
    }

    const canvasRef = firebase.canvasRef(canvasId);
    const { data: snapshot, error: getCanvasError } = await promiseWrapper(
      canvasRef.once('value')
    );

    if (getCanvasError) {
      throw new Error(FETCH_CANVAS_ERROR);
    }

    const canvas = snapshot.val() as CanvasData;

    return { ...canvas, uid: canvasId };
  };
};
