const EVT_SIGN_UP = 'sign_up';
const EVT_BEGIN_CHECKOUT = 'begin_checkout';
const EVT_SOCIAL_CLICK = 'social_click';
const EVT_SELECT_SUBSCRIPTION_PLAN = 'select_subscribe_plan';
const EVT_CLICK_VIEW_ON_CANVAS = 'click_view_on_canvas';
const EVT_CLICK_SUBSCRIBE = 'click_subscribe';
const EVT_EXHIBITION_IMAGE_SELECT = 'exhibition-image:select';
const EVT_CANCEL_MEMBERSHIP = 'cancel_membership';
const EVT_UPGRADE_MEMBERSHIP = 'upgrade_membership';
const EVT_DOWNGRADE_MEMBERSHIP = 'downgrade_membership';
const EVT_SAME_USER_VERIFY_EMAIL = 'same_user_verify_email';
const EVT_SIGNED_OUT_VERIFY_EMAIL = 'signed_out_verify_email';
const EVT_DIFFERENT_USER_VERIFY_EMAIL = 'different_user_verify_email';
const EVT_VERIFY_EMAIL_ERROR = 'verify_email_error';
// const EVT_AUTHENTICATION = {
//   EVT_ANONYMOUS_USER_SIGN_UP_AT_EXHIBITION_PAGE:
//     'anonymous_user_sign_up_at_exhibition_page',
//   EVT_ANONYMOUS_USER_SIGN_IN_AT_EXHIBITION_PAGE:
//     'anonymous_user_sign_in_at_exhibition_page',
//   EVT_ANONYMOUS_USER_SIGN_UP_AT_SIGN_UP_PAGE:
//     'anonymous_user_sign_up_at_sign_up_page',
//   EVT_ANONYMOUS_USER_SIGN_IN_AT_SIGN_IN_PAGE:
//     'anonymous_user_sign_in_at_sign_in_page',
// };

export {
  EVT_SIGN_UP,
  EVT_BEGIN_CHECKOUT,
  EVT_SOCIAL_CLICK,
  EVT_SELECT_SUBSCRIPTION_PLAN,
  EVT_CLICK_VIEW_ON_CANVAS,
  EVT_CLICK_SUBSCRIBE,
  EVT_EXHIBITION_IMAGE_SELECT,
  EVT_CANCEL_MEMBERSHIP,
  EVT_UPGRADE_MEMBERSHIP,
  EVT_DOWNGRADE_MEMBERSHIP,
  EVT_SAME_USER_VERIFY_EMAIL,
  EVT_SIGNED_OUT_VERIFY_EMAIL,
  EVT_DIFFERENT_USER_VERIFY_EMAIL,
  EVT_VERIFY_EMAIL_ERROR,
  // EVT_AUTHENTICATION,
};
