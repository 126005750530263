import React, { LegacyRef, useEffect, useRef, useState, memo } from 'react';
import cn from 'classnames';
import {
  Box,
  CircularProgress,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Typography from 'modules/typography';
import { CanvasData } from 'constants/data/canvas.data';
import { ImageData } from 'constants/data/image.data';
import { Print } from 'constants/data/print.data';
import { isPrint } from 'utils/common/isPrint';
import ImagesListItem from './ImagesListItem';
import { useArtwork } from './ArtworksProvider';
import { FixedSizeList } from 'react-window';
import { useLocation } from 'react-router-dom';
import { useModalState } from 'utils/hooks';
import CanvasesDialog from '../CanvasesDialog';
import { UserData } from 'constants/data';
import { playArtworkErrors } from 'constants/errors';
import {
  useCancelGift,
  useGetImageFromPrint,
  useMemberStreamArtwork,
  usePlayOriginalArtwork,
  usePlayPrint,
} from 'utils/requests';
import ImageSelectionDialog from 'modules/imageSelectionDialog';
import type {
  ImageSelectionParamsType,
  ExtendedImageSelectionParamsType,
} from 'modules/imageSelectionDialog';
import { useModal } from 'modules/modals';
import { ResellOffer, ArtworkOffer } from 'modules/artworkOffer';
import ManageStreamingDialog from './ManageStreamingDialog';
import { useAuthContext } from 'shell/session';
import { StripeAccountPayoutStatus } from 'constants/payment';
import pw from 'a-promise-wrapper';
import CancelGiftDialog from 'modules/cancelGiftDialog';
import UnClaimedGift from 'modules/artworkOffer/UnclaimedGift';
import GiftDialog from 'modules/giftDialog';

export interface CanvasDialogProps {
  printId: string;
  image: ImageData;
  user: UserData;
}

type Props = {
  onAssign?: (image: ImageData | Print) => void;
  canvas?: CanvasData;
  customMobileScreen?: boolean;
  imageListHeight?: number;
};

const DEFAULT_IMAGE_LIST_HEIGHT = 550;
const DEFAULT_ITEM_HEIGHT = 100;

const ImagesList = ({
  onAssign,
  canvas,
  customMobileScreen = false,
  imageListHeight,
}: Props) => {
  const styles = useStyles();
  const { images, isLoading, setReloadPrint } = useArtwork();
  const hasNoImages = !isLoading && images.length === 0;
  const hasImages = !isLoading && images.length > 0;
  const fixedSizeListRef = useRef<FixedSizeList>();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const artworkId = query.get('artworkId');
  const { showSnackbar } = useModal();
  const [canvasDialogProps, setCanvasDialogProps] =
    useState<CanvasDialogProps>();
  const [selectedOriginalArtwork, setSelectedOriginalArtwork] =
    useState<ImageData>();
  const [selectedPrint, setSelectedPrint] = useState<Print>();
  const [isSelectedPrint, setIsSelectedPrint] = useState<boolean>(false);
  const [imageOfSelectedPrint, setImageOfSelectedPrint] = useState<ImageData>();
  const playPrint = usePlayPrint();
  const playOriginalArtwork = usePlayOriginalArtwork();
  const streamingArtwork = useMemberStreamArtwork();
  const { user } = useAuthContext() as { user: UserData };

  const cancelGiftFunc = useCancelGift();
  const getImageFromPrint = useGetImageFromPrint();

  // Loading state **ONLY** for cancel gifting
  const [isCancelling, setIsCancelling] = useState(false);

  const {
    isOpen,
    open: openCanvasesDialog,
    close: closeCanvasesDialog,
  } = useModalState();

  const {
    isOpen: isOpenManageStreamingDialog,
    open: openManageStreamingDialog,
    close: closeManageStreamingDialog,
  } = useModalState();

  const {
    isOpen: isOpenGiftDialog,
    open: openGiftDialog,
    close: closeGiftDialog,
  } = useModalState();

  const {
    isOpen: isOpenCancelGiftDialog,
    open: openCancelGiftDialog,
    close: closeCancelGiftDialog,
  } = useModalState();

  const {
    data,
    isOpen: isOpenSelectionDialog,
    open: openSelectionDialog,
    close: closeSelectionDialog,
  } = useModalState<ExtendedImageSelectionParamsType>();

  const onConfirmPlayOriginalArtwork = ({
    image,
    canvas,
    printTransform,
  }: ImageSelectionParamsType) => {
    const isUserStreamArtwork = user && image.owner !== user.uid;
    if (isUserStreamArtwork) {
      streamingArtwork({
        artworkId: image.uid,
        allowUserPlayCanVasWithoutVerifyingEmail: true,
        canvasId: canvas.uid,
        transform: printTransform,
      });
      return;
    }

    playOriginalArtwork({
      imageId: image.uid,
      canvasId: canvas.uid,
      transform: printTransform,
    })
      .then(() => {
        showSnackbar('The artwork is playing on the canvas');
      })
      .catch(error => {
        const message = error?.message;
        if (message === playArtworkErrors.ORIGINAL_ARTWORK_ALREADY_PLAYED) {
          showSnackbar('The artwork has played on the canvas', {
            severity: 'error',
          });
          return;
        }
        showSnackbar('Fail to play the artwork on the canvas', {
          severity: 'error',
        });
      });
  };

  const onConfirmPlayPrint = ({
    printId,
    canvas,
    printTransform,
  }: Partial<ImageSelectionParamsType>) => {
    if (!printId || !canvas) {
      return;
    }

    playPrint({
      printId: printId,
      canvasId: canvas.uid,
      transform: printTransform,
    })
      .then(() => {
        showSnackbar('The edition is playing on the canvas');
      })
      .catch(error => {
        const message = error?.message;
        if (message === playArtworkErrors.PRINT_ALREADY_PLAYED) {
          showSnackbar('The edition has played on the canvas', {
            severity: 'error',
          });
          return;
        }
        showSnackbar('Fail to play the edition on the canvas', {
          severity: 'error',
        });
      });
  };

  const handleSelectCanvas = (
    selectedCanvas: CanvasData,
    image: ImageData,
    printId: string
  ) => {
    if (!selectedCanvas || !image) {
      return;
    }

    if (isPrint(image)) {
      openSelectionDialog({
        image,
        printId,
        canvas: selectedCanvas,
        printTransform: null,
        onConfirm: onConfirmPlayPrint,
      });

      return;
    }

    openSelectionDialog({
      image,
      canvas: selectedCanvas,
      printTransform: null,
      onConfirm: onConfirmPlayOriginalArtwork,
    });
  };

  const handleOpenManageStreamingDialog = (image: ImageData) => {
    setSelectedOriginalArtwork(image);
    openManageStreamingDialog();
  };
  const handleOpenCanvasDialog = (canvasDialogProps: CanvasDialogProps) => {
    setCanvasDialogProps(canvasDialogProps);
    openCanvasesDialog();
  };

  useEffect(() => {
    if (fixedSizeListRef && fixedSizeListRef.current && images && artworkId) {
      const index = images.findIndex(image => image.uid === artworkId);
      if (index !== -1) {
        fixedSizeListRef.current.scrollToItem(index, 'end');
      }
    }
  }, [fixedSizeListRef, images, artworkId]);

  const handleOpenGiftDialog = async (image: ImageData | Print) => {
    if ((image as Print).imageId) {
      setSelectedPrint(image as Print);
      setSelectedOriginalArtwork(undefined);
      setIsSelectedPrint(true);

      const img = await getImageFromPrint(image as Print);
      if (img) {
        setImageOfSelectedPrint(img);
      }
    } else {
      setSelectedOriginalArtwork(image as ImageData);
      setSelectedPrint(undefined);
      setIsSelectedPrint(false);
    }

    openGiftDialog();
  };

  const handleOpenCancelGiftDialog = (image: ImageData | Print) => {
    if ((image as Print).imageId) {
      setSelectedPrint(image as Print);
    } else {
      setSelectedOriginalArtwork(image as ImageData);
    }

    openCancelGiftDialog();
  };

  const handleCancelGift = async () => {
    if (!selectedOriginalArtwork && !selectedPrint) {
      return;
    }

    let item;
    if (selectedOriginalArtwork) {
      item = selectedOriginalArtwork;
    } else if (selectedPrint) {
      item = selectedPrint;
    } else {
      return;
    }

    const tx = item.currentWaitingGiftTx;

    if (!tx) {
      return;
    }

    setIsCancelling(true);
    const { error } = await pw(cancelGiftFunc(tx));

    if (error) {
      setIsCancelling(false);
      return showSnackbar('Failed to cancel gift', { severity: 'error' });
    }

    showSnackbar('Gift cancelled');
    if (selectedPrint) {
      setReloadPrint(Date.now());
    }
    setIsCancelling(false);
    closeCancelGiftDialog(true);
  };

  const { currentUnclaimedGiftTxs: unclaimedTxsObj } = user;
  const unclaimedTxs = unclaimedTxsObj ? Object.keys(unclaimedTxsObj) : [];
  const hasUnclaimedGifts = !!unclaimedTxs.length;

  const hasSelected = !!selectedOriginalArtwork || !!selectedPrint;
  const isValidGiftDialogData = () => {
    if (!hasSelected) {
      return false;
    }

    if (isSelectedPrint) {
      return !!selectedPrint && !!imageOfSelectedPrint;
    }

    return !!selectedOriginalArtwork;
  };

  return (
    <>
      <ArtworkOffer />
      {hasUnclaimedGifts
        ? unclaimedTxs.map(txId => <UnClaimedGift key={txId} txId={txId} />)
        : null}

      {/* not show If user conncted */}
      {images &&
        images.length !== 0 &&
        !canvas &&
        user?.stripeConnect?.status !== StripeAccountPayoutStatus.Completed && (
          <ResellOffer />
        )}

      {isLoading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {hasNoImages && <Alert>No images found.</Alert>}
      {hasImages && (
        <>
          <Box className={styles.boxHeader}>
            <Box className={styles.boxHeaderLeft}>
              {!customMobileScreen && (
                <Box className={styles.innerAction}>
                  <Typography className={styles.boxHeaderText}>PLAY</Typography>
                </Box>
              )}
              <Typography className={cn(styles.boxHeaderText, styles.title)}>
                Title
              </Typography>
            </Box>
            <Box className={styles.boxHeaderRight}>
              {!customMobileScreen && (
                <Box className={styles.innerRight}>
                  <Typography className={styles.boxHeaderText}>
                    Edition #
                  </Typography>
                </Box>
              )}
              <Box className={cn(!customMobileScreen && styles.innerRight)}>
                <Typography
                  className={cn(styles.boxHeaderText, styles.ellipsisText)}>
                  Status
                </Typography>
              </Box>
            </Box>
          </Box>

          <FixedSizeList
            height={
              imageListHeight ? imageListHeight : DEFAULT_IMAGE_LIST_HEIGHT
            }
            itemCount={images.length}
            itemSize={DEFAULT_ITEM_HEIGHT}
            width={'100%'}
            ref={fixedSizeListRef as LegacyRef<FixedSizeList>}>
            {({ index, style }) => {
              const image = images[index];
              const imageId = isPrint(image) ? image.imageId : image.uid;

              // TODO: optimize rerender performance
              return (
                <div style={style}>
                  <ImagesListItem
                    key={image.uid}
                    imageId={imageId}
                    printId={(image as Print).uid}
                    editionIndex={isPrint(image) ? image.editionIndex : -1}
                    isPrint={isPrint(image)}
                    print={isPrint(image) ? image : null}
                    onAssign={onAssign}
                    image={image}
                    canvas={canvas}
                    handleOpenCanvasDialog={handleOpenCanvasDialog}
                    handleOpenManageStreamingDialog={
                      handleOpenManageStreamingDialog
                    }
                    handleOpenGiftDialog={handleOpenGiftDialog}
                    handleOpenCancelGiftDialog={handleOpenCancelGiftDialog}
                    customMobileScreen={customMobileScreen}
                    setReloadPrint={isPrint(image) ? setReloadPrint : undefined}
                  />
                </div>
              );
            }}
          </FixedSizeList>
        </>
      )}
      {isOpen && canvasDialogProps && (
        <CanvasesDialog
          open={isOpen}
          onClose={() => {
            setReloadPrint && setReloadPrint(Date.now());
            closeCanvasesDialog(false);
          }}
          onSelectCanvas={selectedCanvas =>
            handleSelectCanvas(
              selectedCanvas,
              canvasDialogProps.image,
              canvasDialogProps.printId
            )
          }
          {...canvasDialogProps}
        />
      )}
      {data && isOpenSelectionDialog && (
        <ImageSelectionDialog
          image={data.image}
          printId={data.printId}
          canvas={data.canvas}
          printTransform={data.printTransform}
          open={isOpenSelectionDialog}
          onClose={() => closeSelectionDialog(true)}
          onConfirm={data.onConfirm}
        />
      )}
      {isOpenManageStreamingDialog && selectedOriginalArtwork && (
        <ManageStreamingDialog
          image={selectedOriginalArtwork}
          open={isOpenManageStreamingDialog}
          onClose={closeManageStreamingDialog}
        />
      )}
      {/**
       * TODO:
       * - Handle case gifting print
       */}
      {isOpenGiftDialog && isValidGiftDialogData() && (
        <GiftDialog
          isPrint={isSelectedPrint}
          image={
            isSelectedPrint
              ? imageOfSelectedPrint || ({} as ImageData)
              : selectedOriginalArtwork || ({} as ImageData)
          }
          print={selectedPrint}
          waitingTxId={
            isSelectedPrint
              ? selectedPrint?.currentWaitingGiftTx
              : selectedOriginalArtwork?.currentWaitingGiftTx
          }
          open={isOpenGiftDialog}
          onClose={closeGiftDialog}
          onSuccess={isSelectedPrint ? setReloadPrint : undefined}
        />
      )}
      {isOpenCancelGiftDialog && (selectedOriginalArtwork || selectedPrint) && (
        <CancelGiftDialog
          open={isOpenCancelGiftDialog}
          onClose={closeCancelGiftDialog}
          onConfirm={handleCancelGift}
          isCancelling={isCancelling}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    button: {
      'borderRadius': '30px',
      'marginRight': theme.spacing(1.5),
      '&:last-child': {
        marginRight: 0,
      },
    },
    boxHeader: {
      display: 'flex',
      paddingBottom: theme.spacing(2),
    },
    boxHeaderLeft: {
      display: 'flex',
      width: '55%',
    },
    boxHeaderRight: {
      display: 'flex',
      width: '45%',
    },
    title: {
      flex: 8.5,
    },
    boxHeaderText: {
      fontSize: theme.typography.pxToRem(11),
      letterSpacing: '0.12em',
      textTransform: 'uppercase',
    },
    innerAction: {
      flex: 1.5,
    },
    innerRight: {
      display: 'flex',
      flex: 3,
    },
    ellipsisText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  });
});

export default memo(ImagesList);
