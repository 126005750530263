const getEnvVars = () => {
  const apiKey = process.env.REACT_APP_API_KEY;
  const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
  const databaseURL = process.env.REACT_APP_DATABASE_URL;
  const projectId = process.env.REACT_APP_PROJECT_ID;
  const storageBucket = process.env.REACT_APP_STORAGE_BUCKET;
  const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
  const appId = process.env.REACT_APP_APP_ID;
  const measurementId = process.env.REACT_APP_MEASUREMENT_ID;
  const apiGateway = process.env.REACT_APP_API_GATEWAY;
  const imageDomain = process.env.REACT_APP_IMAGE_DOMAIN;
  const arbiscanDomain = process.env.REACT_APP_ARBISCAN_EXPLORER_URL;
  const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const cookieBotId = process.env.REACT_APP_COOKIEBOT_DOMAIN_ID;
  const isUseDbEmulator = process.env.REACT_APP_USE_DATABSE_EMULATOR;
  const isUseCFEmulator = process.env.REACT_APP_USE_CLOUD_FUNCTIONS_EMULATOR;
  const isUseAuthEmulator = process.env.REACT_APP_USE_AUTH_EMULATOR;
  const isUseFirestoreEmulator = process.env.REACT_APP_USE_FIRESTORE_EMULATOR;
  const emulatorDbUrl = process.env.REACT_APP_EMULATOR_DATABASE_URL;
  const emulatorCFRegion = process.env.REACT_APP_EMULATOR_FUNCTION_REGION;
  const emulatorCFUrl = process.env.REACT_APP_EMULATOR_CLOUD_FUNCTIONS_URL;
  const emulatorAuthUrl = process.env.REACT_APP_EMULATOR_AUTH_URL;
  const emulatorFirestoreUrl = process.env.REACT_APP_EMULATOR_FIRESTORE_URL;

  return {
    apiKey,
    authDomain,
    databaseURL,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    measurementId,
    apiGateway,
    imageDomain,
    arbiscanDomain,
    stripeKey,
    cookieBotId,
    isUseDbEmulator,
    isUseCFEmulator,
    isUseAuthEmulator,
    isUseFirestoreEmulator,
    emulatorDbUrl,
    emulatorCFRegion,
    emulatorCFUrl,
    emulatorAuthUrl,
    emulatorFirestoreUrl,
  };
};

export default getEnvVars;
