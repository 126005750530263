import React from 'react';
import cn from 'classnames';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

import Typography from 'modules/typography';
import { formatDate, DISPLAYED_DATE_FORMAT_2 } from 'utils/common';
import { ExhibitionData } from 'constants/data';

type Props = {
  isOwner: boolean;
  isOriginalArtwork: boolean;
  editionIndex: number;
  numberOfPrints: number;
  exhibition?: ExhibitionData;
  isFreeSlot: boolean;
};

const Type = ({
  isOwner,
  isOriginalArtwork,
  editionIndex,
  numberOfPrints,
  exhibition,
  isFreeSlot,
}: Props) => {
  const styles = useStyles();
  const exhibitionEndTime =
    exhibition && formatDate(exhibition.endTime, DISPLAYED_DATE_FORMAT_2);

  if (isOwner) {
    if (isOriginalArtwork) {
      return (
        <>
          <Typography className={cn(styles.mainText, styles.truncate)}>
            {`Owner, Original`}
          </Typography>
          <Typography className={cn(styles.subText, styles.truncate)}>
            {``}
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography className={cn(styles.mainText, styles.truncate)}>
          {`Edition #${editionIndex} of ${numberOfPrints}`}
        </Typography>
        <Typography className={cn(styles.subText, styles.truncate)}>
          {``}
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography className={cn(styles.mainText, styles.truncate)}>
        {`Original`}
      </Typography>
      {isFreeSlot && (
        <Typography className={cn(styles.subText, styles.truncate)}>
          {exhibition && `Ending on ${exhibitionEndTime}`}
        </Typography>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    mainText: {
      fontSize: theme.typography.pxToRem(12),
      color: '#000000',
    },
    subText: {
      fontSize: theme.typography.pxToRem(12),
      color: '#616161',
    },
    truncate: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  });
});

export default Type;
