import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

type LinkVariant = 'inherit' | 'underline' | 'primary' | 'secondary';
type LinkSize = 'inherit' | 'small' | 'large' | 'x-large';

type BaseLinkProps = {
  className?: string;
  size?: LinkSize;
  variant?: LinkVariant;
};

type RouterLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string;
  component: React.ComponentType<any>;
  onClick?: (e: React.MouseEvent) => void;
};

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string;
  component?: undefined;
  onClick?: (e: React.MouseEvent) => void;
};

type SpanProps = {
  href?: undefined;
  component?: undefined;
  onClick: (e: React.MouseEvent) => void;
};

type LinkWithoutChildrenProps = BaseLinkProps &
  (RouterLinkProps | AnchorProps | SpanProps);

type LinkProps = React.PropsWithChildren<LinkWithoutChildrenProps>;

const getElement = (
  props: Exclude<LinkWithoutChildrenProps, keyof BaseLinkProps>
) => {
  if (props.component) {
    return props.component;
  }

  if (props.href) {
    return 'a';
  }

  return 'span';
};

const getProps = ({
  component,
  href,
  ...otherProps
}: Exclude<LinkWithoutChildrenProps, keyof BaseLinkProps>) => {
  if (component) {
    return {
      ...otherProps,
      to: href,
    };
  }

  return {
    ...otherProps,
    href,
  };
};

const Link = ({
  className,
  variant = 'inherit',
  size = 'inherit',
  children,
  ...linkProps
}: LinkProps) => {
  const Element = getElement(linkProps);
  const elementProps = getProps(linkProps);

  return (
    <Element
      className={cn(
        className,
        styles.link,
        styles[`link--v-${variant}`],
        styles[`link--s-${size}`]
      )}
      {...elementProps}>
      {children}
    </Element>
  );
};

export default Link;
