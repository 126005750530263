import { ComponentType } from 'react';

import SignIn from 'views/auth/signIn/Loadable';
import SignUp from 'views/auth/signUp/Loadable';
import NotFound from 'views/NotFound/Loadable';
import * as ROUTES from 'constants/routes';
import ResetPassword, {
  ResetPasswordConfirmationPage,
  ResetPasswordCompletePage,
} from 'views/auth/resetPassword';
import YouAreSet from 'views/auth/youAreSet/Loadable';
import Verification from 'views/auth/Verification/Loadable';
import AuthAction from 'views/auth/action/Loadable';
import PrivacyPage from 'views/static/privacy/Loadable';
import PurchaseSubscriptionAgreementPage from 'views/static/purchase-subscription-agreement/Loadable';
import ArtistAgreementPage from 'views/static/artist-agreement/Loadable';
import TermsPage from 'views/static/terms/Loadable';
import ImprintPage from 'views/static/imprint/Loadable';
import AboutPage from 'views/static/about/Loadable';
import ContactPage from 'views/static/contact/Loadable';
import OurPartnersPage from 'views/static/ourPartners/Loadable';
import PricingPage from 'views/static/pricing/Loadable';
import DownloadPage from 'views/static/downloads/Loadable';
import ExhibitionsPage from 'views/client/exhibitions/Loadable';
import ExhibitionDetailPage from 'views/client/exhibition/Loadable';
import ExhibitionImage from 'views/client/exhibitionImage/Loadable';
import WatermarkVerificationPage from 'views/client/watermarkVerification/Loadable';
import PromotionComponent from 'views/client/productOverview/promotion/Loadable';
import HowItWorksPage from 'views/client/howItWorks/Loadable';
import HomePage from 'views/client/homepage/Loadable';
import AccountUpload from 'views/account/upload/Loadable';
import AccountProfile from 'views/account/profile/Loadable';
import AccountMembership from 'views/account/membership/Loadable';
import AccountCanvases from 'views/account/canvases/Loadable';
import AccountInviteFriends from 'views/account/inviteFriends/Loadable';
import AccountCommerce from 'views/account/commerce/Loadable';
import {
  ImagesPage as AccountImagesPage,
  UploadedImagesPage as AccountUploadedImagesPage,
} from 'views/account/images';
import Catalog from 'views/client/catalog/Loadable';
import Catalogs from 'views/client/catalogs/Loadable';
import Artist from 'views/client/artist/Loadable';
import ArtistsPage from 'views/client/artists/Loadable';
import Keyword from 'views/client/keyword/Loadable';
import ExplorePage from 'views/client/explore/Loadable';
import VenueDetailPage from 'views/client/venueDetails/Loadable';
import VenuesPage from 'views/client/venues/Loadable';
import VenueArtworksPage from 'views/client/venueArtworks/Loadable';
import VenueExhibitionsPage from 'views/client/venueExhibitions/Loadable';
import ArtworksPage from 'views/client/artworks/Loadable';
import BetaInvitesPage from 'views/admin/invites/Loadable';
import AdminImageList from 'views/admin/images/Loadable';
import AdminFeatureImages from 'views/admin/featuredImages/Loadable';
import AdminFeatureConfiguration from 'views/admin/featureConfiguration/Loadable';
import AdminHomepageConfiguration from 'views/admin/homepageConfiguration/Loadable';
import {
  AdminExhibitionListPage,
  AdminCreateExhibitionPage,
  AdminEditExhibitionPage,
} from 'views/admin/exhibitions/Loadable';
import VenueListPage, {
  AdminCreateVenuePage,
  AdminEditVenuePage,
} from 'views/admin/venues/Loadable';
import HostedVenuePage from 'views/admin/venues/hosted-venue/Loadable';
import {
  AdminUserListPage,
  AdminUserDetailsPage,
} from 'views/admin/users/Loadable';
import QueryDatabaseTool from 'views/admin/queryDatabaseTool/Loadable';
import SellArtworkPage from 'views/account/sellArtworks';
import CollectPage from 'views/client/productOverview/collect/Loadable';
import DisplayPage from 'views/client/productOverview/display/Loadable';
import AdminRefundTool from 'views/admin/refund/Loadable';
import ReceiveGift from 'views/client/receiveGift';
import ManageGift from 'views/admin/manageGift';
import Partners from 'views/admin/partners';
import salesReports from 'views/admin/salesReports';

export interface IPageItem {
  path: string;
  component: ComponentType<any>;
  exact: boolean;
}

export const PUBLIC_PAGES: IPageItem[] = [
  {
    path: ROUTES.SIGN_IN,
    component: SignIn,
    exact: false,
  },
  {
    path: ROUTES.SIGN_UP,
    component: SignUp,
    exact: false,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    component: ResetPassword,
    exact: false,
  },
  {
    path: ROUTES.RESET_PASSWORD_CONFIRMATION,
    component: ResetPasswordConfirmationPage,
    exact: false,
  },
  {
    path: ROUTES.RESET_PASSWORD_COMPLETE,
    component: ResetPasswordCompletePage,
    exact: false,
  },
  {
    path: ROUTES.AUTH_ACTION,
    component: AuthAction,
    exact: false,
  },
  {
    path: ROUTES.YOU_ARE_SET,
    component: YouAreSet,
    exact: false,
  },
  {
    path: ROUTES.VERIFICATION,
    component: Verification,
    exact: false,
  },
  {
    path: ROUTES.NOT_FOUND,
    component: NotFound,
    exact: true,
  },
  {
    path: ROUTES.PRIVACY,
    component: PrivacyPage,
    exact: false,
  },
  {
    path: ROUTES.PURCHASE_SUBSCRIPTION_AGREEMENT,
    component: PurchaseSubscriptionAgreementPage,
    exact: false,
  },
  {
    path: ROUTES.ARTIST_AGREEMENT,
    component: ArtistAgreementPage,
    exact: false,
  },
  {
    path: ROUTES.TERMS,
    component: TermsPage,
    exact: false,
  },
  {
    path: ROUTES.IMPRINT,
    component: ImprintPage,
    exact: false,
  },
  {
    path: ROUTES.EXHIBITIONS,
    component: ExhibitionsPage,
    exact: true,
  },
  {
    path: ROUTES.EXHIBITION,
    component: ExhibitionDetailPage,
    exact: true,
  },
  {
    path: ROUTES.EXHIBITION_IMAGE_DETAILS,
    component: ExhibitionImage,
    exact: false,
  },
  {
    path: ROUTES.HOW_IT_WORKS,
    component: HowItWorksPage,
    exact: false,
  },
  {
    path: ROUTES.ABOUT,
    component: AboutPage,
    exact: false,
  },
  {
    path: ROUTES.CONTACT,
    component: ContactPage,
    exact: false,
  },
  {
    path: ROUTES.OUR_PARTNERS,
    component: OurPartnersPage,
    exact: false,
  },
  {
    path: ROUTES.PRICING,
    component: PricingPage,
    exact: false,
  },
  {
    path: ROUTES.DOWNLOADS,
    component: DownloadPage,
    exact: false,
  },
  {
    path: ROUTES.LANDING,
    component: HomePage,
    exact: true,
  },
  {
    path: ROUTES.VENUES,
    component: VenuesPage,
    exact: true,
  },
  {
    path: ROUTES.VENUE_DETAILS,
    component: VenueDetailPage,
    exact: true,
  },
  {
    path: ROUTES.VENUE_ABOUT,
    component: AboutPage,
    exact: true,
  },
  {
    path: ROUTES.VENUE_ARTWORK,
    component: VenueArtworksPage,
    exact: true,
  },
  {
    path: ROUTES.VENUE_EXHIBITIONS,
    component: VenueExhibitionsPage,
    exact: true,
  },
  {
    path: ROUTES.WATERMARK_VERIFICATION,
    component: WatermarkVerificationPage,
    exact: false,
  },
  {
    path: ROUTES.PRODUCT_OVERVIEW_COLLECT,
    component: CollectPage,
    exact: false,
  },
  {
    path: ROUTES.PRODUCT_OVERVIEW_DISPLAY,
    component: DisplayPage,
    exact: false,
  },
  {
    path: ROUTES.SAMSUNG_PROMOTION,
    component: PromotionComponent,
    exact: false,
  },
  {
    path: ROUTES.RECEIVE_GIFT,
    component: ReceiveGift,
    exact: false,
  },
  {
    path: ROUTES.ARTWORK_DETAILS,
    component: ExhibitionImage,
    exact: false,
  },
];

export const PRIVATE_PAGES: IPageItem[] = [
  {
    path: ROUTES.ARTIST_CATALOG,
    component: Catalog,
    exact: true,
  },
  {
    path: ROUTES.CATALOGS,
    component: Catalogs,
    exact: true,
  },
  {
    path: ROUTES.ARTIST,
    component: Artist,
    exact: false,
  },
  {
    path: ROUTES.KEYWORD,
    component: Keyword,
    exact: true,
  },
  {
    path: ROUTES.EXPLORE,
    component: ExplorePage,
    exact: true,
  },
  {
    path: ROUTES.ACCOUNT_UPLOAD,
    component: AccountUpload,
    exact: true,
  },
  {
    path: ROUTES.ACCOUNT_CANVASES,
    component: AccountCanvases,
    exact: true,
  },
  {
    path: ROUTES.ACCOUNT_IMAGES,
    component: AccountImagesPage,
    exact: true,
  },
  {
    path: ROUTES.ACCOUNT_COMMERCE,
    component: AccountCommerce,
    exact: true,
  },
  {
    path: ROUTES.ACCOUNT_UPLOADED_IMAGES,
    component: AccountUploadedImagesPage,
    exact: true,
  },
  {
    path: ROUTES.ACCOUNT_INVITE_FRIENDS,
    component: AccountInviteFriends,
    exact: true,
  },
  {
    path: ROUTES.ACCOUNT_SELL_ARTWORKS,
    component: SellArtworkPage,
    exact: true,
  },
  {
    path: ROUTES.ACCOUNT_PROFILE,
    component: AccountProfile,
    exact: true,
  },
  {
    path: ROUTES.ACCOUNT_MEMBER_SHIP,
    component: AccountMembership,
    exact: true,
  },
  {
    path: ROUTES.ARTWORKS,
    component: ArtworksPage,
    exact: true,
  },
  {
    path: ROUTES.ARTISTS,
    component: ArtistsPage,
    exact: true,
  },
];

export const ADMIN_PAGES: IPageItem[] = [
  {
    path: ROUTES.ADMIN_USER_LIST,
    component: AdminUserListPage,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_USER_DETAILS,
    component: AdminUserDetailsPage,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_BETA_INVITES,
    component: BetaInvitesPage,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_IMAGES,
    component: AdminImageList,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_FEATURED_IMAGES,
    component: AdminFeatureImages,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_FEATURED_FLAG,
    component: AdminFeatureConfiguration,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_EXHIBITION_LIST,
    component: AdminExhibitionListPage,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_EXHIBITION_EDIT,
    component: AdminEditExhibitionPage,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_HOMEPAGE_CONFIG,
    component: AdminHomepageConfiguration,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_EXHIBITION_CREATE,
    component: AdminCreateExhibitionPage,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_VENUES,
    component: VenueListPage,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_VENUE_CREATE,
    component: AdminCreateVenuePage,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_VENUE_EDIT,
    component: AdminEditVenuePage,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_HOSTED_VENUES,
    component: HostedVenuePage,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_QUERY_TOOL,
    component: QueryDatabaseTool,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_REFUND_TOOL,
    component: AdminRefundTool,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_GIFT,
    component: ManageGift,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_PARTNERS,
    component: Partners,
    exact: true,
  },
  {
    path: ROUTES.ADMIN_SALES_REPORT,
    component: salesReports,
    exact: true,
  },
];
