import { useFirebaseContext } from 'shell/firebase';
import { useUpdate } from './useQuery';
import { commonErrors } from 'constants/errors';
import { ImageData as Artwork } from 'constants/data';

interface ResellOriginalArtworkInputs {
  imageId: string;
  price: number;
}

const { INVALID_PARAMS_ERROR } = commonErrors;

export const isAvailableForSale = (artwork: Artwork) => {
  const { notForSale } = artwork;

  return !notForSale;
};

export const useResellOriginalArtwork = () => {
  const updateFunc = useUpdate();
  const firebase = useFirebaseContext();

  return async (inputs: ResellOriginalArtworkInputs) => {
    const { imageId: originalArtworkId, price } = inputs;
    if (!originalArtworkId || !price || isNaN(price)) {
      throw new Error(INVALID_PARAMS_ERROR);
    }

    const path = firebase.image(originalArtworkId);

    const updates = {
      [`${path}/priceAmount`]: price,
      [`${path}/notForSale`]: false,
    };

    return await updateFunc(updates);
  };
};

export const useMarkOriginalArtworkAsAvailableForSale = () => {
  const updateFunc = useUpdate();
  const firebase = useFirebaseContext();

  return async (originalArtworkId: string) => {
    if (!originalArtworkId) {
      throw new Error(INVALID_PARAMS_ERROR);
    }

    const path = firebase.image(originalArtworkId);

    const updates = {
      [`${path}/notForSale`]: false,
    };

    return await updateFunc(updates);
  };
};

export const useMarkOriginalArtworkAsUnAvailableForSale = () => {
  const updateFunc = useUpdate();
  const firebase = useFirebaseContext();

  return async (originalArtworkId: string) => {
    if (!originalArtworkId) {
      throw new Error(INVALID_PARAMS_ERROR);
    }

    const path = firebase.image(originalArtworkId);

    const updates = {
      [`${path}/notForSale`]: true,
    };

    return await updateFunc(updates);
  };
};
