import { TransactionData } from 'constants/data';
import Typography from 'modules/typography';

interface PriceCellProps {
  tx: TransactionData;
}

const PriceCell = (props: PriceCellProps) => {
  const { tx } = props;
  const { price } = tx;

  return <Typography>{`€${price || ''}`}</Typography>;
};

export default PriceCell;
