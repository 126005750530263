import aPromiseWrapper from 'a-promise-wrapper';
import { commonErrors } from 'constants/errors';
import { useFirebaseContext } from 'shell/firebase';
import { useAuthContext } from 'shell/session';

interface Inputs {
  imageId: string;
  isAnonymous?: boolean;
  pathname?: string;
}

export const useBuyImage = () => {
  const firebase = useFirebaseContext();
  const { isEmailVerified } = useAuthContext();

  return async ({ imageId, isAnonymous = false, pathname }: Inputs) => {
    if (!isEmailVerified) {
      throw new Error(commonErrors.NEED_VERIFY_EMAIL_ERROR);
    }

    const funcName = 'blockchain-buyImage';
    const callable = firebase.functions.httpsCallable(funcName);
    const { data: response, error } = await aPromiseWrapper(
      callable({ imageId, isAnonymous, pathname })
    );

    if (error) {
      throw error;
    }

    return response.data;
  };
};
