import { useState, useEffect } from 'react';
import { capitalCase, camelCase, noCase } from 'change-case';
import { useFirebaseContext } from 'shell/firebase';

export const useGetKeywordInfo = (keyword: string) => {
  const defaultInfo = {
    name: capitalCase(noCase(keyword)),
    description: null,
  };

  const firebase = useFirebaseContext();
  const keywordInCamelCase = camelCase(keyword);
  const [keywordInfo, setKeywordInfo] = useState();

  useEffect(() => {
    if (firebase !== null) {
      const query = firebase.keywordsRef().child(keywordInCamelCase);
      const stopListener = query.on('value', snapshot => {
        if (snapshot.exists()) {
          setKeywordInfo(snapshot.val());
        }
      });
      return () => query.off('value', stopListener);
    }
  }, [firebase, keywordInCamelCase]);

  return {
    ...defaultInfo,
    ...(keywordInfo || {}),
  };
};
