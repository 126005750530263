import React from 'react';
import cs from 'classnames';
import { Box, makeStyles, createStyles, Theme } from '@material-ui/core';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { CellProps, TableInstance, HeaderGroup, Row } from 'react-table';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const ROW_HEIGHT = 56;
interface TableProps {
  tableInstance: TableInstance<any>;
  tableHeight: number;
  getCustomRowProps?: (row: Row) => React.HTMLAttributes<HTMLDivElement>;
}

/**
 * The implemented UI of `react-table` to display the image list, you can choose which fields to display by specifying
 * the corresponding columns when creating the `tableInstance`
 *
 * * You can create your own columns or use the pre-defined columns in this file e.g. `COLUMN_NAME`, `COLUMN_KEYWORDS`...
 * * You must specify the `tableHeight` in pixel, this is required as we are using `react-window` to display the body
 *
 * @example
 *
 * const columns = [COLUMN_NAME, COLUMN_KEYWORDS]
 *
 * const tableInstance = useTable<ImageData>({
 *  columns, data
 * });
 *
 * return <ImagesTable tableInstance={tableInstance} tableHeight={550} />
 *
 */
const VirtualizedTable = ({
  tableInstance,
  tableHeight,
  getCustomRowProps,
}: TableProps) => {
  const classes = useStyles();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
  } = tableInstance;

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);
  const tableWidth = totalColumnsWidth + scrollBarSize;

  const itemData = {
    prepareRow,
    rows,
    getCustomRowProps,
  };

  return (
    <Box {...getTableProps()} className={classes.table}>
      <Box className={classes.thead}>
        {headerGroups.map(headerGroup => (
          <div
            {...headerGroup.getHeaderGroupProps()}
            className={classes.tr}
            style={{ width: tableWidth }}>
            {headerGroup.headers.map(TableHeaderCell)}
          </div>
        ))}
      </Box>
      <div className={classes.tbody} {...getTableBodyProps()}>
        <FixedSizeList
          height={tableHeight}
          itemCount={rows.length}
          itemSize={ROW_HEIGHT}
          itemData={itemData}
          width={tableWidth}>
          {RenderRow}
        </FixedSizeList>
      </div>
    </Box>
  );
};

const TableHeaderCell = (column: HeaderGroup<any>) => {
  const classes = useStyles();
  const { style, ...headerProps } = column.getHeaderProps(
    column.getSortByToggleProps()
  );

  return (
    <div
      {...headerProps}
      style={{ ...style, width: column.width }}
      className={classes.td}>
      {column.render('Header')}
      {column.isSorted && column.isSortedDesc && (
        <ArrowDownwardIcon className={classes.sortIcon} />
      )}
      {column.isSorted && !column.isSortedDesc && (
        <ArrowUpwardIcon className={classes.sortIcon} />
      )}
    </div>
  );
};

type RenderRowProps = ListChildComponentProps<{
  prepareRow: any;
  rows: any;
  getCustomRowProps?: (row: Row) => React.HTMLAttributes<HTMLDivElement>;
}>;

const RenderRow = ({ index, style, data }: RenderRowProps) => {
  const classes = useStyles();
  const { prepareRow, rows, getCustomRowProps } = data;
  const row = rows[index];
  prepareRow(row);

  const {
    style: customStyle,
    className: customClassName,
    ...customRowProps
  } = getCustomRowProps
    ? getCustomRowProps(row)
    : { style: {}, className: null };

  const mergedStyle = {
    ...style,
    ...customStyle,
  };
  const mergedClassName = cs(classes.tr, customClassName);

  const rowProps = row.getRowProps({
    ...customRowProps,
    style: mergedStyle,
    className: mergedClassName,
  });

  return (
    <div {...rowProps}>
      {row.cells.map((cell: CellProps<any>) => {
        return (
          <div
            {...cell.getCellProps()}
            style={{ width: cell.column.width }}
            className={classes.td}>
            <span className={classes.ellipsis}>{cell.render('Cell')}</span>
          </div>
        );
      })}
    </div>
  );
};

const scrollbarWidth = () => {
  // https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement('div');
  scrollDiv.setAttribute(
    'style',
    'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;'
  );
  document.body.appendChild(scrollDiv);
  const width = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return width;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorsCell: {
      display: 'flex',
      gap: 4,
    },
    colorItem: {
      height: theme.spacing(2),
      width: theme.spacing(2),
      backgroundColor: 'currentColor',
      borderRadius: '50%',
      border: '1px solid #999',
    },
    table: {
      overflowX: 'scroll',
      width: '100%',
    },
    thead: {
      '& $td': {
        'fontWeight': 500,
        'position': 'relative',
        '&:not(:first-child)::before': {
          position: 'absolute',
          content: "''",
          height: '40%',
          top: '30%',
          left: 0,
          display: 'block',
          borderLeft: '3px solid #eee',
        },
      },
    },
    tbody: {},
    tr: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      backgroundColor: theme.palette.common.white,
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    td: {
      height: ROW_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    ellipsis: {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    sortIcon: {
      color: theme.palette.grey['600'],
      marginLeft: theme.spacing(1),
      fontSize: '1.5em',
    },
  })
);

export default VirtualizedTable;
