import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { ModalProvider } from 'modules/modals';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import AppThemeProvider from 'ui-components/Theme';
import LayoutStateProvider from 'utils/contexts/LayoutState';
import { FeatureFlagsProvider } from 'utils/contexts/featureFlagContext';
import { ModesProvider } from 'utils/contexts/modesContext';
import './css/styles.scss';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './shell/app';
import FirebaseProvider from 'shell/firebase/FirebaseProvider';
import { AuthContextProvider } from './shell/session';
import theme from './theme';
import ComponentStack, { withProps as __ } from './ComponentStack';

// Trigger deploy 9

ReactDOM.render(
  <ComponentStack
    components={[
      BrowserRouter,
      CookiesProvider,
      FirebaseProvider,
      __(ThemeProvider, { theme }),
      AppThemeProvider,
      AuthContextProvider,
      CssBaseline,
      ModesProvider,
      ModalProvider,
      __(MuiPickersUtilsProvider, { utils: DateFnsUtils }),
      FeatureFlagsProvider,
      LayoutStateProvider,
    ]}>
    <App />
  </ComponentStack>,

  document.getElementById('root')
);

serviceWorker.unregister();
