import React, { useCallback, useContext } from 'react';

import { useAuthContext } from 'shell/session';
import { ImageData } from 'constants/data/image.data';
import { Print } from 'constants/data/print.data';
import { UserData } from 'constants/data/user.data';
import {
  useLoadAllImagesAndPrintsByUserId,
  useLoadMyStreamingExhibitions,
} from 'views/account/images/new-ui/helpers';
import { ExhibitionData } from 'constants/data/exhibition.data';

// No longer use
export enum ArtworkType {
  ALL = 'all',
  OWNED = 'owned',
  STREAMING = 'streaming',
}

export interface StreamingExhibitionsMap {
  [originalArtworkId: string]: ExhibitionData;
}

export interface StreamingImageData extends ImageData {
  isStreaming?: boolean;
}

type ArtworksContextType = {
  isLoading: boolean;
  images: (Print | ImageData | StreamingImageData)[];
  streamingExhibitionsMap: StreamingExhibitionsMap | null | undefined;
  type: ArtworkType;
  setImageType: (type: ArtworkType) => void;
  setReloadPrint: (timestamp: number) => void;
};

const ArtworksContext = React.createContext<ArtworksContextType>({
  isLoading: false,
  images: [],
  streamingExhibitionsMap: undefined,
  type: ArtworkType.ALL,
  setImageType: () => null,
  setReloadPrint: () => null,
});

export const useArtwork = () => useContext(ArtworksContext);

type ArtworksProviderProps = React.PropsWithChildren<{}>;
const ArtworksProvider = ({ children }: ArtworksProviderProps) => {
  const { user } = useAuthContext() as any as { user: UserData };
  const {
    images,
    isLoading: loadingImages,
    setReloadPrint,
  } = useLoadAllImagesAndPrintsByUserId(user.uid);
  const { exhibitionsMap, isLoading: loadingExhibitions } =
    useLoadMyStreamingExhibitions();
  const [type, internalSetType] = React.useState(ArtworkType.ALL);
  const filteredImages = React.useMemo(() => {
    if (type === ArtworkType.OWNED) {
      return images?.filter(image => image.owner === user.uid);
    }

    if (type === ArtworkType.STREAMING) {
      return images?.filter(
        image => !!(image as StreamingImageData).isStreaming
      );
    }

    return images;
  }, [images, user, type]);
  const setImageType = useCallback(
    (newType: ArtworkType) => {
      internalSetType(newType);
    },
    [internalSetType]
  );
  const isLoading = loadingImages || loadingExhibitions;

  const value = React.useMemo(
    () => ({
      isLoading,
      images: filteredImages,
      streamingExhibitionsMap: exhibitionsMap,
      type,
      setImageType,
      setReloadPrint,
    }),
    [
      isLoading,
      filteredImages,
      exhibitionsMap,
      type,
      setImageType,
      setReloadPrint,
    ]
  );

  return (
    <ArtworksContext.Provider value={value}>
      {children}
    </ArtworksContext.Provider>
  );
};

export default ArtworksProvider;
