import { useRef, useState, useMemo } from 'react';
import {
  IconButton,
  Popper,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Paper,
  Divider,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Typography from 'modules/typography';
import { MARK_PRINT_UNAVAILABLE_FOR_SALE } from './helpers';
import { useGetFeatureFlag } from 'utils/requests';
import { FeatureFlagEnum } from 'constants/common';

interface Props {
  onClickPlay?: () => void;
  className?: string;
  openMakeUnavailableDialog?: () => void;
  openResellDialog?: () => void;
  enableForSale: boolean;
  setDisableAction: (action: string) => void;
  isOwner: boolean;
  isPrintOwner: boolean;
  isOriginOwner: boolean;
  isArtist: boolean;
  isStreaming: boolean;
  onOpenProvenance: () => void;
  onOpenManageStreamingDialog?: () => void;
  onMemberLeaveStreaming?: () => void;
  hasWatermak?: boolean;
  onDownloadArtwork: () => void;
  hasWaitingGift?: boolean;
  isGifted?: boolean;
  handleOpenGiftDialog: () => void;
  handleOpenCancelGiftDialog: () => void;
  isPlaying: boolean;
  isPlayingIRIS: boolean;
  hasWatermarks: boolean;
  onClickShowIRIS: () => void;
  onClickHideIRIS: () => void;
  iconButton?: any;
}

const PopperMenu = ({
  onClickPlay,
  openMakeUnavailableDialog,
  openResellDialog,
  className,
  enableForSale,
  setDisableAction,
  isOwner,
  isPrintOwner,
  isOriginOwner,
  isStreaming,
  isArtist,
  hasWaitingGift,
  isGifted,
  handleOpenGiftDialog,
  handleOpenCancelGiftDialog,
  onOpenProvenance,
  // onOpenManageStreamingDialog,
  onMemberLeaveStreaming,
  hasWatermak,
  onDownloadArtwork,
  isPlaying,
  isPlayingIRIS,
  hasWatermarks,
  onClickShowIRIS,
  onClickHideIRIS,
  iconButton,
}: Props) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { featureEnabled: featureRegiftingEnabled } = useGetFeatureFlag(
    FeatureFlagEnum.ReGifting
  );
  const { featureEnabled: featureIRISEnabled } = useGetFeatureFlag(
    FeatureFlagEnum.IrisDigitalSignature
  );
  const canGift = useMemo(
    // enable gifting when artwork/prints is not gifted or regifting when featureFlag endabled
    () => !!(!isGifted || featureRegiftingEnabled),
    [featureRegiftingEnabled, isGifted]
  );

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };
  const handleClickPlay = () => {
    if (onClickPlay) {
      onClickPlay();
    }
    setOpen(false);
  };
  const handleOpenMakeUnavailableDialog = () => {
    openMakeUnavailableDialog && openMakeUnavailableDialog();
    setOpen(false);
  };

  const disableSale = () => {
    setDisableAction(MARK_PRINT_UNAVAILABLE_FOR_SALE);
    handleOpenMakeUnavailableDialog();
  };

  const handleOpenResellDialog = () => {
    openResellDialog && openResellDialog();
    setOpen(false);
  };

  // Comment from Epic 5229
  // const handleOpenManageStreamingDialog = () => {
  //   setOpen(false);
  //   onOpenManageStreamingDialog && onOpenManageStreamingDialog();
  // };

  const handleGetTransaction = () => {
    onOpenProvenance();
    setOpen(false);
  };

  const onLeaveStreaming = () => {
    setOpen(false);
    onMemberLeaveStreaming && onMemberLeaveStreaming();
  };

  return (
    <>
      <IconButton ref={anchorRef} onClick={handleToggle} className={className}>
        {iconButton ? iconButton : <MoreHorizIcon />}
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} transition>
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList autoFocusItem={open}>
              {featureIRISEnabled && isPlaying && hasWatermarks && (
                <MenuItem
                  onClick={isPlayingIRIS ? onClickHideIRIS : onClickShowIRIS}>
                  <Typography fontSize="0.75rem">
                    {isPlayingIRIS
                      ? 'Hide IRIS digital signature'
                      : 'Show IRIS digital signature'}
                  </Typography>
                </MenuItem>
              )}
              <MenuItem onClick={handleClickPlay}>
                <Typography fontSize="0.75rem">Play on canvas</Typography>
              </MenuItem>
              <MenuItem
                onClick={handleGetTransaction}
                disabled={!isPrintOwner && !isOriginOwner}>
                <Typography fontSize="0.75rem">View provenance</Typography>
              </MenuItem>
              {isOwner && canGift && hasWaitingGift && (
                <MenuItem onClick={handleOpenGiftDialog}>
                  <Typography fontSize="0.75rem">See gift status</Typography>
                </MenuItem>
              )}
              <Divider />
              {isOwner && enableForSale && (
                <MenuItem disabled={hasWaitingGift} onClick={disableSale}>
                  <Typography fontSize="0.75rem" fontColor="#CF0E0E">
                    Disable resell
                  </Typography>
                </MenuItem>
              )}
              {isOwner && !enableForSale && (
                <MenuItem
                  disabled={hasWaitingGift}
                  onClick={handleOpenResellDialog}>
                  <Typography fontSize="0.75rem">Enable Resell</Typography>
                </MenuItem>
              )}
              {/*Comment from Epic 5229 */}
              {/*{isOriginOwner && (*/}
              {/*  <MenuItem onClick={handleOpenManageStreamingDialog}>*/}
              {/*    <Typography fontSize="0.75rem">Manage streaming</Typography>*/}
              {/*  </MenuItem>*/}
              {/*)}*/}
              {isOwner && hasWatermak && (
                <MenuItem onClick={onDownloadArtwork}>
                  <Typography fontSize="0.75rem">Download artwork</Typography>
                </MenuItem>
              )}
              {isOwner && (
                <MenuItem disabled>
                  <Typography fontSize="0.75rem">Export to wallet</Typography>
                </MenuItem>
              )}
              {isStreaming && !isArtist && (
                <MenuItem onClick={onLeaveStreaming}>
                  <Typography fontSize="0.75rem" fontColor="#CF0E0E">
                    Stop streaming
                  </Typography>
                </MenuItem>
              )}
              {isOwner && canGift && !hasWaitingGift && (
                <MenuItem onClick={handleOpenGiftDialog}>
                  <Typography fontSize="0.75rem">Send as a gift</Typography>
                </MenuItem>
              )}
              {isOwner && canGift && hasWaitingGift && (
                <MenuItem onClick={handleOpenCancelGiftDialog}>
                  <Typography fontSize="0.75rem" fontColor="#CF0E0E">
                    Cancel gift
                  </Typography>
                </MenuItem>
              )}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export default PopperMenu;
