import React from 'react';
import getEnvVars from 'utils/common/envVars';

// This is the list of widths gotten from imgix. We may change it later for better caching strategy
const WIDTHS = [
  100, 116, 135, 156, 181, 210, 244, 283, 328, 380, 441, 512, 594, 689, 799,
  927, 1075, 1247, 1446, 1678, 1946, 2257, 2619, 3038, 3524, 4087, 4741, 5500,
  6380, 7401, 8192,
];

export interface Sizes {
  mobile?: string;
  desktop?: string;
}
interface Props
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'url' | 'sizes'> {
  path: string;
  sizes?: string | Sizes;
}

/**
 * A wrapper of html `img` tag to provide image optimization feature for images stored in our firebase storage.
 * Intead of specifying the `src` property, specify the `path` only. The `src` and `srcSet` properties will be
 * generated depend on the 3rd service we are using (imgix for now)
 *
 * To make it work properly, you have to specify the `sizes` property, check here for more details
 * https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images#resolution_switching_different_sizes
 *
 * @typedef {Object} Props
 * @prop {string} path The path to the firebase storage image, is usually retrieved from `image.path` e.g. images/ABCXYZ.jpg.
 * @prop {string | Sizes} sizes The img's `sizes` attribute
 * @param {Prop} props
 */
const OptimizedImage = ({
  path,
  sizes: propSizes = '',
  alt,
  ...rest
}: Props) => {
  const { imageDomain } = getEnvVars();
  const sizes = typeof propSizes === 'string' ? propSizes : getSizes(propSizes);
  const src = `${imageDomain}/${path}`;
  const srcSet = buildSrcset(src);
  return (
    <img
      src={src}
      srcSet={srcSet}
      sizes={sizes}
      alt={alt || ''}
      onContextMenu={event => event.preventDefault()}
      {...rest}
    />
  );
};

const buildSrcset = (src: string) => {
  const widths = WIDTHS;
  return widths.reduce((acc, width) => {
    acc += `${src}?auto=format&w=${width} ${width}w, `;
    return acc;
  }, '');
};

const getSizes = (sizes: Sizes) => {
  const mobileStr = sizes.mobile ? `(max-width: 960px) ${sizes.mobile}` : '';
  const desktopStr = sizes.desktop ? `(min-width: 961px) ${sizes.desktop}` : '';
  return `${mobileStr}, ${desktopStr}`;
};

export default OptimizedImage;
