import React from 'react';
import { Box, IconButton, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles } from '@material-ui/styles';
import { mobileMediaQuery } from 'utils/common';

interface ContentWrapperProps {
  children: React.ReactElement;
  onClose: () => void;
  isOpen: boolean;
}

export const ContentWrapper = ({
  children,
  onClose,
  isOpen,
}: ContentWrapperProps) => {
  const classes = useStyles();
  if (!isOpen) {
    return null;
  }
  return (
    <Box className={classes.container}>
      {children}
      <IconButton
        classes={{ root: classes.closeButton }}
        color={'primary'}
        onClick={() => onClose()}>
        <CloseIcon fontSize={'small'} />
      </IconButton>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    container: {
      width: '100%',
      maxHeight: 300,
      background: '#F9F9F9',
      border: '1px solid #F0F0F0',
      borderLeft: '4px solid #000000',
      borderRadius: 3,
      padding: theme.spacing(2, 8, 2, 3),
      marginBottom: theme.spacing(2),
      position: 'relative',
      [mobile]: {
        height: '100%',
        maxHeight: '100%',
        padding: theme.spacing(2, 3, 2, 3),
      },
    },
    closeButton: {
      position: 'absolute',
      right: 0,
      top: 0,
      fontSize: theme.typography.pxToRem(8),
    },
  });
});

export default ContentWrapper;
