export interface ExhibitionCoverImage {
  path: string;
  url: string;
}

export interface ExhibitionPrints {
  [imageId: string]: string[];
}

export interface ExhibitionData {
  uid: string;
  name: string;
  description?: string;
  coverImage?: ExhibitionCoverImage;
  startTime: number;
  endTime: number;
  curatedBy?: string;
  status: string;
  images?: string[];
  prints?: ExhibitionPrints; // TODO: consider to migrate and remove after release streaming feature
  isOnGoing: boolean;
  venue?: string;
  banner?: ExhibitionCoverImage;
  fromCurator?: string;
  curatorByLine?: string;
  type?: number;
  subtitle?: string;
  streamings?: {
    [imageId: string]: number;
  };
}

export const EXHIBITION_STATUS_PUBLISHED = 'published';
export const EXHIBITION_STATUS_DRAFT = 'draft';

export interface PreExhibitionImage {
  id: string;
  selectedStreamingSlots: number;
}

export interface PreExhibitionData {
  name: string;
  description: string;
  coverImageFile?: File;
  coverImage?: ExhibitionCoverImage;
  bannerFile?: File;
  banner?: ExhibitionCoverImage;
  startTime?: Date;
  endTime?: Date;
  curatedBy: string;
  images: PreExhibitionImage[];
  venue: string;
  fromCurator: string;
  curatorByLine: string;
  type?: number;
}

export const EXHIBITION_TYPE_1 = 1;

const exhibitionMap: { [key: number]: number } = {
  1: EXHIBITION_TYPE_1,
};

export const getExhibitionType = (type?: number) => {
  const allKeys = Object.keys(exhibitionMap);

  if (!type || allKeys.indexOf(type.toString()) === -1) {
    return 'default';
  }

  return exhibitionMap[type];
};
