import React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Box,
  Button,
  Tooltip,
  Paper,
} from '@material-ui/core';
import Typography from 'modules/typography';
import { CanvasData } from 'constants/data/canvas.data';
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';
import { useLoadPlayingArtworkByCanvas } from 'utils/requests';

type Props = {
  canvas: CanvasData;
  onAssignArtwork: (canvas: CanvasData) => void;
  isLoading: boolean;
  playingImageId?: string;
};

const CanvasItem = ({
  canvas,
  onAssignArtwork,
  isLoading,
  playingImageId,
}: Props) => {
  const classes = useStyles();
  const deviceName = canvas.meta?.deviceName ?? '--';
  const { image } = useLoadPlayingArtworkByCanvas(canvas.uid);
  const isPlayingImage = !!image && image.uid === playingImageId;

  return (
    <Box className={classes.tableRow}>
      <Box className={classes.canvasCol}>
        <Typography lineClamp={2}>{deviceName}</Typography>
      </Box>
      <Box className={classes.statusCol}>
        {image && (
          <Tooltip
            placement="top"
            title={
              <Paper variant="outlined" className={classes.tooltipContent}>
                <Box display="flex" alignItems="center">
                  <Box className={classes.activeDot} mr={1}></Box>
                  <Typography fontSize={14}>On view</Typography>
                </Box>
                <Box>
                  <Typography fontSize={12} fontColor="#616161">
                    {image.name}
                  </Typography>
                </Box>
              </Paper>
            }
            classes={{ tooltip: classes.tooltip }}>
            <Box className={classes.activeDot}></Box>
          </Tooltip>
        )}
      </Box>
      <Box className={classes.assignCol}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.assignImageButton}
          onClick={() => onAssignArtwork(canvas)}
          disabled={isLoading || isPlayingImage}>
          <PanoramaOutlinedIcon className={classes.buttonIcon} />
          Assign Artwork
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(2.5),
      borderTop: '1px solid #D8D8D8',
      alignItems: 'center',
    },
    canvasCol: {
      flex: 1,
    },
    statusCol: {
      width: 60,
      textAlign: 'center',
    },
    assignCol: {
      width: 160,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    activeDot: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: '#2FBE34',
      display: 'inline-block',
      cursor: 'pointer',
    },
    assignImageButton: {
      fontSize: '0.75rem',
      height: 35,
      borderRadius: 30,
      padding: theme.spacing(1, 1.5),
    },
    buttonIcon: {
      width: 17,
      height: 18,
      marginRight: theme.spacing(1),
    },
    tooltip: {
      backgroundColor: 'transparent',
      padding: 0,
    },
    tooltipContent: {
      padding: theme.spacing(1.5, 2),
      maxWidth: 150,
    },
  });
});

export default CanvasItem;
