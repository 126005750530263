import { useState, useEffect } from 'react';
import { useFirebaseContext } from 'shell/firebase';
import { IVenue } from 'constants/data/venue.data';

export const useLoadVenueDetails = (uid?: string) => {
  const firebase = useFirebaseContext();
  const [venue, setVenue] = useState<IVenue | null>();

  useEffect(() => {
    if (!uid) {
      setVenue(null);
      return;
    }

    const query = firebase.venueRef(uid);
    const stopListener = query.on('value', snapshot => {
      setVenue(snapshot.val());
    });

    return () => query.off('value', stopListener);
  }, [uid, firebase]);

  return { venue, isLoading: venue === undefined };
};

export default useLoadVenueDetails;
