import { TextField as MuiTextField } from '@material-ui/core';
import cs from 'classnames';
import useStyles from './styles';

const TextField = props => {
  const classes = useStyles();
  const {
    InputProps,
    FormHelperTextProps,
    onChange,
    value,
    parseNumber = parseInt,
    ...rest
  } = props;

  const getValue = () => {
    if (rest.type === 'number') {
      /**
       * See this weird issue at here:
       * https://github.com/facebook/react/issues/7779#issuecomment-248432471
       */
      return !value && value !== 0 ? '' : value;
    }

    return value;
  };

  const handleChange = event => {
    if (rest.type === 'number') {
      const parsed = parseNumber(event.target.value);
      onChange({
        ...event,
        target: {
          ...event.target,
          value: isNaN(parsed) ? '' : parsed,
        },
      });
    } else {
      onChange(event);
    }
  };

  return (
    <MuiTextField
      {...rest}
      variant="outlined"
      inputProps={rest.inputProps}
      value={getValue()}
      onChange={handleChange}
      InputProps={{
        ...InputProps,
        classes: mergeInputPropsClasses(classes, InputProps?.classes),
      }}
      InputLabelProps={{
        disableAnimation: true,
        shrink: true,
        classes: {
          root: classes.labelRoot,
          formControl: classes.labelFormControl,
          shrink: classes.labelShrink,
          outlined: classes.labelOutlined,
        },
      }}
      FormHelperTextProps={{
        ...FormHelperTextProps,
        classes: mergeFormHelperTextPros(classes, FormHelperTextProps?.classes),
      }}
    />
  );
};

const mergeFormHelperTextPros = (
  formHelperTextPropsClasses,
  customClasses = {}
) => {
  return {
    ...customClasses,
    contained: cs(
      formHelperTextPropsClasses.formHelperTextContained,
      customClasses.contained
    ),
  };
};

const mergeInputPropsClasses = (inputPropsClasses, customClasses = {}) => {
  return {
    ...customClasses,
    root: cs(inputPropsClasses.inputRoot, customClasses.root),
    notchedOutline: cs(
      inputPropsClasses.inputNotchedOutline,
      customClasses.notchedOutline
    ),
    error: cs(inputPropsClasses.inputError, customClasses.error),
    input: cs(inputPropsClasses.inputInput, customClasses.input),
    inputMultiline: cs(
      inputPropsClasses.inputInputMultiline,
      customClasses.inputMultiline
    ),
  };
};

export default TextField;
