import { ImageData } from 'constants/data/image.data';
import * as Yup from 'yup';

export interface CheckPublishInterface {
  (image: ImageData): CheckPublishableResult;
}

export interface CheckPublishableResult {
  isPublishable: boolean;
  errors: string[];
}

export const checkIsImagePublishable = (image: ImageData) => {
  const schema = Yup.object().shape(shape);
  let errors: string[] = [];
  try {
    schema.validateSync(image, { abortEarly: false });
  } catch (e) {
    errors = (e as Yup.ValidationError).errors;
  }

  const isPublishable = errors.length === 0;
  return { isPublishable, errors };
};

const shape = {
  catalog: Yup.string().required('Collection is required'),
  associations: Yup.string().required('Keywords are required'),
  color: Yup.string().required('Color is required'),
  brightness: Yup.number()
    .min(0, 'Brightness must be a number between 0-5')
    .max(5, 'Brightness must be a number between 0-5')
    .required('Brightness is required'),
  abstraction: Yup.number()
    .min(0, 'Abstraction must be a number between 0-5')
    .max(5, 'Abstraction must be a number between 0-5')
    .required('Abstraction is required'),
  name: Yup.string().required('Name is required'),
  date: Yup.number()
    .min(0, 'Invalid year')
    .max(10000, 'Invalid year')
    .integer('Invalid year')
    .required('Year is required'),
  medium: Yup.string().required('Medium is required'),
  description: Yup.string().required('Description is required'),
  numberOfPrints: Yup.number()
    .min(1, 'Number of prints must be greater than 0')
    .required('Number of prints is required'),
};
