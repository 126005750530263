import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { mobileMediaQuery } from 'utils/common';
import { useIsMobile } from 'utils/hooks';
import Subscribe from 'views/client/subscribe';
import { ReactComponent as CloseIcon } from 'static/images/close-icon.svg';

import UIButton from 'ui-components/Button';

type SubscriptionPlanDialogProps = {
  onClose: () => void;
  onNext: () => void;
  successUrl?: string;
  cancelUrl?: string;
};

const SubscriptionPlanDialog = ({
  onClose,
  onNext,
  successUrl,
  cancelUrl,
}: SubscriptionPlanDialogProps) => {
  const isMobile = useIsMobile();
  const classes = useStyles();

  return (
    <Dialog
      open
      classes={{ paper: classes.paper }}
      fullScreen={isMobile}
      onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box>
          <Typography className={classes.head}>Play artwork</Typography>
          <Typography className={classes.title} variant="h4">
            Pick a subscription plan
          </Typography>
          <Typography className={classes.description}>
            To continue to select the artwork, select a subscription plan
          </Typography>
        </Box>
        <Box>
          <Subscribe successUrl={successUrl} cancelUrl={cancelUrl} />
        </Box>
        <UIButton
          variant="primary"
          size="medium"
          text="Close"
          onClick={onNext}
        />
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    paper: {
      color: '#000',
      maxWidth: 1000,
      [mobile]: {
        minHeight: '65vh',
        maxHeight: '80vh',
      },
    },
    dialogTitle: {
      padding: theme.spacing(3, 3),
    },
    dialogContent: {
      padding: theme.spacing(0, 7, 6),
      [mobile]: {
        padding: theme.spacing(0, 2, 7),
      },
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(3.5),
      right: theme.spacing(4),
      [mobile]: {
        top: theme.spacing(2),
        right: theme.spacing(2),
      },
    },
    head: {
      fontFamily: ['Fraunces', 'Times', 'Times New Roman', 'serif'].join(', '),
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1.25,
      fontWeight: 300,
      marginBottom: theme.spacing(1),
    },
    title: {
      fontWeight: 500,
      letterSpacing: '-0.02em',
      marginBottom: theme.spacing(2),
    },
    description: {
      color: '#030303',
      opacity: '0.8',
      maxWidth: '45%',
      [mobile]: {
        maxWidth: '100%',
      },
    },
  });
});

export default SubscriptionPlanDialog;
