import React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
  IconButton,
} from '@material-ui/core';
import { useIsMobile } from 'utils/hooks';
import { ReactComponent as CloseIcon } from 'static/images/close-icon.svg';

interface Props extends DialogProps {
  isAvailable?: boolean;
}

const BuyImageInfoDialog = (props: Props) => {
  const isMobile = useIsMobile();
  const classes = useStyles();
  const { isAvailable, ...dialogProps } = props;

  let availableText = '1 of 1 is available to buy';
  if (!isAvailable) {
    availableText = '0 of 1 is available to buy';
  }

  const handleClose = (reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (!reason && dialogProps.onClose) {
      dialogProps.onClose({}, 'escapeKeyDown');
    }
  };

  return (
    <Dialog
      {...dialogProps}
      fullScreen={isMobile}
      maxWidth="xs"
      onClose={(event, reason) => handleClose(reason)}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography className={classes.dialogTitleText}>Original</Typography>

        <IconButton
          onClick={() => handleClose()}
          className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>
          The original is the one and only rendition of an artwork. There could
          only be one of an original of the artwork. The original can only be
          bought, not rented.
        </Typography>
        <Box mt={3.5}>
          <Typography className={classes.dialogContentTitle}>
            Availability
          </Typography>
          <Typography className={classes.availableText}>
            {availableText}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    dialogTitle: {
      position: 'relative',
      padding: theme.spacing(7, 7, 3, 7),
    },
    dialogTitleText: {
      fontSize: '1.75rem',
      fontWeight: 500,
      maxWidth: 350,
    },
    dialogContentTitle: {
      fontSize: '1.25rem',
      fontWeight: 500,
      marginBottom: theme.spacing(1),
    },
    closeButton: {
      position: 'absolute',
      right: 40,
      top: 40,
    },
    dialogContent: {
      padding: theme.spacing(0, 7, 7, 7),
    },
    availableText: {
      color: '#30A035',
      fontSize: '0.75rem',
    },
  });
});

export default BuyImageInfoDialog;
