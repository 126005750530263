import { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { EnumVenueModuleType } from 'constants/data/venue.data';
import DefaultTopBar, { useStyles } from './TopBar';
import VenueTopBar from 'views/client/venueDetails/editableModules/TopBar';
import { useLayoutState } from 'utils/contexts/LayoutState';

const TopBar = () => {
  const classes = useStyles();
  const { isHostedVenue, venue } = useLayoutState();

  const renderVenueTopBar = useCallback(() => {
    if (!venue) {
      return null;
    }

    const { configurableModules, isDisplayVenueProfile25 } = venue;

    if (!isDisplayVenueProfile25) {
      return <DefaultTopBar />;
    }

    if (!configurableModules) {
      return null;
    }

    const topBarModule = Object.values(configurableModules).find(
      module => module.type === EnumVenueModuleType.TopBar
    );

    if (!topBarModule?.visible) {
      return null;
    }

    return (
      <Box className={classes.container}>
        <Box className={classes.topBar}>
          <VenueTopBar moduleId={topBarModule.uid} venueId={venue.uid} />
        </Box>
      </Box>
    );
  }, [venue, classes]);

  if (!isHostedVenue) {
    return <DefaultTopBar />;
  }

  return renderVenueTopBar();
};

export default TopBar;
