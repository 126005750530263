import { useState, useEffect } from 'react';
import { useFirebaseContext } from 'shell/firebase';
import { useAuthContext } from 'shell/session';
import { UserData } from 'constants/data/user.data';

export interface FeatureItem {
  users: string[];
  global: boolean;
}

interface FeatureFlagInterface {
  [key: string]: FeatureItem;
}

export const useGetFeatureFlag = (featureName: string) => {
  const firebase = useFirebaseContext();
  const authContext = useAuthContext();
  const user = authContext.user as UserData | undefined;
  const [featureEnabled, setFeatureEnabled] = useState<boolean>();

  useEffect(() => {
    if (firebase !== null) {
      const { email = '' } = user || {};

      const query = firebase.featureFlagRefByKey(featureName);
      const callback = query.on('value', snapshot => {
        const data = snapshot.val();

        if (!data) {
          setFeatureEnabled(false);
          return;
        }

        const { users, global } = data;

        const hasUser =
          users &&
          users.length &&
          email &&
          !!users.find(
            (allowedEmail: string) =>
              !!allowedEmail &&
              allowedEmail.toLowerCase() === email.toLowerCase()
          );

        const enabled = global || hasUser;

        setFeatureEnabled(!!enabled);
        return;
      });

      return () => query.off('value', callback);
    }
  }, [firebase, user, featureName]);

  return {
    featureEnabled,
    isLoading: typeof featureEnabled === 'undefined',
  };
};

export const useGetAllFeatureFlag = () => {
  const firebase = useFirebaseContext();
  const [featureData, setFeatureData] = useState<
    FeatureFlagInterface | undefined
  >();

  useEffect(() => {
    if (firebase !== null) {
      const query = firebase.featureFlagRef();
      const callback = query.on('value', snapshot => {
        const data = snapshot.val();

        if (data) {
          setFeatureData(data);
        }
      });

      return () => query.off('value', callback);
    }
  }, [firebase]);

  return {
    featureData,
    isLoading: typeof featureData === 'undefined',
  };
};
