import * as Yup from 'yup';
import { StreamType } from 'views/account/upload/helpers';
import sanitizeHtml from 'sanitize-html';

const allowedTags = [
  ...sanitizeHtml.defaults.allowedTags,
  'embed',
  'iframe',
  'img',
  'video',
  'details',
  'summary',
];

const allowedAttributes = {
  ...sanitizeHtml.defaults.allowedAttributes,
  iframe: [
    'allow',
    'allowfullscreen',
    'frameborder',
    'height',
    'name',
    'src',
    'title',
    'width',
  ],
};

const patterns = {
  domain:
    /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
};

export const sanitizeHtmlTags = (
  html,
  disallowedTagsMode = sanitizeHtml.defaults.disallowedTagsMode
) => {
  const options = {
    allowedTags,
    allowedAttributes,
    allowedIframeHostnames: ['www.youtube.com', 'player.vimeo.com'],
    disallowedTagsMode,
  };

  if (disallowedTagsMode === sanitizeHtml.defaults.disallowedTagsMode) {
    options.exclusiveFilter = frame => {
      /**
       * For the case, user puts disallowed attributes on img tag, both discard mode and escape mode will response the same content.
       * In this case, we will have exclusiveFilter option to filter out empty/wrong img tag to support compare the difference between 2 above modes.
       */
      const isValidUrl = Yup.string().url().isValidSync(frame.attribs.src);

      return frame.tag === 'img' && !isValidUrl;
    };
  }

  return sanitizeHtml(html, options);
};

// Yup custom validation for text containing html tags
Yup.addMethod(Yup.string, 'validHtmlTags', function (errorMessage) {
  return this.test(`description-valid-html-tags`, function (value) {
    const { path, createError } = this;

    const discardedHtml = sanitizeHtmlTags(value);
    const escapedHtml = sanitizeHtmlTags(value, 'escape');

    /**
     * sanitize-html library doesn't support response true/false value to check the html content is valid or not.
     * It only responses escaped/discarded html content.
     * https://github.com/apostrophecms/sanitize-html/issues/498
     */
    if (discardedHtml !== escapedHtml) {
      return createError({ path, message: errorMessage });
    }

    return discardedHtml;
  });
});

const regexUsername = /^[\w.]+$/;
const isUsernameValid = username => {
  return regexUsername.test(username);
};
const usernameValidation = Yup.string()
  .min(2, 'Username should be more than 2 letters')
  .max(30, 'Username should be less than 30 letters')
  .test(
    'checkValidUsername',
    "Username can only contain letters, numbers, '.' or '_'",
    function (value) {
      return isUsernameValid(value);
    }
  )
  .required('Username is required');
const emailValidation = Yup.string()
  .email('This input is not a valid email address')
  .required('Email address is required');
const emailValidationWithVerify = Yup.string()
  .email('This input is not a valid email address')
  .oneOf([Yup.ref('email'), null], 'Email address must match');
const fullNameValidation = Yup.string()
  .required('Name is required')
  .max(50, 'Name is too long');
const passwordValidation = Yup.string()
  .required('Password is required')
  .max(128, 'Password is too long');
const confirmPasswordValidation = Yup.string()
  .required('Please confirm the password')
  .oneOf([Yup.ref('password'), null], 'Passwords must match');
const priceValidation = Yup.number()
  .transform(emptyStringToUndefined)
  .required('Minimum price is 1 EUR')
  .min(1, 'Minimum price is 1 EUR');
const maxNumberOfPrintsValidation = Yup.number()
  .transform(emptyStringToUndefined)
  .min(1, 'Must be at least 1 print')
  .max(
    Yup.ref('numberOfPrints'),
    'This value should be less than number of prints'
  );
const numberOfPrintsValidation = Yup.number()
  .transform(emptyStringToUndefined)
  .min(1, 'Must be at least 1 print');
const brightnessValidation = Yup.number()
  .transform(emptyStringToUndefined)
  .min(0, 'Must be a number between 0-5')
  .max(5, 'Must be a number between 0-5');
const abstractionValidation = Yup.number()
  .transform(emptyStringToUndefined)
  .min(0, 'Must be a number between 0-5')
  .max(5, 'Must be a number between 0-5');

export const UserCreateSchema = Yup.object().shape({
  username: usernameValidation,
  email: emailValidation,
  fullName: fullNameValidation,
});

export const SignupSchema1 = Yup.object().shape({
  fullName: fullNameValidation,
  username: usernameValidation,
});

export const SignupSchema2 = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
  repeatPassword: confirmPasswordValidation,
});

export const SignupSchemaVerifyEmail = Yup.object().shape({
  fullName: fullNameValidation,
  email: emailValidation,
  verifyEmail: emailValidationWithVerify,
  password: passwordValidation,
});

export const SignupSchema = Yup.object().shape({
  fullName: fullNameValidation,
  email: emailValidation,
  password: passwordValidation,
});

export const SigninSchema = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
});

export const UserSchema = Yup.object().shape({
  username: usernameValidation,
});

export const CanvasSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name is too short')
    .max(30, 'Name is too long')
    .required('Name is required'),
});

export const ProjectSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name is too short')
    .max(30, 'Name is too long')
    .required('Name is required'),
});

export const GroupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name is too short')
    .max(30, 'Name is too long')
    .required('Name is required'),
});

export function emptyStringToUndefined(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return undefined;
  }
  return value;
}

const editPublishedImage = {
  catalog: Yup.string(),
  associations: Yup.string().required('Required'),
  color: Yup.string().required('Required'),
  brightness: brightnessValidation,
  abstraction: abstractionValidation,
  name: Yup.string().required('Required'),
  date: Yup.number()
    .transform(emptyStringToUndefined)
    .integer('Invalid year')
    .min(0, 'Invalid year')
    .max(10000, 'Invalid year')
    .required('Required'),
  medium: Yup.string().required('Required'),
  description: Yup.string().test(
    'description',
    'Invalid description',
    function (value) {
      if (value.length > 0) {
        return Yup.string().validHtmlTags('Invalid HTML tags');
      }
      return true;
    }
  ),
  forSale: Yup.boolean(),
  // Comment from Epic 5229
  // priceAmount: Yup.number()
  //   .transform(emptyStringToUndefined)
  //   .min(1, 'Minimum price is 1 EUR')
  //   .when('forSale', {
  //     is: true,
  //     then: Yup.number()
  //       .transform(emptyStringToUndefined)
  //       .nullable()
  //       .required('Required'),
  //   }),
  // forStream: Yup.boolean(),
  // numberOfStreamings: Yup.number().when(['forStream', 'streamType'], {
  //   is: (forStream, streamType) =>
  //     forStream && streamType === StreamType.LIMITED,
  //   then: Yup.number()
  //     .min(1, 'Minimum Streaming slots is 1')
  //     .transform(emptyStringToUndefined)
  //     .required('Please set streaming slots'),
  // }),
};

const editUnpublishedImage = {
  catalog: Yup.string(),
  associations: Yup.string(),
  color: Yup.string(),
  brightness: brightnessValidation,
  abstraction: abstractionValidation,
  name: Yup.string(),
  date: Yup.number()
    .transform(emptyStringToUndefined)
    .integer('Invalid year')
    .min(0, 'Invalid year')
    .max(10000, 'Invalid year'),
  medium: Yup.string(),
  description: Yup.string().test(
    'description',
    'Invalid description',
    function (value) {
      if (value.length > 0) {
        return Yup.string().validHtmlTags('Invalid HTML tags');
      }
      return true;
    }
  ),
  forSale: Yup.boolean(),
  // Comment from Epic 5229
  // priceAmount: Yup.number()
  //   .transform(emptyStringToUndefined)
  //   .min(1, 'Minimum price is 1 EUR')
  //   .when('forSale', {
  //     is: true,
  //     then: Yup.number()
  //       .transform(emptyStringToUndefined)
  //       .required('Please set price'),
  //   }),
  numberOfPrints: Yup.number()
    .transform(emptyStringToUndefined)
    .min(1, 'Must be at least 1 print'),
  printPriceAmount: Yup.number()
    .transform(emptyStringToUndefined)
    .min(1, 'Minimum price is 1 EUR')
    .when('isPrintsForSale', {
      is: true,
      then: Yup.number()
        .transform(emptyStringToUndefined)
        .required('Please set price'),
    }),
  isPrintsForSale: Yup.boolean(),
  // Comment from Epic 5229
  // forStream: Yup.boolean(),
  // numberOfStreamings: Yup.number().when(['forStream', 'streamType'], {
  //   is: (forStream, streamType) =>
  //     forStream && streamType === StreamType.LIMITED,
  //   then: Yup.number()
  //     .min(1, 'Minimum Streaming slots is 1')
  //     .transform(emptyStringToUndefined)
  //     .required('Please set streaming slots'),
  // }),
  initialNumOfPrintsForSale: Yup.number()
    .transform(emptyStringToUndefined)
    .when('isPrintsForSale', {
      is: true,
      then: Yup.number()
        .transform(emptyStringToUndefined)
        .min(1, 'Must be at least 1 print')
        .max(
          Yup.ref('numberOfPrints'),
          'Should be less than the number of prints'
        )
        .required('Required'),
    }),
};

const MAX_FILE_SIZE = 5 * 1024 * 1024 * 1024;

export const EditPublishedImageSchema = Yup.object().shape(editPublishedImage);
export const EditUnpublishedImageSchema =
  Yup.object().shape(editUnpublishedImage);
export const UploadImageSchema = Yup.object().shape({
  imageFile: Yup.mixed()
    .required('Required')
    .test(
      'invalid_file_size',
      'File size should be less than 5GB',
      function (files) {
        try {
          return (
            files && Array.from(files).every(file => file.size < MAX_FILE_SIZE)
          );
        } catch (e) {
          console.log(e);
          return false;
        }
      }
    ),
  brightness: brightnessValidation,
  abstraction: abstractionValidation,
  numberOfPrints: numberOfPrintsValidation,
  forSale: Yup.boolean(),
  // Comment from Epic 5229
  // priceAmount: Yup.number().transform(emptyStringToUndefined).when('forSale', {
  //   is: true,
  //   then: priceValidation,
  // }),
  isPrintsForSale: Yup.boolean(),
  printPriceAmount: Yup.number()
    .transform(emptyStringToUndefined)
    .when('isPrintsForSale', {
      is: true,
      then: priceValidation,
    }),
  initialNumOfPrintsForSale: Yup.number()
    .transform(emptyStringToUndefined)
    .when('isPrintsForSale', {
      is: true,
      then: maxNumberOfPrintsValidation,
    }),
  description: Yup.string()
    .required('Required')
    .validHtmlTags('Invalid HTML tags'),
  forStream: Yup.boolean(),
  numberOfStreamings: Yup.number().when(['forStream', 'streamType'], {
    is: (forStream, streamType) =>
      forStream && streamType === StreamType.LIMITED,
    then: Yup.number()
      .min(1, 'Minimum Streaming slots is 1')
      .transform(emptyStringToUndefined)
      .required('Please set streaming slots'),
  }),
});

export const ResetPasswordSchema = Yup.object().shape({
  email: emailValidation,
});

export const ResetPasswordConfirmationSchema = Yup.object().shape({
  password: passwordValidation,
  confirmPassword: confirmPasswordValidation,
});

export const RequestInvitecodeSchema = Yup.object().shape({
  email: emailValidation,
});

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Please enter the current password'),
  newPassword: passwordValidation,
  confirmPassword: Yup.string().test(
    'passwords-match',
    'Must match the new password',
    function (value) {
      return this.parent.newPassword === value;
    }
  ),
});

export const EmailsSchema = Yup.object().shape({
  emails: Yup.array()
    .required('Emails are required')
    .transform((str, value) => {
      if (str !== null) {
        return str;
      }
      const emails = value
        .split(',')
        .map(email => email.trim())
        .filter(email => !!email);
      if (!emails || emails.length === 0) {
        return undefined;
      }
      return emails;
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`)),
});

/**
 * This schema is used ONLY for admin changing user's subscription plan
 */
export const ChangeSubscriptionSchema = Yup.object().shape({
  name: Yup.string().required('Please choose a subscription plan'),
});

export const FeatureFlagSchema = Yup.object().shape({
  users: Yup.string(),
  global: Yup.boolean(),
});

export const FeaturedImageSectionSchema = Yup.object().shape({
  id: Yup.string().required('Please select one section'),
});

export const CreateExhibitionSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  startTime: Yup.date().required('Start time is required'),
  endTime: Yup.date().required('End time is required'),
  description: Yup.string().validHtmlTags('Invalid HTML tags'),
});

export const SignInWithOTPSchema = Yup.object().shape({
  otp: Yup.string().required('Please enter otp'),
});

const VenueSchemaBase = {
  name: Yup.string().required('Name is required'),
  shortDescription: Yup.string().required('Short description is required'),
};

export const HostedVenueSchema = Yup.object().shape({
  domainName: Yup.string().test(
    'checkValidDomain',
    'Invalid domain',
    function (value) {
      if (!value) {
        return true;
      }
      const domainRules = [patterns.domain];
      return domainRules.some(regex => regex.test(value));
    }
  ),
});

export const EditVenueSchema = Yup.object().shape({
  ...VenueSchemaBase,
});

export const CreateVenueSchema = Yup.object().shape({
  ...VenueSchemaBase,
  uploadImage: Yup.mixed().nullable().required('Cover image is required'),
  uploadCoverMobileImage: Yup.mixed()
    .nullable()
    .required('Cover mobile image is required'),
  uploadThumbnailImage: Yup.mixed()
    .nullable()
    .required('Thumbnail image is required'),
});

export const AddHomepageModuleSchema = Yup.object().shape({
  type: Yup.string().required('Type is required'),
  uid: Yup.string().required('uid is required'),
  visible: Yup.boolean(),
  data: Yup.string(),
});
