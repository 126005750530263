/**
 * All functions scoped by venue
 */
import { useEffect, useState } from 'react';
import { ImageData } from 'constants/data/image.data';
import { useFirebaseContext } from 'shell/firebase';
import { database } from 'firebase/app';
import { responseToArray } from 'utils/common';

interface LoadImageFilterByItem {
  key: 'spotlight';
  value?: string | number | boolean;
}

interface LoadImageOptionsInterface {
  filterBy?: LoadImageFilterByItem;
  groupBy?: string;
  sortBy?: 'spotlight';
  limit?: number;
}

const filterImages = (
  images: ImageData[],
  options?: LoadImageOptionsInterface
) => {
  if (!options) {
    return [...images];
  }

  const { filterBy, sortBy, limit } = options;

  let result = [...images];

  if (filterBy) {
    const { key, value } = filterBy;

    if (key) {
      if (key === 'spotlight') {
        result = result.filter(image =>
          !!value ? image[key] === value : image[key]
        );
      }
    }
  }

  if (sortBy) {
    result = result.sort((a, b) => {
      const aSortBy = a[sortBy];
      const bSortBy = b[sortBy];

      if (aSortBy && bSortBy) {
        return bSortBy - aSortBy;
      }

      if (aSortBy) {
        return -1;
      }

      if (bSortBy) {
        return 1;
      }

      return 0;
    });
  }

  if (limit) {
    result = result.slice(0, limit);
  }

  return result;
};

export const useLoadImagesByVenue = (
  venue: string,
  options?: LoadImageOptionsInterface
) => {
  const [allImages, setAllImages] = useState<ImageData[] | null>();
  const [images, setImages] = useState<ImageData[] | null>();
  const firebase = useFirebaseContext();

  /***
   * useEffect shallow compare object
   */
  const optionString = options ? JSON.stringify(options) : '';

  useEffect(() => {
    if (firebase !== null) {
      const ref = firebase.imagesRef();
      const query = ref.orderByChild('venue').equalTo(venue);

      const callback = query.on('value', async snapshot => {
        const imagesObj = snapshot.val();

        if (imagesObj === null) {
          setImages(null);

          return;
        }

        const allImages: ImageData[] = Object.values(imagesObj);

        setAllImages(allImages);
      });

      return () => query.off('value', callback);
    }
  }, [firebase, venue]);

  useEffect(() => {
    /**
     * Client side filter, group, order images
     */
    if (allImages) {
      const options = optionString ? JSON.parse(optionString) : undefined;
      const filteredImages = filterImages(allImages, options);

      setImages(filteredImages);
    }
  }, [allImages, optionString]);

  return { images, isLoading: typeof images === 'undefined' };
};

export const useLoadImages = () => {
  const [images, setImages] = useState<ImageData[] | null>();
  const firebase = useFirebaseContext();

  useEffect(() => {
    if (firebase !== null) {
      const query = firebase.imagesRef();

      const callback = query.on('value', async snapshot => {
        const imagesObj = snapshot.val();

        if (imagesObj === null) {
          setImages(null);

          return;
        }

        const allImages = Object.values<ImageData>(imagesObj);

        setImages(allImages);
      });

      return () => query.off('value', callback);
    }
  }, [firebase]);

  return { images, isLoading: typeof images === 'undefined' };
};

export const useLoadPublishedImages = () => {
  const [images, setImages] = useState<ImageData[] | undefined>(undefined);
  const [venueIds, setVenueIds] = useState<string[] | undefined>(undefined);
  const [result, setResult] = useState<ImageData[]>();
  const firebase = useFirebaseContext();

  const filterArchive = (img: ImageData) => !img.archived;
  const filterVenue = (privateVenueIds: string[]) => (img: ImageData) => {
    if (!privateVenueIds.length) {
      return true;
    }

    return privateVenueIds.indexOf(img.venue) === -1;
  };
  const sortFunc = (img1: ImageData, img2: ImageData) => {
    return (img2.createdDate || 0) - (img1.createdDate || 0);
  };

  const isLoadingImages = typeof images === 'undefined';
  const isLoadingVenues = typeof venueIds === 'undefined';
  const isLoading = isLoadingImages || isLoadingVenues;

  useEffect(() => {
    const venuesRef = firebase.venuesRef();
    const venuesQuery = venuesRef.orderByChild('isPrivated').equalTo(true);
    const imageRef = firebase.imagesRef();
    const imagesQuery = imageRef.orderByChild('published').equalTo(true);

    const venueCallback = venuesQuery.on('value', venuesSnapshot => {
      const privateVenueIds = Object.keys(venuesSnapshot.val() || {});

      setVenueIds(privateVenueIds);
    });

    const imagesCallback = imagesQuery.on('value', snapshot => {
      const data = snapshot.val();

      const allImages = responseToArray<ImageData>(data);
      setImages(allImages);
    });

    return () => {
      venuesQuery.off('value', venueCallback);
      imagesQuery.off('value', imagesCallback);
    };
  }, [firebase]);

  useEffect(() => {
    if (!isLoading) {
      const filteredArchive = images?.filter(filterArchive);
      const filtered = filteredArchive?.filter(filterVenue(venueIds || []));
      const result = filtered?.sort(sortFunc);

      setResult(result);
    }
  }, [images, venueIds, isLoading]);

  return { images: result, isLoading };
};

export const useLoadOwnedImagesByUserId = (userId: string) => {
  const firebase = useFirebaseContext();
  const [images, setImages] = useState<ImageData[]>();

  useEffect(() => {
    const query = firebase.imagesRef().orderByChild('owner').equalTo(userId);

    const callback = async (snapshots: database.DataSnapshot) => {
      const imagesObject = snapshots.val();
      if (!imagesObject) {
        setImages([]);
        return;
      }

      setImages(Object.values(imagesObject));
    };

    query.on('value', callback);

    return () => query.off('value', callback);
  }, [firebase, userId]);

  return { images, isLoading: images === undefined };
};
