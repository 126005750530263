import { useEffect, useState } from 'react';
import { useFirebaseContext } from 'shell/firebase';
import { getErrorMessage } from 'constants/errors';

export const useSubmitNewPassword = (actionCode: string) => {
  const firebase = useFirebaseContext();
  return (password: string) =>
    firebase.auth.confirmPasswordReset(actionCode, password);
};

export const useVerifyPasswordResetCode = (actionCode: string) => {
  const [email, setEmail] = useState<string>();
  const [error, setError] = useState<string>();
  const firebase = useFirebaseContext();

  useEffect(() => {
    if (!actionCode) {
      setError('Invalid Action Code');
      return;
    }
    firebase.auth
      .verifyPasswordResetCode(actionCode)
      .then(setEmail)
      .catch(err => setError(getErrorMessage(err.code)));
  }, [firebase, actionCode]);

  return {
    email,
    error,
    isLoading: email === undefined && error === undefined,
  };
};
