import { CanvasData, CanvasType } from 'constants/data/canvas.data';
import { useUpdate } from './useQuery';
import { useEffect, useState } from 'react';
import { useFirebaseContext } from 'shell/firebase';

export const useUpdateCanvasType = () => {
  const updateFunc = useUpdate();

  return async ({
    canvasId,
    printId,
    type,
  }: {
    canvasId: string;
    printId?: string;
    type: CanvasType;
  }) => {
    if (!canvasId) {
      return;
    }

    const timestamp = Date.now();
    const updateObj = {
      [`canvases/${canvasId}/printId`]: printId || '',
      [`canvases/${canvasId}/type`]: type,
      [`canvases/${canvasId}/media_last_changed`]: timestamp,
    };

    await updateFunc(updateObj);
  };
};

export const useListenCanvasType = (canvasId: string) => {
  const firebase = useFirebaseContext();
  const [canvasType, setCanvasType] = useState<'' | CanvasType>('');

  useEffect(() => {
    if (!canvasId) {
      return;
    }

    let canvasListener: firebase.database.Reference | null = null;
    const handleCanvasChanged = (snap: firebase.database.DataSnapshot) => {
      const updateDCanvas = snap.val() as CanvasData;
      if (updateDCanvas) {
        setCanvasType(updateDCanvas.type);
      }
    };
    canvasListener = firebase.canvasRef(canvasId);
    canvasListener.on('value', handleCanvasChanged);
    return () => {
      if (canvasListener) {
        canvasListener.off('value', handleCanvasChanged);
      }
    };
  }, [canvasId, firebase]);

  return canvasType;
};
