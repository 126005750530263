import { useState } from 'react';
import { useHistory } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ResetPasswordConfirmationSchema } from 'constants/validationSchema';
import { TextField } from 'modules/formFields';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSubmitNewPassword } from './helpers';
import * as ROUTES from 'constants/routes';
import { getErrorMessage } from 'constants/errors';

import UIButton from 'ui-components/Button';

interface IProps {
  actionCode: string;
}

const ResetPasswordConfirmationForm = ({ actionCode }: IProps) => {
  const history = useHistory();
  const submitNewPassword = useSubmitNewPassword(actionCode);
  const classes = useStyles();
  const { control, handleSubmit } = useForm<IResetPasswordConfirmationForm>({
    defaultValues: getFormDefaultValues(),
    resolver: yupResolver(ResetPasswordConfirmationSchema),
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (values: IResetPasswordConfirmationForm) => {
    setLoading(true);
    setErrorMessage(null);
    submitNewPassword(values.password)
      .then(() => {
        history.push(ROUTES.RESET_PASSWORD_COMPLETE);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        setErrorMessage(getErrorMessage(err.code));
      });
  };

  return (
    <>
      {errorMessage && (
        <Box mb={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Box className={classes.fields}>
          {FIELDS.map(field => {
            return (
              <Controller
                key={field.name}
                name={field.name}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Box mb={3} className={classes.field}>
                      <TextField
                        onChange={onChange}
                        value={value}
                        error={!!error}
                        helperText={error?.message || ''}
                        fullWidth
                        type={field.type}
                        label={field.label}
                        disabled={!!field.disabled}
                        autoFocus={!!field.autoFocus}
                        placeholder={field.placeholder}
                      />
                    </Box>
                  );
                }}
              />
            );
          })}
        </Box>
        <Box className={classes.submitWrapper}>
          <UIButton
            type="submit"
            variant="primary"
            size="medium"
            loading={loading}
            disabled={loading}
            text="Reset password"
          />
        </Box>
      </form>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    fields: {},
    field: {
      position: 'relative',
      maxWidth: 584,
    },
    submitWrapper: {
      marginTop: theme.spacing(2),
    },
  })
);

interface IResetPasswordConfirmationForm {
  password: string;
  confirmPassword: string;
}

const getFormDefaultValues = (): IResetPasswordConfirmationForm => {
  return {
    password: '',
    confirmPassword: '',
  };
};

interface IFormField {
  name: keyof IResetPasswordConfirmationForm;
  label: string;
  autoFocus?: boolean;
  type?: string;
  disabled?: boolean;
  placeholder: string;
}

const FIELDS: IFormField[] = [
  {
    name: 'password',
    label: 'New password',
    type: 'password',
    autoFocus: true,
    placeholder: 'Your password',
  },
  {
    name: 'confirmPassword',
    label: 'Repeat new password',
    type: 'password',
    placeholder: 'Your password',
  },
];

export default ResetPasswordConfirmationForm;
