import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useCheckHostedVenue } from 'utils/requests/useVenue';
import { IVenue } from 'constants/data/venue.data';
import { ARTWORKS, LANDING } from 'constants/routes';
import promiseWrapper from 'a-promise-wrapper';
import { SPECIFIC_HOSTED_VENUES } from 'constants/venue';
import { specialGiftingBg } from 'constants/common';
import { useRouteMatch } from 'react-router';
import { useSpecialGifting } from 'utils/hooks';
import * as ROUTES from 'constants/routes';

type LayoutStateContextType = {
  showFooter: boolean;
  setShowFooter: (state: boolean) => void;
  showTopBar: boolean;
  setShowTopBar: (state: boolean) => void;
  isSpecificVenue: boolean;
  venueId: string;
  venue: IVenue | null;
  isHostedVenue: boolean;
  isLoadingApp: boolean;
  hasError: boolean;
  giftingBg: string | null;
};
const LayoutStateContext = React.createContext<null | LayoutStateContextType>(
  null
);

const getFooterState = (pathname: string) => {
  return ![ARTWORKS].includes(pathname);
};

type LayoutStateProviderProps = React.PropsWithChildren<{}>;

function LayoutStateProvider({ children }: LayoutStateProviderProps) {
  const location = useLocation();
  const specialGifting = useSpecialGifting();
  const isGiftingRoute = useRouteMatch(ROUTES.RECEIVE_GIFT);
  const checkHostedVenue = useCheckHostedVenue();
  const [showFooter, setInternalFooterState] = useState(false);
  const [venue, setVenue] = useState<IVenue | null>(null);
  const [isLoadingApp, setLoadingApp] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const setShowFooter = useCallback((state: boolean) => {
    setInternalFooterState(state);
  }, []);
  const [showTopBar, setInternalTopBarState] = useState<boolean>(false);
  const setShowTopBar = useCallback((state: boolean) => {
    setInternalTopBarState(state);
  }, []);

  // Check if is specific hosted venue
  const [isSpecificVenue, setSpecificVenue] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      showFooter,
      setShowFooter,
      showTopBar,
      setShowTopBar,
      isSpecificVenue,
      venue,
      venueId: venue?.uid || '',
      isHostedVenue: !!(venue?.uid || ''),
      isLoadingApp,
      hasError,
      giftingBg: isGiftingRoute && specialGifting ? specialGiftingBg : null,
    }),
    [
      showFooter,
      setShowFooter,
      showTopBar,
      setShowTopBar,
      isSpecificVenue,
      venue,
      isLoadingApp,
      hasError,
      isGiftingRoute,
      specialGifting,
    ]
  );

  useEffect(() => {
    // Remove Footer from story 5475
    if (specialGifting && isGiftingRoute) {
      setInternalFooterState(false);
      return;
    }
    setInternalFooterState(getFooterState(location.pathname));
  }, [location.pathname, isGiftingRoute, specialGifting]);

  useEffect(() => {
    const checkVenue = async () => {
      const { data: venueData, error } = await promiseWrapper(
        checkHostedVenue()
      );

      if (error) {
        // current app is not hosted venue
        setHasError(true);
      } else {
        // current app is hosted venue
        setVenue(venueData);

        setSpecificVenue(
          SPECIFIC_HOSTED_VENUES.includes(window.location.hostname)
        );

        // Handle show navigation bar if first access is not venue page or venue 2.5 page
        if (
          location.pathname !== LANDING ||
          !venueData?.isDisplayVenueProfile25
        ) {
          setShowTopBar(true);
        }

        if (
          !getFooterState(location.pathname) ||
          location.pathname !== LANDING ||
          !venueData?.isDisplayVenueProfile25
        ) {
          // Remove Footer from story 5475
          if (!specialGifting || !isGiftingRoute) {
            setShowFooter(true);
          }
        }
      }
      setLoadingApp(false);
    };

    if (isLoadingApp) {
      checkVenue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, setShowTopBar]);

  return (
    <LayoutStateContext.Provider value={value}>
      {children}
    </LayoutStateContext.Provider>
  );
}

export const useLayoutState = () => {
  const ctx = useContext(LayoutStateContext);

  if (!ctx) {
    throw new Error('LayoutStateContext not found');
  }

  return ctx;
};

export default LayoutStateProvider;
