export enum CanvasType {
  ORIGINAL = 'ORIGINAL',
  WATERMARKED = 'WATERMARKED',
}

export interface CanvasData {
  deviceId: string;
  uid: string;
  // TODO: remove public_id
  media?: Array<{ public_id?: string; src: string; publicId?: string }>;
  platform?: string;
  projects: any; // TODO: can be removed
  groups: any; // TODO: can be removed
  name?: string;
  state?: string | boolean;
  status?: string;
  originalArtwork?: string;
  type: '' | CanvasType;
  transform?: ITransform;
  meta: {
    appVersion: string;
    dimensions: string;
    deviceName: string;
    platform: string;
    platformTV: boolean;
    platformTablet: boolean;
  };
}

export interface ITransform {
  scale: number;
  rotate: number;
  translateX: number;
  translateY: number;
}
