import { StripeAccountPayoutStatus } from 'constants/payment';

export enum SubscribePlatform {
  Web = 'web',
  App = 'app',
}

export interface FreeSlot {
  artworkId: string;
  exhibitionId: string;
}

export interface UserData {
  email: string;
  username: string;
  fullName: string;
  location: string;
  description: string;
  uid: string;
  projects: any; // TODO: can be removed
  canUpload: boolean;
  avatar: string;
  avatarPath?: string;
  canvases?: Record<string, boolean>;
  images: {
    [id: string]: { url: string };
  };
  isAdmin: boolean;
  active: boolean;
  membership: string;
  featuredImage?: string;
  emailVerified: boolean;
  subscribedBy?: SubscribePlatform;
  isEmailVerified?: boolean;
  isSubscribed: boolean;
  streamings?: {
    [id: string]: boolean;
  };
  freeSlot: {
    current?: FreeSlot;
    previous?: FreeSlot;
  };
  editions?: {
    [printId: string]: string; // printId: imageId
  };
  stripeConnect?: {
    id?: string;
    url?: string;
    updatedDate?: string;
    status?: StripeAccountPayoutStatus;
  };
  isJoinWaitlist?: boolean;
  currentUnclaimedGiftTxs?: {
    [txId: string]: boolean;
  };
  source?: string;
}

export type PublicSignUpParams = {
  email: string;
  username: string;
  fullName: string;
  password: string;
  code?: string;
  continueUrl?: string;
  isSubscribed: boolean;
  verificationLink?: string;
};

export interface AnonymousUserData {
  displayName: string | null;
  email: string | null;
  emailVerified: boolean;
  isAnonymous: boolean;
  refreshToken: string;
  uid: string;
  tenantId: string | null;
}

export interface AnonymousSignUpParams extends PublicSignUpParams {
  uid: string;
}
