import React from 'react';
import {
  CircularProgress,
  Box,
  makeStyles,
  createStyles,
} from '@material-ui/core';

const LoadingIndicator = () => {
  const styles = useStyles();

  return (
    <Box className={styles.wrapper}>
      <CircularProgress />
    </Box>
  );
};

const useStyles = makeStyles(() => {
  return createStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // TODO: I don't know why the header was be used absolute position in CSS, after reworked the header, we can remove this attribute
    },
  });
});

export default LoadingIndicator;
