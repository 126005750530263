import { useEffect, useState } from 'react';
import { useFirebaseContext } from 'shell/firebase';
import { ImageData } from 'constants/data/image.data';

export const useLoadImage = (imageId?: string) => {
  const firebase = useFirebaseContext();
  const [image, setImage] = useState<ImageData | null>();
  useEffect(() => {
    if (imageId) {
      const query = firebase.imageRef(imageId);
      const stopListener = query.on('value', snapshot => {
        const data = snapshot.val();
        setImage(data);
      });
      return () => query.off('value', stopListener);
    }

    setImage(null);
  }, [firebase, imageId]);

  return image;
};

export const useArchiveImage = () => {
  const firebase = useFirebaseContext();
  return (imageId: string) => {
    if (!firebase) {
      return Promise.reject('firebase is not defined');
    }
    const firebaseApp = firebase;
    const callable = firebaseApp.functions.httpsCallable('archiveImage');
    return callable({ imageId });
  };
};

export const useUnarchiveImage = () => {
  const firebase = useFirebaseContext();
  return (imageId: string) => {
    if (!firebase) {
      return Promise.reject('firebase is not defined');
    }
    const firebaseApp = firebase;
    const imageUpdates = {
      [`images/${imageId}/archived`]: false,
    };
    return firebaseApp.db.ref().update(imageUpdates);
  };
};
