import {
  FormControl,
  InputLabel,
  FormHelperText,
  Box,
} from '@material-ui/core';
import ColorSelect from 'modules/colorSelect';
import { multiValuesStringToArray } from 'utils/common';
import useStyles from './styles';
import Typography from 'modules/typography';
import { ColorType } from 'constants/colors';
interface Props {
  value: string;
  onChange: (newValue: string) => void;
  error?: boolean;
  helperText?: string;
  className?: string;
  label: string;
}

export const ColorSelectField = ({
  value,
  onChange,
  error,
  helperText,
  className,
  label,
}: Props) => {
  const classes = useStyles();
  const handleChange = (newValues: string[]) => {
    onChange(newValues.join(', '));
  };
  return (
    <FormControl className={className} fullWidth>
      <InputLabel
        error={error}
        disableAnimation={true}
        shrink={false}
        classes={{
          root: classes.labelRoot,
          formControl: classes.labelFormControl,
        }}>
        {label}
      </InputLabel>
      <Box className={classes.selectInput}>
        <ColorSelect
          values={multiValuesStringToArray(value || '') as ColorType[]}
          onChange={handleChange}
          renderEmptyText={() => (
            <Typography fontColor="#aaa">Select color</Typography>
          )}
        />
      </Box>

      {helperText && (
        <FormHelperText
          error={error}
          classes={{
            contained: classes.formHelperTextContained,
          }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default ColorSelectField;
