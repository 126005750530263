import { useState, useEffect, useCallback } from 'react';
import { useFirebaseContext } from 'shell/firebase';
import { ImageData } from 'constants/data/image.data';
import { Print as PrintData } from 'constants/data/print.data';

export const useLoadImages = () => {
  const firebase = useFirebaseContext();
  const [imagesMap, setImagesMap] = useState<Record<
    string,
    ImageData
  > | null>();

  useEffect(() => {
    if (firebase !== null) {
      const query = firebase.imagesRef();
      const stopListener = query.on('value', snapshot => {
        setImagesMap(snapshot.val());
      });
      return () => query.off('value', stopListener);
    }
  }, [firebase]);

  return { imagesMap };
};

export const useCreateLoadPrintsOfImageFunc = () => {
  const firebase = useFirebaseContext();
  return useCallback(
    async (imageId: string) => {
      // TODO
      /**
       * TODO: check if this query can work
       * const ref = firebase.artworkPrintsRef(imageId);
       * const query = ref.orderByChild('tenant').equalTo(null)
       *
       * With this, we can get desired data with one single query and no more filter on client
       */
      const snapshot = await firebase.artworkPrintsRef(imageId).once('value');
      if (snapshot.val() === null) {
        return 0;
      }
      const availablePrints = Object.values<PrintData>(snapshot.val()).filter(
        print => !print.tenant
      );
      return availablePrints.length;
    },
    [firebase]
  );
};
