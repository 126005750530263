import { useIsMobile } from './useIsMobile';

export const useSizes = (
  width: number,
  height: number,
  artworkMaxWidth: number,
  artworkMaxHeight: number
) => {
  const isMobile = useIsMobile();
  if (isMobile) {
    return {
      maxWidth: '100%',
      maxHeight: 'auto',
      sizes: '100vw',
    };
  }
  const scale = Math.max(width / artworkMaxWidth, height / artworkMaxHeight, 1);
  const displayWidth = Math.ceil(width / scale);
  const displayHeight = Math.ceil(height / scale);
  return {
    maxWidth: displayWidth,
    maxHeight: displayHeight,
    sizes: displayWidth + 'px',
  };
};
