import { useState, ReactElement } from 'react';
import { useModalState } from 'utils/hooks';
import { ReactComponent as DropdownIcon } from 'static/images/dropdown.svg';

import {
  Box,
  Popover,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Typography from 'modules/typography';
import { COLORS_MAP, ColorType } from 'constants/colors';
import { mobileMediaQuery } from 'utils/common';

// The color name must be one of the key of COLOR_MAPS in constants/colors
const colorOptions: ColorType[] = [
  'white',
  'red',
  'light red',
  'orange',
  'cream',
  'yellow',
  'light green',
  'green',
  'light blue',
  'blue',
  'violet',
  'pink',
  'brown',
  'grey',
  'black',
];

interface Props {
  renderEmptyText?: () => ReactElement;
  values?: ColorType[];
  onChange: (newValues: string[]) => void;
}

const ColorSelect = ({ values = [], onChange, renderEmptyText }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { isOpen, open, close } = useModalState();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    open();
  };

  const handleClose = () => {
    setAnchorEl(null);
    close(false);
  };

  const handleSelectItem = (color: ColorType) => {
    const newValues = values.includes(color)
      ? values.filter(i => i !== color)
      : [...values, color];
    onChange(newValues);
  };

  return (
    <div className={classes.root}>
      <Box className={classes.button} onClick={handleClick}>
        <Label colors={values} renderEmptyText={renderEmptyText} />
        <DropdownIcon className={classes.dropdownIcon} />
      </Box>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Box className={classes.popoverContent}>
          {colorOptions.map(color => (
            <div
              key={color}
              className={classes.colorOption}
              style={{ color: COLORS_MAP[color] }}
              onClick={() => handleSelectItem(color)}
              title={color}>
              {values.includes(color) && <CheckIcon color={color} />}
            </div>
          ))}
        </Box>
      </Popover>
    </div>
  );
};

const CheckIcon = ({ color }: { color: string }) => {
  const classes = useStyles();
  if (color === 'black') {
    return <CheckCircleOutlineIcon className={classes.outlineCheckIcon} />;
  }
  return <CheckCircleIcon className={classes.filledCheckIcon} />;
};

const Label = ({
  colors,
  renderEmptyText,
}: {
  colors: ColorType[];
  renderEmptyText?: () => ReactElement;
}) => {
  const classes = useStyles();
  if (colors.length <= 0) {
    return renderEmptyText ? (
      renderEmptyText()
    ) : (
      <Typography fontSize="1rem" fontWeight={500}>
        Color
      </Typography>
    );
  }
  return (
    <div className={classes.colorsFilterLabel}>
      {colors.map(colorName => {
        const colorHex = COLORS_MAP[colorName] ? COLORS_MAP[colorName] : '';
        return (
          <div
            className={classes.colorItem}
            style={{ color: colorHex }}
            key={colorName}
            title={colorName}></div>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);

  return createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      [mobile]: {
        display: 'block',
      },
    },
    button: {
      position: 'relative',
      fontSize: '1rem',
      paddingRight: theme.spacing(3),
      cursor: 'pointer',
      height: 32,
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
    popoverContent: {
      padding: theme.spacing(1),
      display: 'grid',
      gridTemplateColumns: 'repeat(8, 1fr)',
      gridGap: theme.spacing(1),
      [mobile]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
    },
    colorOption: {
      'borderRadius': theme.shape.borderRadius,
      'backgroundColor': 'currentColor',
      'width': 70,
      'height': 42,
      'border': '1px solid #C6C6C6',
      'cursor': 'pointer',
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      '&:hover': {
        borderWidth: 3,
      },
    },
    colorsFilterLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    colorItem: {
      backgroundColor: 'currentColor',
      height: '1.2em',
      width: '1.2em',
      borderRadius: '50%',
      marginRight: theme.spacing(0.5),
      border: '1px solid #C6C6C6',
    },
    outlineCheckIcon: {
      color: '#aaa',
    },
    filledCheckIcon: {
      color: '#000',
    },
    dropdownIcon: {
      position: 'absolute',
      top: '50%',
      color: '#000000',
      right: theme.spacing(1),
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
    },
  });
});

export default ColorSelect;
