import React, { useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
  makeStyles,
  createStyles,
  Theme,
  IconButton,
  Box,
} from '@material-ui/core';
import Typography from 'modules/typography';
import CloseIcon from '@material-ui/icons/Close';
import { useIsMobile } from 'utils/hooks';
import { mobileMediaQuery } from 'utils/common';
import { useModalState } from 'utils/hooks';

import UIButton from 'ui-components/Button';

interface Props extends Omit<DialogProps, 'onClose'> {
  currentStreaming?: number;
  onClose?: () => void;
  onNext?: () => void;
}

const ConfirmEditStreamingDialog = ({
  onClose,
  onNext,
  currentStreaming,
  ...dialogProps
}: Props) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  const handleOnClose = (reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (!reason && onClose) {
      onClose();
    }
  };

  const handleOnNext = () => {
    onNext && onNext();
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullWidth
      {...dialogProps}
      onClose={(event, reason) => handleOnClose(reason)}
      fullScreen={isMobile}>
      <DialogTitle>
        <IconButton
          className={classes.closeButton}
          onClick={() => handleOnClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box>
          <Typography className={classes.title}>
            Are you sure you want to reduce the number of available streaming
            slots to be less than the number of customers currently streaming?
          </Typography>
          <Typography className={classes.description}>
            There are currently {currentStreaming} users streaming this artwork.
            These users can continue streaming the artwork, but no new streams
            will be available until enough of these users have quit streaming it
            that this number drops below the new limit.
          </Typography>
          <UIButton
            variant="primary"
            size="medium"
            onClick={handleOnNext}
            text="Confirm"
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    closeButton: {
      position: 'absolute',
      right: 40,
      top: 40,
    },
    paper: {
      color: '#000',
      height: 'auto',
      maxWidth: 500,
      [mobile]: {
        height: '100%',
        minHeight: '100%',
      },
    },
    content: {
      padding: theme.spacing(3, 12, 7, 7),
    },
    title: {
      fontSize: theme.typography.pxToRem(28),
      fontWeight: 500,
      lineHeight: 1.2,
      marginBottom: theme.spacing(2),
    },
    description: {
      marginBottom: theme.spacing(3),
    },
  });
});

export const useConfirmEditStreamingDialog = () => {
  const { data, open, close } = useModalState<Props>();
  const openDialog = useCallback(
    async (props: Props) =>
      new Promise<boolean>(resolve => {
        const { onClose, onNext, ...rest } = props;
        open({
          onClose: () => {
            close(true);
            resolve(false);
          },
          onNext: () => {
            close(true);
            resolve(true);
          },
          ...rest,
        });
      }),
    [close, open]
  );
  const render = data && <ConfirmEditStreamingDialog {...data} />;
  return { openDialog, render };
};

export default ConfirmEditStreamingDialog;
