import {
  Box,
  Card,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ProductData } from 'constants/data/product.data';
import { ReactElement } from 'react';
import { ReactComponent as DeviceIcon } from 'static/images/device_icon.svg';
import CancelSubIcon from 'static/images/icons/cancel_sub_icon.png';
import { ReactComponent as ImageIcon } from 'static/images/image_icon.svg';
import { ReactComponent as RentalIcon } from 'static/images/rental_icon.svg';
import useAnalytics from 'utils/requests/useAnalytics';
import { getBenefits, getPriceLabel } from './helpers';
import { SubscribeDataInterface } from './Subscribe';

interface Props {
  productData: ProductData;
  onCardClick: ({ priceId, membership }: SubscribeDataInterface) => void;
  isComingSoon: boolean;
  showFreeMonth: boolean;
}

const SubscribeCard = ({
  productData,
  onCardClick,
  isComingSoon,
  showFreeMonth,
}: Props) => {
  const classes = useStyles();
  const { trackSelectSubscriptionPlan } = useAnalytics();
  const { name, description, prices } = productData;
  const price = prices && prices[0];
  const benefits = getBenefits(description);
  const isValidData = price && price.uid && !!price.membership;
  const priceLabel = isValidData ? getPriceLabel(price) : '';

  const handleSubscribeCardClick = () => {
    const isEnabled = isValidData && !isComingSoon;

    if (isEnabled) {
      const { uid: priceId, membership } = price;

      trackSelectSubscriptionPlan({
        name,
        priceId,
        membership,
      });

      onCardClick({
        priceId,
        membership,
      });
    }
  };
  const getBenefitIcon = (index: number) => {
    switch (index) {
      case 1:
        return <DeviceIcon className={classes.benefitIcon} />;
      case 2:
        return <RentalIcon className={classes.benefitIcon} />;
      default:
        return <ImageIcon className={classes.benefitIcon} />;
    }
  };

  return (
    <Card
      elevation={1}
      className={classes.subscribeCard}
      onClick={handleSubscribeCardClick}>
      <Box>
        <Typography className={classes.title}>{name}</Typography>
        <Typography className={classes.price}>{priceLabel}</Typography>
        {showFreeMonth && (
          <Box className={classes.freeMonthBox}>One free month</Box>
        )}
      </Box>
      <Box mt={4}>
        <Typography className={classes.benefit}>Benefits</Typography>
        {benefits.map((benefit: string, index: number) => {
          if (!benefit) {
            return null;
          }
          const key = benefit + index;
          return (
            <BenefitItem
              key={key}
              icon={getBenefitIcon(index)}
              label={benefit.trim()}
            />
          );
        })}
        <BenefitItem
          icon={
            <img
              src={CancelSubIcon}
              alt="Cancel anytime"
              className={classes.cancelSubIcon}
            />
          }
          label="Cancel anytime"
        />
      </Box>
      {isComingSoon && (
        <Box className={classes.comingSoon}>
          <Typography variant="button">Coming Soon</Typography>
        </Box>
      )}
    </Card>
  );
};

interface BenefitItemProps {
  icon: ReactElement;
  label: string;
}

const BenefitItem = ({ icon, label }: BenefitItemProps) => {
  return (
    <Box mt={1}>
      <Grid container alignItems="center">
        {icon}
        <Box ml={1} flex={1}>
          {label}
        </Box>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    subscribeCard: {
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      height: '100%',
      cursor: 'pointer',
      position: 'relative',
      fontSize: '0.75rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      boxShadow: '0px 4px 20px rgba(184, 184, 184, 0.25)',
      border: '1px solid #F0F0F0',
    },
    comingSoon: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(232, 232, 232, 0.9)',
      width: '100%',
      height: '100%',
      zIndex: 1,
      cursor: 'auto',
    },
    title: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    price: {
      fontSize: theme.typography.pxToRem(12),
      color: '#434343',
      opacity: '0.8',
    },
    benefit: {
      fontSize: '0.6875rem',
      textTransform: 'uppercase',
      marginBottom: theme.spacing(2),
    },
    benefitIcon: {
      height: '13px',
      objectFit: 'contain',
    },
    freeMonthBox: {
      padding: theme.spacing(1),
      textTransform: 'uppercase',
      fontSize: '0.69rem',
      marginTop: theme.spacing(1),
      border: '1px solid #000',
      borderRadius: theme.shape.borderRadius,
    },
    cancelSubIcon: {
      height: '20px',
      objectFit: 'contain',
    },
  });
});

export default SubscribeCard;
