import Typography, { H1 } from 'modules/typography';
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

export const Headline = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <Typography
      fontWeight={300}
      fontFamily="Fraunces, serif"
      fontSize="1.5rem"
      lineHeight={1.4}>
      {children}
    </Typography>
  );
};

const Title = ({ children }: React.PropsWithChildren<{}>) => {
  return <H1 lineHeight={1.2}>{children}</H1>;
};

export const Heading = ({ children }: React.PropsWithChildren<{}>) => {
  return <Typography component={Title}>{children}</Typography>;
};

export const Description = ({ children }: React.PropsWithChildren<{}>) => {
  const classes = useStyles();
  return (
    <Typography
      fontColor="#030303"
      fontSize="0.875rem"
      className={classes.description}>
      {children}
    </Typography>
  );
};

const useStyles = makeStyles(() => {
  return createStyles({
    description: {
      opacity: 0.8,
    },
    heading: {
      lineHeight: 1.2,
    },
  });
});
