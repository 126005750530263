import React from 'react';
import cn from 'classnames';
import {
  makeStyles,
  createStyles,
  Tooltip,
  withStyles,
  Theme,
} from '@material-ui/core';

import Typography from 'modules/typography';

type TooltipContentProps = {
  deviceName: string;
};
const TooltipContent = ({ deviceName }: TooltipContentProps) => {
  const styles = useStyles();

  return (
    <div className={styles.tooltipWrapper}>
      <div className={styles.content}>
        <span className={styles.circle} />
        <Typography className={styles.text} fontSize={14} fontColor="#000000">
          On view
        </Typography>
      </div>
      <div className={styles.content}>
        <span className={cn(styles.circle, styles.hide)} />
        <Typography className={styles.text} fontSize={12} fontColor="#616161">
          {deviceName}
        </Typography>
      </div>
    </div>
  );
};

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(1),
    maxWidth: 220,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
  },
}))(Tooltip);

type AssignArtworkStatusProps = {
  deviceName: string;
};

const AssignArtworkStatus = ({ deviceName }: AssignArtworkStatusProps) => {
  const styles = useStyles();

  return (
    <HtmlTooltip
      title={<TooltipContent deviceName={deviceName} />}
      placement="top">
      <span className={styles.circle} />
    </HtmlTooltip>
  );
};

const useStyles = makeStyles(() => {
  return createStyles({
    circle: {
      width: '8px',
      height: '8px',
      backgroundColor: '#2FBE34',
      borderRadius: '50%',
    },
    hide: {
      opacity: 0,
    },
    tooltipWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      'display': 'flex',
      'alignItems': 'center',
      '&:not(:first-child)': {
        marginTop: '4px',
      },
    },
    text: {
      paddingLeft: '8px',
    },
  });
});

export default AssignArtworkStatus;
