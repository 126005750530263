import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  List,
  ListItem,
} from '@material-ui/core';
import Logo from 'static/images/white_logo_with_text.png';
import * as ROUTES from 'constants/routes';
import { Link as RouterLink } from 'react-router-dom';
import { mobileMediaQuery } from 'utils/common';
import FooterBottom from './FooterBottom';
import SocialIconList from './SocialIconList';
import NewsletterLink from './NewsletterLink';
import UILink from 'ui-components/Link';

const NAV_LINKS = [
  {
    id: 'Company',
    label: 'Company',
    links: [
      {
        id: 'About',
        title: 'About',
        path: ROUTES.ABOUT,
      },
      // {
      //   id: 'Pricing',
      //   title: 'Subscription Plans',
      //   path: ROUTES.PRICING,
      //   external: false,
      // },
      {
        id: 'News',
        title: 'News',
        path: 'https://info.activeimage.io/news',
        external: true,
      },
      {
        id: 'OurPartners',
        title: 'Our Partners',
        path: ROUTES.OUR_PARTNERS,
        external: false,
      },
      {
        id: 'Contact',
        title: 'Contact',
        path: ROUTES.CONTACT,
        external: false,
      },
    ],
  },
  {
    id: 'Browse',
    label: 'Browse',
    links: [
      {
        id: 'Artworks',
        title: 'Artworks',
        path: ROUTES.ARTWORKS,
        external: false,
      },
      {
        id: 'Artists',
        title: 'Artists',
        path: ROUTES.ARTISTS,
        external: false,
      },
      {
        id: 'Venues',
        title: 'Venues',
        path: ROUTES.VENUES,
        external: false,
      },
    ],
  },
  {
    id: 'Support',
    label: 'Support',
    links: [
      {
        id: 'HowItWorks',
        title: 'How it Works',
        path: ROUTES.HOW_IT_WORKS,
        external: false,
      },
      {
        id: 'FAQs',
        title: 'FAQs',
        path: 'https://info.activeimage.io/faq-support',
        external: true,
      },
      {
        id: 'Downloads',
        title: 'Downloads',
        path: ROUTES.DOWNLOADS,
        external: false,
      },
    ],
  },
];

const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box mx="auto" className={classes.footer}>
        <Box className={classes.footerContent}>
          <RouterLink to={ROUTES.LANDING}>
            <Box className={classes.logo}>
              <img src={Logo} alt="logo" />
            </Box>
          </RouterLink>

          <Box className={classes.navContainer}>
            {NAV_LINKS.map(navLink => (
              <Box key={navLink.id} className={classes.nav}>
                <Typography className={classes.navLabel}>
                  {navLink.label}
                </Typography>
                <List className={classes.navList}>
                  {navLink.links.map(link => (
                    <ListItem className={classes.navItem} key={link.id}>
                      {link.external ? (
                        <UILink
                          href={link.path}
                          target="_blank"
                          rel="noreferrer"
                          size={'large'}
                          className={classes.link}>
                          {link.title}
                        </UILink>
                      ) : (
                        <UILink
                          component={RouterLink}
                          href={link.path}
                          size={'large'}
                          className={classes.link}>
                          {link.title}
                        </UILink>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>

          <Box className={classes.footerSocial}>
            <SocialIconList />
            <Box className={classes.newsletter}>
              <NewsletterLink />
            </Box>
          </Box>
        </Box>

        <FooterBottom />
      </Box>
    </Box>
  );
};

export const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    container: {
      width: '100%',
      backgroundColor: '#000',
      height: 'auto',
      color: '#FFF',
      marginTop: theme.spacing(10),
    },
    footer: {
      height: '100%',
      width: '100%',
      padding: theme.spacing(8.5, 4),
      maxWidth: '1440px',
      [mobile]: {
        padding: theme.spacing(8.5, 2),
      },
    },
    logo: {
      'width': 187,
      '& img': {
        display: 'block',
        width: '100%',
      },
    },
    navContainer: {
      display: 'flex',
      gap: theme.spacing(4),
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      flex: 3,
      [mobile]: {
        justifyContent: 'flex-start',
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(5),
      },
    },
    nav: {
      flex: 1,
      maxWidth: 188,
      [mobile]: {
        maxWidth: 'calc(50% - 32px)',
      },
    },
    navList: {
      padding: 0,
    },
    navLabel: {
      color: '#fff',
      fontSize: '1.5rem',
      fontWeight: 500,
      marginBottom: theme.spacing(1.5),
    },
    navItem: {
      'padding': 0,
      'color': '#fff',
      'marginBottom': theme.spacing(1.5),
      'cursor': 'pointer',
      '& a': {
        color: '#fff',
      },
      '&:empty': {
        display: 'none',
      },
    },
    link: {
      'color': '#FFF',
      '&:hover': {
        color: '#FFF',
      },
    },
    footerContent: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(3),
      marginBottom: theme.spacing(13),
      [mobile]: {
        flexDirection: 'column',
        marginBottom: theme.spacing(5),
      },
    },
    footerSocial: {
      flex: 1,
    },
    newsletter: {
      marginTop: theme.spacing(7),
      [mobile]: {
        marginTop: theme.spacing(3),
      },
    },
    newsletterEditMode: {
      '&:has(div>p>*)': {
        marginTop: theme.spacing(7),
        [mobile]: {
          marginTop: theme.spacing(3),
        },
      },
    },
  });
});

export default Footer;
