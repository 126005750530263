import { useState, useEffect, useMemo } from 'react';
import {
  ExhibitionData,
  PreExhibitionData,
  EXHIBITION_STATUS_DRAFT,
  EXHIBITION_STATUS_PUBLISHED,
  ExhibitionCoverImage,
} from 'constants/data/exhibition.data';
import Firebase, { useFirebaseContext } from 'shell/firebase';
import { responseToArray } from 'utils/common';
import promiseWrapper from 'a-promise-wrapper';
import { uploadCoverImage } from './uploadImage';

import useQuery from 'utils/hooks/useQuery';
import { database } from 'firebase/app';

export interface ExhibitionItem extends ExhibitionData {
  id: string;
}

export const useLoadExhibitions = () => {
  const firebase = useFirebaseContext();
  const query = firebase.exhibitionsRef();
  const queryOptions = useMemo(
    () => ({
      transform: (snapshot: database.DataSnapshot): ExhibitionItem[] => {
        const data = snapshot.val();

        if (data !== null) {
          return responseToArray(data);
        }

        return [];
      },
    }),
    []
  );

  return useQuery<ExhibitionItem[]>(query, queryOptions);
};

const useUpdateExhibitionStatusViaCloud = () => {
  const firebase = useFirebaseContext();

  return (
    exhibitionId: string,
    action: 'startExhibition' | 'endStreamingExhibition'
  ) => {
    const callable = firebase.functions.httpsCallable(action);

    return callable({ exhibitionId });
  };
};

export const useStartExhibition = () => {
  const updateExhibitionStatus = useUpdateExhibitionStatusViaCloud();

  return (exhibitionId: string) => {
    return updateExhibitionStatus(exhibitionId, 'startExhibition');
  };
};

export const useEndExhibition = () => {
  const updateExhibitionStatus = useUpdateExhibitionStatusViaCloud();

  return (exhibitionId: string) => {
    return updateExhibitionStatus(exhibitionId, 'endStreamingExhibition');
  };
};

const useUpdateExhibitionStatus = () => {
  const firebase = useFirebaseContext();

  return (exhibitionId: string, status: string) => {
    return firebase.db.ref().update({
      [`exhibitions/${exhibitionId}/status`]: status,
    });
  };
};

export const usePublishExhibition = () => {
  const updateExhibitionStatus = useUpdateExhibitionStatus();

  return (exhibitionId: string) => {
    return updateExhibitionStatus(exhibitionId, EXHIBITION_STATUS_PUBLISHED);
  };
};

export const useUnpublishExhibition = () => {
  const updateExhibitionStatus = useUpdateExhibitionStatus();

  return (exhibitionId: string) => {
    return updateExhibitionStatus(exhibitionId, EXHIBITION_STATUS_DRAFT);
  };
};

export const useCreateExhibition = () => {
  const firebase = useFirebaseContext();

  return async (exhibition: PreExhibitionData) => {
    const { coverImageFile, startTime, endTime, images, bannerFile } =
      exhibition;
    let newCoverImage: ExhibitionCoverImage = { url: '', path: '' };
    let newBanner: ExhibitionCoverImage = { url: '', path: '' };
    let streamings = {};
    let streamingExhibitionsCommand = {};

    // Upload the cover image
    if (coverImageFile) {
      try {
        const { url, path } = await uploadCoverImage(firebase, coverImageFile);
        newCoverImage = {
          url: url,
          path: path,
        };
      } catch (e) {
        return Promise.reject('Error to upload the cover image');
      }
    }

    // Upload the banner image
    if (bannerFile) {
      try {
        const { url, path } = await uploadCoverImage(firebase, bannerFile);
        newBanner = {
          url: url,
          path: path,
        };
      } catch (e) {
        // if error, delete the new cover image file silently
        deleteImageStorage(firebase, newCoverImage);
        return Promise.reject('Error to upload the banner');
      }
    }

    const exhibitionId = firebase.exhibitionsRef().push().key;

    if (!exhibitionId) {
      return Promise.reject('Error to get the unique key');
    }

    if (images && images.length > 0) {
      streamings = images.reduce(
        (result, current) => ({
          ...result,
          [current.id]: current.selectedStreamingSlots,
        }),
        {}
      );
      streamingExhibitionsCommand = images.reduce(
        (result, image) => ({
          ...result,
          [`streamingExhibitions/${image.id}/${exhibitionId}`]:
            image.selectedStreamingSlots,
        }),
        {}
      );
    }

    // Create an exhibition
    delete exhibition.coverImageFile;
    delete exhibition.bannerFile;
    const newExhibition = {
      ...exhibition,
      uid: exhibitionId,
      coverImage: newCoverImage.path ? newCoverImage : null,
      banner: newBanner.path ? newBanner : null,
      isOnGoing: false,
      status: EXHIBITION_STATUS_DRAFT,
      startTime: Number(startTime),
      endTime: Number(endTime),
      streamings,
      images: images.length === 0 ? null : images.map(image => image.id),
    };

    const updates = {
      ...streamingExhibitionsCommand,
      [`exhibitions/${exhibitionId}`]: newExhibition,
    };

    const { error } = await promiseWrapper(firebase.db.ref().update(updates));

    if (error) {
      // if error, delete the new cover image file silently
      deleteImageStorage(firebase, newCoverImage);
      deleteImageStorage(firebase, newBanner);
      return Promise.reject(error);
    }

    return Promise.resolve(true);
  };
};

export const useEditExhibition = () => {
  const firebase = useFirebaseContext();

  return async (
    oldExhibition: ExhibitionData,
    exhibition: PreExhibitionData
  ) => {
    if (!oldExhibition) {
      return Promise.reject('Missing id params');
    }
    const { uid: exhibitionId } = oldExhibition;
    const {
      coverImageFile,
      startTime,
      endTime,
      images,
      coverImage,
      bannerFile,
      banner,
    } = exhibition;
    let newCoverImage: ExhibitionCoverImage = { url: '', path: '' };
    let newBanner: ExhibitionCoverImage = { url: '', path: '' };
    let streamings = {};
    let streamingExhibitionsCommand = {};

    // Upload the cover image
    if (coverImageFile) {
      try {
        const { url, path } = await uploadCoverImage(firebase, coverImageFile);
        newCoverImage = {
          url: url,
          path: path,
        };
      } catch (e) {
        return Promise.reject('Error to upload the cover image');
      }
    }

    // Upload the banner image
    if (bannerFile) {
      try {
        const { url, path } = await uploadCoverImage(firebase, bannerFile);
        newBanner = {
          url: url,
          path: path,
        };
      } catch (e) {
        // if error, delete the new cover image file silently
        deleteImageStorage(firebase, newCoverImage);
        return Promise.reject('Error to upload the banner');
      }
    }

    if (images && images.length > 0) {
      streamings = images.reduce(
        (result, current) => ({
          ...result,
          [current.id]: current.selectedStreamingSlots,
        }),
        {}
      );
      streamingExhibitionsCommand = images.reduce(
        (result, image) => ({
          ...result,
          [`streamingExhibitions/${image.id}/${exhibitionId}`]:
            image.selectedStreamingSlots,
        }),
        {}
      );
    }

    // Update an exhibition
    delete exhibition.coverImageFile; // TODO: why do we need to remove here
    delete exhibition.bannerFile; // TODO: why do we need to remove here
    const updatedExhibition = {
      ...oldExhibition,
      ...exhibition,
      coverImage: newCoverImage.path ? newCoverImage : coverImage || null,
      banner: newBanner.path ? newBanner : banner || null,
      startTime: Number(startTime),
      endTime: Number(endTime),
      streamings,
      images: images.length === 0 ? null : images.map(image => image.id),
    };

    const updates = {
      ...streamingExhibitionsCommand,
      [`exhibitions/${exhibitionId}`]: updatedExhibition,
    };

    const { error } = await promiseWrapper(firebase.db.ref().update(updates));

    if (error) {
      // if error, delete the new cover image and new banner file silently
      deleteImageStorage(firebase, newCoverImage);
      deleteImageStorage(firebase, newBanner);
      return Promise.reject(error);
    }

    // if success, delete the old cover image and banner silently
    newCoverImage.path && deleteImageStorage(firebase, coverImage);
    newBanner.path && deleteImageStorage(firebase, banner);
    return Promise.resolve(updatedExhibition);
  };
};

const deleteImageStorage = (
  firebase: Firebase,
  image?: ExhibitionCoverImage
) => {
  image && image.path && firebase.storage.ref().child(image.path).delete();
};

export const useLoadAvailableExhibitionsByVenue = (venueId: string) => {
  const firebase = useFirebaseContext();
  const [exhibitions, setExhibitions] = useState<ExhibitionData[] | null>();

  useEffect(() => {
    if (!venueId) {
      setExhibitions(null);
      return;
    }

    if (firebase) {
      const query = firebase
        .exhibitionsRef()
        .orderByChild('venue')
        .equalTo(venueId);
      query.on('value', snapshot => {
        const data = snapshot.val();

        if (data) {
          const filteredExhibitions = Object.values<ExhibitionData>(
            data
          ).filter(
            exhibition => exhibition.status === EXHIBITION_STATUS_PUBLISHED
          );
          setExhibitions(filteredExhibitions);
        } else {
          setExhibitions(null);
        }
      });
    }
  }, [firebase, venueId]);

  return {
    exhibitions,
    isLoading: typeof exhibitions === 'undefined',
  };
};
