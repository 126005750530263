import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Location } from 'history';
import { useAuthContext } from 'shell/session';
import {
  createStyles,
  makeStyles,
  Theme,
  List,
  Box,
  Badge,
} from '@material-ui/core';
import { mobileMediaQuery } from 'utils/common';
import { UserData } from 'constants/data/user.data';
import { MAIN_MENU } from 'constants/menu';
import {
  SIGN_UP,
  SIGN_IN,
  ABOUT,
  VENUES,
  EXHIBITIONS,
  ACCOUNT_IMAGES,
} from 'constants/routes';
import AnimatedListItem from './AnimatedListItem';
import { useGetSignUpPath, useIsMobile } from 'utils/hooks';
import FeatureFlag from 'modules/featureFlag';
import { useGiftIndicator } from 'utils/hooks/useLocalStorage';
import GiftIndicator from './GiftIndicator';

const isHasUnclaimedGift = (user: UserData | undefined | null) => {
  if (!user) {
    return false;
  }

  const { currentUnclaimedGiftTxs: unclaimedTxsObj } = user;
  const unclaimedTxs = unclaimedTxsObj ? Object.keys(unclaimedTxsObj) : [];

  return !!unclaimedTxs.length;
};

const NavMain = () => {
  const isMobile = useIsMobile();
  const authContext = useAuthContext();
  const user = authContext.user as UserData | undefined | null;
  const hasUnclaimedGifts = isHasUnclaimedGift(user);
  const [giftIndicator] = useGiftIndicator();

  const classes = useStyles();

  return (
    <List className={classes.navMain}>
      {!!user && !!giftIndicator && !isMobile && <GiftIndicator />}
      {user &&
        MAIN_MENU.map(menu => {
          if (menu.feature) {
            return (
              <FeatureFlag key={menu.path} feature={menu.feature}>
                <AnimatedListItem to={menu.path} label={menu.label} />
              </FeatureFlag>
            );
          }
          return (
            <AnimatedListItem
              key={menu.path}
              to={menu.path}
              label={menu.label}
              isButton={menu.isButton}
            />
          );
        })}
      {user && (
        <Box className={classes.myArtworksItem}>
          {hasUnclaimedGifts ? (
            <Badge
              overlap="rectangular"
              color="secondary"
              variant="dot"
              invisible={false}
              classes={{
                dot: classes.myArtworksDot,
              }}>
              <AnimatedListItem to={ACCOUNT_IMAGES} label="My Collection" />
            </Badge>
          ) : (
            <AnimatedListItem to={ACCOUNT_IMAGES} label="My Collection" />
          )}
        </Box>
      )}
      {user === null && <GuestMenuItems />}
    </List>
  );
};

const GuestMenuItems = () => {
  const isMobile = useIsMobile();
  const getSignUpPath = useGetSignUpPath();
  const location = useLocation<{ from: Location }>();
  const isSignUpPage = matchPath(location.pathname, { path: SIGN_UP });
  const isSignInPage = matchPath(location.pathname, { path: SIGN_IN });

  const [giftIndicator] = useGiftIndicator();

  let previousLocation: any = location;
  if (isSignInPage || isSignUpPage) {
    // If the current page is Sign In/Sign Up, forward the previous location
    previousLocation = location.state?.from;
  }

  const signInLink = (
    <AnimatedListItem
      to={{
        pathname: SIGN_IN,
        state: {
          from: previousLocation,
        },
      }}
      label="Sign In"
    />
  );

  const signUpLink = (
    <AnimatedListItem
      to={getSignUpPath(previousLocation)}
      label="Create Account"
    />
  );

  const venuesLink = <AnimatedListItem to={VENUES} label="Venues" />;
  const exhibitionsLink = (
    <AnimatedListItem to={EXHIBITIONS} label="Exhibitions" />
  );
  const aboutLink = <AnimatedListItem to={ABOUT} label="About" />;

  return (
    <>
      {!!giftIndicator && !isMobile && <GiftIndicator />}
      {!isSignInPage && signInLink}
      {!isSignUpPage && signUpLink}
      {exhibitionsLink}
      {venuesLink}
      {aboutLink}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    navMain: {
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
      [mobile]: {
        width: '100%',
        flexDirection: 'column',
      },
    },
    myArtworksItem: {
      borderLeft: '1px solid #CFCFCF',
    },
    myArtworksDot: {
      backgroundColor: '#16CE13',
      minWidth: '6px',
      width: '6px',
      height: '6px',
      top: '14px',
      right: '20px',
    },
  });
});

export default NavMain;
