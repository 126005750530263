import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

export type VerifiedStatus = 'none' | 'success' | 'fail';

type VerificationContextType = {
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  verifiedStatus: VerifiedStatus;
  setVerifiedStatus: (verifiedStatus: VerifiedStatus) => void;
  metaData: any;
  setMetaData: (metaData: any) => void;
};

const VerificationContext = createContext<VerificationContextType | null>(null);

type VerificationProviderProps = PropsWithChildren<{}>;

const VerificationProvider = ({ children }: VerificationProviderProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [verifiedStatus, setVerifiedStatus] = useState<VerifiedStatus>('none');
  const [metaData, setMetaData] = useState<any>();

  const contextValue = useMemo(
    () => ({
      isSubmitting,
      setIsSubmitting,
      verifiedStatus,
      setVerifiedStatus,
      metaData,
      setMetaData,
    }),
    [
      isSubmitting,
      setIsSubmitting,
      verifiedStatus,
      setVerifiedStatus,
      metaData,
      setMetaData,
    ]
  );

  return (
    <VerificationContext.Provider value={contextValue}>
      {children}
    </VerificationContext.Provider>
  );
};

export const useVerification = (): VerificationContextType => {
  const ctx = useContext(VerificationContext);

  if (!ctx) {
    throw new Error('VerificationContext not found');
  }

  return ctx;
};

export default VerificationProvider;
