import { createLocalStorageStateHook } from 'use-local-storage-state';

interface GiftIndicatorInterface {
  id: string;
  otp?: string;
}

const STORAGE_KEY = 'showTopBarGiftIndicator';

/**
 * Values:
 * - `undefined`: initial state
 * - `null`: no data
 * - has data
 */
const useGiftIndicator = createLocalStorageStateHook<
  GiftIndicatorInterface | undefined | null
>(STORAGE_KEY, undefined);

export { useGiftIndicator };
