import { useMemo } from 'react';
import { OptionRenderProps } from '../FilterOption';
import { FilterAutocomplete } from 'modules/filterControls';
import { multiValuesStringToArray } from 'utils/common';
import { useFilterContext } from '../context';
import { ImageData } from 'constants/data/image.data';

const UNCATEGORIZED = '<uncategorized>';

export const CatalogOption = ({ values = [], onChange }: OptionRenderProps) => {
  const { originalList } = useFilterContext();
  const catalogOptions = useMemo(
    () => (originalList ? createCatalogOptions(originalList) : []),
    [originalList]
  );

  return (
    <FilterAutocomplete
      options={catalogOptions}
      value={values}
      onChange={onChange}
      renderValue={renderCatalogsLabel}
    />
  );
};

const createCatalogOptions = (images: ImageData[] = []) => {
  const concatedString = images.reduce(
    (acc, image) => acc + ',' + (image.catalog || UNCATEGORIZED),
    ''
  );
  const array = multiValuesStringToArray(concatedString).sort();
  return Array.from(new Set(array)).map(keyword => ({
    value: keyword,
    label: keyword,
  }));
};

const renderCatalogsLabel = (value: string[]) =>
  value.length > 0 ? `Collections: ${value.join(', ')}` : 'Collections';
