import React from 'react';

type ComponentPropsTuple<T extends {}> = [
  React.JSXElementConstructor<React.PropsWithChildren<T>>,
  T?
];

type ComponentStackProps = React.PropsWithChildren<{
  components: Array<
    | React.JSXElementConstructor<React.PropsWithChildren<{}>>
    | ComponentPropsTuple<any>
  >;
}>;

const ComponentStack = ({ components, children }: ComponentStackProps) => {
  return (
    <>
      {components.reduceRight((child, stack) => {
        const [Component, props] = Array.isArray(stack) ? stack : [stack];
        return <Component {...props}>{child}</Component>;
      }, children)}
    </>
  );
};

export const withProps = <T,>(
  Component: React.JSXElementConstructor<React.PropsWithChildren<T>>,
  props: T
): ComponentPropsTuple<T> => [Component, props];

export default ComponentStack;
