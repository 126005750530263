import { useMemo } from 'react';
import { ImageData } from 'constants/data/image.data';
import { CatalogPreviewData } from 'constants/data/catalog.data';
import { multiValuesStringToArray } from 'utils/common';
import { useLoadImages } from './useLoadImages';
import { filterPublishedImages } from 'utils/common/filterPublishedImages';

export const useLoadCatalogs = () => {
  const { images } = useLoadImages();

  const catalogs = useMemo(() => {
    if (images) {
      const publishedImages = filterPublishedImages(images);
      const groups = groupImagesByCatalog(publishedImages);
      const sortedCatalogs = Object.values(groups).sort(
        (a, b) => b.images.length - a.images.length
      );
      return sortedCatalogs;
    }
    // return `undefined` or `null` depend on the value of `images`
    return images;
  }, [images]);

  return { catalogs, isLoading: typeof catalogs === 'undefined' };
};

const groupImagesByCatalog = (images: ImageData[]) =>
  images.reduce<Record<string, CatalogPreviewData>>((acc, image) => {
    const catalogArray = multiValuesStringToArray(image.catalog || '');
    if (catalogArray.length <= 0) {
      return acc;
    }
    catalogArray.forEach(catalog => {
      const key = catalog + '---' + image.artist;

      if (!acc[key]) {
        acc[key] = {
          name: catalog,
          images: [],
          featureImage: undefined,
          artistId: image.artist,
        };
      }

      acc[key].images.push(image);
      if (!acc[key].featureImage && image.feature) {
        acc[key].featureImage = image;
      }
    });

    return acc;
  }, {});
