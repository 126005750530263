// .env.development and .env.production
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
// import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/storage';
import 'firebase/functions';
import { nanoid } from 'nanoid';
import {
  getEmailTemplates,
  setEmailTemplate,
  getEmailTemplate,
} from './firestore';
import { EmailTemplateValues } from 'views/admin/queryDatabaseTool/tabs/EmailTemplateTab';
import getEnvVars from 'utils/common/envVars';

interface ICredentials {
  email: string;
  password: string;
}

interface ISignInCallbacks {
  onSuccess?: (user: app.auth.UserCredential) => void;
  onFailed?: (error: any) => void;
}

interface ISignIn {
  credentials: ICredentials;
  callbacks?: ISignInCallbacks;
}

const {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
  apiGateway,
  isUseDbEmulator,
  isUseCFEmulator,
  isUseAuthEmulator,
  isUseFirestoreEmulator,
  emulatorDbUrl,
  emulatorCFRegion,
  emulatorCFUrl,
  emulatorAuthUrl,
  emulatorFirestoreUrl,
} = getEnvVars();

export const config = {
  apiKey,
  authDomain,
  databaseURL: isUseDbEmulator === 'true' ? emulatorDbUrl : databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
};
const functionsUrl = isUseCFEmulator === 'true' ? emulatorCFRegion : apiGateway;

class Firebase {
  auth: firebase.auth.Auth;
  db: firebase.database.Database;
  storage: firebase.storage.Storage;
  store: firebase.firestore.Firestore | undefined;
  functions: firebase.functions.Functions;
  analytics: firebase.analytics.Analytics;
  getTimestamp: () => Object;

  constructor() {
    const firebaseApp = app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
    // this.store = app.firestore();
    this.functions = firebaseApp.functions(functionsUrl);
    this.analytics = app.analytics();
    this.useEmulators();
    this.getTimestamp = () => app.database.ServerValue.TIMESTAMP;
  }

  useEmulators = () => {
    const useAuth = isUseAuthEmulator === 'true';
    const useFirestore = isUseFirestoreEmulator === 'true';
    const useFunctions = isUseCFEmulator === 'true';

    const authURL = emulatorAuthUrl;
    const firestoreURL = emulatorFirestoreUrl;
    const functionURL = emulatorCFUrl;

    if (useAuth && authURL) {
      this.auth.useEmulator(authURL);
    }

    if (useFirestore && this.store) {
      this.store.settings({
        host: firestoreURL,
        ssl: false,
      });
    }
    if (useFunctions && functionURL) {
      this.functions.useFunctionsEmulator(functionURL);
    }
  };

  getStore = async () => {
    if (this.store) {
      return this.store;
    }

    await import('firebase/firestore');
    this.store = app.firestore();

    return this.store;
  };

  firestore = () => ({
    fireStoreInit: () => this.getStore(),
    getEmailTemplates: async () => {
      const store = await this.getStore();
      return getEmailTemplates(store as app.firestore.Firestore);
    },
    getEmailTemplate: async (id: string) => {
      const store = await this.getStore();
      return getEmailTemplate(store as app.firestore.Firestore, id);
    },
    setEmailTemplate: async (values: EmailTemplateValues) => {
      const store = await this.getStore();

      return setEmailTemplate(store as app.firestore.Firestore, values);
    },
  });

  // *** Auth API ***
  doSignInWithEmailAndPassword = ({ credentials, callbacks }: ISignIn) => {
    const { email, password } = credentials;

    this.auth
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        if (callbacks && callbacks.onSuccess) {
          callbacks.onSuccess(user);
        }
        this.analytics.logEvent('login', {
          method: 'Email',
        });
      })
      .catch(error => {
        if (callbacks && callbacks.onFailed) {
          callbacks.onFailed(error);
        }
      });
  };

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (
    next: (arg0: app.User) => void,
    fallback: (arg0: null) => void
  ) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        next(authUser);
      } else {
        fallback(null);
      }
    });

  storageRef = () => this.storage.ref();
  storageDelete = (path: string) => {
    return this.storageRef().child(path).delete();
  };
  /**
   * Upload into "images" folder for article images
   */
  imagesStorageRef = () => this.storageRef().child('images');
  imagesStorageCreate = (imageFile: Blob | null, fileExt?: string) => {
    if (!imageFile) {
      throw new Error('imageFile is null or undefined');
    }

    const fileExtension = fileExt
      ? fileExt
      : (imageFile as unknown as File).name.split('.').pop();
    const fileName = `${nanoid()}--${Date.now()}.${fileExtension}`;
    const metadata = {
      contentType: imageFile.type,
    };
    return this.imagesStorageRef().child(fileName).put(imageFile, metadata);
  };

  /**
   * Upload into "statics/partners" folder for partner logo
   */
  partnersStorageRef = () => this.storageRef().child('statics/partners');
  partnersStorageCreate = (imageFile: Blob | null, fileExt?: string) => {
    if (!imageFile) {
      throw new Error('logo is null or undefined');
    }

    const fileExtension = fileExt
      ? fileExt
      : (imageFile as unknown as File).name.split('.').pop();
    const fileName = `${nanoid()}.${fileExtension}`;
    const metadata = {
      contentType: imageFile.type,
    };
    return this.partnersStorageRef().child(fileName).put(imageFile, metadata);
  };

  /**
   * Upload into "avatars" folder for user profile
   */

  avatarstorageRef = () => this.storageRef().child('avatars');
  avatarsStorageCreate = (imageFile: Blob) => {
    if (!imageFile) {
      throw new Error('imageFile is null or undefined');
    }

    const fileExtension = (imageFile as unknown as File).name.split('.').pop();
    const fileName = `${nanoid()}.${fileExtension}`;
    const metadata = {
      contentType: imageFile.type,
    };
    return this.avatarstorageRef().child(fileName).put(imageFile, metadata);
  };

  // *** User API ***
  user = (uid: string) => `users/${uid}`;
  users = () => this.db.ref('users').orderByChild('username');
  userImage = (uid: string, imageId: string) =>
    `users/${uid}/images/${imageId}`;
  userImages = (uid: string) => `users/${uid}/images`;
  userMembership = (uid: string) => `users/${uid}/membership`;

  userUpdate = (uid: string, values: Object) =>
    this.db.ref(`users/${uid}`).update(values);

  // -- ref
  userRef = (uid: string) => this.db.ref(this.user(uid));
  usersRef = () => this.db.ref('users').orderByChild('username');
  userImageRef = (uid: string, imageId: string) =>
    this.db.ref(this.userImage(uid, imageId));
  userImagesRef = (uid: string) => this.db.ref(this.userImages(uid));
  userMembershipRef = (uid: string) => this.db.ref(this.userMembership(uid));
  artistsPath = () => 'artists';
  artistsRef = () => this.db.ref(this.artistsPath());

  // *** CANVAS API ***
  canvases = () => 'canvases';
  canvas = (uid: string) => `canvases/${uid}`;
  canvasToMediaLastChanged = (uid: string) =>
    `canvases/${uid}/media_last_changed`;
  canvasGroup = (uid: string, groupId: string) =>
    `canvases/${uid}/groups/${groupId}`;
  canvasSync = (uid: string) => `canvases/${uid}/sync`;
  // -- ref
  canvasesRef = () => this.db.ref(this.canvases());
  canvasRef = (uid: string) => this.db.ref(this.canvas(uid));
  canvasToMediaLastChangedRef = (uid: string) =>
    this.db.ref(this.canvasToMediaLastChanged(uid));
  canvasesWithNameRef = () =>
    this.db.ref(this.canvases()).orderByChild('name').startAt(false);
  canvasesToPairRef = () =>
    this.db.ref(this.canvases()).orderByChild('name').equalTo(null);

  // *** IMAGES API ***
  images = () => 'images';
  image = (uid: string) => `images/${uid}`;

  // -- ref
  imagesRef = () => this.db.ref(this.images());
  imageRef = (uid: string) => this.db.ref(this.image(uid));

  // *** HeroMedia API ***
  heroMedia = () => 'heroMedia';
  // -- ref
  heroMediaRef = () => this.db.ref(this.heroMedia());

  // *** BetaInvites API ***
  betaInvites = () => 'betaInvites';
  // -- ref
  betaInvitesRef = () => this.db.ref(this.betaInvites());
  betaInvitesCreateRef = (email: string) =>
    this.db.ref(this.betaInvites()).push({ email: email });

  keywordsPath = () => 'keywords';
  keywordsRef = () => this.db.ref(this.keywordsPath());
  staticTextsPath = () => 'staticTexts';
  staticTextsRef = () => this.db.ref(this.staticTextsPath());
  staticTextPath = (uid: string) => `${this.staticTextsPath()}/${uid}`;
  staticTextPathRef = (uid: string) => this.db.ref(this.staticTextPath(uid));

  editableImagesPath = () => 'editableImages';
  editableImagesRef = () => this.db.ref(this.editableImagesPath());
  // *** Prints API ***
  prints = () => 'prints';
  print = (uid: string) => `prints/${uid}`;
  // -- ref
  printsRef = () => this.db.ref(this.prints());
  printRef = (uid: string) => this.db.ref(this.print(uid));
  userPrintsRef = (userId: string) =>
    this.db.ref(this.prints()).orderByChild('tenant').equalTo(userId);

  // new artwork prints
  artworkPrints = (imageId: string) => `artworkPrints/${imageId}`;
  artworkPrint = (imageId: string, uid: string) =>
    `artworkPrints/${imageId}/${uid}`;

  artworkPrintsRef = (imageId: string) =>
    this.db.ref(this.artworkPrints(imageId));
  artworkPrintRef = (imageId: string, uid: string) =>
    this.db.ref(this.artworkPrint(imageId, uid));
  // End of new artwork prints

  featureFlagRef = () => this.db.ref('featureFlags');
  featureFlagRefByKey = (key: string) => this.db.ref(`featureFlags/${key}`);

  // *** Exihibtions API ***
  exhibitionsRef = () => this.db.ref('exhibitions');
  exhibitionRef = (uid: string) => this.db.ref(`exhibitions/${uid}`);
  // *** Exihibtions API ***

  venuesRef = () => this.db.ref('venues');
  venueRef = (uid: string) => this.venuesRef().child(uid);

  homePageModules = () => 'homePageModules';
  homePageModulesRef = () => this.db.ref('homePageModules');

  // *** Prints API ***
  transactions = () => 'transactions';
  // -- ref
  transactionsRef = () => this.db.ref(this.transactions());
  transactionsRefByKey = (key: string) =>
    this.db.ref(`${this.transactions()}/${key}`);

  wallets = () => 'wallets';
  walletsRef = () => this.db.ref(this.wallets());
  walletRefByKey = (key: string) => this.db.ref(`${this.wallets()}/${key}`);

  /** Anonymous login */
  doAnonymousLogin = async () => await app.auth().signInAnonymously();
  generateAuthCredentials = async (email: string, password: string) =>
    app.auth.EmailAuthProvider.credential(email, password);

  // *** streaming ***
  streamings = () => 'streamings';
  streamingsRef = () => this.db.ref(this.streamings());
  streamingRefByArtworkId = (artworkId: string) =>
    this.db.ref(`${this.streamings()}/${artworkId}`);
  streamingRefByArtworkIdAndUserId = (artworkId: string, userId: string) =>
    this.db.ref(`${this.streamings()}/${artworkId}/${userId}`);
  streamingLogs = () => 'streamingLogs';
  streamingLogsRef = () => this.db.ref(this.streamingLogs());
  streamingLogsRefByArtworkId = (artworkId: string) =>
    this.db.ref(`${this.streamingLogs()}/${artworkId}`);

  // *** streaming exhibitions ***
  streamingExhibitions = () => 'streamingExhibitions';
  streamingExhibitionsRef = () => this.db.ref(this.streamingExhibitions());
  streamingExhibitionsRefByArtworkId = (artworkId: string) =>
    this.db.ref(`${this.streamingExhibitions()}/${artworkId}`);
  streamingExhibitionsRefByArtworkIdAndExhibitionId = (
    artworkId: string,
    exhibitionId: string
  ) =>
    this.db.ref(`${this.streamingExhibitions()}/${artworkId}/${exhibitionId}`);

  // *** Config ***
  configs = () => 'configs';
  configsRef = (key: string) => this.db.ref(`${this.configs()}/${key}`);

  //*** Partners ***
  partners = () => 'partners';
  partnersRef = () => this.db.ref(this.partners());

  logs = () => 'logs';
  editableImageLog = () => `${this.logs()}/editableImages`;
  editableImageLogRef = () => this.db.ref(this.editableImageLog());
  staticTextLog = () => `${this.logs()}/staticTexts`;
  staticTextLogRef = () => this.db.ref(this.staticTextLog());
  partnerImageLog = () => `${this.logs()}/partners`;
  partnerImageLogRef = () => this.db.ref(this.partnerImageLog());
}

export default Firebase;
