import { useState, useEffect } from 'react';
import { useFirebaseContext } from '../firebase';
import firebase from 'firebase';
import { UserData } from 'constants/data/user.data';

const useAuthentication = () => {
  const firebase = useFirebaseContext();
  const [user, setUser] = useState<UserData | null>();
  const [authUser, setAuthUser] = useState<firebase.User | null>();
  // const [anonymousUser, setAnonymousUser] = useState<firebase.User | null>(); // TODO: anonymous

  // const handleAnonymousSignIn = useCallback(async () => {
  //   const anonymousUser = await firebase.doAnonymousLogin();
  //   if (!anonymousUser.user?.uid) {
  //     console.log('can not sign in as anonymous user');
  //     return;
  //   }
  //   setAnonymousUser(anonymousUser.user);
  // }, [firebase]);
  useEffect(() => {
    const stopListening = firebase.onAuthUserListener(
      newAuthUser => {
        if (newAuthUser.isAnonymous) {
          setAuthUser(null);
          // setAnonymousUser(newAuthUser);
        } else {
          setAuthUser(newAuthUser);
          // setAnonymousUser(null);
        }
      },
      () => {
        setAuthUser(null);
        // handleAnonymousSignIn();
      }
    );
    return stopListening;
  }, [firebase]);

  useEffect(() => {
    const stopListening = firebase.auth.onIdTokenChanged(newAuthUser => {
      // Trigger update auth user in case emailVerified is true
      if (newAuthUser && newAuthUser.emailVerified) {
        setAuthUser({ ...newAuthUser });
        return;
      }
      if (newAuthUser && newAuthUser.isAnonymous) {
        setAuthUser(null);
        // setAnonymousUser(newAuthUser);
      } else {
        setAuthUser(newAuthUser);
        // setAnonymousUser(null);
      }
    });
    return stopListening;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebase]);

  useEffect(() => {
    if (authUser === null) {
      setUser(null);
    } else if (authUser?.uid && !authUser?.isAnonymous) {
      const query = firebase.userRef(authUser.uid);
      const callback = query.on('value', snapshot => {
        const dbUser = snapshot.val();
        if (dbUser === null) {
          setUser(null);
          return;
        }
        setUser({
          uid: authUser.uid,
          email: authUser.email,
          isEmailVerified: !!dbUser.isEmailVerified && !!authUser.emailVerified,
          ...dbUser,
        });
      });

      // After the user verified the email we will trigger `isEmailVerified` field of user for realtime update
      // Reload user token to update current user `emailVerified` attribute to make sure the email is verified in the right way
      const queryEmailVerified = firebase.db.ref(
        `users/${authUser.uid}/isEmailVerified`
      );
      const callbackEmailVerified = queryEmailVerified.on('value', snapshot => {
        const isEmailVerified = snapshot.val();

        if (!!isEmailVerified && !authUser.emailVerified) {
          firebase.auth.currentUser?.reload();
          firebase.auth.currentUser?.getIdToken(true);
        }
      });

      return () => {
        query.off('value', callback);
        queryEmailVerified.off('value', callbackEmailVerified);
      };
    }
  }, [authUser, firebase]);

  return { user, isLoading: user === undefined };
};

export default useAuthentication;
