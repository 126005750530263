import React, { useState } from 'react';
import cn from 'classnames';
import { Box, Theme, CircularProgress, Link } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import Typography from 'modules/typography';
import { Print as PrintData } from 'constants/data';
import OptimizedImage from 'modules/OptimizedImage';
import WhiteInfoIcon from 'static/images/white-info-icon.svg';
import ClockIcon from 'static/images/clock.svg';
import {
  useLoadArtist,
  useLoadBuyablePrints,
  useLoadExhibition,
  useLoadImage,
} from 'utils/requests';
import { getSlug, mobileMediaQuery } from 'utils/common';
import { useAuthContext } from 'shell/session';
import {
  ConfirmPurchaseOriginalDialog,
  ConfirmPurchasePrintDialog,
  SelectPrintDialog,
} from '../dialogs';
import { useModalState } from 'utils/hooks';
import { useRandomPrint } from 'views/client/exhibitionImage/ArtworkContext';
import SubscriptionPlanDialog from 'views/client/exhibitionImage/DialogsContext/SubscriptionPlanDialog';
import { useIsMobile } from 'utils/hooks';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import ContentWrapper from './ContentWrapper';
import UILink from 'ui-components/Link';
import UIButton from 'ui-components/Button';

const ArtworkOffer = () => {
  const { user, isSubscribedPackage, isEmailVerified, showVerifyDialog } =
    useAuthContext();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const [isOpen, setOpen] = useState<boolean>(true);
  const image = useLoadImage(user?.freeSlot?.previous?.artworkId || '');
  const { artist } = useLoadArtist(image?.artist || '');
  const { data: printsForSale } = useLoadBuyablePrints(
    user?.freeSlot?.previous?.artworkId || '',
    user?.uid
  );
  const [selectedBuyablePrint] = useRandomPrint(printsForSale ?? []);
  const { exhibition } = useLoadExhibition(
    user?.freeSlot?.previous?.exhibitionId || ''
  );
  const [isThumbnailLoaded, setThumbnailLoaded] = useState<boolean>(false);

  const {
    data: selectPrint,
    isOpen: isOpenConfirmPurchasePrintDialog,
    open: openConfirmPurchasePrintDialog,
    close: closeConfirmPurchasePrintDialog,
  } = useModalState<PrintData>();

  const {
    open: openPurchaseOriginDialog,
    isOpen: isOpenPurchaseOriginDialog,
    close: closePurchaseOriginDialog,
  } = useModalState();

  const {
    isOpen: isOpenSelectPrintDialog,
    open: openSelectPrintDialog,
    close: closeSelectPrintDialog,
  } = useModalState();

  const {
    isOpen: isOpenSubscriptionDialog,
    open: openSubscriptionDialog,
    close: closeSubscriptionDialog,
  } = useModalState();

  const handleOpenConfirmPurchasePrint = () => {
    if (!isEmailVerified) {
      showVerifyDialog();
      return;
    }
    if (selectedBuyablePrint) {
      openConfirmPurchasePrintDialog(selectedBuyablePrint);
    }
  };

  const handleOpenConfirmPurchaseOrigin = () => {
    if (!isEmailVerified) {
      showVerifyDialog();
      return;
    }
    openPurchaseOriginDialog();
  };

  const handleChangePrint = () => {
    openSelectPrintDialog();
  };

  const handleSelectPrint = (print: PrintData) => {
    closeSelectPrintDialog(true);
    openConfirmPurchasePrintDialog(print);
  };

  const handleClickSubscribe = () => {
    if (!isEmailVerified) {
      showVerifyDialog();
      return;
    }
    openSubscriptionDialog();
  };

  if (!image) {
    return null;
  }

  const linkToArtworkDetailPage = generatePath(ROUTES.ARTWORK_DETAILS, {
    imageId: image.uid,
    name: getSlug(image.name),
  });

  const isImageNotForSale =
    image.notForSale ||
    !image.hasOwnProperty('notForSale') ||
    user?.uid === image.artist;

  return (
    <ContentWrapper onClose={() => setOpen(false)} isOpen={isOpen}>
      {artist ? (
        <Box className={classes.content}>
          <Box display={'flex'} mb={isMobile ? 5 : 0}>
            <Box>
              <img
                src={WhiteInfoIcon}
                alt="info"
                className={classes.infoIcon}
              />
            </Box>
            <Box ml={1.75}>
              <Typography
                fontWeight={500}
                lineHeight={'160%'}>{`The free exhibition ${
                exhibition?.name || ''
              } has ended`}</Typography>
              <Typography fontSize={'0.875rem'} lineHeight={'150%'}>
                Thanks for viewing the artwork through the exhibition. You can
                still access this artwork by buying it or subscribing to our
                streaming service.
              </Typography>
              <Box className={classes.artworkInfo}>
                <Box className={classes.image}>
                  <Link component={RouterLink} to={linkToArtworkDetailPage}>
                    <OptimizedImage
                      path={image.path}
                      className={cn(
                        classes.thumbnail,
                        isThumbnailLoaded && classes.displayThumbnail
                      )}
                      onLoad={() => setThumbnailLoaded(true)}
                    />
                    <img
                      src={ClockIcon}
                      alt="clock-icon"
                      className={classes.clockIcon}
                    />
                  </Link>
                </Box>
                {!isThumbnailLoaded && <CircularProgress />}
                <Box ml={2}>
                  <UILink
                    component={RouterLink}
                    href={linkToArtworkDetailPage}
                    variant={'secondary'}>
                    <Typography fontSize={'0.875rem'} lineHeight={'150%'}>
                      {image.name}
                    </Typography>
                  </UILink>
                  <Typography
                    fontSize={'0.75rem'}
                    lineHeight={'160%'}
                    fontColor={'#616161'}>
                    {artist.fullName}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.CTABox}>
            <UIButton
              variant="primary"
              size="medium"
              text={
                selectedBuyablePrint
                  ? `Buy edition €${selectedBuyablePrint.price}`
                  : 'Not for sale'
              }
              onClick={handleOpenConfirmPurchasePrint}
              className={classes.buttonBlack}
              disabled={!selectedBuyablePrint}
            />
            <UIButton
              variant="primary"
              size="medium"
              text={
                isImageNotForSale
                  ? `Not for sale`
                  : `Buy original €${image.priceAmount}`
              }
              onClick={handleOpenConfirmPurchaseOrigin}
              className={classes.buttonBlack}
              disabled={isImageNotForSale}
            />
            {!isSubscribedPackage && (
              <UIButton
                variant="primary"
                size="medium"
                text={'Subscribe from €7/month'}
                onClick={handleClickSubscribe}
                className={classes.buttonBlack}
              />
            )}
          </Box>
          {selectedBuyablePrint &&
            selectPrint &&
            isOpenConfirmPurchasePrintDialog && (
              <ConfirmPurchasePrintDialog
                print={selectPrint}
                image={image}
                open={isOpenConfirmPurchasePrintDialog}
                onClose={() => closeConfirmPurchasePrintDialog(true)}
                onChangePrint={handleChangePrint}
              />
            )}
          {printsForSale &&
            printsForSale.length > 0 &&
            isOpenSelectPrintDialog && (
              <SelectPrintDialog
                prints={printsForSale!}
                image={image}
                open={isOpenSelectPrintDialog}
                onClose={() => closeSelectPrintDialog(true)}
                onSelectPrint={handleSelectPrint}
              />
            )}
          {isOpenPurchaseOriginDialog && (
            <ConfirmPurchaseOriginalDialog
              image={image}
              open={isOpenPurchaseOriginDialog}
              onClose={() => {
                closePurchaseOriginDialog(true);
              }}
            />
          )}
          {isOpenSubscriptionDialog && (
            <SubscriptionPlanDialog
              onClose={() => closeSubscriptionDialog(true)}
              onNext={() => closeSubscriptionDialog(true)}
            />
          )}
        </Box>
      ) : (
        <Box>
          <CircularProgress />
        </Box>
      )}
    </ContentWrapper>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    container: {
      width: '100%',
      maxHeight: 300,
      background: '#F9F9F9',
      border: '1px solid #F0F0F0',
      borderLeft: '4px solid #000000',
      borderRadius: 3,
      padding: theme.spacing(2, 8, 2, 3),
      marginBottom: theme.spacing(2),
      position: 'relative',
      [mobile]: {
        height: '100%',
        maxHeight: '100%',
        padding: theme.spacing(2, 3, 2, 3),
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      [mobile]: {
        flexDirection: 'column',
      },
    },
    infoIcon: {
      width: 24,
      height: 24,
    },
    clockIcon: {
      position: 'absolute',
      width: 20,
      height: 20,
      bottom: -10,
      right: -10,
    },
    artworkInfo: {
      maxHeight: 80,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(2),
      [mobile]: {
        maxHeight: '100%',
      },
    },
    image: {
      position: 'relative',
      maxHeight: 80,
    },
    thumbnail: {
      maxWidth: '100%',
      maxHeight: 80,
      height: 80,
      display: 'none',
    },
    displayThumbnail: {
      display: 'block',
    },
    imageRoot: {
      background: 'transparent',
    },
    CTABox: {
      marginLeft: theme.spacing(2),
      maxWidth: 275,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      [mobile]: {
        maxWidth: '100%',
        marginLeft: 0,
      },
    },
    buttonBlack: {
      marginBottom: theme.spacing(1.5),
    },
    closeButton: {
      position: 'absolute',
      right: 0,
      top: 0,
      fontSize: theme.typography.pxToRem(8),
    },
  });
});

export default ArtworkOffer;
