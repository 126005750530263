import { useState, useCallback } from 'react';
import Firebase from 'shell/firebase';
import { storage } from 'firebase/app';
import { getMetadata, getThumbnails } from 'video-metadata-thumbnails';
import { getImageFileDimensions, multiValuesStringToArray } from 'utils/common';
import { PreImageValues } from 'constants/formdata/image.formdata';
import { UserData } from 'constants/data/user.data';
import { formatDate, DATE_FORMAT_MILLI_TIMESTAMP } from 'utils/common/format';
import { STREAMING_SLOT_UNLIMITED } from 'constants/data';

export interface ProcessMediaResult {
  url: string;
  path: string;
  width?: number;
  height?: number;
  duration?: number;
  videoUrl?: string;
  videoPath?: string;
  createdDate?: number;
}

export enum StreamType {
  LIMITED = 'limited',
  UNLIMITED = 'unlimited',
}

export const isStreamingUnlimited = (numberOfStreamings: number): boolean => {
  return numberOfStreamings <= STREAMING_SLOT_UNLIMITED;
};

interface GetNumberOfStreamings {
  forStream: boolean;
  streamType: StreamType;
  numberOfStreamingSlots: number;
}
export const getNumberOfStreamings = ({
  forStream,
  streamType,
  numberOfStreamingSlots,
}: GetNumberOfStreamings): number => {
  if (!forStream) {
    return numberOfStreamingSlots || 0;
  }
  return streamType === StreamType.UNLIMITED
    ? STREAMING_SLOT_UNLIMITED
    : numberOfStreamingSlots;
};

const appendGoogleVisionsKeywords = async ({
  imageUrl,
  userKeywords,
  firebase,
}: {
  imageUrl: string;
  userKeywords: string;
  firebase: Firebase;
}) => {
  const callable = firebase.functions.httpsCallable('extractKeywords');
  const { data: extractedKeywords } = await callable({
    imageURL: imageUrl,
  });
  const userKeywordsArr = multiValuesStringToArray(userKeywords || '');
  const finalKeywords = Array.from(
    new Set([userKeywordsArr, extractedKeywords].flat())
  );

  return finalKeywords.join(',');
};

const getNameFromFileName = (name: string) =>
  name.substring(0, name.lastIndexOf('.'));

const getNameWithIndex = (name: string, index: number) =>
  name.replaceAll('{i}', index + '');

export const processSubmittedValues = async ({
  file,
  values,
  newImageKey,
  firebase,
  user,
  onUpload,
  index = 1,
}: {
  file: File;
  values: PreImageValues;
  newImageKey: string;
  firebase: Firebase;
  user: UserData;
  onUpload: (a: storage.UploadTaskSnapshot) => any;
  index?: number;
}) => {
  const {
    imageFile,
    forSale,
    forStream,
    isPrintsForSale,
    streamType,
    numberOfStreamings: numberOfStreamingSlots,
    ...imageValues
  } = values;

  /**
   * upload video/image, create thumbnail
   */
  let processFile: ProcessMediaResult;
  if (file.type.includes('video')) {
    processFile = await processVideo(file, firebase, onUpload);
  } else {
    processFile = await processImage(file, firebase, onUpload);
  }

  /**
   * Append keywords from Google Vision to user keywords
   */
  const { url: imageUrl } = processFile;
  const associations = await appendGoogleVisionsKeywords({
    imageUrl,
    userKeywords: values.associations,
    firebase,
  });

  /* price amount should be null if the image is not for sale */
  const priceAmount = forSale ? imageValues.priceAmount : null;
  const printPriceAmount = isPrintsForSale
    ? imageValues.printPriceAmount
    : null;
  const initialNumOfPrintsForSale = isPrintsForSale
    ? imageValues.initialNumOfPrintsForSale
    : null;

  /**
   * if image name isn't specified, set the file name without extension as image name
   * if image name is specified, replace all `{i}` if any with the `index`
   */
  const name = values.name
    ? getNameWithIndex(values.name, index)
    : getNameFromFileName(file.name);

  return {
    ...imageValues,
    name,
    uid: newImageKey,
    artist: user.uid,
    owner: user.uid,
    feature: false,
    catalog: values.catalog.trim().toLocaleLowerCase(),
    priceAmount,
    printPriceAmount,
    associations,
    published: false,
    archived: false,
    notForSale: !forSale,
    initialNumOfPrintsForSale,
    enableStreaming: forStream,
    numberOfStreamings: getNumberOfStreamings({
      forStream,
      streamType,
      numberOfStreamingSlots,
    }),
    ...processFile,
  };
};
const processVideo = async (
  file: Blob,
  firebase: Firebase,
  onUpload: (a: storage.UploadTaskSnapshot) => any
): Promise<ProcessMediaResult> => {
  const [video, thumbnail] = await Promise.all([
    uploadVideo(file, firebase, onUpload),
    createAndUploadThumbnail(file, firebase),
  ]);

  return {
    ...video,
    ...thumbnail,
  };
};

const processImage = async (
  file: Blob,
  firebase: Firebase,
  onUpload: (a: storage.UploadTaskSnapshot) => any
): Promise<ProcessMediaResult> => {
  const query = firebase.imagesStorageCreate(file);
  query.on('state_changed', onUpload);
  const uploadFileSnapshot = await query;

  const url: string = await uploadFileSnapshot.ref.getDownloadURL();
  const path = uploadFileSnapshot.ref.fullPath;
  const createdDate = Number(
    formatDate(
      uploadFileSnapshot.metadata.timeCreated,
      DATE_FORMAT_MILLI_TIMESTAMP
    )
  );
  const size = await getImageFileDimensions(file);

  return {
    url,
    path,
    ...size,
    createdDate,
  };
};

const uploadVideo = async (
  file: Blob,
  firebase: Firebase,
  onUpload: (a: storage.UploadTaskSnapshot) => any
) => {
  const query = firebase.imagesStorageCreate(file);
  query.on('state_changed', onUpload);
  const uploadFileSnapshot = await query;

  const videoUrl = await uploadFileSnapshot.ref.getDownloadURL();
  const videoPath = uploadFileSnapshot.ref.fullPath;
  const createdDate = Number(
    formatDate(
      uploadFileSnapshot.metadata.timeCreated,
      DATE_FORMAT_MILLI_TIMESTAMP
    )
  );
  return { videoUrl, videoPath, createdDate };
};

const createAndUploadThumbnail = async (file: Blob, firebase: Firebase) => {
  const metadata = await getMetadata(file);

  const thumbnails = await getThumbnails(file, {
    quality: 0.7,
    interval: 0.1,
    start: 0,
    end: 0.5,
  });
  const thumbnailBlob = thumbnails[0].blob;

  const uploadFileSnapshot = await firebase.imagesStorageCreate(
    thumbnailBlob,
    'jpeg'
  );
  const url = await uploadFileSnapshot.ref.getDownloadURL();
  const path = uploadFileSnapshot.ref.fullPath;
  return {
    url,
    path,
    duration: metadata.duration,
    width: metadata.width,
    height: metadata.height,
  };
};

export type UploadStateType = 'waiting' | 'uploading' | 'success' | 'failed';

export const useUploadState = () => {
  const [filesState, setFilesState] = useState<Record<string, UploadStateType>>(
    {}
  );

  const setUploadFiles = useCallback((files: FileList) => {
    const arr = Array.from(files);
    const newFilesState = Object.fromEntries<UploadStateType>(
      arr.map(file => [file.name, 'waiting'])
    );
    setFilesState(newFilesState);
  }, []);

  const setFileState = useCallback(
    (fileName: string, uploadState: UploadStateType) => {
      setFilesState(oldState => ({
        ...oldState,
        [fileName]: uploadState,
      }));
    },
    []
  );

  return {
    setUploadFiles,
    filesState,
    setFileState,
  };
};
