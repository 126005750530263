import {
  EVT_SIGN_UP,
  EVT_BEGIN_CHECKOUT,
  EVT_SOCIAL_CLICK,
  EVT_SELECT_SUBSCRIPTION_PLAN,
  EVT_CLICK_VIEW_ON_CANVAS,
  EVT_CLICK_SUBSCRIBE,
  EVT_EXHIBITION_IMAGE_SELECT,
  EVT_CANCEL_MEMBERSHIP,
  EVT_UPGRADE_MEMBERSHIP,
  EVT_DOWNGRADE_MEMBERSHIP,
  EVT_SAME_USER_VERIFY_EMAIL,
  EVT_SIGNED_OUT_VERIFY_EMAIL,
  EVT_DIFFERENT_USER_VERIFY_EMAIL,
  EVT_VERIFY_EMAIL_ERROR,
  // EVT_AUTHENTICATION,
} from 'constants/analytics';
import { MembershipItemInterface } from 'constants/membership';
import { useFirebaseContext } from 'shell/firebase';

interface SignUpData {
  method: string;
  code?: string;
}

interface BeginCheckOutData {
  membership: string;
  userId: string;
  items: [
    {
      item_id: string;
    }
  ];
}

interface SocialData {
  name: string;
}

interface SelectSubscriptionPlanData {
  name: string;
  priceId: string;
  membership: MembershipItemInterface;
}

interface ViewOnCanvasData {
  printId: string;
  userId: string;
}

interface SubscribeData {
  userId: string;
}

interface MembershipData {
  subscriptionId: string;
  planName: string;
}

interface EmailVerificationData {
  currentUserEmail: string | null | undefined;
  verificationEmail: string | null | undefined;
}

const useAnalytics = () => {
  const firebase = useFirebaseContext();
  const logEvent = firebase.analytics.logEvent;

  const trackSignUp = (data: SignUpData) => {
    logEvent(EVT_SIGN_UP, data);
  };

  const trackBeginCheckout = (data: BeginCheckOutData) => {
    logEvent(EVT_BEGIN_CHECKOUT, data);
  };

  const trackSocialClick = (data: SocialData) => {
    logEvent(EVT_SOCIAL_CLICK, data);
  };

  const trackSelectSubscriptionPlan = (data: SelectSubscriptionPlanData) => {
    logEvent(EVT_SELECT_SUBSCRIPTION_PLAN, data);
  };

  const trackClickViewOnCanvas = (data: ViewOnCanvasData) => {
    logEvent(EVT_CLICK_VIEW_ON_CANVAS, data);
  };

  const trackClickSubscribe = (data: SubscribeData) => {
    logEvent(EVT_CLICK_SUBSCRIBE, data);
  };

  const trackExhibitionImageSelect = () => {
    logEvent(EVT_EXHIBITION_IMAGE_SELECT);
  };

  const trackCancelMembership = (data: MembershipData) => {
    logEvent(EVT_CANCEL_MEMBERSHIP, data);
  };

  const trackUpgradeMembership = (data: MembershipData) => {
    logEvent(EVT_UPGRADE_MEMBERSHIP, data);
  };

  const trackDowngradeMembership = (data: MembershipData) => {
    logEvent(EVT_DOWNGRADE_MEMBERSHIP, data);
  };

  const trackSameUserVerifyEmail = ({
    currentUserEmail,
    verificationEmail,
  }: EmailVerificationData) => {
    logEvent(EVT_SAME_USER_VERIFY_EMAIL, {
      currentUserEmail,
      verificationEmail,
    });
  };

  const trackSignedOutVerifyEmail = ({
    verificationEmail,
  }: EmailVerificationData) => {
    logEvent(EVT_SIGNED_OUT_VERIFY_EMAIL, {
      verificationEmail,
    });
  };

  const trackDifferentUserVerifyEmail = ({
    currentUserEmail,
    verificationEmail,
  }: EmailVerificationData) => {
    logEvent(EVT_DIFFERENT_USER_VERIFY_EMAIL, {
      currentUserEmail,
      verificationEmail,
    });
  };

  const trackVerifyEmailError = (code: string) => {
    logEvent(EVT_VERIFY_EMAIL_ERROR, { code });
  };

  // const trackSignUpAtExh = (anonymousUserId: string) => {
  //   logEvent(EVT_AUTHENTICATION.EVT_ANONYMOUS_USER_SIGN_UP_AT_EXHIBITION_PAGE, {
  //     anonymousUserId,
  //   });
  // };
  // const trackSignInAtExh = (anonymousUserId: string) => {
  //   logEvent(EVT_AUTHENTICATION.EVT_ANONYMOUS_USER_SIGN_IN_AT_EXHIBITION_PAGE, {
  //     anonymousUserId,
  //   });
  // };
  // const trackSignUpAtSignUpPage = (anonymousUserId: string) => {
  //   logEvent(EVT_AUTHENTICATION.EVT_ANONYMOUS_USER_SIGN_UP_AT_SIGN_UP_PAGE, {
  //     anonymousUserId,
  //   });
  // };
  // const trackSignInAtSignInPage = (anonymousUserId: string) => {
  //   logEvent(EVT_AUTHENTICATION.EVT_ANONYMOUS_USER_SIGN_IN_AT_SIGN_IN_PAGE, {
  //     anonymousUserId,
  //   });
  // };

  return {
    trackSignUp,
    trackBeginCheckout,
    trackSocialClick,
    trackSelectSubscriptionPlan,
    trackClickViewOnCanvas,
    trackClickSubscribe,
    trackExhibitionImageSelect,
    trackCancelMembership,
    trackUpgradeMembership,
    trackDowngradeMembership,
    trackSameUserVerifyEmail,
    trackSignedOutVerifyEmail,
    trackDifferentUserVerifyEmail,
    trackVerifyEmailError,
    // trackSignUpAtExh,
    // trackSignInAtExh,
    // trackSignUpAtSignUpPage,
    // trackSignInAtSignInPage,
  };
};

export default useAnalytics;
