import { useMemo } from 'react';
import { useFilterContext } from './context';

export interface OptionRenderProps<T = any> {
  values: T[];
  onChange: (newValues: T | T[]) => void;
}
interface FilterOptionProps<T = any> {
  name: string;
  render: ({ values, onChange }: OptionRenderProps<T>) => JSX.Element;
}

export const FilterOption = ({ name, render }: FilterOptionProps) => {
  const { activeFilter, createHandleChangeFunc } = useFilterContext();
  const onChange = useMemo(
    () => createHandleChangeFunc(name),
    [name, createHandleChangeFunc]
  );
  const values = activeFilter[name];
  return render({ values, onChange });
};
