import { useState, useEffect } from 'react';
import { useFirebaseContext } from 'shell/firebase';
import { UserData } from 'constants/data/user.data';

export const useLoadUsers = () => {
  const firebase = useFirebaseContext();
  const [usersMap, setUsersMap] = useState<Record<string, UserData> | null>();

  useEffect(() => {
    if (firebase !== null) {
      const query = firebase.usersRef();
      const stopListener = query.on('value', snapshot => {
        setUsersMap(snapshot.val());
      });
      return () => query.off('value', stopListener);
    }
  }, [firebase]);

  return { usersMap };
};

export const useDisableUser = () => {
  const firebase = useFirebaseContext();
  return (uid: string) => {
    if (!firebase) {
      return Promise.reject('firebase is not defined');
    }
    const callable = firebase.functions.httpsCallable('disableUser');
    return callable({
      uid,
    }).then(response => Promise.resolve(response.data));
  };
};

export const useEnableUser = () => {
  const firebase = useFirebaseContext();
  return (uid: string) => {
    if (!firebase) {
      return Promise.reject('firebase is not defined');
    }
    const callable = firebase.functions.httpsCallable('enableUser');
    return callable({
      uid,
    }).then(response => Promise.resolve(response.data));
  };
};
