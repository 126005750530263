import { useRef, useEffect } from 'react';
import { Control, useWatch, Path } from 'react-hook-form';

interface IParams<FormValuesType = {}, FieldValueType = any> {
  control: Control<FormValuesType>;
  fieldName: Path<FormValuesType>;
  callback: (value: FieldValueType, prevValue: FieldValueType) => void;
  defaultValue: FieldValueType;
}

export const useOnFieldChange = <T = {}>({
  control,
  fieldName,
  callback,
  defaultValue,
}: IParams<T>) => {
  const callbackRef = useRef(callback);
  const prevValue = useRef(defaultValue);
  const value = useWatch({ control, name: fieldName, defaultValue });

  useEffect(() => {
    if (prevValue.current !== value) {
      prevValue.current = value;
      callbackRef.current(value, prevValue.current);
    }
  }, [value]);
};
