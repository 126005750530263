import { format } from 'date-fns';

export const DISPLAYED_DATE_FORMAT = 'yyyy-MM-dd';
export const DISPLAYED_DATE_FORMAT_2 = 'dd.MM.yyyy';
export const DISPLAYED_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const DATE_FORMAT_DAY = 'MMM dd, yyyy';
export const DATE_FORMAT_MILLI_TIMESTAMP = 'T';
export const DATE_FORMAT_MONTH_DAY = 'MMM dd';

export const formatDate = (
  date: string | number,
  dateFormat?: string
): string => {
  if (!date && date !== 0) {
    return '';
  }
  const d = format(
    new Date(date),
    dateFormat ? dateFormat : DISPLAYED_DATE_FORMAT
  );

  return d;
};

export const captalize = (str: string): string => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const formatCurrency = (name: string): string => {
  if (!name) {
    return '';
  }

  const currencySymbols: { [key: string]: string } = {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    CRC: '₡', // Costa Rican Colón
    GBP: '£', // British Pound Sterling
    ILS: '₪', // Israeli New Sheqel
    INR: '₹', // Indian Rupee
    JPY: '¥', // Japanese Yen
    KRW: '₩', // South Korean Won
    NGN: '₦', // Nigerian Naira
    PHP: '₱', // Philippine Peso
    PLN: 'zł', // Polish Zloty
    PYG: '₲', // Paraguayan Guarani
    THB: '฿', // Thai Baht
    UAH: '₴', // Ukrainian Hryvnia
    VND: '₫', // Vietnamese Dong
  };

  const uppercaseName = name.toUpperCase();

  if (currencySymbols[uppercaseName] !== undefined) {
    return currencySymbols[uppercaseName];
  }

  return '';
};

export const formatDurationForVideo = (duration: number): string => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = minutes >= 10 ? 0 : Math.floor((duration % 3600) % 60);

  return [
    hours ? `${hours}h` : false,
    minutes ? `${minutes}m` : false,
    seconds ? `${seconds}s` : false,
  ]
    .filter(Boolean)
    .join(' ');
};
