import axios from 'axios';
import pw from 'a-promise-wrapper';
import { useState, useRef, useEffect } from 'react';
import { useVerification } from 'views/client/watermarkVerification/VerificationContext';
import { urltoFile } from 'modules/watermarkVerificationForm/helpers';
import getEnvVars from 'utils/common/envVars';

const RESIZE_WIDTH = 1920;
const RESIZE_HEIGHT = 1080;
const MAX_FRAME_COUNT = 15;
const INVALID_RESOLUTION_MSG =
  'Invalid resolution, resolution of an image or video must be 1920x1080p';

const validResolution = ({
  width,
  height,
}: {
  width: number;
  height: number;
}): boolean =>
  width === RESIZE_WIDTH && height === RESIZE_HEIGHT ? true : false;

export const useExtractVideoFrame = (videoRef: any) => {
  const { setIsSubmitting, setVerifiedStatus, setMetaData } = useVerification();
  const submitFile = useSubmitFile();
  const [videoFrameCount, setVideoFrameCount] = useState<number>(0);
  const videoWidth = useRef(0);
  const videoHeight = useRef(0);
  const videoDuration = useRef(0);
  const videoName = useRef<string>('');

  const onSuccess = async (res: any) => {
    const metaData = res.data;

    if (metaData && typeof metaData === 'object') {
      setVerifiedStatus('success');
      setMetaData(metaData);
    } else {
      setVerifiedStatus('fail');
    }
    setIsSubmitting(false);
  };

  const onError = () => {
    if (
      videoRef.current.currentTime > MAX_FRAME_COUNT ||
      videoRef.current.currentTime >= videoDuration.current
    ) {
      setVerifiedStatus('fail');
      setIsSubmitting(false);
      return;
    }
    setVideoFrameCount(count => count + 1);
  };

  const handleLoadeddata = async () => {
    if (!videoRef.current) {
      return;
    }
    videoWidth.current = videoRef.current.videoWidth;
    videoHeight.current = videoRef.current.videoHeight;
    videoDuration.current = videoRef.current.duration;

    if (
      !validResolution({
        width: videoWidth.current,
        height: videoHeight.current,
      })
    ) {
      alert(INVALID_RESOLUTION_MSG);
      setIsSubmitting(false);
      return;
    }

    setVideoFrameCount(1);
  };

  const handleSeeked = async () => {
    const canvas: HTMLCanvasElement = document.getElementById(
      'canvasImg'
    ) as HTMLCanvasElement;

    canvas.width = videoWidth.current;
    canvas.height = videoHeight.current;
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }
    context.drawImage(
      videoRef.current,
      0,
      0,
      videoWidth.current,
      videoHeight.current
    );

    const fileName = (videoName.current || '').split('.')[0];
    const { data: file } = await pw(
      urltoFile(
        canvas.toDataURL(),
        `${videoRef.current.currentTime || 0}s-${fileName}.png`,
        'image/png'
      )
    );

    setIsSubmitting(true);
    submitFile({ file, onSuccess, onError });
  };

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current.currentTime = videoFrameCount;
  }, [videoRef, videoFrameCount]);

  return async ({ file }: { file: File }) => {
    const media = URL.createObjectURL(file);
    videoName.current = file.name;
    videoRef.current = document.getElementById(
      'artworkVideo'
    ) as HTMLVideoElement;

    if (!videoRef.current) {
      return;
    }

    videoRef.current.src = media;
    videoRef.current.removeEventListener('loadeddata', handleLoadeddata);
    videoRef.current.addEventListener('loadeddata', handleLoadeddata, false);

    videoRef.current.removeEventListener('seeked', handleSeeked);
    videoRef.current.addEventListener('seeked', handleSeeked, false);
  };
};

export const useSubmitFile = () => {
  return async ({
    file,
    onSuccess,
    onError,
  }: {
    file: File;
    onSuccess: (res: any) => void;
    onError: () => void;
  }) => {
    /**
     * Call to watermark handler to decode
     */
    const { apiGateway } = getEnvVars();
    const domain = apiGateway;
    const url = `${domain}/nftMetaData-getFromFile`;
    const formData = new FormData();
    formData.append('File', file);

    axios
      .post(url, formData)
      .then(response => onSuccess(response))
      .catch(onError);
    /**
     * End of decoding
     */
  };
};

export const useVerify = (videoRef: any) => {
  const { setIsSubmitting, setVerifiedStatus, setMetaData } = useVerification();
  const submitFile = useSubmitFile();
  const extractVideoFrame = useExtractVideoFrame(videoRef);

  return async ({ file }: { file: File }) => {
    if (file.type === 'video/mp4') {
      setIsSubmitting(true);
      extractVideoFrame({ file });
      return;
    }

    try {
      var _URL = window.URL || window.webkitURL;
      const img = new Image();
      var objectUrl = _URL.createObjectURL(file);

      img.addEventListener('load', function () {
        _URL.revokeObjectURL(objectUrl);
        if (!validResolution({ width: this.width, height: this.height })) {
          alert(INVALID_RESOLUTION_MSG);
          return;
        }

        const onSuccess = async (res: any) => {
          const metaData = res.data;

          if (metaData && typeof metaData === 'object') {
            setVerifiedStatus('success');
            setMetaData(metaData);
          } else {
            setVerifiedStatus('fail');
          }
          setIsSubmitting(false);
        };
        const onError = () => {
          setVerifiedStatus('fail');
          setIsSubmitting(false);
        };
        submitFile({ file, onSuccess, onError });

        setIsSubmitting(true);
      });
      img.src = objectUrl;
    } catch (e) {
      alert('something went wrong please try again');
      console.error(e);
    }
  };
};
