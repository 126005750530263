import { useState, useEffect } from 'react';
import { useFirebaseContext } from 'shell/firebase';

export const useDetectInternetConnection = () => {
  const firebase = useFirebaseContext();
  const [isConnected, setConnection] = useState(true);

  useEffect(() => {
    const connectedRef = firebase.db.ref('.info/connected');
    const stopListener = connectedRef.on('value', (snap: any) => {
      if (!snap.val() && isConnected) {
        setConnection(false);
      }
      if (!isConnected && snap.val()) {
        setConnection(true);
      }
    });
    return () => connectedRef.off('value', stopListener);
  }, [firebase, isConnected]);
  return isConnected;
};
