import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { CanvasData } from 'constants/data/canvas.data';
import OptimizedImage from 'modules/OptimizedImage';
import { DraggableCore, DraggableEvent, DraggableData } from 'react-draggable';
import { ISize } from './selectionHelpers';
import { IPrintTransform } from 'constants/data/print.data';

import {
  getFrameStyles,
  getDimensions,
  getFrameScale,
} from './deviceFrameHelpers';

interface Props {
  orientation: 'landscape' | 'portrait';
  imagePath: string;
  imageBoxSize: ISize;
  canvas: CanvasData;
  transform: IPrintTransform;
  imageMaxWidth: number;
  onDrag: (deltaX: number, deltaY: number) => void;
}
const propsAfterScale = (props: Props) => {
  const frameScale = getFrameScale(props.canvas);
  const transform: IPrintTransform = {
    ...props.transform,
    translateX: props.transform.translateX * frameScale,
    translateY: props.transform.translateY * frameScale,
  };
  const imageMaxWidth = props.imageMaxWidth * frameScale;
  const imageBoxSize: ISize = {
    width: props.imageBoxSize.width * frameScale,
    height: props.imageBoxSize.height * frameScale,
  };
  return {
    ...props,
    imageBoxSize,
    imageMaxWidth,
    transform,
  };
};

const DeviceFrame = (props: Props) => {
  // Every sizes passed to this component is realworld sizes, while the device frame's size is different to the canvas's
  // real size,  we have to scale every sizes follow the ratio (device frame size)/(canvas size)
  const { imagePath, imageBoxSize, canvas, transform, imageMaxWidth, onDrag } =
    propsAfterScale(props);

  const frameStyles = getFrameStyles(canvas);
  const canvasSize = getDimensions(canvas);

  const classes = useStyles({
    frame: frameStyles,
    canvas: canvasSize,
  });

  const { imgStyles, boxStyles } = transformToStyles(imageBoxSize, transform);
  const frameScale = getFrameScale(props.canvas);
  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    if (props.orientation === 'landscape') {
      // transforms affect the drag event coordinates, so they have to be swapped
      onDrag(data.deltaY / frameScale, -data.deltaX / frameScale);
    } else {
      onDrag(data.deltaX / frameScale, data.deltaY / frameScale);
    }
  };

  return (
    <div
      style={
        props.orientation === 'landscape' ? { transform: 'rotate(270deg)' } : {}
      }
      className={classes.root}>
      <div
        className={classes.ratioBox}
        style={
          props.orientation === 'landscape'
            ? // this "reverts" the above transform, so that the image isn't affected by it
              { transform: 'rotate(180deg)' }
            : {}
        }>
        <div className={classes.wrapper}>
          <div className={classes.viewport}>
            <div className={classes.imageBox} style={boxStyles}>
              <OptimizedImage
                path={imagePath}
                alt="to-select"
                className={classes.image}
                sizes={`${imageMaxWidth}px`}
                style={imgStyles}
              />
            </div>
            <div className={classes.overlay}></div>
            <div className={classes.innerFrame}>
              <div className={classes.outerFrame}></div>
            </div>
          </div>
          <DraggableCore onDrag={handleDrag}>
            <div className={classes.draggableLayer}></div>
          </DraggableCore>
        </div>
      </div>
    </div>
  );
};

/**
 * The values in `transform` cannot be applied directly to the image's css transform because it make the calculation become more complicated.
 * Instead, I create a box to wrap the image inside, calculate the translate value of the image so that it fix inside the box.
 *
 * To scale the image
 * - Instead of setting `scale` value in the css transform, we multiply the image's size with the transform.scale
 * e.g. The image's size is 1920 x 1080, scale is 0.5, then set the size of the box & image to 960 x 540
 *
 * To rotate the image
 * - We set the image's width & height to the size of the rotated box
 * - Translate the image so that its transform-origin match the box's transform-origin
 * - Rotate the image follow the `rotate` value
 * e.g. Size of the original image is 1920 x 1080, when rotating 90deg, set the box's size to 1080 x 1920,
 *
 */
const transformToStyles = (imageBoxSize: ISize, transform: IPrintTransform) => {
  // Create styles for the box
  const boxWidth = imageBoxSize.width * transform.scale;
  const boxHeight = imageBoxSize.height * transform.scale;
  const boxTranslateX = transform.translateX;
  const boxTranslateY = transform.translateY;
  const boxStyles = {
    width: boxWidth,
    height: boxHeight,
    transform: `translate(${boxTranslateX}px, ${boxTranslateY}px)`,
  };

  /**
   * Create styles for the img element
   * - If the user didn't rotate the image, or rotated it 180 deg, the img element's size should be the box's size, nothing is complicated
   * - If the user rotated the image 90deg or 270deg, it's mean that the box's edges have been swapped (height becomes width, width becomes height),
   * so we need to set the img's size to the box's size before swapping
   */
  const imgDisplayWidth = transform.rotate % 180 === 0 ? boxWidth : boxHeight;
  const imgDisplayHeight = transform.rotate % 180 === 0 ? boxHeight : boxWidth;
  const imgTranslateX = (boxWidth - imgDisplayWidth) / 2;
  const imgTranslateY = (boxHeight - imgDisplayHeight) / 2;

  const imgStyles = {
    width: imgDisplayWidth,
    height: imgDisplayHeight,
    transform: `translate(${imgTranslateX}px, ${imgTranslateY}px) rotate(${transform.rotate}deg)`,
  };
  return {
    imgStyles,
    boxStyles,
  };
};

interface StylesProps {
  frame: {
    innerFrameImage: string;
    outerFrameImage: string;
    innerFrameWidth: number;
    overlayBorderRadius: number;
    outerFramePaddingX: number;
    outerFramePaddingY: number;
  };
  canvas: { width: number; height: number };
}
const useStyles = makeStyles<Theme, StylesProps>(
  createStyles({
    root: ({ frame: { innerFrameWidth } }) => ({
      position: 'relative',
      maxWidth: innerFrameWidth,
      width: '100%',
    }),
    ratioBox: ({ canvas: { width, height } }) => ({
      width: '100%',
      paddingTop: `${(height / width) * 100}%`,
    }),
    wrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
    },
    viewport: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      userSelect: 'none',
      pointerEvents: 'none',
      userDrag: 'none',
    },
    image: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    overlay: ({ frame: { overlayBorderRadius } }) => ({
      boxShadow: '0 0 0 10000px rgba(255,255,255,0.6)',
      borderRadius: `${overlayBorderRadius}px`,
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    }),
    innerFrame: ({ frame: { innerFrameImage } }) => {
      return {
        position: 'absolute',
        top: -3,
        bottom: -3,
        left: -3,
        right: -3,
        backgroundImage: `url(${innerFrameImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
      };
    },
    outerFrame: ({
      frame: { outerFrameImage, outerFramePaddingX, outerFramePaddingY },
    }) => ({
      position: 'absolute',
      top: `-${outerFramePaddingY}px`,
      bottom: `-${outerFramePaddingY}px`,
      left: `-${outerFramePaddingX}px`,
      right: `-${outerFramePaddingX}px`,
      backgroundImage: `url(${outerFrameImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      userSelect: 'none',
      pointerEvents: 'none',
    }),
    draggableLayer: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      cursor: 'all-scroll',
    },
  })
);

export default DeviceFrame;
