import React from 'react';
import { makeStyles, createStyles, Theme, Box, Link } from '@material-ui/core';
import { mobileMediaQuery } from 'utils/common';
import AuthLayout from 'views/auth/AuthLayout';
import { Headline, Heading, Description } from '../../common/Typography';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Location } from 'history';
import * as ROUTES from 'constants/routes';

import UIButton from 'ui-components/Button';

const ResetPasswordComplete = () => {
  const classes = useStyles();
  const { state: locationState } = useLocation<{ from: Location }>();

  return (
    <AuthLayout>
      <Box className={classes.container}>
        <Headline>Account</Headline>
        <Box mt={2}>
          <Heading>Alright, your password has been reset</Heading>
        </Box>
        <Box mt={2}>
          <Description>
            That’s it. Now you can use your new password to sign in.
          </Description>
        </Box>
        <Link
          className={classes.signInLink}
          component={RouterLink}
          to={{ pathname: ROUTES.SIGN_IN, state: locationState }}>
          <UIButton
            variant="primary"
            size="medium"
            text="Sign in"
            className={classes.submitBtn}
          />
        </Link>
      </Box>
    </AuthLayout>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(11),
      width: '100%',
      [mobile]: {
        marginLeft: 0,
      },
    },
    resetPasswordForm: {
      flex: 1,
      marginTop: theme.spacing(2.5),
    },
    signInLink: {
      display: 'block',
      marginTop: theme.spacing(5.5),
    },
    submitBtn: {
      minWidth: 175,
      justifyContent: 'space-between',
    },
  });
});

export default ResetPasswordComplete;
