import React from 'react';
import { Box } from '@material-ui/core';
import Typography from 'modules/typography';
import AccountLayout from 'views/account/AccountLayout';
import ImagesList from './ImagesList';
import ArtworksProvider from './ArtworksProvider';

const Images = () => {
  return (
    <AccountLayout>
      <ArtworksProvider>
        <Box mb={2}>
          <Typography variant="h5" fontWeight={500} fontSize="1.75rem">
            My Collection
          </Typography>
        </Box>
        <ImagesList />
      </ArtworksProvider>
    </AccountLayout>
  );
};

export default Images;
