import { useMemo, useCallback, useReducer } from 'react';

const DEFAULT_ITEM_PER_PAGE = 50;

type State = {
  data: Array<any>;
  currentPage: number;
};

type Action =
  | {
      type: 'SET_DATA';
      payload: Array<any>;
    }
  | { type: 'NEXT_PAGE' | 'PREV_PAGE' };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_DATA': {
      return { data: action.payload, currentPage: 1 };
    }
    case 'NEXT_PAGE': {
      return { ...state, currentPage: state.currentPage + 1 };
    }
    case 'PREV_PAGE': {
      return { ...state, currentPage: state.currentPage - 1 };
    }
    default:
      return state;
  }
}

export interface PaginationProps {
  itemPerPage?: number;
  data?: Array<any>;
}

const defaultProps = {
  itemPerPage: DEFAULT_ITEM_PER_PAGE,
  data: [],
};

export function useClientPagination(props: PaginationProps = defaultProps) {
  const { itemPerPage, data: initialData } = { ...defaultProps, ...props };

  const [{ currentPage, data }, dispatch] = useReducer(reducer, {
    data: initialData,
    currentPage: 1,
  });

  const totalPage = Math.ceil(data.length / itemPerPage);
  const hasNextPage = currentPage < totalPage;
  const toNextPage = useCallback(() => dispatch({ type: 'NEXT_PAGE' }), []);
  const toPreviousPage = useCallback(() => dispatch({ type: 'PREV_PAGE' }), []);
  const setData = useCallback(
    theData => dispatch({ type: 'SET_DATA', payload: theData }),
    []
  );
  const currentPageData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemPerPage;
    const endIndex = startIndex + itemPerPage;
    return data.slice(startIndex, endIndex);
  }, [currentPage, data, itemPerPage]);

  const dataFromFirstPage = useMemo(() => {
    const endIndex = currentPage * itemPerPage;
    return data.slice(0, endIndex);
  }, [currentPage, data, itemPerPage]);

  return {
    dataFromFirstPage,
    data: currentPageData,
    toNextPage,
    toPreviousPage,
    setData,
    hasNextPage,
    currentPage,
  };
}
