import React, { useState } from 'react';
import {
  NavLink,
  NavLinkProps,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { Location } from 'history';
import cs from 'classnames';
import { useIsMobile } from 'utils/hooks';
import { createStyles, makeStyles, Theme, ListItem } from '@material-ui/core';
import { mobileMediaQuery } from 'utils/common';
import { motion } from 'framer-motion';

interface ListItemProps extends NavLinkProps {
  label: string;
  isButton?: boolean;
}

const AnimatedListItem = ({
  label,
  isButton = false,
  ...navLinkProps
}: ListItemProps) => {
  const location = useLocation();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const [isHover, setHover] = useState<boolean>(false);

  const toPath =
    typeof navLinkProps.to === 'string'
      ? navLinkProps.to
      : (navLinkProps.to as Location).pathname;

  const isActive = matchPath(location.pathname, {
    path: toPath,
    exact: true,
  });

  const variants = {
    slide: () => ({
      width: isHover || isActive ? '100%' : 0,
      height: isHover || isActive ? '2px' : 0,
      transition: {
        duration: isActive ? 0 : 0.3,
      },
    }),
  };

  return (
    <ListItem className={cs(classes.navMainItem, { active: isActive })}>
      <motion.div
        whileHover={isHover && !isActive ? { opacity: 0.5 } : { opacity: 1 }}
        onHoverStart={() => setHover(true)}
        onHoverEnd={() => setHover(false)}
        className={isButton ? classes.navMainLinkButtonWrap : ''}>
        <NavLink
          {...navLinkProps}
          className={cs(classes.navMainLink, {
            [classes.navMainLinkButton]: isButton,
            [classes.navMainLinkButtonActive]: isButton && isActive,
          })}
          activeClassName="active">
          {label}
        </NavLink>
      </motion.div>
      {!isMobile && (
        <motion.div
          variants={variants}
          animate="slide"
          className={classes.slideBorderBottom}
        />
      )}
    </ListItem>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    navMainItem: {
      position: 'relative',
      margin: '0 0.5rem',
      padding: 0,
      fontSize: '1rem',
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },
    navMainLink: {
      'whiteSpace': 'nowrap',
      'display': 'inline-block',
      'color': '#000',
      'padding': '0.5rem 1rem',
      '&:hover': {
        color: '#000',
      },
      [mobile]: {
        display: 'block',
      },
    },
    navMainLinkButtonWrap: {
      '& + div[class*=slideBorderBottom]': {
        display: 'none !important',
      },
    },
    navMainLinkButton: {
      border: '1px solid #030303',
    },
    navMainLinkButtonActive: {
      'background': '#030303',
      'color': '#fff',
      '&:hover': {
        color: '#fff',
      },
    },
    slideBorderBottom: {
      backgroundColor: '#000',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
  });
});

export default AnimatedListItem;
