import React from 'react';
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  List,
  ListItem,
} from '@material-ui/core';
import YoutubeIcon from 'static/images/footer/youtube_icon.png';
import InstagramIcon from 'static/images/footer/instagram_icon.png';
import LinkedInIcon from 'static/images/footer/linkedin_icon.png';
import FacebookIcon from 'static/images/footer/facebook_icon.png';
import TwitterIcon from 'static/images/footer/twitter_icon.png';
import { mobileMediaQuery } from 'utils/common';
import useAnalytics from 'utils/requests/useAnalytics';
import UILink from 'ui-components/Link';

const ICON_LIST = [
  {
    id: 'facebook',
    iconUrl: FacebookIcon,
    link: 'https://www.facebook.com/ActiveImageCompany/',
  },
  {
    id: 'instagram',
    iconUrl: InstagramIcon,
    link: 'https://www.instagram.com/activeimage.io/',
  },
  {
    id: 'twitter',
    iconUrl: TwitterIcon,
    link: 'https://twitter.com/image_active',
  },
  {
    id: 'youtube',
    iconUrl: YoutubeIcon,
    link: 'https://www.youtube.com/channel/UC8bbTTip1TtCx6q926PGnhQ',
  },
  {
    id: 'linkedin',
    iconUrl: LinkedInIcon,
    link: 'https://www.linkedin.com/company/active-image/',
  },
];

const SocialIconList = () => {
  const { trackSocialClick } = useAnalytics();
  const classes = useStyles();

  return (
    <Box>
      <List className={classes.iconList}>
        {ICON_LIST.map(item => {
          return (
            <ListItem key={item.iconUrl} className={classes.iconItem}>
              <UILink
                href={item.link}
                target="_blank"
                rel="noreferrer"
                className={classes.iconLink}
                onClick={() => {
                  trackSocialClick({ name: item.id });
                }}>
                <img
                  src={item.iconUrl}
                  alt={item.id}
                  className={classes.icon}
                />
              </UILink>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    iconList: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      [mobile]: {
        width: 'auto',
        justifyContent: 'flex-start',
      },
    },
    iconItem: {
      'width': 'auto',
      'padding': 0,
      'marginRight': theme.spacing(2),
      '&:last-child': {
        marginRight: 0,
      },
      '&:has(> div:empty)': {
        display: 'none',
      },
    },
    iconLink: {
      position: 'relative',
    },
    icon: {
      height: 24,
      objectFit: 'contain',
    },
  });
});

export default SocialIconList;
