import React, { ReactNode } from 'react';
import {
  List,
  ListItem,
  Box,
  Theme,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { mobileMediaQuery } from 'utils/common';
import { NavLink } from 'react-router-dom';
import { IMenuItem } from 'constants/menu';
interface ItemProps {
  data: IMenuItem;
}

export const MenuItem = ({ data }: ItemProps) => {
  const classes = useStyles();
  return (
    <ListItem className={classes.item}>
      <NavLink
        to={data.path}
        activeClassName={classes.activeLink}
        className={classes.itemLink}>
        {data.label}
      </NavLink>
    </ListItem>
  );
};

interface MenuLinkProps {
  menu: IMenuItem[];
  renderItem?: (item: IMenuItem) => ReactNode;
}

const LeftMenu = ({ menu, renderItem }: MenuLinkProps) => {
  const classes = useStyles();
  if (!menu || menu.length === 0) {
    return null;
  }
  return (
    <Box className={classes.leftMenu}>
      <List className={classes.list}>
        {menu.map((item: IMenuItem) => {
          if (renderItem) {
            return renderItem(item);
          }
          return <MenuItem data={item} key={item.path} />;
        })}
      </List>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    leftMenu: {
      width: '300px',
      [mobile]: {
        width: '100%',
      },
    },
    list: {
      backgroundColor: 'transparent',
      padding: 0,
      fontSize: '1rem',
      color: '#000',
    },
    item: {
      'padding': 0,
      'borderBottom': '1px solid #F0F0F0',
      '&:last-child': {
        borderBottom: 0,
      },
    },
    itemLink: {
      'paddingTop': theme.spacing(2),
      'paddingBottom': theme.spacing(2),
      'paddingLeft': theme.spacing(3),
      'paddingRight': theme.spacing(3),
      'color': '#000',
      'width': '100%',
      '&:hover': {
        color: '#000',
      },
    },
    activeLink: {
      borderRight: '3px solid #000',
    },
  });
});

export default LeftMenu;
