import { useState, useEffect } from 'react';
import { useFirebaseContext } from 'shell/firebase';
import { TransactionData } from 'constants/data';
import { useAuthContext } from 'shell/session';

interface Props {
  txId: string;
}

const useGetTransaction = ({ txId }: Props) => {
  const firebase = useFirebaseContext();
  const { user } = useAuthContext();
  const [transaction, setTransaction] = useState<TransactionData | null>();

  useEffect(() => {
    if (txId && user) {
      firebase
        .transactionsRef()
        .child(txId)
        .once(
          'value',
          snapshot => {
            const data = snapshot.val();
            if (!data) {
              setTransaction(null);
              return;
            }
            setTransaction(data);
          },
          error => {
            console.error(error);
            setTransaction(null);
          }
        );
    }
  }, [txId, user, firebase]);
  return { transaction, isLoading: transaction === undefined };
};

export default useGetTransaction;
