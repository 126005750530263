import { useEffect, useState } from 'react';
import Firebase, { useFirebaseContext } from 'shell/firebase';
import { useIsMounted } from 'utils/hooks';

interface QueryParams {
  query: (firebase: Firebase) => firebase.database.Query;
}

interface QueryResult {
  snapshot: firebase.database.DataSnapshot | undefined;
  isLoading: boolean;
}

/**
 * Base method for querying data from Firebase realtime database
 *
 * @param {QueryParams} params - the params
 * @returns {QueryResult} the result of query with loading status included
 */
export const useQuery = (params: QueryParams): QueryResult => {
  const { query } = params;
  const firebase = useFirebaseContext();
  const [snapshot, setSnapshot] = useState<
    firebase.database.DataSnapshot | undefined
  >();

  useEffect(() => {
    if (firebase !== null) {
      const listener = query(firebase).on(
        'value',
        (snapshot: firebase.database.DataSnapshot) => {
          setSnapshot(snapshot);
        }
      );

      return query(firebase).off('value', listener);
    }
  }, [firebase, query]);

  return {
    snapshot,
    isLoading: typeof snapshot === 'undefined',
  };
};

/**
 * Base method for read data one time from Firebase realtime database
 *
 * @param {QueryParams} params - the params
 * @returns {QueryResult} the result of query with loading status included
 */
export const useQueryOnce = (params: QueryParams): QueryResult => {
  const { query } = params;
  const firebase = useFirebaseContext();
  const [snapshot, setSnapshot] = useState<
    firebase.database.DataSnapshot | undefined
  >();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (firebase !== null) {
      query(firebase).once(
        'value',
        (snapshot: firebase.database.DataSnapshot) => {
          if (isMounted() === true) {
            setSnapshot(snapshot);
          }
        }
      );
    }
  }, [firebase, query, isMounted]);

  return {
    snapshot,
    isLoading: typeof snapshot === 'undefined',
  };
};

/**
 * Base method for update data into Firebase realtime database
 *
 * @example
 * ```javascript
 * const updateFunc = useUpdate();
 * await updateFunc({ key: value });
 * ```
 */
export const useUpdate = () => {
  const firebase = useFirebaseContext();

  return (updates: Object) => firebase.db.ref().update(updates);
};
