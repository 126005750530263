import React from 'react';
import { Box } from '@material-ui/core';
import Typography from 'modules/typography';

const EmptyCanvasMessageBox = () => {
  return (
    <Box>
      <Typography>
        There are no available canvases. To get started displaying your artwork,
        download the Digital Canvas app on your iOS or Android device.
      </Typography>
    </Box>
  );
};

export default EmptyCanvasMessageBox;
