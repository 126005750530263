import { getSlug } from 'utils/common';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import UILink from 'ui-components/Link';
import { useLoadImage } from 'utils/requests';

interface ArtworkCellProps {
  imageId: string;
}

const ArtworkCell = (props: ArtworkCellProps) => {
  const { imageId } = props;
  const image = useLoadImage(imageId);

  if (!image) {
    return null;
  }

  const { name } = image;

  return (
    <UILink
      component={RouterLink}
      href={generatePath(ROUTES.ARTWORK_DETAILS, {
        imageId,
        name: getSlug(name || 'untitled'),
      })}
      variant={'secondary'}>
      {name}
    </UILink>
  );
};

export default ArtworkCell;
