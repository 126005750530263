import { useState, useEffect } from 'react';
import { useFirebaseContext } from 'shell/firebase';
import { ImageData } from 'constants/data/image.data';
import {
  filterImages as filterImagesBase,
  isPassAssociationFilter,
  isPassColorFilter,
  isPassBrightnessFilter,
  isPassAbstractionFilter,
} from 'utils/common/filterImages';
import { filterPublishedImages } from 'utils/common/filterPublishedImages';
import { ExhibitionData } from 'constants/data/exhibition.data';
import { ModuleKeyInputs } from 'constants/data';

export const useLoadImagesByVenue = (venueId: string) => {
  const firebase = useFirebaseContext();
  const [images, setImages] = useState<ImageData[] | null>();

  useEffect(() => {
    if (firebase && venueId) {
      const query = firebase.imagesRef().orderByChild('venue').equalTo(venueId);
      const stopListener = query.on('value', snapshot => {
        if (!snapshot.exists()) {
          setImages(null);
          return;
        }

        const rawImages = Object.values<ImageData>(snapshot.val());
        const filteredImages = filterPublishedImages(rawImages);
        setImages(filteredImages);
      });
      return () => query.off('value', stopListener);
    }
  }, [firebase, venueId]);

  return { images };
};

export const filterImages = (
  images: ImageData[],
  { associationArr, colorArr, brightnessArr, abstractionArr }: any
) => {
  return filterImagesBase(
    images,
    isPassAssociationFilter(associationArr),
    isPassColorFilter(colorArr),
    isPassBrightnessFilter(brightnessArr),
    isPassAbstractionFilter(abstractionArr)
  );
};

const timeFilters = {
  current: (exhibition: ExhibitionData) =>
    exhibition.startTime <= Date.now() && exhibition.endTime > Date.now(),
  past: (exhibition: ExhibitionData) => exhibition.endTime <= Date.now(),
  future: (exhibition: ExhibitionData) => exhibition.startTime > Date.now(),
};

export const getCurrentExhibitions = (exhibitions: ExhibitionData[]) =>
  exhibitions.filter(timeFilters.current);
export const getFutureExhibitions = (exhibitions: ExhibitionData[]) =>
  exhibitions.filter(timeFilters.future);
export const getPastExhibitions = (exhibitions: ExhibitionData[]) =>
  exhibitions.filter(timeFilters.past);

export const getVenueInfoKey = (venueId: string, moduleId: string) => ({
  logoImage: `venue_${venueId}/${moduleId}_venue_info_logo_image_key`,
  name: `venue_${venueId}/${moduleId}_venue_info_name_key`,
  location: `venue_${venueId}/${moduleId}_venue_info_location_key`,
  email: `venue_${venueId}/${moduleId}_venue_info_email_key`,
  description: `venue_${venueId}/${moduleId}_venue_info_description_key`,
});

export const getVenueHeroBannerKeys = (inputs: ModuleKeyInputs) => {
  const { venueId, moduleId } = inputs;
  const prefix = `venue_${venueId}/${moduleId}_venue_hero_banner_`;

  return {
    textKey: `${prefix}text_key`,
    imageKey: `${prefix}image_key`,
  };
};

export const getVenueSplashScreenBannerKeys = (inputs: ModuleKeyInputs) => {
  const { venueId, moduleId } = inputs;
  const prefix = `venue_${venueId}/${moduleId}_venue_splash_screen_banner_`;

  return {
    imageKey: `${prefix}image_key`,
    textKey: `${prefix}text_key`,
    topLeftLogoKey: `${prefix}top_left_logo_key`,
    topRightLogoKey: `${prefix}top_right_logo_key`,
  };
};

export const getVenueBrowseExhibitionsKeys = (inputs: ModuleKeyInputs) => {
  const { venueId, moduleId } = inputs;
  const prefix = `venue_${venueId}/${moduleId}_venue_browse_exhibitions_`;

  return {
    titleKey: `${prefix}title_key`,
    titleLinkKey: `${prefix}title_link_key`,
    buttonLinkKey: `${prefix}button_link_key`,
    buttonTextKey: `${prefix}button_text_key`,
  };
};

export const getVenueFeaturedMonthKeys = (inputs: ModuleKeyInputs) => {
  const { venueId, moduleId } = inputs;
  const prefix = `venue_${venueId}/${moduleId}_venue_featured_month_`;

  return {
    headKey: `${prefix}_head_key`,
    headTitleKey: `${prefix}_head_title_key`,
    headSubTitleKey: `${prefix}_head_subTitle_key`,
    imageKey: `${prefix}_image_key`,
    infoTitleKey: `${prefix}_info_title_key`,
    infoDescriptionKey: `${prefix}_info_description_key`,
    infoNoteKey: `${prefix}_info_note_key`,
    infoLinkTextKey: `${prefix}_info_link_text_key`,
    infoLinkKey: `${prefix}_info_link_key`,
  };
};

export const getVenueFeaturedStyleKeys = (inputs: ModuleKeyInputs) => {
  const { venueId, moduleId } = inputs;
  const prefix = `venue_${venueId}/${moduleId}_venue_featured_style_`;

  return {
    subtitleKey: `${prefix}section_subtitle`,
    titleKey: `${prefix}section_title`,
    buttonTextKey: `${prefix}section_buttonText`,
    buttonLinkKey: `${prefix}section_buttonLink`,
    editableTextKey: `${prefix}section_name`,
  };
};

export const getVenueProductIntroKeys = (inputs: ModuleKeyInputs) => {
  const { venueId, moduleId } = inputs;
  const prefix = `venue_${venueId}/${moduleId}_product_intro_`;

  return {
    section1Image: `${prefix}_section_1_image_key`,
    section1Subtitle: `${prefix}_section_1_subtitle_key`,
    section1Title: `${prefix}_section_1_title_key`,
    section1ButtonText: `${prefix}_section_1_button_text_key`,
    section1ButtonLinkKey: `${prefix}_section_1_button_link_key`,
    section2Image: `${prefix}_section_2_image_key`,
    section2Subtitle: `${prefix}_section_2_subtitle_key`,
    section2Title: `${prefix}_section_2_title_key`,
    section2ButtonText: `${prefix}_section_2_button_text_key`,
    section2ButtonLinkKey: `${prefix}_section_2_button_link_key`,
  };
};

export const getVenueFeaturedExhibitionKey = (
  venueId: string,
  moduleId: string
) => ({
  titleKey: `venue_${venueId}/${moduleId}_venue_featured_exhibition_title_key`,
  exhibitionNameTextKey: `venue_${venueId}/${moduleId}_venue_featured_exhibition_name_text_key`,
  exhibitionNameLinkKey: `venue_${venueId}/${moduleId}_venue_featured_exhibition_name_link_key`,
  exhibitionImageKey: `venue_${venueId}/${moduleId}_venue_featured_exhibition_image_key`,
  exhibitionTimeTextKey: `venue_${venueId}/${moduleId}_venue_featured_exhibition_time_text_key`,
  exhibitionDescriptionTextKey: `venue_${venueId}/${moduleId}_venue_featured_exhibition_description_text_key`,
  exhibitionCuratedByTextKey: `venue_${venueId}/${moduleId}_venue_featured_exhibition_curated_by_text_key`,
  exhibitionAddressTextKey: `venue_${venueId}/${moduleId}_venue_featured_exhibition_address_text_key`,
  exhibitionAddressLinkKey: `venue_${venueId}/${moduleId}_venue_featured_exhibition_address_link_key`,
});

export const getTopBarKey = (
  venueId: string,
  moduleId: string,
  index?: number
) => ({
  logo: `venue_${venueId}/${moduleId}_venue_top_bar_logo_key`,
  menuItemTextKey: `venue_${venueId}/${moduleId}_venue_menu_item_${index}_text_key`,
  menuItemLinkKey: `venue_${venueId}/${moduleId}_venue_menu_item_${index}_link_key`,
});

export const getFooterKey = (venueId: string, moduleId: string) => {
  const navKeys = Array.from(Array(3).keys()).reduce(
    (list: Record<string, string>, colIndex: number) => {
      const navItems = Array.from(Array(4).keys()).reduce(
        (listNav: Record<string, string>, itemIndex) => {
          return {
            ...listNav,
            [`nav${colIndex}_item${itemIndex}_text`]: `venue_${venueId}/${moduleId}_venue_footer_nav${colIndex}_item${itemIndex}_text_key`,
            [`nav${colIndex}_item${itemIndex}_link`]: `venue_${venueId}/${moduleId}_venue_footer_nav${colIndex}_item${itemIndex}_link_key`,
          };
        },
        {}
      );

      return {
        ...list,
        [`nav${colIndex}_label`]: `venue_${venueId}/${moduleId}_venue_footer_nav${colIndex}_label_key`,
        ...navItems,
      };
    },
    {}
  );

  const bottomNavKeys = Array.from(Array(3).keys()).reduce(
    (listNav: Record<string, string>, itemIndex) => {
      return {
        ...listNav,
        [`bottom_item${itemIndex}_text`]: `venue_${venueId}/${moduleId}_venue_footer_bottom_item${itemIndex}_text_key`,
        [`bottom_item${itemIndex}_link`]: `venue_${venueId}/${moduleId}_venue_footer_bottom_item${itemIndex}_link_key`,
      };
    },
    {}
  );

  const socialKeys = Array.from(Array(5).keys()).reduce(
    (listNav: Record<string, string>, itemIndex) => {
      return {
        ...listNav,
        [`social_item${itemIndex}`]: `venue_${venueId}/${moduleId}_venue_footer_social_item${itemIndex}_key`,
      };
    },
    {}
  );

  return {
    logo: `venue_${venueId}/${moduleId}_venue_footer_logo_key`,
    bottomLogo: `venue_${venueId}/${moduleId}_venue_footer_bottom_logo_key`,
    bottomText: `venue_${venueId}/${moduleId}_venue_footer_bottom_key`,
    newsLetterText: `venue_${venueId}/${moduleId}_venue_footer_newsletter_text_key`,
    newsLetterBtnText: `venue_${venueId}/${moduleId}_venue_footer_newsletter_btn_text_key`,
    newsLetterLink: `venue_${venueId}/${moduleId}_venue_footer_newsletter_link_key`,
    ...navKeys,
    ...socialKeys,
    ...bottomNavKeys,
  };
};
