import pw from 'a-promise-wrapper';

export const urltoFile = async (url: any, filename: any, mimeType: any) => {
  const { data, error } = await pw(
    fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      })
  );

  if (error) {
    throw error;
  }
  return data;
};
