import { Box, Button, Chip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { ExtendedTransactionVenueData } from 'constants/data';
import Typography from 'modules/typography';
import { Column, CellProps } from 'react-table';
import { DATE_FORMAT_DAY, formatDate } from 'utils/common';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import getEnvVars from 'utils/common/envVars';
import HeaderWithTooptip from './components/HeaderWithTooptip';
import TxArtworkTypeCell from './components/TxArtworkTypeCell';
import UserCell from './components/UserCell';
import VenueCell from './components/VenueCell';
import ArtworkCell from './components/ArtworkCell';
import PriceCell from './components/PriceCell';

import UIButton from 'ui-components/Button';

export const COLUMN_SALES_REPORT_SELLER: Column<ExtendedTransactionVenueData> =
  {
    accessor: 'sellerId',
    Header: () => {
      const tooltip =
        'Seller is the person that transfers ownership of the unique digital item to the new owner.';

      return <HeaderWithTooptip title="Seller" tooltip={tooltip} />;
    },
    width: 275,
    disableSortBy: true,
    Cell: ({ row }: CellProps<ExtendedTransactionVenueData>) => {
      const tx = row.original;
      const { sellerId } = tx;

      return <UserCell userId={sellerId} />;
    },
  };

export const COLUMN_SALES_REPORT_BUYER: Column<ExtendedTransactionVenueData> = {
  accessor: 'buyerId',
  Header: () => {
    const tooltip =
      'Buyer possesses ownership for the unique digital item that is encoded into a blockchain.';

    return <HeaderWithTooptip title="Buyer" tooltip={tooltip} />;
  },
  width: 275,
  disableSortBy: true,
  Cell: ({ row }: CellProps<ExtendedTransactionVenueData>) => {
    const tx = row.original;
    const { buyerId } = tx;

    return <UserCell userId={buyerId} />;
  },
};

export const COLUMN_SALES_REPORT_ITEM_TYPE: Column<ExtendedTransactionVenueData> =
  {
    accessor: 'originalArtworkId',
    Header: () => {
      const tooltip = 'It is either edition or original artwork';

      return <HeaderWithTooptip title="Type" tooltip={tooltip} />;
    },
    width: 145,
    disableSortBy: true,
    Cell: ({ row }: CellProps<ExtendedTransactionVenueData>) => {
      const tx = row.original;

      return <TxArtworkTypeCell tx={tx} />;
    },
  };

export const COLUMN_SALES_REPORT_TIMESTAMPE: Column<ExtendedTransactionVenueData> =
  {
    accessor: 'createdDate',
    Header: () => {
      const tooltip = 'Timestamp of creating and completing';

      return <HeaderWithTooptip title="Timestamp" tooltip={tooltip} />;
    },
    width: 225,
    Cell: ({ row }: CellProps<ExtendedTransactionVenueData>) => {
      const tx = row.original;
      const { createdDate, completedDate } = tx;
      const isInvalid = !createdDate;

      if (isInvalid) {
        return <Alert severity="error" />;
      }

      return (
        <Box>
          <Typography>
            Created: {formatDate(createdDate, DATE_FORMAT_DAY)}
          </Typography>
          {!!completedDate && (
            <Typography>
              Completed: {formatDate(completedDate, DATE_FORMAT_DAY)}
            </Typography>
          )}
        </Box>
      );
    },
  };

export const COLUMN_SALES_REPORT_OWNERSHIP: Column<ExtendedTransactionVenueData> =
  {
    accessor: 'blockchainTransactionHash',
    Header: () => {
      const tooltip =
        'Record of transfer is a unique string of characters that is given to every transaction that is verified and added to the blockchain.';

      return <HeaderWithTooptip title="Record" tooltip={tooltip} />;
    },
    width: 130,
    disableSortBy: true,
    Cell: ({ row }: CellProps<ExtendedTransactionVenueData>) => {
      const { arbiscanDomain } = getEnvVars();

      const tx = row.original;
      const { blockchainTransactionHash } = tx;
      const href = `${arbiscanDomain}/tx/${blockchainTransactionHash}`;

      if (!blockchainTransactionHash) {
        return null;
      }

      return (
        <Button
          href={href}
          target="_blank"
          variant="text"
          endIcon={<OpenInNewIcon />}>
          View
        </Button>
      );
    },
  };

export const COLUMN_SALES_REPORT_ARTIST: Column<ExtendedTransactionVenueData> =
  {
    accessor: 'artistId',
    Header: () => {
      const tooltip =
        'Artist is the person who upload the artwork into the system';

      return <HeaderWithTooptip title="Artist" tooltip={tooltip} />;
    },
    width: 275,
    Cell: ({ row }: CellProps<ExtendedTransactionVenueData>) => {
      const tx = row.original;
      const { artistId } = tx;

      if (!artistId) {
        return null;
      }

      return <UserCell userId={artistId} />;
    },
  };

export const COLUMN_SALES_REPORT_VENUE: Column<ExtendedTransactionVenueData> = {
  accessor: 'venueId',
  Header: () => {
    const tooltip = 'The artwork may belong to one venue or not';

    return <HeaderWithTooptip title="Venue" tooltip={tooltip} />;
  },
  Cell: ({ row }: CellProps<ExtendedTransactionVenueData>) => {
    const tx = row.original;
    const { venueId } = tx;

    if (!venueId) {
      return null;
    }

    return <VenueCell venueId={venueId} />;
  },
};

export const COLUMN_SALES_REPORT_ARTWORK: Column<ExtendedTransactionVenueData> =
  {
    accessor: 'imageId',
    Header: () => {
      const tooltip = 'The artwork being transfered';

      return <HeaderWithTooptip title="Artwork" tooltip={tooltip} />;
    },
    disableSortBy: true,
    Cell: ({ row }: CellProps<ExtendedTransactionVenueData>) => {
      const tx = row.original;

      return <ArtworkCell imageId={tx.imageId} />;
    },
  };

export const COLUMN_SALES_REPORT_PRICE: Column<ExtendedTransactionVenueData> = {
  accessor: 'price',
  Header: () => {
    const tooltip = 'The price of being sold artwork';

    return <HeaderWithTooptip title="Price" tooltip={tooltip} />;
  },
  Cell: ({ row }: CellProps<ExtendedTransactionVenueData>) => {
    const tx = row.original;

    return <PriceCell tx={tx} />;
  },
};

export const COLUMN_SALES_REPORT_PAYOUT: Column<ExtendedTransactionVenueData> =
  {
    accessor: 'paidToArtist',
    Header: () => {
      const tooltip = 'Determine whether artist has been paid or not';

      return <HeaderWithTooptip title="Paid out" tooltip={tooltip} />;
    },
    Cell: ({ row }: CellProps<ExtendedTransactionVenueData>) => {
      const tx = row.original;
      const { paidToArtist } = tx;

      if (!paidToArtist) {
        return <Chip color="secondary" label="No" />;
      }

      return <Chip color="primary" label="Yes" />;
    },
  };

export const COLUMN_SALES_REPORT_ID: Column<ExtendedTransactionVenueData> = {
  accessor: 'uid',
  disableSortBy: true,
  Header: () => {
    const tooltip = '';

    return <HeaderWithTooptip title="Action" tooltip={tooltip} />;
  },
  Cell: ({ row }: CellProps<ExtendedTransactionVenueData>) => {
    const tx = row.original;
    const { uid } = tx;
    const onCopy = () => {
      uid && navigator.clipboard.writeText(uid);
      alert('Successfully copy transaction id into clipboard');
    };

    return (
      <UIButton variant="text" size="medium" text="Copy ID" onClick={onCopy} />
    );
  },
};
