import React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  IconButton,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FilterListIcon from '@material-ui/icons/FilterList';
import ColorSelect from 'modules/colorSelect';
import { FilterValues } from 'modules/filter';
import { ImageData } from 'constants/data/image.data';

import Filter, {
  FilterOption,
  CatalogOption,
  KeywordOption,
  BrightnessOption,
  AbstractionOption,
  ImageStatusOption,
  ClearFilter,
} from 'modules/filter';

interface Props {
  onChange: any;
  defaultValues: FilterValues;
  originalList: ImageData[];
}

const ImagesFilter = ({ onChange, defaultValues, originalList }: Props) => {
  const classes = useStyles();
  return (
    <Filter
      onChange={onChange}
      originalList={originalList}
      defaultValues={defaultValues}
      className={classes.root}>
      <FilterListIcon />
      <Box className={classes.filterOptionWraper}>
        <FilterOption
          name="colorArr"
          render={renderProps => <ColorSelect {...renderProps} />}
        />
        <FilterOption
          name="brightnessArr"
          render={renderProps => (
            <Box>
              <BrightnessOption {...renderProps} />
            </Box>
          )}
        />
        <FilterOption
          name="abstractionArr"
          render={renderProps => (
            <Box>
              <AbstractionOption {...renderProps} />
            </Box>
          )}
        />
        <FilterOption
          name="catalogArr"
          render={renderProps => <CatalogOption {...renderProps} />}
        />
        <FilterOption
          name="associationArr"
          render={renderProps => <KeywordOption {...renderProps} />}
        />
        <FilterOption
          name="isPublished"
          render={renderProps => (
            <Box>
              <ImageStatusOption {...renderProps} />
            </Box>
          )}
        />
      </Box>
      <ClearFilter
        render={({ clearFilter }) => (
          <IconButton
            onClick={clearFilter}
            color="primary"
            size="small"
            title="clear filter">
            <HighlightOffIcon />
          </IconButton>
        )}
      />
    </Filter>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    filterOptionWraper: {
      flexGrow: 1,
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
  })
);
export default ImagesFilter;
