import { useFirebaseContext } from 'shell/firebase';
import { ImageData } from 'constants/data/image.data';

export const usePublishImages = () => {
  const firebase = useFirebaseContext();
  return (images: ImageData[]) => {
    const updates = images.reduce<Record<string, any>>((acc, image) => {
      const {
        initialNumOfPrintsForSale,
        uid: imageId,
        artist,
        printPriceAmount,
        numberOfPrints,
      } = image;

      acc[`images/${image.uid}/published`] = true;

      // edition index start from 1, e.g. [1,2,3,4...]
      const editionIndexes = Array.from(
        { length: numberOfPrints },
        (_, i) => i + 1
      );
      editionIndexes.forEach(editionIndex => {
        const printKey = firebase.printsRef().push().key;
        const timestamp = Date.now();
        const notForSale = !!initialNumOfPrintsForSale
          ? initialNumOfPrintsForSale < editionIndex
          : true;
        const print = {
          imageId,
          editionIndex,
          owner: artist,
          price: printPriceAmount || null,
          notForSale,
          publishedDate: timestamp,
        };

        acc[`prints/${printKey}`] = print;
        acc[`${firebase.artworkPrints(imageId)}/${printKey}`] = print;
        acc[`users/${artist}/editions/${printKey}`] = imageId;
      });

      return acc;
    }, {});

    return firebase.db.ref().update(updates);
  };
};
