export interface ISize {
  width: number;
  height: number;
}

/**
 * Return the actual width of the image to display on the selection box
 */
export const calculateScale = ({
  imageBoxSize,
  canvasSize,
}: {
  imageBoxSize: ISize;
  canvasSize: ISize;
}) => {
  const { width: imageW, height: imageH } = imageBoxSize;
  const { width: canvasW, height: canvasH } = canvasSize;

  //  if (imageW < canvasW && imageH < canvasH) {
  //    return { maxScale: 1, minScale: 1 };
  //  }
  //
  //  if (imageW < canvasW) {
  //    const scale = canvasH / imageH;
  //    return {
  //      maxScale: 1,
  //      minScale: scale,
  //    };
  //  }
  //
  //  if (imageH < canvasH) {
  //    const scale = canvasW / imageW;
  //    return {
  //      maxScale: 1,
  //      minScale: scale,
  //    };
  //  }

  const maxScale = Math.max(canvasW / imageW, canvasH / imageH);
  const minScale = Math.min(canvasW / imageW, canvasH / imageH);

  return {
    maxScale,
    minScale,
  };
};

export const calculateTranslate = ({
  imageBoxSize,
  canvasSize,
  currentScale,
}: {
  imageBoxSize: ISize;
  canvasSize: ISize;
  currentScale: number;
}) => {
  const imageW = imageBoxSize.width * currentScale;
  const imageH = imageBoxSize.height * currentScale;

  const { width: canvasW, height: canvasH } = canvasSize;

  const translateX = (canvasW - imageW) / 2;
  const translateY = (canvasH - imageH) / 2;

  let minX, minY, maxX, maxY;

  if (imageW < canvasW && imageH < canvasH) {
    minX = maxX = translateX;
    minY = maxY = translateY;
  } else if (imageW < canvasW) {
    minX = 0;
    maxX = canvasW - imageW;
    minY = canvasH - imageH;
    maxY = 0;
  } else if (imageH < canvasH) {
    minX = canvasW - imageW;
    maxX = 0;
    minY = 0;
    maxY = canvasH - imageH;
  } else {
    minX = canvasW - imageW;
    maxX = 0;
    minY = canvasH - imageH;
    maxY = 0;
  }

  return {
    translateX,
    translateY,
    maxY,
    maxX,
    minX,
    minY,
  };
};

export const calculateMiddleTranslate = ({
  imageBoxSize,
  canvasSize,
  currentScale,
}: {
  imageBoxSize: ISize;
  canvasSize: ISize;
  currentScale: number;
}) => {
  const imageW = imageBoxSize.width * currentScale;
  const imageH = imageBoxSize.height * currentScale;

  const { width: canvasW, height: canvasH } = canvasSize;

  const translateX = (canvasW - imageW) / 2;
  const translateY = (canvasH - imageH) / 2;
  return {
    translateX,
    translateY,
  };
};
