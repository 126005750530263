import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  DialogProps,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import Typography from 'modules/typography';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from './images/success_icon.png';

const CreatedNewUserDialog = (props: DialogProps) => {
  const classes = useStyles();
  const { ...dialogProps } = props;

  const handleClose = () => {
    if (dialogProps.onClose) {
      dialogProps.onClose({}, 'backdropClick');
    }
  };

  return (
    <Dialog
      {...dialogProps}
      maxWidth="sm"
      disableEscapeKeyDown
      BackdropProps={{ invisible: true }}
      classes={{
        container: classes.container,
        root: classes.root,
        paper: classes.paper,
      }}>
      <DialogContent className={classes.dialogContent}>
        <CloseIcon onClick={handleClose} className={classes.closeButton} />
        <Box className={classes.content}>
          <img
            src={SuccessIcon}
            className={classes.successIcon}
            alt="Successfully verified"
          />
          <Box>
            <Typography lineHeight={1.6} fontWeight={500}>
              Success
            </Typography>
            <Typography fontSize={14} lineHeight={1.6}>
              Your email has been successfully verified
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'flex-end',
    },
    root: {
      backgroundColor: 'transparent',
    },
    paper: {
      backgroundColor: '#EFFBEE',
      maxWidth: 800,
      boxShadow: 'none',
      borderLeft: '4px solid #3FBB35',
    },
    dialogContent: {
      padding: theme.spacing(3, 8, 3, 3),
      position: 'relative',
    },
    leftDialogTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    successIcon: {
      display: 'inline-block',
      width: 24,
      height: 24,
      marginRight: theme.spacing(1.75),
      position: 'relative',
    },
    closeButton: {
      cursor: 'pointer',
      position: 'absolute',
      right: 24,
      top: 24,
      fontSize: '1rem',
    },
    content: {
      display: 'flex',
    },
  })
);

export default CreatedNewUserDialog;
