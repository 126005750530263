import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
} from '@material-ui/core';
import Typography from 'modules/typography';
import Tooltip from 'modules/tooltip';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  ImageData,
  TransactionData,
  UserData,
  WalletData,
} from 'constants/data';
import BlackInfoIcon from 'static/images/black-info-icon.svg';
import { useIsMobile } from 'utils/hooks';
import { useFirebaseContext } from 'shell/firebase';
import { useObjectVal } from 'react-firebase-hooks/database';
import getEnvVars from 'utils/common/envVars';
import { useGetPrintFromId } from 'utils/requests';

interface ProofOfOwnershipProps {
  image: ImageData;
  transactionId: string;
  printId?: string;
  hideTitle?: boolean;
  isGift?: boolean;
}

const { arbiscanDomain } = getEnvVars();

const useGetUser = (userId?: string) => {
  const firebase = useFirebaseContext();
  const [user, setUser] = useState<UserData | null>();

  useEffect(() => {
    if (userId) {
      const query = firebase.userRef(userId);
      query.once(
        'value',
        snapshot => {
          setUser(snapshot.val());
        },
        () => {
          setUser(null);
        }
      );
    }
  }, [firebase, userId]);

  return { user, isLoading: user === undefined };
};

const getTokenId = (udId?: string, index?: number): string => {
  // tokenId is an uint256 number. The max uint256 number convert to hex form has 64 characters
  // We are constructing a 64-char hex string from udId and index

  // index number for origin artwork is 1
  // index number for print is editionIndex + 1
  if (udId && index) {
    const typeBits = udId.padStart(32, '0');
    const indexBits = index.toString(16).padStart(32, '0');
    return '0x' + typeBits + indexBits;
  }
  return 'pending blockchain transaction...';
};

const redirectToWalletInArbiscan = (walletId: string) =>
  `${arbiscanDomain}/address/${walletId}`;

const TooltipWithIconInfo = ({
  className,
  title,
}: {
  className: string;
  title: string;
}) => {
  return (
    <Tooltip title={title}>
      <img src={BlackInfoIcon} alt="More info" className={className} />
    </Tooltip>
  );
};

const getWallet = (w: WalletData | string) => {
  if (typeof w === 'object') {
    return Object.keys(w)[0];
  }

  return w;
};

const ProofOfOwnership = ({
  image,
  transactionId,
  printId,
  hideTitle = false,
  isGift = false,
}: ProofOfOwnershipProps) => {
  const firebase = useFirebaseContext();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const [transaction] = useObjectVal<TransactionData>(
    firebase.transactionsRefByKey(transactionId)
  );
  const [buyerWallet, isBuyerWalletIdLoading] = useObjectVal<
    WalletData | string
  >(transaction?.buyerId ? firebase.walletRefByKey(transaction.buyerId) : null);
  const [sellerWallet, isSellerWalletIdLoading] = useObjectVal<
    WalletData | string
  >(
    transaction?.sellerId ? firebase.walletRefByKey(transaction.sellerId) : null
  );

  const buyerWalletId =
    isBuyerWalletIdLoading || !buyerWallet ? '' : getWallet(buyerWallet);
  const sellerWalletId =
    isSellerWalletIdLoading || !sellerWallet ? '' : getWallet(sellerWallet);

  const { print } = useGetPrintFromId(printId || transaction?.completedPrintId);
  const { user: buyer } = useGetUser(transaction?.buyerId);
  const { user: seller } = useGetUser(transaction?.sellerId);
  const { user: creator } = useGetUser(image.artist);

  if (!transaction) {
    return <CircularProgress />;
  }

  const title = isGift ? 'Transfer of Ownership' : 'Proof of Ownership';
  const timeLabel = isGift ? 'Time of transfer' : 'Time of sale';
  const salePrice = isGift
    ? 'N/A'
    : transaction.price
    ? `€${transaction.price}`
    : 'N/A';
  const dateLabel = transaction?.completedDate
    ? new Date(transaction?.completedDate).toUTCString()
    : 'pending blockchain transaction...';
  const sellerLabel = isGift ? 'Sender' : 'Seller';
  const sellerTooltip = isGift
    ? 'Sender is the person that transfers ownership of the unique digital item to the new owner.'
    : 'Seller is the person that transfers ownership of the unique digital item to the new owner.';
  const txHashLabel = isGift ? 'Record of transfer' : 'Record of sale';
  const txHashTooltip = isGift
    ? 'Record of transfer is a unique string of characters that is given to every transaction that is verified and added to the blockchain.'
    : 'Record of sale is a unique string of characters that is given to every transaction that is verified and added to the blockchain.';

  return (
    <Box data-transaction={transactionId}>
      {!hideTitle && (
        <Typography
          fontWeight={500}
          fontSize={'1.25rem'}
          lineHeight={'135%'}
          letterSpacing={'-0.02em'}>
          {title}
        </Typography>
      )}
      <TableContainer className={classes.table}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} className={classes.cell}>
                <Box display={'flex'} justifyContent={'flex-start'}>
                  <Typography className={classes.title}>Owner</Typography>
                  <TooltipWithIconInfo
                    title={
                      'Owner possesses ownership for the unique digital item that is encoded into a blockchain.'
                    }
                    className={classes.infoIcon}
                  />
                </Box>
                <Box className={classes.value}>
                  <Typography component={'span'} className={classes.text}>
                    {buyer?.fullName}{' '}
                    <span>{isBuyerWalletIdLoading ? '' : buyerWalletId}</span>
                    <a
                      href={redirectToWalletInArbiscan(buyerWalletId || '')}
                      target={'__blank'}>
                      <OpenInNewIcon className={classes.openInNewIcon} />
                    </a>
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                className={cn(
                  classes.cell,
                  !isMobile && classes.halfBorderCell
                )}
                colSpan={isMobile ? 2 : 1}>
                <Typography className={classes.title}>Artwork name</Typography>
                <Typography
                  component={'span'}
                  className={cn(classes.value, classes.text)}>
                  {transaction.completedPrintId &&
                    `${image.name}, edition #${print?.editionIndex || 0} of ${
                      image.numberOfPrints
                    }`}
                  {transaction.completedOriginalId && `${image.name}, original`}
                </Typography>
              </TableCell>

              {!isMobile && (
                <TableCell className={classes.cell}>
                  <Typography className={classes.title}>Creator</Typography>
                  <Typography
                    component={'span'}
                    className={cn(classes.value, classes.text)}>
                    {creator?.fullName}
                  </Typography>
                </TableCell>
              )}
            </TableRow>

            {isMobile && (
              <TableRow>
                <TableCell className={classes.cell} colSpan={2}>
                  <Typography className={classes.title}>Creator</Typography>
                  <Typography
                    component={'span'}
                    className={cn(classes.value, classes.text)}>
                    {creator?.fullName}
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell
                className={cn(
                  classes.cell,
                  !isMobile && classes.halfBorderCell
                )}
                colSpan={isMobile ? 2 : 1}>
                <Typography className={classes.title}>Sale price</Typography>
                <Typography
                  component={'span'}
                  className={cn(classes.value, classes.text)}>
                  {salePrice}
                </Typography>
              </TableCell>
              {!isMobile && (
                <TableCell className={classes.cell}>
                  <Typography className={classes.title}>{timeLabel}</Typography>
                  <Typography
                    component={'span'}
                    className={cn(classes.value, classes.text)}>
                    {dateLabel}
                  </Typography>
                </TableCell>
              )}
            </TableRow>

            {isMobile && (
              <TableRow>
                <TableCell className={classes.cell} colSpan={2}>
                  <Typography className={classes.title}>{timeLabel}</Typography>
                  <Typography
                    component={'span'}
                    className={cn(classes.value, classes.text)}>
                    {dateLabel}
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell colSpan={2} className={classes.cell}>
                <Box display={'flex'} justifyContent={'flex-start'}>
                  <Typography className={classes.title}>
                    {sellerLabel}
                  </Typography>
                  <TooltipWithIconInfo
                    title={sellerTooltip}
                    className={classes.infoIcon}
                  />
                </Box>
                <Box className={classes.value}>
                  <Typography component={'span'} className={classes.text}>
                    {seller ? (
                      <>
                        {seller.fullName}{' '}
                        <span>
                          {isSellerWalletIdLoading
                            ? 'Loading wallets id'
                            : sellerWalletId}
                        </span>
                        <a
                          href={redirectToWalletInArbiscan(
                            sellerWalletId || ''
                          )}
                          target={'__blank'}>
                          <OpenInNewIcon className={classes.openInNewIcon} />
                        </a>
                      </>
                    ) : (
                      ''
                    )}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2} className={classes.cell}>
                <Box display={'flex'} justifyContent={'flex-start'}>
                  <Typography className={classes.title}>
                    {txHashLabel}
                  </Typography>
                  <TooltipWithIconInfo
                    className={classes.infoIcon}
                    title={txHashTooltip}
                  />
                </Box>
                <Box className={classes.value}>
                  {transaction?.blockchainTransactionHash ? (
                    <Typography component={'span'} className={cn(classes.text)}>
                      {transaction?.blockchainTransactionHash}
                      <a
                        href={`${arbiscanDomain}/tx/${
                          transaction?.blockchainTransactionHash || ''
                        }`}
                        target={'__blank'}>
                        <OpenInNewIcon className={classes.openInNewIcon} />
                      </a>
                    </Typography>
                  ) : (
                    'pending blockchain transaction...'
                  )}
                </Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2} className={classes.cell}>
                <Box display={'flex'} justifyContent={'flex-start'}>
                  <Typography className={classes.title}>Token ID</Typography>
                  <TooltipWithIconInfo
                    className={classes.infoIcon}
                    title={
                      'Token ID is the unique identifier code for digital collectible.'
                    }
                  />
                </Box>
                <Box className={classes.value}>
                  <Typography component={'span'} className={classes.text}>
                    {getTokenId(
                      image.udId,
                      print && transaction?.completedPrintId
                        ? print.editionIndex + 1
                        : 1
                    )}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={cn(classes.halfBorderCell, classes.cell)}>
                <Box display={'flex'} justifyContent={'flex-start'}>
                  <Typography className={classes.title}>
                    Token standard
                  </Typography>
                  <TooltipWithIconInfo
                    className={classes.infoIcon}
                    title={
                      'A token standard is an interface, and a set of rules, that a smart contract must respect to be compatible with the common standards.'
                    }
                  />
                </Box>
                <Typography
                  component={'span'}
                  className={cn(classes.value, classes.text)}>
                  ERC-1155
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Box display={'flex'} justifyContent={'flex-start'}>
                  <Typography className={classes.title}>Blockchain</Typography>
                  <TooltipWithIconInfo
                    className={classes.infoIcon}
                    title={
                      'Arbitrum is a layer 2 solution, boosting speed and scalability on the Ethereum blockchain.'
                    }
                  />
                </Box>
                <Typography
                  component={'span'}
                  className={cn(classes.value, classes.text)}>
                  Arbitrum One
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    infoIcon: {
      width: 14,
      height: 14,
      cursor: 'pointer',
    },
    table: {
      border: '1px solid #000',
      borderBottom: 0,
      marginTop: theme.spacing(1.5),
    },
    halfBorderCell: {
      'position': 'relative',
      '&::after': {
        width: 1,
        height: 15,
        content: `""`,
        position: 'absolute',
        right: 0,
        bottom: 0,
        background: 'black',
      },
    },

    title: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(10),
      lineHeight: '160%',
      margin: theme.spacing(0.625),
    },
    value: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(0.75),
    },
    cell: {
      width: '50%',
      borderBottom: '1px solid #000',
      padding: theme.spacing(0.25, 0.5, 1, 0.5),
    },
    text: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: '150%',
      wordBreak: 'break-word',
    },
    openInNewIcon: {
      color: 'black',
      width: 10,
      height: 10,
      marginLeft: theme.spacing(1.25),
      cursor: 'pointer',
    },
  });
});

export default ProofOfOwnership;
