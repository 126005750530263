import Typography from 'modules/typography';

const AIPoweredBy = () => {
  return (
    <Typography fontWeight={400} fontSize="11px" lineHeight={1.5}>
      POWERED BY ACTIVE IMAGE
    </Typography>
  );
};

export default AIPoweredBy;
