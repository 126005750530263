import React from 'react';
import cn from 'classnames';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import Typography from 'modules/typography';
import { CanvasData, ExhibitionData } from 'constants/data';
import { getGiftExpireDays, getSlug } from 'utils/common';
import useGetTransaction from 'utils/requests/useGetTransaction';
import UILink from 'ui-components/Link';

type Props = {
  enableForSale: boolean;
  playingCanvas?: CanvasData | null;
  exhibition?: ExhibitionData;
  isFreeSlot: boolean;
  waitingGiftTx: string | undefined;
};

const Status = ({
  enableForSale,
  playingCanvas,
  exhibition,
  isFreeSlot,
  waitingGiftTx,
}: Props) => {
  const styles = useStyles();
  const activeTypes = [enableForSale ? 'Sales' : null]
    .filter(Boolean)
    .join(', ');

  const { transaction } = useGetTransaction({
    txId: waitingGiftTx || '',
  });
  const hasWaitingGift = !!transaction;

  if (playingCanvas) {
    return (
      <>
        <Typography
          component={'span'}
          className={cn(styles.status, styles.truncate)}>
          <span className={styles.circle} />
          Playing on {playingCanvas.meta?.deviceName || ''}
        </Typography>
        {exhibition && isFreeSlot && (
          <Typography className={cn(styles.type, styles.truncate)}>
            as part of{' '}
            <UILink
              component={RouterLink}
              href={generatePath(ROUTES.EXHIBITION, {
                id: exhibition.uid,
                name: getSlug(exhibition.name),
              })}
              variant={'secondary'}>
              {exhibition.name}
            </UILink>
          </Typography>
        )}
      </>
    );
  }

  if (enableForSale) {
    return (
      <>
        <Typography className={cn(styles.status, styles.truncate)}>
          {`Active`}
        </Typography>
        <Typography className={cn(styles.type, styles.truncate)}>
          {activeTypes}
        </Typography>
      </>
    );
  }

  if (hasWaitingGift) {
    const { createdDate, giftReceiverEmail } = transaction;
    const days = getGiftExpireDays(createdDate);
    const postfix = days === 1 ? 'day' : 'days';
    const expireLabel = `(expires in ${days} ${postfix})`;

    return (
      <>
        <Typography className={cn(styles.status, styles.truncate)}>
          {`Sent as Gift ${expireLabel}`}
        </Typography>
        {!!giftReceiverEmail && (
          <Typography className={cn(styles.type, styles.truncate)}>
            to {giftReceiverEmail}
          </Typography>
        )}
      </>
    );
  }

  return (
    <>
      <Typography className={cn(styles.status, styles.truncate)}>
        {`Personal use`}
      </Typography>
      <Typography className={cn(styles.type, styles.truncate)}>
        {'n/a'}
      </Typography>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    status: {
      fontSize: theme.typography.pxToRem(12),
      color: '#000000',
    },
    type: {
      fontSize: theme.typography.pxToRem(12),
      color: '#616161',
    },
    truncate: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    circle: {
      marginRight: theme.spacing(1),
      display: 'inline-block',
      width: 8,
      height: 8,
      backgroundColor: '#2FBE34',
      borderRadius: '50%',
    },
  });
});

export default Status;
