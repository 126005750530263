import { NavLink, useLocation } from 'react-router-dom';
import { UserData } from 'constants/data';
import { useEffect, useRef, useState } from 'react';
import OptimizedImage from 'modules/OptimizedImage';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import HamburgerButton from 'modules/topBar/HamburgerButton';
import NavMain from './NavMain';
import NavUser from 'modules/topBar/NavUser';
import { motion } from 'framer-motion';
import { mobileMediaQuery } from 'utils/common';
import { useIsMobile } from 'utils/hooks';
import { useAuthContext } from 'shell/session';
import EditableImage from 'modules/editableImages/EditableImage';
import { getTopBarKey } from '../helpers';
import { LANDING } from 'constants/routes';

interface TopBarProps {
  moduleId: string;
  venueId: string;
}

const TopBar = ({ moduleId, venueId }: TopBarProps) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const location = useLocation();
  const authContext = useAuthContext();
  const user = authContext.user as UserData | undefined | null;
  const isLoading = user === undefined;
  const showHamburger = isMobile && !isLoading;
  const { logo: logoKey } = getTopBarKey(venueId, moduleId);
  const mobileNavInner = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isMobile) {
      setIsOpen(false);
    }
  }, [location.pathname, isMobile]);
  const mobileNavVariants = {
    slide: () => ({
      height: isOpen ? mobileNavInner?.current?.clientHeight : 0,
      transition: {
        duration: 0.3,
      },
    }),
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.topBar}>
        <EditableImage
          imageKey={logoKey}
          render={({ editableImage }) => {
            return editableImage ? (
              <NavLink
                to={editableImage?.url || LANDING}
                className={classes.topBarLogo}>
                <OptimizedImage
                  path={editableImage.path}
                  alt={editableImage.alt}
                  className={classes.logo}
                />
              </NavLink>
            ) : null;
          }}
        />
        <Box className={classes.topBarRight}>
          {showHamburger && (
            <Box className={classes.hamburgerIcon}>
              <HamburgerButton
                isOpen={isOpen}
                toggleButton={isOpen => setIsOpen(isOpen)}
              />
            </Box>
          )}
          {!isMobile && !isLoading && (
            <NavMain moduleId={moduleId} venueId={venueId} />
          )}
          <NavUser />
        </Box>
      </Box>
      {showHamburger && (
        <motion.div
          className={classes.mobileNav}
          variants={mobileNavVariants}
          animate="slide">
          <div ref={mobileNavInner}>
            <NavMain moduleId={moduleId} venueId={venueId} />
          </div>
        </motion.div>
      )}
    </Box>
  );
};

export const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    container: {
      position: 'relative',
      width: '100%',
      maxWidth: 1440,
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    topBar: {
      height: '100px',
      width: '100%',
      maxWidth: '1440px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      [mobile]: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
      },
    },
    topBarLogo: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      maxWidth: 135,
      maxHeight: 68,
    },
    logoText: {
      fontSize: '1rem',
      lineHeight: '1rem',
      marginLeft: '10px',
      color: '#000',
    },
    topBarRight: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    hamburgerIcon: {
      display: 'block',
      width: '20px',
      height: '30px',
    },
    mobileNav: {
      backgroundColor: '#FFF',
      overflow: 'hidden',
      width: '100%',
    },
  });
});

export default TopBar;
