import React, { useState } from 'react';
import { useModalState } from 'utils/hooks';
import {
  Box,
  makeStyles,
  useTheme,
  createStyles,
  TextField,
  useMediaQuery,
  ListSubheader,
  Popover,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ReactComponent as DropdownIcon } from 'static/images/dropdown.svg';
import { VariableSizeList } from 'react-window';
import Typography from 'modules/typography';

interface FilterAutocompleteProps {
  value: string[];
  onChange: (values: (string | number)[]) => void;
  options: FilterOption[];
  renderValue?: (value: string[]) => string;
}

export interface FilterOption {
  value: string | number;
  label: string;
}

/**
 * This component source code is from here:
 * https://material-ui.com/components/autocomplete/#virtualization
 */

const useStyles = makeStyles(theme => {
  return createStyles({
    selectRoot: {
      display: 'flex',
      alignItems: 'center',
    },
    label: {
      whiteSpace: 'pre-wrap',
      color: '#000000',
      fontSize: '1rem',
      fontWeight: 500,
      textTransform: 'capitalize',
    },
    popoverContent: {
      minWidth: 280,
      padding: theme.spacing(1),
    },
    button: {
      position: 'relative',
      fontSize: '1rem',
      paddingRight: theme.spacing(3),
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    dropdownIcon: {
      position: 'absolute',
      top: '50%',
      color: '#000000',
      right: theme.spacing(1),
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
    },
  });
});

const popperStyles = makeStyles(() => {
  return createStyles({
    popperDisablePortal: {
      position: 'relative',
    },
    popupIndicator: {
      display: 'none',
    },
  });
});

const LISTBOX_PADDING = 8; // px

function renderRow(props: { data: any; index: any; style: any }) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLInputElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: number) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

/**
 * Please see this:
 * https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884#issuecomment-673676740
 *
 * Related issue: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
 */
const ListboxComponent: React.ComponentType<React.HTMLAttributes<HTMLElement>> =
  React.forwardRef(function ListboxComponent(
    props,
    ref:
      | ((instance: HTMLInputElement | null) => void)
      | React.MutableRefObject<HTMLInputElement | null>
      | null
  ) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: {} | null | undefined) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);
    const height = getHeight() + 2 * LISTBOX_PADDING;

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={height}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={index => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}>
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

const FilterAutocomplete = (props: FilterAutocompleteProps) => {
  const classes = useStyles();
  const popperClasses = popperStyles();
  const { value, onChange, options, renderValue } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { isOpen, open, close } = useModalState();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    open();
  };

  const handleClose = () => {
    setAnchorEl(null);
    close(false);
  };

  const handleChange = (
    _event: React.ChangeEvent<{}>,
    newValues: (string | number)[]
  ) => {
    onChange(newValues);
  };
  const getOptionLabel = (option: string | number) => {
    return options.find(opt => opt.value === option)?.label ?? '';
  };

  const defaultDisplay = value.length
    ? `Keyword: ${value.join(', ')}`
    : 'Keyword';
  const display = renderValue ? renderValue(value) : defaultDisplay;

  return (
    <Box className={classes.selectRoot}>
      <Box className={classes.button} onClick={handleClick}>
        <Typography className={classes.label}>{display}</Typography>
        <DropdownIcon className={classes.dropdownIcon} />
      </Box>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Box className={classes.popoverContent}>
          <Autocomplete
            multiple
            value={value}
            onChange={handleChange}
            options={options.map(option => option.value)}
            popupIcon={<DropdownIcon />}
            disablePortal
            getOptionLabel={getOptionLabel}
            ListboxComponent={ListboxComponent}
            open={true}
            renderInput={params => (
              <TextField
                {...params}
                autoFocus
                placeholder={value.length ? '' : 'Keyword'}
              />
            )}
            classes={popperClasses}
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default FilterAutocomplete;
