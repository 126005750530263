import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

export type TabProps = React.PropsWithChildren<{
  title: string;
  disabled?: boolean;
}>;
export const Tab = ({ children }: TabProps) => {
  /**
   * It's an abstract component that is only valid as a direct Child of the `Tabs` Component.
   */
  return <>{children}</>;
};

type TabNavProps = TabProps & {
  isActive?: boolean;
  onClick: () => void;
};
const TabNav = (props: TabNavProps) => {
  return (
    <div
      onClick={props.onClick}
      className={cn(styles.tab, {
        [styles.active]: props.isActive,
        [styles.disabled]: props.disabled,
      })}>
      {props.title}
    </div>
  );
};

export type TabsProps = React.PropsWithChildren<{
  activeTabIndex?: number;
  className?: string;
}>;
const Tabs = (props: TabsProps) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(
    props.activeTabIndex || 0
  );
  const TabContent = React.Children.toArray(props.children)[activeTabIndex];

  /* eslint-disable react-hooks/exhaustive-deps */
  // `activeTabIndex` doesn't need to be triggered
  React.useEffect(() => {
    if (props.activeTabIndex && props.activeTabIndex !== activeTabIndex) {
      setActiveTabIndex(props.activeTabIndex);
    }
  }, [props.activeTabIndex]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div className={cn(styles.wrapper, props.className)}>
      <div className={styles.navTabs}>
        {React.Children.map(
          props.children,
          (element: React.ReactNode, elementIndex: number) => {
            if (!React.isValidElement(element)) {
              return null;
            }

            const elementProps = { ...element.props };
            return (
              <TabNav
                title={elementProps.title}
                disabled={elementProps.disabled}
                isActive={activeTabIndex === elementIndex}
                onClick={() => setActiveTabIndex(elementIndex)}
              />
            );
          }
        )}
      </div>
      <div className={styles.content}>{TabContent}</div>
    </div>
  );
};

export default Tabs;
