import { /* AnonymousUserData, */ UserData } from 'constants/data';
import VerificationEmailDialog from 'modules/dialogs/VerificationEmailSentDialog';
import React, { useMemo } from 'react';
import { useContext } from 'react';
import { useModalState } from 'utils/hooks';
import useAuthentication from './useAuthentication';
import { EmailVerifiedDialog } from 'modules/dialogs';
import { getMembershipItem } from 'utils/common';
import { useCookies } from 'react-cookie';

interface ContextProps {
  user: UserData | null | undefined;
  isLoadingUser: boolean;
  isEmailVerified: boolean;
  numberOfAvailableSlots: number;
  isSubscribedPackage: boolean;
  isSignedIn: boolean;
  showVerifyDialog: () => void;
  hideVerifyDialog: (arg0: boolean) => void;
  streamingArtworkIds?: string[];
  // anonymousUser: AnonymousUserData | null | undefined; // TODO: anonymous
  isFreeSlotUsed: boolean;
}

const AuthContext = React.createContext<ContextProps>({
  user: null,
  isLoadingUser: true,
  isEmailVerified: false,
  numberOfAvailableSlots: 0,
  isSubscribedPackage: false,
  isSignedIn: false,
  showVerifyDialog: () => {},
  hideVerifyDialog: () => {},
  streamingArtworkIds: [],
  // anonymousUser: null,
  isFreeSlotUsed: false,
});

export const useAuthContext = () => useContext(AuthContext);

interface isFreeSlotUsedProps {
  user?: UserData;
  cookies: Record<string, string>;
}

// TODO: need to check again the renting logic
const isFreeSlotUsed = ({ user, cookies }: isFreeSlotUsedProps): boolean => {
  // anonymous user
  if (!user) {
    return !!Object.keys(cookies).find(key => key.includes('anonymous-rented'));
  }
  // logged-in user && user.freeSlot.current has data
  return !!(user.freeSlot && user.freeSlot.current);
};

const getAvailableSlots = (user: UserData | undefined | null): number => {
  const streamingArtworkIds = Object.keys(user?.streamings || {});
  const subscribedPackage = getSubscribedPackage(user);

  if (subscribedPackage === null) {
    return 0;
  }

  if (!streamingArtworkIds) {
    return subscribedPackage.maxPrints;
  }

  return Math.max(subscribedPackage.maxPrints - streamingArtworkIds.length, 0);
};

const getSubscribedPackage = (user: UserData | undefined | null) => {
  const subscribedPackage = getMembershipItem(user?.membership || '');

  return subscribedPackage;
};

export const AuthContextProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const authContext = useAuthentication();
  const [cookies] = useCookies();
  const user = authContext.user as UserData;
  // const anonymousUser = authContext.anonymousUser;
  const { isLoading: isLoadingUser } = authContext;
  const streamingArtworkIds = Object.keys(user?.streamings || {});

  const numberOfAvailableSlots = getAvailableSlots(user);
  const subscribedPackage = getSubscribedPackage(user);
  const {
    isOpen: isOpenVerificationEmail,
    // open: openVerificationEmail,
    close: closeVerificationEmail,
  } = useModalState();
  const {
    isOpen: isOpenEmailVerified,
    // open: openEmailVerified,
    close: closeEmailVerified,
  } = useModalState();

  const { email, isEmailVerified = false } = user || {};
  const isVerified = isEmailVerified;
  // const previousIsVerified = usePrevious<boolean>(isVerified);

  // useEffect(() => {
  //   // Close verification sent email dialog after the email is verified
  //   if (!previousIsVerified && isVerified && isOpenVerificationEmail) {
  //     closeVerificationEmail(false);
  //     openEmailVerified();
  //   }

  //   // Close email verified success dialog if the email is not verified
  //   if (!isVerified && isOpenEmailVerified) {
  //     closeEmailVerified(false);
  //   }
  // }, [
  //   previousIsVerified,
  //   isVerified,
  //   closeVerificationEmail,
  //   isOpenVerificationEmail,
  //   openEmailVerified,
  //   isOpenEmailVerified,
  //   closeEmailVerified,
  // ]);

  const contextValue = useMemo(
    () => ({
      user,
      isLoadingUser,
      isEmailVerified: isVerified,
      showVerifyDialog: () => null,
      hideVerifyDialog: () => null,
      isSubscribedPackage: subscribedPackage !== null,
      isSignedIn: user !== null && user !== undefined,
      numberOfAvailableSlots,
      isFreeSlotUsed: isFreeSlotUsed({ user, cookies }),
      streamingArtworkIds,
      // anonymousUser,
    }),
    [
      user,
      cookies,
      isLoadingUser,
      isVerified,
      // openVerificationEmail,
      // closeVerificationEmail,
      subscribedPackage,
      numberOfAvailableSlots,
      streamingArtworkIds,
      // anonymousUser,
    ]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
      {isOpenVerificationEmail && (
        <VerificationEmailDialog
          email={email}
          open={isOpenVerificationEmail}
          onClose={() => closeVerificationEmail(false)}
        />
      )}
      {isOpenEmailVerified && (
        <EmailVerifiedDialog
          open={isOpenEmailVerified}
          onClose={() => closeEmailVerified(false)}
        />
      )}
    </AuthContext.Provider>
  );
};
