import {
  Select,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
  SelectProps,
  ListItemText,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import { ReactComponent as DropdownIcon } from 'static/images/dropdown.svg';
import { mobileMediaQuery } from 'utils/common';

export interface ISelectOption {
  value: string | number;
  label: string;
  disabled?: boolean;
}
interface SingleFilterSelectProps
  extends Omit<SelectProps, 'onChange' | 'name'> {
  options: ISelectOption[];
  onChange: (values: string | number) => void;
  label?: string;
  helperText?: string;
  fullWidth?: boolean;
  error?: boolean;
  placeholder?: string;
}

const SingleSelectField = ({
  options,
  onChange,
  value,
  renderValue,
  label,
  helperText,
  fullWidth,
  error,
  placeholder,
  ...rest
}: SingleFilterSelectProps) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValues = event.target.value as string | number;
    onChange(newValues);
  };
  const getValue = () => {
    const foundItem = options?.find(option => option.value === value);
    return foundItem ? foundItem.value : '';
  };

  return (
    <FormControl
      fullWidth={!!fullWidth}
      className={classes.container}
      error={!!error}>
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}
      <Select
        displayEmpty
        value={getValue()}
        onChange={handleChange}
        classes={{
          root: classes.root,
          icon: classes.icon,
          select: classes.select,
        }}
        renderValue={renderValue}
        disableUnderline
        IconComponent={DropdownIcon}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        fullWidth={!!fullWidth}
        {...rest}>
        {placeholder && (
          <MenuItem value="" disabled classes={{ root: classes.item }}>
            <ListItemText
              primary={placeholder}
              classes={{
                root: classes.itemText,
                primary: classes.placeholder,
              }}
            />
          </MenuItem>
        )}
        {options.map((option: ISelectOption) => {
          return (
            <MenuItem
              value={option.value}
              key={option.value}
              disabled={!!option.disabled}>
              <ListItemText
                primary={option.label}
                classes={{
                  root: classes.itemText,
                  primary: classes.itemTextPrimary,
                }}
              />
            </MenuItem>
          );
        })}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    container: {
      'marginBottom': theme.spacing(3),
      '& .Mui-error $select': {
        border: '1px solid red',
      },
    },
    options: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: theme.spacing(4) + 'px',
      rowGap: theme.spacing(1) + 'px',
      [mobile]: {
        flexDirection: 'column',
      },
    },
    root: {
      whiteSpace: 'pre-wrap',
      color: theme.palette.common.black,
      fontWeight: 500,
      [mobile]: {
        maxWidth: 'unset',
      },
    },
    select: {
      'width': '100%',
      'backgroundColor': '#EEEEEE',
      'padding': theme.spacing(2, 1.75),
      '&:focus': {
        backgroundColor: '#EEEEEE',
      },
    },
    icon: {
      color: theme.palette.common.black,
      top: '50%',
      right: theme.spacing(1),
      transform: 'translateY(-50%)',
    },
    label: {
      position: 'static',
      transform: 'none',
      color: theme.palette.common.black,
      textTransform: 'uppercase',
      fontSize: '0.75rem',
      letterSpacing: '0.12em',
    },
    item: {
      '&.Mui-disabled': {
        opacity: 1,
      },
    },
    itemText: {
      marginTop: 0,
      marginBottom: 0,
    },
    itemTextPrimary: {
      fontSize: '1rem',
      lineHeight: '1.2rem',
    },
    placeholder: {
      fontSize: '1rem',
      lineHeight: '1.2rem',
      color: 'rgb(102, 102, 102)',
      opacity: 0.42,
    },
    itemDisabled: {
      opacity: 1,
    },
  });
});

export default SingleSelectField;
