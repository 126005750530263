import { makeStyles, createStyles } from '@material-ui/core';
import cs from 'classnames';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { forwardRef } from 'react';
import { checkIsInternalLink } from 'utils/common';

interface ExternalRouterLinkProps extends LinkProps {}

/**
 * Wrapper of Link from `react-router-dom` which support navigating to extenal link
 */
const ExternalRouterLink = forwardRef<
  HTMLAnchorElement,
  ExternalRouterLinkProps
>((props, ref) => {
  const classes = useStyles();
  const { to, children, className } = props;
  const mergedClassName = cs(classes.text, className);

  const isInternalLink = typeof to === 'string' && checkIsInternalLink(to);
  if (isInternalLink) {
    return <RouterLink ref={ref} {...props} className={mergedClassName} />;
  }
  return (
    <a
      href={to as string}
      target="__blank"
      className={mergedClassName}
      ref={ref}>
      {children}
    </a>
  );
});

const useStyles = makeStyles(
  createStyles({
    text: {
      'color': 'inherit',
      '&:hover': {
        color: 'inherit',
      },
    },
  })
);

export default ExternalRouterLink;
