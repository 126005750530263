import loadable from 'utils/loadable';

const ValidState = loadable(
  () =>
    import(
      /* webpackChunkName: "views.client.receiveGift.components.ValidState" */ './ValidState'
    )
);
const InvalidState = loadable(
  () =>
    import(
      /* webpackChunkName: "views.client.receiveGift.components.InvalidState" */ './InvalidState'
    )
);

export { ValidState, InvalidState };
