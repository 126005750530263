import {
  ExtendedTransactionVenueData,
  ImageData,
  Print,
  TransactionData,
  TransactionType,
} from 'constants/data';
import { useEffect, useState } from 'react';
import Firebase, { useFirebaseContext } from 'shell/firebase';
import { responseToArray } from 'utils/common';

const postProcessTx = async (firebase: Firebase, txs: TransactionData[]) => {
  const total = txs.length;

  console.time('parseTxs');
  let result: ExtendedTransactionVenueData[] = [];
  for (let index = 0; index < total; index++) {
    const tx = txs[index];

    /**
     * Get image
     */
    const { completedPrintId, completedOriginalId } = tx;
    const isPrint = !completedOriginalId && !!completedPrintId;
    const isInvalid = !completedOriginalId && !completedPrintId;
    let imageId = isPrint ? '' : completedOriginalId || '';

    if (isInvalid) {
      continue;
    }

    if (isPrint) {
      const ref = firebase.printRef(completedPrintId);
      const pSnapshot = await ref.once('value');
      const print = pSnapshot.val() as Print | null;

      if (!print) {
        continue;
      }

      imageId = print.imageId;
    }

    /**
     * Get artist
     */
    if (!imageId) {
      continue;
    }

    const imageSnapshot = await firebase.imageRef(imageId).once('value');
    const image = imageSnapshot.val() as ImageData | null;

    if (!image) {
      continue;
    }

    const { artist: artistId, venue: venueId } = image;

    const newTx: ExtendedTransactionVenueData = {
      ...tx,
      imageId,
      artistId,
      venueId,
    };

    result.push(newTx);
  }

  console.timeEnd('parseTxs');

  return result;
};

export const useLoadTransactions = () => {
  const [txs, setTxs] = useState<ExtendedTransactionVenueData[] | null>();
  const firebase = useFirebaseContext();

  useEffect(() => {
    if (firebase) {
      const ref = firebase.transactionsRef();
      const query = ref.orderByChild('createdDate');

      const callback = query.on('value', async snapshot => {
        const txsObj = snapshot.val();

        if (txsObj === null) {
          setTxs(null);
          return;
        }

        const txs = responseToArray(txsObj, 'uid') as TransactionData[];
        const filteredTxs = txs.filter(tx => {
          const { eventType, status } = tx;

          if (eventType !== TransactionType.TRANSACTION_TYPE_TRANSFER) {
            return false;
          }

          if (status !== 'completed') {
            return false;
          }

          return true;
        });

        const result = await postProcessTx(firebase, filteredTxs);
        setTxs(result);
      });

      return () => query.off('value', callback);
    }
  }, [firebase]);

  return { txs, isLoading: typeof txs === 'undefined' };
};
