import React, { lazy, Suspense } from 'react';

const loadable = <T extends React.ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
  { fallback }: { fallback: NonNullable<React.ReactNode> | null } = {
    fallback: null,
  }
) => {
  const LazyLoadedComponent = lazy(factory);

  return (props: React.ComponentProps<T>): React.ReactElement => (
    <Suspense fallback={fallback}>
      <LazyLoadedComponent {...props} />
    </Suspense>
  );
};

export default loadable;
