import Firebase, { useFirebaseContext } from 'shell/firebase';
import promiseWrapper from 'a-promise-wrapper';
import { ImageData } from 'constants/data/image.data';
import { Print as PrintData, IPrintTransform } from 'constants/data/print.data';
import { useAuthContext } from 'shell/session';
import { CanvasData } from 'constants/data';
import { useUpdate } from './useQuery';
import { responseToArray } from 'utils/common';
import { commonErrors, playArtworkErrors } from 'constants/errors';

interface PlayPrintProps {
  printId: string;
  canvasId: string;
  transform?: IPrintTransform | null;
}

interface PlayOriginalArtworkProps {
  imageId: string;
  canvasId: string;
  transform?: IPrintTransform | null;
}

const {
  INVALID_PARAMS_ERROR,
  FETCH_ORIGINAL_ARTWORK_ERROR,
  FETCH_PLAYING_PRINT_ERROR,
  // FETCH_PLAYING_ORIGINAL_ARTWORK_ERROR,
  FETCH_PRINT_ERROR,
  FETCH_CANVAS_ERROR,
  PRINT_NOT_FOUND_ERROR,
  CANVAS_NOT_FOUND_ERROR,
  INSUFFICIENT_PERMISSIONS_ERROR,
} = commonErrors;
const {
  PLAY_ORIGINAL_ARTWORK_FAILED,
  PLAY_PRINT_FAILED,
  ORIGINAL_ARTWORK_ALREADY_PLAYED,
  PRINT_ALREADY_PLAYED,
} = playArtworkErrors;

/**
 * Check which prints are playing on given canvas.
 * <br />Return object for update operation in Firebase realtime database.
 * <br />
 * <br />Throw `FETCH_PLAYING_PRINT_ERROR` when fetching data error.
 *
 * @example
 * ```javascript
 * const func = useGetPlayingPrintsCommand();
 * const updates = await func(canvasId);
 * ```
 */
export const useGetPlayingPrintsCommand = () => {
  const firebase = useFirebaseContext();

  return async (canvasId: string) => {
    const ref = firebase.printsRef();
    const query = ref.orderByChild('canvasId').equalTo(canvasId);
    const { data: snapshot, error: fetchError } = await promiseWrapper(
      query.once('value')
    );

    if (fetchError) {
      throw new Error(FETCH_PLAYING_PRINT_ERROR);
    }

    const value = snapshot?.val() || {};
    const prints = responseToArray<PrintData>(value, 'uid');
    const updates = prints.reduce((acc, curr) => {
      return {
        ...acc,
        [`${firebase.prints()}/${curr.uid}/canvasId`]: null,
      };
    }, {});

    return updates;
  };
};

/**
 * Check given image is owned by current user or not.
 * <br />
 * <br />- Throw `FETCH_ORIGINAL_ARTWORK_ERROR` when fetch image error.
 * <br />- Throw `INSUFFICIENT_PERMISSIONS_ERROR` when the current user is not owner
 *
 * @example
 * ```javascript
 * const checkFunc = useCheckOriginalArtworkPermission();
 * await checkFunc(imageId);
 * ```
 */
const useCheckOriginalArtworkPermission = () => {
  const firebase = useFirebaseContext();
  const { user } = useAuthContext();

  return async (imageId: string) => {
    if (!user) {
      throw new Error(INVALID_PARAMS_ERROR);
    }

    const imageQuery = firebase.imageRef(imageId).once('value');
    const { data: snapshot, error: fetchError } = await promiseWrapper(
      imageQuery
    );

    if (fetchError) {
      throw new Error(FETCH_ORIGINAL_ARTWORK_ERROR);
    }

    const image = snapshot?.val() as ImageData;
    const { owner } = image || {};

    if (user.uid !== owner) {
      throw new Error(INSUFFICIENT_PERMISSIONS_ERROR);
    }
  };
};

/**
 * Check given original artwork is playing on any canvases or not.
 * <br />Return object for update operation in Firebase realtime database.
 * <hr />
 * <br />Throw `FETCH_PLAYING_ORIGINAL_ARTWORK_ERROR` when fetch data error.
 */
// const useGetPlayingOriginalArtworkCommand = () => {
//   const firebase = useFirebaseContext();
//
//   return async (artworkId: string) => {
//     const ref = firebase.canvasesRef();
//     const query = ref.orderByChild('originalArtwork').equalTo(artworkId);
//     const { data: snapshot, error } = await promiseWrapper(query.once('value'));
//
//     if (error) {
//       throw new Error(FETCH_PLAYING_ORIGINAL_ARTWORK_ERROR);
//     }
//
//     const value = snapshot?.val() || {};
//     const canvases = responseToArray<CanvasData>(value, 'uid');
//     const updates = canvases.reduce((acc, curr) => {
//       return {
//         ...acc,
//         [`${firebase.canvas(curr.uid)}/originalArtwork`]: null,
//         [`${firebase.canvas(curr.uid)}/transform`]: null,
//         [`${firebase.canvasToMediaLastChanged(curr.uid)}`]:
//           firebase.getTimestamp(),
//       };
//     }, {});
//
//     return updates;
//   };
// };

/**
 * TODO - remove this function when user can choose exact print to play, not by artwork.
 * <br />
 * Play a print on a canvas, also handle additional cases below:
 * 1. If the selected print is being played on another canvas, remove it from that canvas.
 * 2. If the selected canvas is playing another print, remove that print from it.
 *
 * IMPORTANT:
 * Whenever a canvas plays/removes a print,
 * its `media_last_changed` must be updated to the current server timestamp
 */
const playPrint = ({
  canvasId,
  firebase,
  print,
  printTransform,
}: {
  canvasId: string;
  firebase: Firebase;
  print: PrintData;
  printTransform: IPrintTransform | null;
}) =>
  new Promise<void>(async (resolve, reject) => {
    const updates: Record<string, string | Object | null> = {};

    // Queries to play the print on the selected canvas
    // Assign the canvas' uid to the print, also update the canvas' `media_last_changed`
    updates[`prints/${print.uid}/canvasId`] = canvasId;
    updates[`prints/${print.uid}/transform`] = printTransform;
    updates[`canvases/${canvasId}/transform`] = printTransform;
    updates[`canvases/${canvasId}/type`] = 'ORIGINAL';
    updates[`canvases/${canvasId}/printId`] = print.uid || '';
    updates[`canvases/${canvasId}/originalArtwork`] = null;
    updates[`canvases/${canvasId}/media_last_changed`] =
      firebase.getTimestamp();

    // case 1
    // the print is being played on another canvas
    if (print.canvasId) {
      // As this print will be removed from that canvas, we have to update that canvas' `media_last_changed` and clear the `transform` object
      updates[`canvases/${print.canvasId}/media_last_changed`] =
        firebase.getTimestamp();
      updates[`canvases/${print.canvasId}/transform`] = null;
    }
    // End case 1

    // case 2
    // check if the selected canvas is play another print
    const playingPrintSnapshot = await firebase
      .printsRef()
      .orderByChild('canvasId')
      .equalTo(canvasId)
      .once('value');

    if (playingPrintSnapshot.val() !== null) {
      // snapshot.val() contains only 1 print, because 1 canvas can play 1 print at a time.
      const playingPrintId = Object.keys(playingPrintSnapshot.val())[0];

      // Remove the playing print from the selected canvas
      updates[`prints/${playingPrintId}/canvasId`] = null;
    }
    // End case 2

    // Case 3
    // check if selected canvas is playing another original artwork
    const canvasSnapshot = await firebase.canvasRef(canvasId).once('value');
    const canvas = canvasSnapshot.val();

    if (canvas && !canvas.originalArtwork) {
      updates[`canvases/${canvasId}/originalArtwork`] = null;
    }

    firebase.db.ref().update(updates, (error: Error | null) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });

/**
 * TODO - remove this function when user can choose exact print to play, not by artwork.
 *
 */
export const playPrintOnCanvas = async (props: {
  image: ImageData;
  canvasId: string;
  userId: string;
  firebase: Firebase;
  printTransform: IPrintTransform | null;
}) => {
  const { image, canvasId, userId, firebase, printTransform } = props;

  /**
   * Steps:
   * - Find all prints has `tenant` is current user
   * - Filter above prints to get print has `imageId` is current image
   * So basically it finds print has both below requirements:
   * - `tenant` is current user
   * - `imageId` is current image
   */

  const query = firebase.userPrintsRef(userId);
  const { data: printsSnapshot, error: getPrintsError } = await promiseWrapper(
    query.once('value')
  );

  if (getPrintsError) {
    throw new Error(FETCH_PRINT_ERROR);
  }

  const printsOfTenant = printsSnapshot.val();

  if (!printsOfTenant) {
    throw new Error(PRINT_NOT_FOUND_ERROR);
  }

  // find selecting print
  const printKeys = Object.keys(printsOfTenant);
  const printId = printKeys.find(
    printKey => printsOfTenant[printKey].imageId === image.uid
  );

  if (typeof printId === 'undefined') {
    throw new Error(PRINT_NOT_FOUND_ERROR);
  }

  const { error } = await promiseWrapper(
    playPrint({
      canvasId,
      print: { uid: printId, ...printsOfTenant[printId] },
      firebase,
      printTransform,
    })
  );

  if (error) {
    throw error;
  }
};

/**
 * Play given print on canvas.
 * Owner or renter can play a print on canvas ✔️
 *
 * Steps:
 * <br />- Check current user is owner OR renter of print or not               ✔️
 * <br />- Check if print is already played on canvas or not                   ✔️
 * <br />- Check if print is played on another canvas or not                   ✔️
 * <br />- Check if canvas is playing another original arwork or not           ✔️
 * <br />- Check if canvas is playing another print or not                     ✔️
 */
export const usePlayPrint = () => {
  const { user } = useAuthContext();
  const firebase = useFirebaseContext();
  const getPlayingPrints = useGetPlayingPrintsCommand();
  const updateFunc = useUpdate();

  return async ({ printId, canvasId, transform }: PlayPrintProps) => {
    if (!printId || !canvasId || !user) {
      throw new Error(INVALID_PARAMS_ERROR);
    }

    const { uid: userId } = user;
    const getPrintQuery = firebase.printRef(printId);
    const { data: printSnapshot, error: getPrintError } = await promiseWrapper(
      getPrintQuery.once('value')
    );

    if (getPrintError) {
      throw new Error(FETCH_PRINT_ERROR);
    }

    const print = printSnapshot?.val();

    if (!print) {
      throw new Error(PRINT_NOT_FOUND_ERROR);
    }

    const { owner, tenant, canvasId: currentCanvasId } = print as PrintData;
    const isOwner = owner === userId;
    const isRenter = tenant === userId;

    if (!isOwner && !isRenter) {
      throw new Error(INSUFFICIENT_PERMISSIONS_ERROR);
    }

    if (currentCanvasId === canvasId) {
      throw new Error(PRINT_ALREADY_PLAYED);
    }

    let printUpdates;

    if (currentCanvasId) {
      printUpdates = {
        [`${firebase.canvasToMediaLastChanged(currentCanvasId)}`]:
          firebase.getTimestamp(),
        [`${firebase.canvas(currentCanvasId)}/transform`]: null,
      };
    }

    printUpdates = {
      ...printUpdates,
      [`${firebase.print(printId)}/canvasId`]: canvasId,
      [`${firebase.print(printId)}/transform`]: transform || null,
    };

    if (isOwner) {
      // Set current user to tenant if he is playing his own print
      printUpdates[`${firebase.print(printId)}/tenant`] = userId;
    }

    const getCanvasQuery = firebase.canvasRef(canvasId);
    const { data: canvasSnapshot, error: getCanvasError } =
      await promiseWrapper(getCanvasQuery.once('value'));

    if (getCanvasError) {
      throw new Error(FETCH_CANVAS_ERROR);
    }

    const canvas = canvasSnapshot?.val();
    if (!canvas) {
      throw new Error(CANVAS_NOT_FOUND_ERROR);
    }

    // remove canvasId from streaming collection if is streaming originals
    const { originalArtwork } = canvas as CanvasData;
    let streamingUpdates: { [key: string]: Object | null } = {};
    if (originalArtwork) {
      streamingUpdates[
        `${firebase.streamings()}/${originalArtwork}/${userId}/canvasId`
      ] = null;
    }

    let canvasUpdates: { [key: string]: Object | null } = {
      [`${firebase.canvasToMediaLastChanged(canvasId)}`]:
        firebase.getTimestamp(),
      [`${firebase.canvas(canvasId)}/transform`]: transform || null,
      [`${firebase.canvas(canvasId)}/originalArtwork`]: null,
      [`${firebase.canvas(canvasId)}/printId`]: printId || '',
      [`${firebase.canvas(canvasId)}/type`]: 'ORIGINAL',
    };

    const { data: playingPrintUpdates, error: fetchPlayingPrintError } =
      await promiseWrapper(getPlayingPrints(canvasId));

    if (fetchPlayingPrintError) {
      throw fetchPlayingPrintError;
    }

    const updates = {
      ...printUpdates,
      ...playingPrintUpdates,
      ...canvasUpdates,
      ...streamingUpdates,
    };

    const { error } = await promiseWrapper(updateFunc(updates));

    if (error) {
      throw new Error(PLAY_PRINT_FAILED);
    }
  };
};

/**
 * NOTE `image` is legacy term, it means "original artwork" in this case.
 * <br />Return a function to play an original artwork on canvas.
 *
 * @exam
 * ```javascript
 * const playFunc = usePlayOriginalArtwork();
 *
 * await playFunc({
 *   imageId: 'abc',
 *   canvasId: 'abc-123'
 * });
 *
 * ```
 */
export const usePlayOriginalArtwork = () => {
  /**
   * Only owner of original artwork can play it on canvas
   *
   * Steps:
   * - Check current user is owner of original artwork or not               ✔️
   * - Check if original artwork is already played on canvas or not         ✔️
   * - Check if current original artwork is played on another canvas or not ✔️
   * - Check if current canvas is playing another original arwork or not    ✔️
   * - Check if current canvas is playing another print or not              ✔️
   */
  const firebase = useFirebaseContext();
  const checkOwner = useCheckOriginalArtworkPermission();
  // const getPlayingOriginalArtwork = useGetPlayingOriginalArtworkCommand();
  const getPlayingPrints = useGetPlayingPrintsCommand();
  const updateFunc = useUpdate();
  const { user } = useAuthContext();

  return async ({ imageId, canvasId, transform }: PlayOriginalArtworkProps) => {
    if (!imageId || !canvasId || !user) {
      throw new Error(INVALID_PARAMS_ERROR);
    }

    const { error: checkOwnerError } = await promiseWrapper(
      checkOwner(imageId)
    );

    if (checkOwnerError) {
      throw checkOwnerError;
    }

    const getCanvasQuery = firebase.canvasRef(canvasId);
    const { data: canvasSnapshot, error: getCanvasError } =
      await promiseWrapper(getCanvasQuery.once('value'));

    if (getCanvasError) {
      throw new Error(FETCH_CANVAS_ERROR);
    }

    const canvas = canvasSnapshot?.val();
    if (!canvas) {
      throw new Error(CANVAS_NOT_FOUND_ERROR);
    }

    const { originalArtwork } = canvas as CanvasData;

    if (originalArtwork === imageId) {
      throw new Error(ORIGINAL_ARTWORK_ALREADY_PLAYED);
    }

    const { uid: userId } = user;

    // remove canvasId from streaming collection if is streaming originals
    let streamingUpdates: { [key: string]: Object | null } = {};
    if (originalArtwork) {
      streamingUpdates[
        `${firebase.streamings()}/${originalArtwork}/${userId}/canvasId`
      ] = null;
    }

    const canvasUpdates = {
      [`${firebase.canvas(canvasId)}/originalArtwork`]: imageId,
      [`${firebase.canvas(canvasId)}/printId`]: null,
      [`${firebase.canvas(canvasId)}/transform`]: transform || null,
      [`${firebase.canvas(canvasId)}/type`]: 'ORIGINAL',
      [`${firebase.canvasToMediaLastChanged(canvasId)}`]:
        firebase.getTimestamp(),
    };

    const { data: playingPrintUpdate, error: fetchPlayingPrintError } =
      await promiseWrapper(getPlayingPrints(canvasId));

    if (fetchPlayingPrintError) {
      throw fetchPlayingPrintError;
    }

    // const {
    //   data: playingOriginalArtworkUpdate,
    //   error: fetchPlayingOriginalArtworkError,
    // } = await promiseWrapper(getPlayingOriginalArtwork(imageId));

    // if (fetchPlayingOriginalArtworkError) {
    //   throw fetchPlayingOriginalArtworkError;
    // }

    const updates = {
      ...playingPrintUpdate,
      // ...playingOriginalArtworkUpdate,
      ...canvasUpdates,
      ...streamingUpdates,
    };

    const { error } = await promiseWrapper(updateFunc(updates));

    if (error) {
      throw new Error(PLAY_ORIGINAL_ARTWORK_FAILED);
    }
  };
};
