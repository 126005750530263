/* eslint-disable no-useless-escape */
// https://stackoverflow.com/questions/23187013/is-there-a-better-way-to-sanitize-input-with-javascript
export const sanitizeString = (str: string) => {
  str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, '');
  return str.trim();
};

//https://stackoverflow.com/questions/1053902/how-to-convert-a-title-to-a-url-slug-in-jquery
export const getSlug = (str: string) => {
  if (!str) return '';

  return str.replace(/[^(\w\-) ]+/g, '').replace(/ +/g, '-');
};
