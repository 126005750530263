import { useState, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Button,
} from '@material-ui/core';
import cn from 'classnames';

import { ImageData } from 'constants/data/image.data';
import { CanvasData } from 'constants/data/canvas.data';
import { IPrintTransform } from 'constants/data/print.data';
import { ReactComponent as RotateRightIcon } from 'static/images/icons/rotate_right.svg';
import { ReactComponent as RotateLeftIcon } from 'static/images/icons/rotate_left.svg';
import { ReactComponent as ZoomInIcon } from 'static/images/icons/zoomin.svg';
import { ReactComponent as ZoomOutIcon } from 'static/images/icons/zoomout.svg';
import {
  calculateScale,
  calculateTranslate,
  calculateMiddleTranslate,
} from './selectionHelpers';
import { getDimensions } from './deviceFrameHelpers';

import DeviceFrame from './DeviceFrame';
interface Props {
  image: ImageData;
  canvas: CanvasData;
  initialValue: IPrintTransform | null;
  onChange: (transform: IPrintTransform) => void;
}

type CanvasOrientation = 'portrait' | 'landscape';

const SelectionBox = ({ image, canvas, onChange, initialValue }: Props) => {
  const isFirstTimeRenderRef = useRef(true);
  const classes = useStyles();
  const [canvasOrientation, setCanvasOrientation] =
    useState<CanvasOrientation>('portrait');

  const canvasSize = getDimensions(canvas);

  /**
   * We will create a box to wrap the image inside
   * When the image is rotated into landscape mode, we swap the edges of this box so that its width become the height,
   * and its height become the width, then make the image fit inside this box
   *
   * However, it's important to note that all image calculations and transforms are done based on 0deg rotation.
   * In simple terms that means, once the canvas UI is rotated:
   * - dragging the image up/down changes the translateX value
   * - dragging the image left/right changes the translateY value
   */
  const imageBoxSize =
    canvasOrientation === 'portrait'
      ? { width: image.width, height: image.height }
      : { width: image.height, height: image.width };

  const { maxScale, minScale } = calculateScale({
    imageBoxSize,
    canvasSize,
  });
  const [scale, setScale] = useState<number>(initialValue?.scale || maxScale);
  const { maxX, maxY, minX, minY } = calculateTranslate({
    imageBoxSize,
    canvasSize,
    currentScale: scale,
  });

  const middleTranslate = calculateMiddleTranslate({
    imageBoxSize,
    canvasSize,
    currentScale: scale,
  });

  const { translateX: middleX, translateY: middleY } = middleTranslate;

  const [translate, setTranslate] = useState<{ x: number; y: number }>({
    x: initialValue?.translateX ?? middleX,
    y: initialValue?.translateY ?? middleY,
  });
  useEffect(() => {
    if (isFirstTimeRenderRef.current) {
      return;
    }
    setTranslate({
      x: middleX,
      y: middleY,
    });
  }, [middleX, middleY]);

  useEffect(() => {
    setScale(prevScale => {
      if (prevScale !== maxScale && prevScale !== minScale) {
        return maxScale;
      }
      return prevScale;
    });
  }, [maxScale, minScale]);

  useEffect(() => {
    setTranslate(({ x: prevX, y: prevY }) => ({
      x: getInRangeValue(prevX, minX, maxX),
      y: getInRangeValue(prevY, minY, maxY),
    }));
  }, [minX, maxX, minY, maxY]);

  useEffect(() => {
    setTranslate(
      canvasOrientation === 'portrait'
        ? {
            x: middleX,
            y: middleY,
          }
        : {
            x: middleY,
            y: middleX,
          }
    );
    /**
     * the following deps array is ignoring the exhaustive-deps rule on purpose,
     * because the translate should specifically NOT be reset when the middleX/middleY values change.
     * It causes the image to be positioned incorrectly.
     */
  }, [canvasOrientation]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleZoomIn = () => setScale(z => Math.min(maxScale, z + 1));
  const handleZoomOut = () => setScale(z => Math.max(minScale, z - 1));
  const handleCanvasOrientationChange = () =>
    setCanvasOrientation(o => (o === 'portrait' ? 'landscape' : 'portrait'));
  const handleDrag = (deltaX: number, deltaY: number) => {
    setTranslate(({ x: prevX, y: prevY }) => ({
      x: getInRangeValue(prevX + deltaX, minX, maxX),
      y: getInRangeValue(prevY + deltaY, minY, maxY),
    }));
  };

  const imageMaxWidth = maxScale * imageBoxSize.width;

  const transform = useMemo(
    () => ({
      rotate: canvasOrientation === 'portrait' ? 0 : -90,
      scale,
      translateX: translate.x,
      translateY: translate.y,
    }),
    [canvasOrientation, scale, translate.x, translate.y]
  );
  useEffect(() => {
    onChange(transform);
  }, [onChange, transform]);

  useEffect(() => {
    isFirstTimeRenderRef.current = false;
  }, []);

  return (
    <Box className={classes.root}>
      <DeviceFrame
        orientation={canvasOrientation}
        imagePath={image.path}
        imageBoxSize={imageBoxSize}
        canvas={canvas}
        transform={transform}
        imageMaxWidth={imageMaxWidth}
        onDrag={handleDrag}
      />
      <div className={classes.buttonsArea}>
        <Button
          className={cn(classes.button, {
            [classes.disabled]: scale >= maxScale,
          })}
          onClick={handleZoomIn}
          disabled={scale >= maxScale}>
          <ZoomInIcon />
        </Button>
        <Button
          className={cn(classes.button, {
            [classes.disabled]: scale <= minScale,
          })}
          onClick={handleZoomOut}
          disabled={scale <= minScale}>
          <ZoomOutIcon />
        </Button>
        <Button
          className={classes.button}
          onClick={handleCanvasOrientationChange}>
          {canvasOrientation === 'portrait' ? (
            <RotateRightIcon />
          ) : (
            <RotateLeftIcon />
          )}
        </Button>
      </div>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    buttonsArea: {
      position: 'absolute',
      bottom: theme.spacing(3),
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      gap: theme.spacing(1) + 'px',
    },
    button: {
      'backgroundColor': theme.palette.common.white,
      'height': 30,
      'width': 30,
      'minWidth': 30,
      'fontSize': '2rem',
      'padding': 0,
      '&:hover': {
        backgroundColor: theme.palette.grey['300'],
      },
    },
    disabled: {
      backgroundColor: theme.palette.grey['300'],
    },
    viewport: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    image: {
      position: 'relative',
    },
  });
});

const getInRangeValue = (value: number, min: number, max: number) => {
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
};

export default SelectionBox;
