import React, { useMemo } from 'react';
import cs from 'classnames';
import { Box, makeStyles, createStyles, Theme } from '@material-ui/core';
import { useTable, useSortBy, useRowSelect, Row } from 'react-table';
import {
  COLUMN_CHECKBOX,
  COLUMN_THUMBNAIL,
  COLUMN_NAME,
  COLUMN_PRINTS,
  COLUMN_CATALOG,
  COLUMN_BRIGHTNESS,
  COLUMN_ABSTRACTION,
  COLUMN_MEDIUM,
  COLUMN_DATE,
  COLUMN_COLOR,
  COLUMN_WIDTH,
  COLUMN_HEIGHT,
  COLUMN_ASPECT_RATIO,
  COLUMN_KEYWORDS,
  COLUMN_FEATURED,
  COLUMN_STATUS,
} from 'modules/tables/ImagesTable';
import { VirtualizedTable } from 'modules/tables';
import { useModalState } from 'utils/hooks';
import { ImageData } from 'constants/data/image.data';
import EditImagesDialog from 'modules/editImagesDialog';
import { useImageFieldsContext } from 'utils/contexts/imageFieldsContext';
import { useModal } from 'modules/modals';
import { useBatchMint, usePublishImages } from 'utils/requests';
import { useAuthContext } from 'shell/session';

import UIButton from 'ui-components/Button';

const IMAGES_TABLE_COLUMNS = [
  COLUMN_CHECKBOX,
  COLUMN_STATUS,
  COLUMN_THUMBNAIL,
  COLUMN_NAME,
  COLUMN_PRINTS,
  COLUMN_FEATURED,
  COLUMN_MEDIUM,
  COLUMN_DATE,
  COLUMN_COLOR,
  COLUMN_WIDTH,
  COLUMN_HEIGHT,
  COLUMN_ASPECT_RATIO,
  COLUMN_BRIGHTNESS,
  COLUMN_ABSTRACTION,
  COLUMN_CATALOG,
  COLUMN_KEYWORDS,
];

interface TableProps {
  data: ImageData[];
}

const ImagesTable = ({ data }: TableProps) => {
  const authContext = useAuthContext();
  const { isEmailVerified, showVerifyDialog } = authContext;

  const publishImages = usePublishImages();
  const classes = useStyles();
  const { openDialog: openConfirmDialog, showSnackbar } = useModal();
  const { checkIsPublishable } = useImageFieldsContext();
  const { isOpen, open, close } = useModalState<string[]>();
  const tableInstance = useTable<ImageData>(
    {
      columns: IMAGES_TABLE_COLUMNS,
      data: data,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      disableMultiSort: true,
    },
    useSortBy,
    useRowSelect
  );
  const { toggleAllRowsSelected, selectedFlatRows } = tableInstance;
  const mintFunc = useBatchMint();

  const selectedImages = useMemo(
    () => selectedFlatRows?.map(row => row.original),
    [selectedFlatRows]
  );

  const selectedReadyToPublishedImages = useMemo(
    () =>
      selectedImages.filter(
        image => !image.published && checkIsPublishable(image).isPublishable
      ),
    [selectedImages, checkIsPublishable]
  );

  const handleEdit = () => {
    if (!isEmailVerified) {
      return showVerifyDialog();
    }

    open();
  };

  const handleClickPublishButton = async () => {
    if (!isEmailVerified) {
      return showVerifyDialog();
    }

    const selectedCount = selectedImages.length;
    const publishableCount = selectedReadyToPublishedImages.length;
    const message =
      selectedCount > publishableCount
        ? `You are selecting ${selectedCount} images but only ${publishableCount} can be published. Are you sure you want to publish and mint ${publishableCount} image(s)?`
        : `You are publishing and minting ${publishableCount} image(s), are you sure?`;

    const confirmPublish = await openConfirmDialog({
      message,
      labelConfirm: 'Yes, publish them',
      labelCancel: 'No',
    });
    if (!confirmPublish) {
      return;
    }

    const mintArr = selectedReadyToPublishedImages.reduce((acc, image) => {
      const { uid, artist } = image;

      if (!acc[artist]) {
        acc[artist] = [];
      }

      acc[artist].push(uid.toString());

      return {
        ...acc,
      };
    }, {} as { [key: string]: string[] });

    const mintPromises = Object.keys(mintArr).map(artistId => {
      return mintFunc({
        artistId,
        imageIds: mintArr[artistId],
      });
    });

    publishImages(selectedReadyToPublishedImages)
      .then(() => {
        showSnackbar(`The images have been published successfully`);

        // NOTE: no await here to block UI
        try {
          Promise.all(mintPromises);
        } catch (error) {
          console.error(error);
        }
      })
      .catch(error => {
        console.error(error);
        showSnackbar('Failed to publish the images', { severity: 'error' });
      });
  };

  const getCustomRowProps = (row: Row) => {
    const image = row.original as ImageData;
    const isPublished = image.published;
    const { isPublishable } = checkIsPublishable(image);

    return {
      className: cs({
        [classes.incompleteRow]: !isPublished && !isPublishable,
      }),
    };
  };

  return (
    <Box>
      <Box padding={2} bgcolor="#efefef" display="flex" alignItems="center">
        <UIButton
          className={classes.editButton}
          onClick={handleEdit}
          variant="primary"
          size="medium"
          disabled={selectedImages?.length <= 0}
          text={`Edit ${selectedImages?.length} image(s)`}
        />
        <UIButton
          className={classes.editButton}
          onClick={handleClickPublishButton}
          variant="primary"
          size="medium"
          disabled={selectedReadyToPublishedImages?.length <= 0}
          text={`Publish and mint ${selectedReadyToPublishedImages?.length} image(s)`}
        />

        {selectedImages?.length > 0 && (
          <UIButton
            variant="text"
            size="medium"
            onClick={() => toggleAllRowsSelected(false)}
            text="Cancel"
          />
        )}
        <Box marginLeft="auto">Total {data?.length ?? 0} image(s)</Box>
      </Box>
      <VirtualizedTable
        tableInstance={tableInstance}
        tableHeight={550}
        getCustomRowProps={getCustomRowProps}
      />
      {isOpen && selectedImages?.length > 0 && (
        <EditImagesDialog
          open={isOpen}
          onClose={close}
          images={selectedImages}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editButton: {
      marginRight: theme.spacing(2),
      minWidth: 180,
    },
    incompleteRow: {
      backgroundColor: '#ffeeff',
    },
  })
);

export default ImagesTable;
