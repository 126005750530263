import React from 'react';
import { OptionRenderProps } from '../FilterOption';
import { FilterSelect } from 'modules/filterControls';

const OPTIONS_0_TO_5 = new Array(6)
  .fill(0)
  .map((_, index) => ({ value: index, label: index + '' }));

export const AbstractionOption = ({
  values = [],
  onChange,
}: OptionRenderProps) => {
  const renderAbstractionLabel = (value: unknown) =>
    (value as string[]).length
      ? `Abstraction: ${(value as string[]).join(', ')}`
      : 'Abstraction';
  return (
    <FilterSelect
      value={values}
      onChange={onChange}
      options={OPTIONS_0_TO_5}
      renderValue={renderAbstractionLabel}
    />
  );
};
