import { useEffect, useState } from 'react';
import { useFirebaseContext } from 'shell/firebase';

export const useLoadMembership = (userId: string) => {
  const [membership, setMembership] = useState<string>('');
  const firebase = useFirebaseContext();

  const changeMembership = async ({
    membership,
    userId,
  }: {
    membership: string;
    userId: string;
  }) => {
    if (!firebase) {
      return Promise.reject('firebase is not defined');
    }
    const callable = firebase.functions.httpsCallable('changeMembership');

    return callable({
      membership,
      userId,
    });
  };

  useEffect(() => {
    if (!userId) {
      setMembership('');
    } else if (firebase !== null) {
      const query = firebase.userMembershipRef(userId);
      const callback = query.on('value', async snapshot => {
        const value = snapshot.val();

        if (value === null) {
          setMembership('');
        } else {
          setMembership(value);
        }
      });
      return () => query.off('value', callback);
    }
  }, [firebase, userId]);

  return {
    membership,
    changeMembership,
    isLoading: typeof membership === 'undefined',
  };
};
