export type MembershipNameType =
  | 'Complimentary'
  | 'Basic'
  | 'Plus'
  | 'Prime'
  | 'Unlimited'
  | 'Unsubscribed';
export interface MembershipItemInterface {
  maxPrints: number;
  isPaid: boolean;
  name: MembershipNameType;
}

export interface MembershipInterface {
  [key: string]: MembershipItemInterface;
}

export const MEMBERSHIP: MembershipInterface = {
  complimentary: {
    maxPrints: 3,
    isPaid: false,
    name: 'Complimentary',
  },
  basic: {
    maxPrints: 3,
    isPaid: true,
    name: 'Basic',
  },
  plus: {
    maxPrints: 10,
    isPaid: true,
    name: 'Plus',
  },
  prime: {
    maxPrints: 25,
    isPaid: true,
    name: 'Prime',
  },
  unlimited: {
    maxPrints: Number.MAX_SAFE_INTEGER,
    isPaid: false,
    name: 'Unlimited',
  },
};
