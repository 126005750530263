import { Route, Switch, Redirect } from 'react-router-dom';
import { Box, makeStyles, createStyles } from '@material-ui/core';
import * as ROUTES from 'constants/routes';
import TopBar from 'modules/topBar';
import { PrivateRoute, AdminRoute } from 'modules/route';
import Footer from 'modules/footer';
import { useLayoutState } from 'utils/contexts/LayoutState';
import LoadingIndicator from 'views/client/exhibitionImage/ArtworkContext/LoadingIndicator';
import { PUBLIC_PAGES, PRIVATE_PAGES, ADMIN_PAGES } from './constants';
import ScrollToTop from './ScrollToTop';
import ErrorFallback from 'views/Error';
import { ErrorBoundary } from 'react-error-boundary';

const App = () => {
  const {
    showFooter,
    showTopBar,
    isLoadingApp,
    hasError,
    isHostedVenue,
    venue,
    giftingBg,
  } = useLayoutState();
  const classes = useStyles({
    background: isHostedVenue
      ? venue?.backgroundColor
      : giftingBg || 'transparent',
  });

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {hasError ? (
        <ErrorFallback showHeader={false} showFooter={false} />
      ) : isLoadingApp ? (
        <LoadingIndicator />
      ) : (
        <>
          {showTopBar && <TopBar />}
          <Box className={classes.container}>
            <Box className={classes.contentContainer}>
              <Switch>
                {PUBLIC_PAGES.map(page => (
                  <Route
                    exact={page.exact}
                    path={page.path}
                    component={page.component}
                    key={page.path}
                  />
                ))}

                {PRIVATE_PAGES.map(page => (
                  <PrivateRoute
                    exact={page.exact}
                    path={page.path}
                    component={page.component}
                    key={page.path}
                  />
                ))}

                {ADMIN_PAGES.map(page => (
                  <AdminRoute
                    exact={page.exact}
                    path={page.path}
                    component={page.component}
                    key={page.path}
                  />
                ))}

                <Redirect to={ROUTES.NOT_FOUND} />
              </Switch>
            </Box>
            {showFooter && <Footer />}
          </Box>
          <ScrollToTop />
        </>
      )}
    </ErrorBoundary>
  );
};

type StyleProps = {
  backgroundColor?: string;
  background?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      background: ({ background }: StyleProps) =>
        `${background} no-repeat center center fixed`,
    },
    contentContainer: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

export default App;
