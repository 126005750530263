import React from 'react';
import { useGetFeatureFlag } from 'utils/requests/useGetFeatureFlag';

type Props = React.PropsWithChildren<{ feature: string }>;

/**
 * This component render its children if the feature is enabled
 */
const FeatureFlag = ({ feature, children }: Props) => {
  const { featureEnabled, isLoading } = useGetFeatureFlag(feature);

  if (isLoading || featureEnabled === false) {
    return null;
  }
  return <>{children}</>;
};

export default FeatureFlag;
