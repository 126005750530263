import { useEffect } from 'react';

export const useClickOutSide = ({
  ref,
  callback,
}: {
  ref: any;
  callback?: () => void;
}) => {
  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback && callback();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [callback, ref]);
};
