import loadable from 'utils/loadable';

const PromotionComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "views.client.productOverview.promotion.Promotion" */ './Promotion.component'
    )
);

export default PromotionComponent;
