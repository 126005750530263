const commonErrors = {
  INVALID_PARAMS_ERROR: 'invalid-params',
  PRINT_NOT_FOUND_ERROR: 'print-not-found-error',
  CANVAS_NOT_FOUND_ERROR: 'canvas-not-found-error',
  FETCH_PRINT_ERROR: 'fetch-print-error',
  FETCH_CANVAS_ERROR: 'fetch-canvas-error',
  FETCH_ORIGINAL_ARTWORK_ERROR: 'fetch-original-artwork-error',
  FETCH_PLAYING_PRINT_ERROR: 'fetch-playing-print-error',
  FETCH_PLAYING_ORIGINAL_ARTWORK_ERROR: 'fetch-playing-original-artwork-error',
  INSUFFICIENT_PERMISSIONS_ERROR: 'insufficient-permission',
  NEED_VERIFY_EMAIL_ERROR: 'need-verify-email-error',
  USER_NOT_FOUND: 'user_not_found',
  NO_CANVAS_FOUND_IN_USER: 'no-canvas-found-in-user',
  ARTWORK_PLAY_ON_CANVAS_NOT_FOUND: 'artwork-play-on-canvas-not-found',
};

const playArtworkErrors = {
  PLAY_ORIGINAL_ARTWORK_FAILED: 'play-artwork-failed',
  PLAY_PRINT_FAILED: 'play-print-failed',
  ORIGINAL_ARTWORK_ALREADY_PLAYED: 'original-artwork-already-played',
  PRINT_ALREADY_PLAYED: 'print-already-played',
};

const buyingAndSellingErrors = {
  MARK_NOT_AVAILABLE_FOR_RENT_FAILED: 'mark-not-available-for-rent-failed',
};

const signupErrors = {
  ACCOUNT_EXISTED_ERROR: 'account-existed-error',
  EMAIL_EXISTS: 'account-existed-error',
  INVALID_INVITE_CODE: 'invalid-invite-code',
};

const pairingCodeErrors = {
  INVALID_OTP: 'invalid-otp',
};

const sendingEmailVerificationErrors = {
  GENERATE_VERIFY_LINK_ERROR: 'generate-verify-link-error',
};

const errorMessages = {
  [commonErrors.INVALID_PARAMS_ERROR]: 'Invalid params',
  [commonErrors.PRINT_NOT_FOUND_ERROR]: 'Print not found',
  [commonErrors.CANVAS_NOT_FOUND_ERROR]: 'Canvas not found',
  [commonErrors.FETCH_PRINT_ERROR]: 'Failed to fetch print',
  [commonErrors.FETCH_CANVAS_ERROR]: 'Failed to fetch canvas',
  [commonErrors.FETCH_ORIGINAL_ARTWORK_ERROR]:
    'Failed to fetch original artwork',
  [commonErrors.FETCH_PLAYING_PRINT_ERROR]: 'Failed to fetch playing prints',
  [commonErrors.FETCH_PLAYING_ORIGINAL_ARTWORK_ERROR]:
    'Failed to fetch playing original artwork',
  [commonErrors.INSUFFICIENT_PERMISSIONS_ERROR]: 'Insufficient permission',
  [commonErrors.NEED_VERIFY_EMAIL_ERROR]:
    'You need to verify email to accomplish this action',
  [commonErrors.USER_NOT_FOUND]: 'User not found',
  [playArtworkErrors.PLAY_ORIGINAL_ARTWORK_FAILED]:
    'Failed to play original artwork',
  [playArtworkErrors.PLAY_PRINT_FAILED]: 'Failed to play print',
  [playArtworkErrors.ORIGINAL_ARTWORK_ALREADY_PLAYED]:
    'This original artwork has already played on your Digital canvas app',
  [playArtworkErrors.PRINT_ALREADY_PLAYED]: 'print-already-played',
  [buyingAndSellingErrors.MARK_NOT_AVAILABLE_FOR_RENT_FAILED]:
    'Failed to disable for rent',
  [signupErrors.ACCOUNT_EXISTED_ERROR]: 'Your email or username was used',
  [signupErrors.EMAIL_EXISTS]: 'Your email or username was used',
  [signupErrors.INVALID_INVITE_CODE]: 'Invalid invite code',
  // Errors from firebase.auth
  'auth/expired-action-code': 'Action code expired',
  'auth/invalid-action-code': 'Invalid action code',
  'auth/user-disabled': 'The user has been disabled',
  'auth/user-not-found':
    'There is no user record corresponding to this identifier. The user may have been deleted.',
  'auth/weak-password': 'The password is too weak',
  'auth/wrong-password':
    'The password is invalid or the user does not have a password.',
  'auth/invalid-email': 'The email address is not valid.',
  'auth/email-already-in-use': 'Your email or username was used',
  [pairingCodeErrors.INVALID_OTP]: 'Invalid OTP',
  [sendingEmailVerificationErrors.GENERATE_VERIFY_LINK_ERROR]:
    'Please wait 2 minutes before resending verification email again.',
  'E_001':
    'This original artwork has already been sold to another user. The new owner may offer the artwork for sale again when the purchase is complete. Check the page later to see new offers.',
  'E_002':
    'This edition has been already sold to another user. Please select another edition number or check back later for new offers.',
  'E_003': 'Something went wrong',
  'E_004': 'Get Promo Code error',
  'E_005': 'No Promo Code found',
  'internet-unavailable':
    'Your internet connection is unstable or unavailable. Please try again',
};

const getErrorMessage = (code: string) => {
  if (errorMessages[code]) {
    return errorMessages[code];
  }

  return 'Unknown error';
};

export {
  commonErrors,
  playArtworkErrors,
  buyingAndSellingErrors,
  getErrorMessage,
  pairingCodeErrors,
  sendingEmailVerificationErrors,
};
