import { useEffect, useState } from 'react';
import { useFirebaseContext } from 'shell/firebase';
import { UserData } from 'constants/data/user.data';
import { CanvasData } from 'constants/data/canvas.data';
import { responseToArray } from '../common';
import { useAuthContext } from 'shell/session';

export const useLoadCanvasesByUser = (user: UserData) => {
  const firebase = useFirebaseContext();
  const [canvases, setCanvases] = useState<CanvasData[]>();

  useEffect(() => {
    if (user) {
      const canvasIds = Object.keys(user.canvases || {});
      const promises = canvasIds.map(canvasId =>
        firebase.canvasRef(canvasId).once('value')
      );
      Promise.all(promises).then(snapshots => {
        const result = snapshots.map(snap => ({
          uid: snap.key,
          ...snap.val(),
        }));
        setCanvases(result);
      });
    } else {
      setCanvases([]);
    }
  }, [firebase, user]);
  return { canvases, isLoading: typeof canvases === 'undefined' };
};

export const useLoadCanvasById = ({
  canvasId,
  originalArtwork,
}: {
  canvasId?: string;
  originalArtwork?: string;
}) => {
  const firebase = useFirebaseContext();
  const [canvas, setCanvas] = useState<CanvasData | null>(null);
  const { user } = useAuthContext();
  useEffect(() => {
    if (canvasId) {
      firebase.canvasRef(canvasId).once(
        'value',
        snapshot => {
          setCanvas({
            ...snapshot.val(),
            uid: canvasId,
          });
        },
        () => {
          setCanvas(null);
        }
      );
      return;
    }
    if (originalArtwork && user) {
      firebase
        .canvasesRef()
        .orderByChild('originalArtwork')
        .equalTo(originalArtwork)
        .once(
          'value',
          snapshot => {
            if (snapshot.val() === null) {
              setCanvas(null);
              return;
            }
            const canvases = responseToArray(
              snapshot.val(),
              'uid'
            ) as CanvasData[];
            const myCanvas = canvases.find(
              canvas => user.canvases && user.canvases[canvas.uid]
            );
            setCanvas(myCanvas || null);
          },
          () => {
            setCanvas(null);
          }
        );
      return;
    }
  }, [canvasId, originalArtwork, firebase, user]);

  return { canvas, isLoading: canvas === undefined };
};
