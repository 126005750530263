import { useEffect, useState } from 'react';
import { database } from 'firebase/app';
import Firebase, { useFirebaseContext } from 'shell/firebase';
import { ImageData } from 'constants/data/image.data';
import { UserData } from 'constants/data/user.data';
import { CanvasData } from 'constants/data/canvas.data';
import { Print as PrintData } from 'constants/data/print.data';
import { CheckPublishInterface } from 'utils/common/checkIsImagePublishable';
import { FilterValues } from 'modules/filter';
import {
  filterImages as filterImagesBase,
  isPassCatalogFilter,
  isPassAssociationFilter,
  isPassColorFilter,
  isPassBrightnessFilter,
  isPassAbstractionFilter,
  isPassStatusFilter,
} from 'utils/common/filterImages';
import { filterPublishedImages } from 'utils/common/filterPublishedImages';

export const useLoadImages = (userId: string, firebase: Firebase) => {
  const [images, setImages] = useState<ImageData[]>();
  useEffect(() => {
    const query = firebase.userImagesRef(userId);
    const callback = async (snapshot: database.DataSnapshot) => {
      const imageIds = Object.keys(snapshot.val() || {});
      const promises = imageIds.map(imageId =>
        firebase.imageRef(imageId).once('value')
      );
      const snapshots = await Promise.all(promises);
      const result = snapshots.map(snap => ({
        uid: snap.key,
        ...snap.val(),
      }));
      setImages(filterPublishedImages(result));
    };
    query.on('value', callback);

    return () => query.off('value', callback);
  }, [firebase, userId]);
  return { images, isLoading: typeof images === undefined };
};

// TODO: move this method to somewhere for the reuse purpose
export const useLoadArtist = (artistId: string) => {
  const firebase = useFirebaseContext();
  const [artist, setArtist] = useState<UserData>();
  useEffect(() => {
    if (firebase !== null) {
      const query = firebase.userRef(artistId);
      const callback = (snapshot: database.DataSnapshot) => {
        const data = snapshot.val();
        setArtist(data);
      };
      query.on('value', callback);
      return () => query.off('value', callback);
    }
  }, [artistId, firebase]);

  return {
    artist,
    isLoadingArtist: typeof artist === 'undefined',
  };
};

export const useLoadPlayingCanvas = ({
  imageId,
  userId,
  firebase,
}: {
  imageId: string;
  userId: string;
  firebase: Firebase;
}) => {
  const [playingCanvasId, setPlayingCanvasId] = useState<string | null>();
  const [playingCanvas, setPlayingCanvas] = useState<CanvasData | null>();

  // effect to load canvasId of the canvas of the user that is playing the image
  useEffect(() => {
    if (firebase !== null) {
      const query = firebase
        .printsRef()
        .orderByChild('imageId')
        .equalTo(imageId);
      const stopListener = query.on('value', snapshot => {
        const value = snapshot.val();
        if (value === null) {
          setPlayingCanvasId(null);
          return;
        }
        const prints = Object.values<PrintData>(value);
        const ownedPrints = prints.filter(({ tenant }) => tenant === userId);
        // TODO: update when Multiple Prints feature is available
        const canvasId = ownedPrints[0] && ownedPrints[0].canvasId;
        setPlayingCanvasId(canvasId ? canvasId : null);
      });
      return () => query.off('value', stopListener);
    }
  }, [firebase, imageId, userId]);

  // Effect to load canvas details
  useEffect(() => {
    if (playingCanvasId === null) {
      setPlayingCanvas(null);
    }
    if (firebase !== null && playingCanvasId) {
      const query = firebase.canvasRef(playingCanvasId);
      const stopListener = query.on('value', snapshot => {
        const value = snapshot.val();
        if (value === null) {
          setPlayingCanvas(null);
        } else {
          setPlayingCanvas({
            uid: playingCanvasId,
            ...value,
          });
        }
      });
      return () => query.off('value', stopListener);
    }
  }, [firebase, playingCanvasId]);

  return {
    canvas: playingCanvas,
  };
};

export const filterImages = (
  images: ImageData[],
  {
    catalogArr,
    associationArr,
    colorArr,
    brightnessArr,
    abstractionArr,
    isPublished,
  }: FilterValues,
  { checkIsPublishable }: { checkIsPublishable: CheckPublishInterface }
) => {
  return filterImagesBase(
    images,
    isPassCatalogFilter(catalogArr || []),
    isPassAssociationFilter(associationArr || []),
    isPassColorFilter(colorArr || []),
    isPassBrightnessFilter(brightnessArr || []),
    isPassAbstractionFilter(abstractionArr || []),
    isPassStatusFilter(isPublished, { checkIsPublishable })
  );
};
