import { Box, makeStyles, createStyles, Theme } from '@material-ui/core';
import cs from 'classnames';
import { mobileMediaQuery } from 'utils/common';
import AppIcon from 'static/images/mobile_logo.png';
import { GOOGLE_PLAY_APP_URL, APP_STORE_APP_URL } from 'constants/common';
import GooglePlayIcon from 'static/images/google_play_badge.png';
import AppStoreIcon from 'static/images/app_store_badge.png';
import Typography from 'modules/typography';

const LogoAndDownload = ({ className }: { className?: string }) => {
  const classes = useStyles();

  return (
    <Box className={cs(classes.logoAndDownload, className)}>
      <Box className={classes.logoWrapper}>
        <img src={AppIcon} alt="canvas-app" className={classes.canvasAppLogo} />
        <Box>
          <Typography className={classes.digitalCanvas}>
            Digital Canvas
          </Typography>
          <Typography className={classes.byActiveImage}>
            By Active Image
          </Typography>
        </Box>
      </Box>
      <AppLogos />
    </Box>
  );
};

export const AppLogos = () => {
  const classes = useStyles();

  return (
    <Box className={classes.buttonsWrapper}>
      <a href={APP_STORE_APP_URL} target="_blank" rel="noreferrer">
        <img src={AppStoreIcon} alt="App Store" className={classes.badge} />
      </a>
      <a href={GOOGLE_PLAY_APP_URL} target="_blank" rel="noreferrer">
        <img src={GooglePlayIcon} alt="Google Play" className={classes.badge} />
      </a>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    logoAndDownload: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [mobile]: {
        flexWrap: 'wrap',
        gap: theme.spacing(4) + 'px',
      },
    },
    logoWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    canvasAppLogo: {
      width: 40,
      height: 40,
      marginRight: theme.spacing(1.2),
    },
    digitalCanvas: {
      fontWeight: 500,
      fontSize: '0.9375rem',
      lineHeight: 1.5,
    },
    byActiveImage: {
      fontSize: '0.5625rem',
      lineHeight: 1.2,
      textTransform: 'uppercase',
      letterSpacing: '0.12em',
    },
    buttonsWrapper: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    badge: {
      width: 100,
      display: 'block',
    },
  });
});

export default LogoAndDownload;
