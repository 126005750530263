import { createContext, useCallback, useMemo, useContext, useRef } from 'react';
import { checkIsImagePublishable, CheckPublishableResult } from 'utils/common';
import { ImageData } from 'constants/data/image.data';
interface ContextProps {
  checkIsPublishable: (image: ImageData) => CheckPublishableResult;
}

const ImageFieldsContext = createContext<ContextProps>({
  checkIsPublishable: () => ({ isPublishable: true, errors: [] }),
});

export const ImageFieldsProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const statusMapRef = useRef<Record<string, CheckPublishableResult>>({});

  const checkIsPublishable = useCallback((image: ImageData) => {
    // Use the whole image as key instead of uid to make sure the image with updated fields will be checked again.
    const key = JSON.stringify(image);
    if (typeof statusMapRef.current[key] === 'undefined') {
      statusMapRef.current[key] = checkIsImagePublishable(image);
    }
    return statusMapRef.current[key];
  }, []);

  const contextValue = useMemo(
    () => ({
      checkIsPublishable,
    }),
    [checkIsPublishable]
  );

  return (
    <ImageFieldsContext.Provider value={contextValue}>
      {children}
    </ImageFieldsContext.Provider>
  );
};

export const useImageFieldsContext = () => useContext(ImageFieldsContext);
