import { Box, makeStyles, createStyles, Theme } from '@material-ui/core';
import Typography from 'modules/typography';
import LogoAndDownload from 'modules/logoAndDownload';
import PairingCode from './PairingCode';
import { ImageData } from 'constants/data';

interface PairingCanvasBoxProps {
  image?: ImageData;
  printId?: string;
  onNext?: () => void;
}

const PairingCanvasBox = ({
  image,
  printId,
  onNext,
}: PairingCanvasBoxProps) => {
  const classes = useStyles();

  return (
    <Box>
      <Box pb={2}>
        <Typography className={classes.boxHeaderText}>
          Canvas Pairing
        </Typography>
      </Box>
      <Box display="flex" className={classes.pairingBox}>
        <Box pt={3} pr={10} className={classes.pairingBoxDesc}>
          <Typography className={classes.titleDisplayOnDevices}>
            Display artworks on your TV, phone or tablet now
          </Typography>
          <Typography className={classes.descriptionDisplayOnDevices}>
            Download the Digital Canvas App, enter the pairing code below, and
            the artwork will appear on display on your device
          </Typography>
        </Box>
      </Box>
      <Box mt={4} mb={3}>
        <LogoAndDownload />
      </Box>
      <Box px={0} pb={3}>
        <PairingCode image={image} printId={printId} onNext={onNext} />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    pairingBox: {
      borderTop: '1px solid #D8D8D8',
    },
    pairingBoxDesc: {
      [theme.breakpoints.down('md')]: {
        paddingRight: 0,
      },
    },
    boxHeaderText: {
      fontSize: theme.typography.pxToRem(11),
      letterSpacing: '0.12em',
      textTransform: 'uppercase',
    },
    titleDisplayOnDevices: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.2,
      marginBottom: theme.spacing(1.5),
    },
    descriptionDisplayOnDevices: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.6,
      color: '#030303',
      marginBottom: theme.spacing(2),
    },
  });
});

export default PairingCanvasBox;
