import { Box, makeStyles, createStyles, Theme } from '@material-ui/core';
import { ReactComponent as Logo } from 'static/images/logo.svg';
import { useLog } from 'utils/hooks/useLog';
import { useEffect } from 'react';
import { useStyles } from 'modules/topBar/TopBar';
import Footer from 'modules/footer';
import { Headline } from 'views/auth/common/Typography';

const Error = ({
  error,
  showHeader = true,
  showFooter = true,
}: {
  error?: any;
  showHeader?: boolean;
  showFooter?: boolean;
}) => {
  const log = useLog();
  const classes = useStyles();
  const errorClasses = useErrorStyles();

  useEffect(() => {
    if (error) {
      log({
        type: 'error',
        message: error.message || 'Unknown error',
      });
    }
  }, [log, error]);

  return (
    <>
      {showHeader && (
        <Box className={classes.container}>
          <Box className={classes.topBar}>
            <Box className={classes.topBarLogo}>
              <Logo className={classes.logo} />
            </Box>
          </Box>
        </Box>
      )}
      <div className="page">
        <Box className={errorClasses.content}>
          <h1>Error</h1>
          <Headline>
            Oops, something went wrong, please try again later.
          </Headline>
        </Box>
      </div>
      {showFooter && <Footer />}
    </>
  );
};

const useErrorStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      minHeight: '50vh',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  })
);

export default Error;
