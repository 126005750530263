import { Box, createStyles, makeStyles } from '@material-ui/core';
import Typography from 'modules/typography';
import ImageBox from 'modules/imageBox';

const ResellGuidanceSteps = ({
  containerClassName,
}: {
  containerClassName?: string;
}) => {
  const classes = useStyles();
  return (
    <Box className={containerClassName}>
      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <ImageBox
          path={'statics%2Fstep1-connect-bank-account.png'}
          ratio={'1:1'}
          alt={'step1-connect-bank-account'}
          width={46}
          classNames={{ root: classes.imageBox }}
        />
        <Box ml={2.5}>
          <Box mb={0.25}>
            <Typography
              fontSize={'0.675rem'}
              lineHeight={'150%'}
              letterSpacing={'0.12em'}>
              STEP 1
            </Typography>
          </Box>
          <Typography fontSize={'0.875rem'} lineHeight={'150%'}>
            Connect bank account
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <ImageBox
          path={'statics%2Fstep2-set-price.png'}
          ratio={'1:1'}
          alt={'step2-set-price'}
          width={46}
          classNames={{ root: classes.imageBox }}
        />
        <Box ml={2.5}>
          <Box mb={0.25}>
            <Typography
              fontSize={'0.675rem'}
              lineHeight={'150%'}
              letterSpacing={'0.12em'}>
              STEP 2
            </Typography>
          </Box>
          <Typography fontSize={'0.875rem'} lineHeight={'150%'}>
            Set price
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <ImageBox
          path={'statics%2Fstep3-resell.png'}
          ratio={'1:1'}
          alt={'step3-resell'}
          width={46}
          classNames={{ root: classes.imageBox }}
        />
        <Box ml={2.5}>
          <Box mb={0.25}>
            <Typography
              fontSize={'0.675rem'}
              lineHeight={'150%'}
              letterSpacing={'0.12em'}>
              STEP 3
            </Typography>
          </Box>
          <Typography fontSize={'0.875rem'} lineHeight={'150%'}>
            Enable resell
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => {
  return createStyles({
    imageBox: {
      backgroundColor: 'transparent',
    },
  });
});

export default ResellGuidanceSteps;
