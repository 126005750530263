import { makeStyles, createStyles, Theme } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      backgroundColor: '#EEEEEE',
    },
    inputError: {
      border: '1px solid red',
    },
    inputNotchedOutline: {
      border: 'none',
    },
    input: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    inputInput: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    inputInputMultiline: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    labelRoot: {
      'color': theme.palette.common.black,
      'textTransform': 'uppercase',
      'fontSize': '0.75rem',
      'letterSpacing': '0.12em',
      'marginBottom': theme.spacing(1.5),
      '&$labelOutlined$labelShrink': {
        transform: 'none',
      },
    },
    labelShrink: {},
    labelOutlined: {},
    labelFormControl: {
      transform: 'none',
      position: 'relative',
    },
    formHelperTextContained: {
      marginLeft: 0,
      marginRight: 0,
      whiteSpace: 'pre-wrap',
    },
    selectInput: {
      'backgroundColor': '#EEEEEE',
      'padding': theme.spacing(1.5),
      'borderRadius': theme.shape.borderRadius,
      'border': '1px solid rgba(0, 0, 0, 0.23)',
      '&:hover': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
      },
    },
  })
);

export default useStyles;
