import React, { useEffect } from 'react';

import styles from './styles.module.scss';

type AppThemeContextType = {};
const AppThemeContext = React.createContext<AppThemeContextType | null>(null);

type AppThemeProviderProps = React.PropsWithChildren<{}>;
function AppThemeProvider({ children }: AppThemeProviderProps) {
  useEffect(() => {
    document.body.classList.add(styles.appTheme);

    return () => {
      document.body.classList.remove(styles.appTheme);
    };
  }, []);

  return (
    <AppThemeContext.Provider value={null}>{children}</AppThemeContext.Provider>
  );
}

export default AppThemeProvider;
