import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogTitle,
  DialogActions,
  makeStyles,
  createStyles,
  CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, FileField } from 'modules/formFields';
import { useModal } from 'modules/modals';
import { EditableImageLog, IEditableImage } from 'constants/data';
import {
  useSubmitEditableImage,
  IEditableImageFormValues,
  useLogEditableImageChange,
} from './helpers';
import { getLinkToSave } from '../editableText';
import { ALLOWED_VIDEO_FORMAT, ALLOWED_IMAGE_FORMAT } from 'constants/common';

import UIButton from 'ui-components/Button';

interface IProps extends Omit<DialogProps, 'onClose'> {
  onClose: () => void;
  imageKey: string;
  editableImage?: IEditableImage | null;
}

const defaultValues = {
  alt: '',
  imageFile: undefined,
  videoFile: undefined,
  url: '',
};

const UploadImageDialog = ({
  imageKey,
  editableImage,
  ...dialogProps
}: IProps) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const submitEditableImage = useSubmitEditableImage();
  const { openDialog: openConfirmDialog, showSnackbar } = useModal();
  const classes = useStyles();
  const logEditableImageChange = useLogEditableImageChange();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<IEditableImageFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  useEffect(() => {
    reset({
      ...defaultValues,
      alt: editableImage?.alt || '',
      url: editableImage?.url || '',
    });
  }, [editableImage?.alt, reset, editableImage?.url]);

  const logEventUpdate = (
    values: IEditableImageFormValues,
    newImagePath: string,
    newVideoPath: string,
    newMobileVideoPath: string
  ) => {
    const logData: EditableImageLog = {
      imageKey,
      oldData: {
        imagePath: editableImage?.path || '',
        videoPath: editableImage?.videoPath || '',
        mobileVideoPath: editableImage?.mobileVideoPath || '',
        alt: editableImage?.alt || '',
        link: editableImage?.url || '',
      },
      newData: {
        imagePath: newImagePath || '',
        videoPath: newVideoPath || '',
        mobileVideoPath: newMobileVideoPath || '',
        alt: values.alt || '',
        link: values.url || '',
      },
    };
    return logEditableImageChange(logData);
  };

  const onSubmit = (values: IEditableImageFormValues) => {
    setIsSubmiting(true);
    if (values.url) {
      values.url = getLinkToSave(values.url);
    }

    if (!imageKey) {
      return;
    }

    submitEditableImage({
      values,
      imageKey,
      oldImagePath: editableImage?.path,
      oldVideoPath: editableImage?.videoPath,
      oldMobileVideoPath: editableImage?.mobileVideoPath,
    })
      .then(({ newImagePath, newVideoPath, newMobileVideoPath }) => {
        setIsSubmiting(false);
        logEventUpdate(values, newImagePath, newVideoPath, newMobileVideoPath);
        showSnackbar('Updated successfully');
        dialogProps.onClose();
      })
      .catch(error => {
        setIsSubmiting(false);
        console.error('error', error);
        showSnackbar('Something went wrong', { severity: 'error' });
      });
  };
  const handleCancel = async () => {
    let canClose = true;
    if (isDirty === true) {
      canClose = await openConfirmDialog({
        message: 'Are you sure you want to discard your changes and quit?',
      });
    }
    if (canClose) {
      reset({ ...defaultValues });
      dialogProps.onClose();
    }
  };

  const handleClose = (reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (!reason && dialogProps.onClose) {
      dialogProps.onClose();
    }
  };

  return (
    <Dialog {...dialogProps} onClose={(event, reason) => handleClose(reason)}>
      <DialogTitle>Upload Image</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="imageFile"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FileField
                className={classes.textField}
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error && error.message}
                label="Image File"
                type="file"
                inputProps={{ accept: ALLOWED_IMAGE_FORMAT.join(',') }}
              />
            )}
          />
          <Controller
            name="videoFile"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FileField
                className={classes.textField}
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error && error.message}
                label="Video File"
                type="file"
                inputProps={{ accept: ALLOWED_VIDEO_FORMAT.join(',') }}
              />
            )}
          />
          <Controller
            name="mobileVideoFile"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FileField
                className={classes.textField}
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error && error.message}
                label="Mobile Video File"
                type="file"
                inputProps={{ accept: ALLOWED_VIDEO_FORMAT.join(',') }}
              />
            )}
          />
          <Controller
            name="alt"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                className={classes.textField}
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error && error.message}
                label="Alt Text"
                type="text"
              />
            )}
          />
          <Controller
            name="url"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Link"
                fullWidth
                autoFocus={true}
                autoComplete="off"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error && error.message}
              />
            )}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {isSubmiting && <CircularProgress size={20} />}
          <UIButton
            variant="text"
            size="medium"
            onClick={handleCancel}
            disabled={isSubmiting}
            text="Cancel"
            type={'button'}
          />
          <UIButton
            variant="primary"
            size="medium"
            type="submit"
            disabled={isSubmiting}
            text="Submit"
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    dialogActions: {
      padding: theme.spacing(0, 3, 2),
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
  })
);

const MAX_FILE_SIZE = 25 * 1024 * 1024;
const validationSchema = Yup.object().shape({
  imageFile: Yup.mixed().test(
    'invalid_file_type',
    'Invalid image format',
    function (file) {
      if (!file) {
        return true;
      }
      try {
        return ALLOWED_IMAGE_FORMAT.includes(file.type);
      } catch (e) {
        console.error(e);
        return false;
      }
    }
  ),
  videoFile: Yup.mixed()
    .test('invalid_file_type', 'Invalid video format', function (file) {
      if (!file) {
        return true;
      }
      try {
        return ALLOWED_VIDEO_FORMAT.includes(file.type);
      } catch (e) {
        console.error(e);
        return false;
      }
    })
    .test(
      'invalid_file_size',
      `File size should be less than ${MAX_FILE_SIZE / 1024 / 1024}MB`,
      function (file) {
        if (!file) {
          return true;
        }
        try {
          return file.size < MAX_FILE_SIZE;
        } catch (e) {
          console.error(e);
          return false;
        }
      }
    ),
  alt: Yup.string().required('Required'),
});
export default UploadImageDialog;
