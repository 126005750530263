import { useEffect, useState } from 'react';
import { Box, Theme, CircularProgress, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
  ImageData,
  Print as PrintData,
  TransactionData,
  UserData,
} from 'constants/data';
import { mobileMediaQuery } from 'utils/common';
import { useIsMobile } from 'utils/hooks';
import ContentWrapper from './ContentWrapper';
import { useFirebaseContext } from 'shell/firebase';
import pw from 'a-promise-wrapper';
import { Alert } from '@material-ui/lab';
import { ReactComponent as GiftIcon } from 'static/images/giftIcon.svg';
import Typography from 'modules/typography';
import OptimizedImage from 'modules/OptimizedImage';
import { useAcceptGift } from 'utils/requests';
import { useModal } from 'modules/modals';
import { useAuthContext } from 'shell/session';

import UIButton from 'ui-components/Button';

interface UnClaimedGiftProps {
  txId: string;
}

interface GetGiftNameInputs {
  isPrint: boolean;
  name: string;
  editionIndex?: number;
  total?: number;
}

interface PreviewImageProps {
  image: ImageData;
}

const getGiftName = (inputs: GetGiftNameInputs) => {
  const { isPrint, name, editionIndex, total } = inputs;

  if (isPrint) {
    if (editionIndex && total) {
      return `${name}, Edition #${editionIndex} of ${total}`;
    }

    return `${name}, unknown data`;
  }

  return `${name}, Original`;
};

/**
 * This function is not good enough for reuseable,
 * consider later
 */
const useLoadInitData = (txId: string) => {
  /**
   * Input: transactionId
   * TODO:
   * - Get transaction
   * - Get image/print data
   * - Get sender information
   */
  const firebase = useFirebaseContext();

  const [isPrint, setIsPrint] = useState<boolean>(true);
  const [image, setImage] = useState<ImageData | null>();
  const [print, setPrint] = useState<PrintData | null>();
  const [sender, setSender] = useState<UserData | null>();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const onError = () => {
      setImage(null);
      setPrint(null);
      setSender(null);
      setIsPrint(false);
      setIsError(true);
      setIsLoading(false);
    };
    const getTransaction = async (id: string) => {
      const ref = firebase.transactionsRefByKey(id);
      const { data: snapshot, error } = await pw(ref.once('value'));

      if (error) {
        onError();

        return;
      }

      const value = snapshot.val() as TransactionData;
      if (!value) {
        onError();

        return;
      }

      return value;
    };
    const getPrint = async (id: string) => {
      const ref = firebase.printRef(id);
      const { data: snapshot, error } = await pw(ref.once('value'));

      if (error) {
        onError();

        return;
      }

      const value = snapshot.val() as PrintData;
      if (!value) {
        onError();

        return;
      }

      return value;
    };
    const getImage = async (id: string) => {
      const ref = firebase.imageRef(id);
      const { data: snapshot, error } = await pw(ref.once('value'));

      if (error) {
        onError();

        return;
      }

      const value = snapshot.val() as ImageData;
      if (!value) {
        onError();

        return;
      }

      return value;
    };
    const getUser = async (id: string) => {
      const ref = firebase.userRef(id);
      const { data: snapshot, error } = await pw(ref.once('value'));

      if (error) {
        onError();

        return;
      }

      const value = snapshot.val() as UserData;
      if (!value) {
        onError();

        return;
      }

      return value;
    };
    const getData = async () => {
      const transaction = await getTransaction(txId);

      if (!transaction) {
        return;
      }

      const { printId, originalArtworkId } = transaction;
      const isPrint = !!printId;

      /** Get image */
      let imageId = originalArtworkId;
      let senderId;
      let print;
      if (isPrint) {
        print = await getPrint(printId);

        if (!print) {
          return;
        }

        imageId = print.imageId;
        senderId = print.owner;
      }

      const image = await getImage(imageId);
      /** End of Get image */

      /** Get sender */
      if (!image) {
        return;
      }

      if (!isPrint) {
        senderId = image.owner;
      }

      if (!senderId) {
        return;
      }

      const sender = await getUser(senderId);

      if (!sender) {
        return;
      }
      /** End of Get sender */

      /**
       * All data is ready now
       */
      setIsPrint(isPrint);
      setImage(image);
      setPrint(print);
      setSender(sender);
      setIsLoading(false);
    };

    getData();
  }, [txId, firebase]);

  return {
    isLoading,
    isError,
    isPrint,
    image,
    print,
    sender,
  };
};

const PreviewImage = (props: PreviewImageProps) => {
  const { image } = props;
  const classes = useStyles();

  return (
    <OptimizedImage
      path={image.path}
      alt={image.name}
      className={classes.imagePreview}
    />
  );
};

const UnClaimedGift = (props: UnClaimedGiftProps) => {
  const { txId } = props;
  const initData = useLoadInitData(txId);
  const [isOpen, setOpen] = useState<boolean>(true);
  const { isEmailVerified, showVerifyDialog } = useAuthContext();
  const classes = useStyles();
  const isMobile = useIsMobile();

  // Loading state for claiming request
  const [isWaiting, setIsWaiting] = useState(false);

  const claimFunc = useAcceptGift();
  const { showSnackbar } = useModal();

  const { isError, isLoading, isPrint, image, print, sender } = initData;

  if (isLoading) {
    return (
      <ContentWrapper onClose={() => {}} isOpen={isOpen}>
        <Box>
          <CircularProgress />
        </Box>
      </ContentWrapper>
    );
  }

  const isInvalid = !sender || !image;
  if (isError || isInvalid) {
    return (
      <ContentWrapper onClose={() => {}} isOpen={isOpen}>
        <Alert severity="error">Failed to load this gift</Alert>
      </ContentWrapper>
    );
  }

  const { email: senderEmail } = sender;
  const { name, numberOfPrints } = image;

  const giftName = getGiftName({
    isPrint,
    name,
    editionIndex: isPrint && print ? print.editionIndex : undefined,
    total: numberOfPrints,
  });

  const onClaim = async () => {
    if (!isEmailVerified) {
      showVerifyDialog();
      return;
    }

    setIsWaiting(true);

    const { error } = await pw(claimFunc(txId));
    if (error) {
      setIsWaiting(false);
      return showSnackbar('Failed to claim gift', { severity: 'error' });
    }

    setIsWaiting(false);
    showSnackbar('Successfully claimed!');
  };

  return (
    <ContentWrapper onClose={() => setOpen(false)} isOpen={isOpen}>
      <Box pl={4.5}>
        <Grid container>
          <Box
            mb={1.5}
            height={24}
            position="absolute"
            top={20}
            left={24}
            overflow="hidden">
            <GiftIcon />
          </Box>

          <Grid item container>
            <Grid item container xs={12} sm={9}>
              <Grid item xs={12}>
                <Typography fontWeight={500} fontSize={16} lineHeight={1.6}>
                  You Received a Gift!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize={'0.875rem'} lineHeight={'150%'}>
                  After you claim the gift, you’ll become the new owner. You’ll
                  be able to play the artwork/collectible on your devices or
                  resell.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2} mb={1}>
                  <Box width={80} display="inline-block">
                    <Typography
                      variant="caption"
                      fontSize={11}
                      fontWeight={400}>
                      GIFT
                    </Typography>
                  </Box>
                  <Typography variant="caption" fontSize={14} lineHeight={1.5}>
                    {giftName}
                  </Typography>
                </Box>
                <Box my={1}>
                  <Box width={80} display="inline-block">
                    <Typography
                      variant="caption"
                      fontSize={11}
                      fontWeight={400}>
                      FROM
                    </Typography>
                  </Box>
                  <Typography variant="caption" fontSize={14} lineHeight={1.5}>
                    {senderEmail}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={3}
              justifyContent={isMobile ? 'center' : 'flex-end'}>
              <Box mt={3}>
                <PreviewImage image={image} />
              </Box>
            </Grid>
            <Grid item container xs={12}>
              <Box mt={4} mb={2} width="100%">
                <UIButton
                  fullWidth={isMobile}
                  variant="primary"
                  size="small"
                  text="Claim Your Gift"
                  className={classes.actionButton}
                  disabled={isWaiting}
                  loading={isWaiting}
                  onClick={onClaim}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ContentWrapper>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    imagePreview: {
      maxWidth: '100%',
      maxHeight: 160,
      [mobile]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    },
    actionButton: {
      marginRight: theme.spacing(2),
      [mobile]: {
        marginRight: 0,
        marginBottom: theme.spacing(2),
      },
    },
  });
});

export default UnClaimedGift;
