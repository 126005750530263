import useSWR from 'swr';
import { useFirebaseContext } from 'shell/firebase';
import pw from 'a-promise-wrapper';

export const useLoadTransferPrintGasFee = (printId: string) => {
  const firebase = useFirebaseContext();
  const callable = firebase.functions.httpsCallable(
    'blockchain-estimateTransferPrintGasFee'
  );

  const { data, error, isValidating } = useSWR(
    `loadTransferPrintGasFee-${printId}`,
    () => callable({ printId }).then(res => res.data)
  );

  return {
    gasFee:
      typeof data !== 'undefined' ? Math.round(data * 100) / 100 : undefined,
    error,
    isLoading: isValidating,
  };
};

export const useLoadTransferOriginalGasFee = (imageId: string) => {
  const firebase = useFirebaseContext();
  const callable = firebase.functions.httpsCallable(
    'blockchain-estimateTransferOriginalGasFee'
  );

  const { data, error, isValidating } = useSWR(
    `loadTransferOriginalGasFee-${imageId}`,
    () => callable({ imageId }).then(res => res.data)
  );

  return {
    gasFee:
      typeof data !== 'undefined' ? Math.round(data * 100) / 100 : undefined,
    error,
    isLoading: isValidating,
  };
};

export const useLoadCountriesList = () => {
  const fb = useFirebaseContext();
  return async () => {
    const { data, error } = await pw(
      fb.configsRef('stripeCountries').once('value')
    );
    if (error) {
      console.error(error);
      return [];
    }

    return data.val() || [];
  };
};
