import { useState } from 'react';
import { useFirebaseContext } from 'shell/firebase';
import { makeStyles, createStyles, Theme, Box } from '@material-ui/core';
import { mobileMediaQuery } from 'utils/common';
import { useModal } from 'modules/modals';
import AuthLayout from '../AuthLayout';
import ResetPasswordForm from './ResetPasswordForm';
import { Heading, Headline } from '../common/Typography';

const ResetPassword = () => {
  const firebase = useFirebaseContext();
  const classes = useStyles();
  const { showSnackbar } = useModal();
  const [loading, setLoading] = useState<boolean>(false);

  const query = (values: any) => {
    setLoading(true);
    firebase
      .doPasswordReset(values.email)
      .then(() => {
        setLoading(false);
        showSnackbar(
          'Please check your inbox. You should receive an email soon with instructions on how to reset your password.'
        );
      })
      .catch(error => {
        setLoading(false);
        showSnackbar(error.message, { severity: 'error' });
      });
  };

  return (
    <AuthLayout>
      <Box className={classes.container}>
        <Headline>Account</Headline>
        <Box mt={2}>
          <Heading>Reset Password</Heading>
        </Box>
        <Box className={classes.resetPasswordForm}>
          <ResetPasswordForm onSubmit={query} loading={loading} />
        </Box>
      </Box>
    </AuthLayout>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(11),
      width: '100%',
      [mobile]: {
        marginLeft: 0,
      },
    },
    resetPasswordForm: {
      flex: 1,
      marginTop: theme.spacing(2.5),
    },
  });
});

export default ResetPassword;
