import React, { useContext, useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ExhibitionData } from 'constants/data/exhibition.data';
import { Print as PrintData } from 'constants/data/print.data';
import { ImageData } from 'constants/data/image.data';
import { useFirebaseContext } from 'shell/firebase';
import { useAuthContext } from 'shell/session';
import useQuery, { QueryStatus } from 'utils/hooks/useQuery';
import { isStreamingUnlimited } from 'views/account/upload/helpers';
import LoadingIndicator from './LoadingIndicator';
import {
  CanvasData,
  IPrintTransform,
  StreamingArtworkSlotsInfo,
} from 'constants/data';
import {
  useGetExhibitionArtworkStreaming,
  useLoadBuyablePrints,
} from 'utils/requests';
import { useLoadPrintsOwnedByUser } from 'utils/requests';

type ArtworkContextType = {
  exhibition: ExhibitionData | null;
  exhibitionId: string;
  image: ImageData;
  imageId: string;
  hasAvailPrints: boolean;
  hasAvailOriginal: boolean;
  selectedCanvas: CanvasData | null;
  setSelectedCanvas: (canvas: CanvasData | null) => void;
  printTransform: IPrintTransform | null;
  setPrintTransform: (printTransform: IPrintTransform | null) => void;
  selectedBuyablePrint: PrintData | null;
  setSelectedBuyablePrint: (print: PrintData | null) => void;
  printsForSale: PrintData[] | null;
  streamingArtworkInfo: StreamingArtworkSlotsInfo | null;
  hasAvailableStreamings: boolean;
  isUserJoinedStreaming: boolean;
  ownedEditions: PrintData[];
  isPlayEdition: boolean;
  setIsPlayEdition: (value: boolean) => void;
  isPlayOriginal: boolean;
  setIsPlayOriginal: (value: boolean) => void;
};

const ArtworkContext = React.createContext<ArtworkContextType | null>(null);

type ArtworkParams = {
  imageId: string;
  exhibitionId: string;
};

type ArtworkProviderProps = React.PropsWithChildren<{}>;

const useFetchExhibition = (exhibitionId: string) => {
  const firebase = useFirebaseContext();
  const query = firebase.exhibitionRef(exhibitionId);

  return useQuery<ExhibitionData>(query);
};

export const useFetchImage = (imageId: string) => {
  const firebase = useFirebaseContext();
  const query = firebase.imageRef(imageId);

  return useQuery<ImageData>(query);
};

const getRandomPrint = (prints: PrintData[]): PrintData | null => {
  const randomIndex = Math.floor(prints.length * Math.random());
  const print = prints[randomIndex];

  return print ?? null;
};

export const useRandomPrint = (
  prints: PrintData[]
): [
  PrintData | null,
  React.Dispatch<React.SetStateAction<PrintData | null>>
] => {
  const [print, setPrint] = useState<PrintData | null>(null);

  useEffect(() => {
    if (
      print === null ||
      (prints.length !== 0 && print.imageId !== prints[0].imageId)
    ) {
      const randomPrint = getRandomPrint(prints);

      randomPrint !== null && setPrint(randomPrint);
    }
  }, [print, setPrint, prints]);

  return [print, setPrint];
};

const ArtworkProvider = ({ children }: ArtworkProviderProps) => {
  const { exhibitionId, imageId } = useParams<ArtworkParams>();
  const { data: image, status: loadingImageStatus } = useFetchImage(imageId);
  const { data: exhibition, status: loadingExhibitionStatus } =
    useFetchExhibition(exhibitionId);

  const { user } = useAuthContext();
  const { data: buyablePrints } = useLoadBuyablePrints(imageId, user?.uid);
  const [selectedCanvas, setSelectedCanvas] = useState<CanvasData | null>(null);
  const [printTransform, setPrintTransform] = useState<IPrintTransform | null>(
    null
  );
  const [streamingArtworkInfo, setStreamingArtworkInfo] =
    useState<StreamingArtworkSlotsInfo | null>(null);
  const [selectedBuyablePrint, setSelectedBuyablePrint] = useRandomPrint(
    buyablePrints ?? []
  );
  const [hasAvailableStreamings, setHasAvailableStreamings] =
    useState<boolean>(false);
  const fullLoaded =
    loadingImageStatus === QueryStatus.success &&
    loadingExhibitionStatus === QueryStatus.success &&
    image;

  // for streaming
  const getStreamsData = useGetExhibitionArtworkStreaming();
  useEffect(() => {
    if (!!fullLoaded && imageId && image?.enableStreaming) {
      getStreamsData(image, exhibition)
        .then(res => {
          if (!res) {
            return;
          }

          setStreamingArtworkInfo({ ...res });
          setHasAvailableStreamings(
            isStreamingUnlimited(res.totalSlots) || res.availableSlots > 0
              ? true
              : false
          );
        })
        .catch(err => {
          console.error('error getting streaming slots info', err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exhibition, exhibitionId, imageId, image, user, fullLoaded]);

  const { prints = [] } = useLoadPrintsOwnedByUser();
  const [isPlayEdition, setIsPlayEdition] = useState<boolean>(false);
  const [isPlayOriginal, setIsPlayOriginal] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      exhibition,
      exhibitionId,
      image: image as ImageData,
      imageId,
      hasAvailPrints: buyablePrints !== null && buyablePrints.length > 0,
      hasAvailOriginal:
        image !== null && !image.notForSale && Boolean(image.priceAmount),
      selectedCanvas,
      setSelectedCanvas,
      printTransform,
      setPrintTransform,
      printsForSale: buyablePrints,
      selectedBuyablePrint,
      setSelectedBuyablePrint,
      streamingArtworkInfo,
      hasAvailableStreamings,
      isUserJoinedStreaming:
        streamingArtworkInfo !== null && streamingArtworkInfo.isUserJoined!,
      ownedEditions: prints.filter(print => print.imageId === imageId),
      isPlayEdition,
      setIsPlayEdition,
      isPlayOriginal,
      setIsPlayOriginal,
    }),
    [
      exhibition,
      exhibitionId,
      image,
      imageId,
      buyablePrints,
      selectedCanvas,
      setSelectedCanvas,
      printTransform,
      setPrintTransform,
      selectedBuyablePrint,
      setSelectedBuyablePrint,
      streamingArtworkInfo,
      hasAvailableStreamings,
      prints,
      isPlayEdition,
      isPlayOriginal,
    ]
  );

  return fullLoaded ? (
    <ArtworkContext.Provider value={contextValue}>
      {children}
    </ArtworkContext.Provider>
  ) : (
    <LoadingIndicator />
  );
};

export const useArtwork = (): ArtworkContextType => {
  const ctx = useContext(ArtworkContext);

  if (!ctx) {
    throw new Error('ArtworkContext not found');
  }

  return ctx;
};

export default ArtworkProvider;
