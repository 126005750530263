export interface ImageData {
  id: string;
  catalog?: string;
  associations: string;
  color: string;
  brightness: number;
  date: string;
  medium: string;
  duration?: number;
  artist: string;
  feature: boolean;
  description: string;
  name: string;
  uid: string;
  url: string;
  path: string;
  spotlight?: number;
  videoUrl?: string;
  videoPath?: string;
  height: number;
  width: number;
  numberOfPrints: number;
  abstraction: number;
  owner: string;
  venue: string;
  published: boolean;
  createdDate: number;
  archived?: boolean;
  udId?: string;
  notForSale?: boolean;
  priceAmount?: number; // TODO: it's a required field
  printPriceAmount?: number;
  initialNumOfPrintsForSale?: number;
  previewUrl?: string;
  previewPath?: string;
  lastSoldPrice?: number;
  allowSoundControls?: boolean;
  redirectTo?: string;
  enableStreaming?: boolean;
  numberOfStreamings?: number;
  currentWaitingGiftTx?: string;
  isGifted?: boolean;
  currentTransferTx?: string;
  watermarks?: {
    invisible: string;
    visible: string;
  };
}

export enum ImageStatusEnum {
  Incomplete = 'Incomplete',
  ReadyToPublish = 'ReadyToPublish',
  Published = 'Published',
}
export interface FeaturedImageSection {
  imageIds?: string[];
  id: string;
  name: string;
}

export enum ImageType {
  Original = 'original',
  Print = 'print',
}

export type ImageResponse = Partial<ImageData> & {
  imageId: string;
  printId: string | null;
  type: ImageType;
  forSale: boolean;
  artist: string;
  owner: string;
  tenant: string | null;
  price: number | null;
  pricePerPrint: number | null;
  editionIndex: number | null;
  numberOfPrints: number | null;
};

export interface ImageWithExhibition extends ImageData {
  exhibitionId: string;
}
