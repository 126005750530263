import { useFirebaseContext } from 'shell/firebase';
import pw from 'a-promise-wrapper';

interface Inputs {
  artistId: string;
  imageIds: string[];
}

export const useBatchMint = () => {
  const firebase = useFirebaseContext();

  return async ({ artistId, imageIds }: Inputs) => {
    const funcName = 'blockchain-nfTBatchMint';
    const callable = firebase.functions.httpsCallable(funcName);
    const { data: response, error } = await pw(
      callable({ artistId, imageIds })
    );

    if (error) {
      throw error;
    }

    return response.data;
  };
};
