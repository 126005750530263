import { Box, Chip, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { TransactionData } from 'constants/data';
import Typography from 'modules/typography';
import { Column, CellProps } from 'react-table';
import { DATE_FORMAT_DAY, formatDate } from 'utils/common';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import getEnvVars from 'utils/common/envVars';
import HeaderWithTooptip from './components/HeaderWithTooptip';
import UserCell from './components/UserCell';
import TxArtworkTypeCell from './components/TxArtworkTypeCell';
import { useAdminCancelGift } from 'utils/requests';
import UIButton from 'ui-components/Button';
import { useModal } from 'modules/modals';
import promiseWrapper from 'a-promise-wrapper';

export const COLUMN_GIFT_SENDER: Column<TransactionData> = {
  accessor: 'sellerId',
  Header: () => {
    const tooltip =
      'Sender is the person that transfers ownership of the unique digital item to the new owner.';

    return <HeaderWithTooptip title="Sender" tooltip={tooltip} />;
  },
  width: 275,
  disableSortBy: true,
  Cell: ({ row }: CellProps<TransactionData>) => {
    const tx = row.original;
    const { sellerId } = tx;

    return <UserCell userId={sellerId} />;
  },
};

export const COLUMN_GIFT_RECEIVER: Column<TransactionData> = {
  accessor: 'buyerId',
  Header: () => {
    const tooltip =
      'Owner possesses ownership for the unique digital item that is encoded into a blockchain.';

    return <HeaderWithTooptip title="Receiver" tooltip={tooltip} />;
  },
  width: 275,
  disableSortBy: true,
  Cell: ({ row }: CellProps<TransactionData>) => {
    const tx = row.original;
    const { buyerId, giftReceiverEmail } = tx;
    if (!buyerId && giftReceiverEmail) {
      return (
        <Box>
          <Typography>{giftReceiverEmail}</Typography>
          <Typography color="textSecondary" fontSize={'0.75rem'}>
            <i>(Email not found in the system)</i>
          </Typography>
        </Box>
      );
    }
    return <UserCell userId={buyerId} />;
  },
};

export const COLUMN_GIFT_ITEM_TYPE: Column<TransactionData> = {
  accessor: 'originalArtworkId',
  Header: () => {
    const tooltip = 'It is either edition or original artwork';

    return <HeaderWithTooptip title="Type" tooltip={tooltip} />;
  },
  width: 145,
  disableSortBy: true,
  Cell: ({ row }: CellProps<TransactionData>) => {
    const tx = row.original;

    return <TxArtworkTypeCell tx={tx} />;
  },
};

export const COLUMN_GIFT_METHOD: Column<TransactionData> = {
  accessor: 'giftOTP',
  Header: () => {
    const tooltip = 'Gift via either email or OTP';

    return <HeaderWithTooptip title="Method" tooltip={tooltip} />;
  },
  width: 175,
  disableSortBy: true,
  Cell: ({ row }: CellProps<TransactionData>) => {
    const tx = row.original;
    const { giftOTP, giftReceiverEmail } = tx;
    const isEmail = !giftOTP && !!giftReceiverEmail;
    const isInvalid = !giftOTP && !giftReceiverEmail;

    if (isInvalid) {
      return <Alert severity="error" />;
    }

    if (isEmail) {
      return <Chip color="secondary" label="Email" />;
    }

    return <Chip color="primary" label="OTP" />;
  },
};

export const COLUMN_GIFT_STATUS: Column<TransactionData> = {
  accessor: 'status',
  Header: () => {
    const tooltip = 'Status of the gift';

    return <HeaderWithTooptip title="Status" tooltip={tooltip} />;
  },
  width: 215,
  disableSortBy: true,
  Cell: ({ row }: CellProps<TransactionData>) => {
    const tx = row.original;
    const { status } = tx;
    const allowedValues = [
      'created',
      'payment-success',
      'cancelled',
      'completed',
    ];
    const isInvalid = allowedValues.indexOf(status) === -1;
    const labelMapping = {
      'created': 'Pending',
      'payment-success': 'Waiting for blockchain',
      'cancelled': 'Cancelled',
      'completed': 'Completed',
    };
    const colorMapping = {
      'created': 'secondary',
      'payment-success': 'secondary',
      'cancelled': 'error',
      'completed': 'primary',
    };

    if (isInvalid) {
      return <Alert severity="error" />;
    }

    return (
      //@ts-ignore
      <Typography color={colorMapping[status]}>
        {labelMapping[status]}
      </Typography>
    );
  },
};

export const COLUMN_GIFT_TIMESTAMPE: Column<TransactionData> = {
  accessor: 'createdDate',
  Header: () => {
    const tooltip = 'Timestamp of sending and receiving gift';

    return <HeaderWithTooptip title="Timestamp" tooltip={tooltip} />;
  },
  width: 205,
  Cell: ({ row }: CellProps<TransactionData>) => {
    const tx = row.original;
    const { createdDate, completedDate } = tx;
    const isInvalid = !createdDate;

    if (isInvalid) {
      return <Alert severity="error" />;
    }

    return (
      <Box>
        <Typography>
          Sent: {formatDate(createdDate, DATE_FORMAT_DAY)}
        </Typography>
        {!!completedDate && (
          <Typography>
            Received: {formatDate(completedDate, DATE_FORMAT_DAY)}
          </Typography>
        )}
      </Box>
    );
  },
};

export const COLUMN_GIFT_OWNERSHIP: Column<TransactionData> = {
  accessor: 'blockchainTransactionHash',
  Header: () => {
    const tooltip =
      'Record of transfer is a unique string of characters that is given to every transaction that is verified and added to the blockchain.';

    return <HeaderWithTooptip title="Record" tooltip={tooltip} />;
  },
  width: 130,
  disableSortBy: true,
  Cell: ({ row }: CellProps<TransactionData>) => {
    const { arbiscanDomain } = getEnvVars();

    const tx = row.original;
    const { blockchainTransactionHash } = tx;
    const href = `${arbiscanDomain}/tx/${blockchainTransactionHash}`;

    if (!blockchainTransactionHash) {
      return null;
    }

    return (
      <Button
        href={href}
        target="_blank"
        variant="text"
        endIcon={<OpenInNewIcon />}>
        View
      </Button>
    );
  },
};

export const COLUMN_CANCEL_GIFT: Column<TransactionData> = {
  accessor: 'uid',
  Header: () => {
    const tooltip = 'Cancel gift';

    return <HeaderWithTooptip title="Action" tooltip={tooltip} />;
  },
  width: 180,
  disableSortBy: true,
  Cell: ({ row }: CellProps<TransactionData>) => {
    const adminCancelGift = useAdminCancelGift();
    const { openDialog: openConfirmDialog, showSnackbar } = useModal();
    const tx = row.original;

    const handleClickCancel = () => {
      openConfirmDialog({
        title: 'Cancel Confirmation',
        message: `Do you want to cancel this gifting process ${tx.uid}, please double check with the owner of this gifting process before executing this function`,
        onConfirm: async () => {
          const { error } = await promiseWrapper(adminCancelGift(tx.uid));
          if (error) {
            console.error(error);
            showSnackbar('Cancel failed', { severity: 'error' });
            return;
          }
          showSnackbar('Update DB successfully!');
        },
        labelConfirm: 'Yes, I confirm',
      });
    };

    return (
      <UIButton
        variant="primary"
        size="small"
        onClick={() => handleClickCancel()}
        text={'Cancel Gift'}></UIButton>
    );
  },
};
