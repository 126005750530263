import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';

import {
  FilterValues,
  FilterContextProvider,
  useFilterContext,
} from './context';

interface BaseFilterProps {
  className?: string;
  onChange: (values: FilterValues) => void;
}

const BaseFilter = ({
  onChange,
  className,
  children,
}: React.PropsWithChildren<BaseFilterProps>) => {
  const { activeFilter } = useFilterContext();
  useEffect(() => {
    onChange(activeFilter);
  }, [activeFilter, onChange]);

  return <Box className={className}>{children}</Box>;
};

interface FilterProps<T = any> {
  onChange: (values: FilterValues) => void;
  defaultValues: FilterValues;
  originalList?: T[] | null;
  className?: string;
}

const Filter = ({
  originalList,
  onChange,
  children,
  defaultValues,
  className,
}: React.PropsWithChildren<FilterProps>) => {
  return (
    <FilterContextProvider
      originalList={originalList || []}
      defaultFilterValues={defaultValues}>
      <BaseFilter onChange={onChange} className={className}>
        {children}
      </BaseFilter>
    </FilterContextProvider>
  );
};

interface ClearFilterRenderProps {
  clearFilter: () => void;
}

type ClearFilterProps = {
  render: (props: ClearFilterRenderProps) => JSX.Element;
};

export const ClearFilter = ({ render }: ClearFilterProps) => {
  const { clearFilter } = useFilterContext();
  return render({ clearFilter });
};

export default Filter;
