import { useFirebaseContext } from 'shell/firebase';
import { IVenue, IPreVenue, IVenueCoverImage } from 'constants/data/venue.data';
import promiseWrapper from 'a-promise-wrapper';
import { uploadCoverImage } from './uploadImage';
import {
  formatArtworkFilters,
  formatModules,
} from 'views/admin/venues/helpers';
import { useLog } from 'utils/hooks/useLog';

export const useSetPublishStatusVenue = () => {
  const firebase = useFirebaseContext();

  return (venueId: string, status: boolean) => {
    const callable = firebase.functions.httpsCallable(
      'venue-updatePublishStatus'
    );

    return callable({ id: venueId, status });
  };
};

export const useCreateVenue = () => {
  const firebase = useFirebaseContext();

  return async (venue: IPreVenue) => {
    const {
      uploadImage,
      uploadCoverMobileImage,
      uploadThumbnailImage,
      uploadLogo,
    } = venue;
    let newCoverImage: IVenueCoverImage = { url: '', path: '' };
    let newCoverMobileImage: IVenueCoverImage = { url: '', path: '' };
    let newThumbnailImage: IVenueCoverImage = { url: '', path: '' };
    let newLogo: IVenueCoverImage = { url: '', path: '' };

    // Upload the cover image
    if (uploadImage) {
      try {
        const { url, path } = await uploadCoverImage(firebase, uploadImage);
        newCoverImage = {
          url: url,
          path: path,
        };
      } catch (e) {
        return Promise.reject('Error to upload the cover image');
      }
    }

    if (uploadCoverMobileImage) {
      try {
        const { url, path } = await uploadCoverImage(
          firebase,
          uploadCoverMobileImage
        );
        newCoverMobileImage = {
          url: url,
          path: path,
        };
      } catch (e) {
        return Promise.reject('Error to upload the cover mobile image');
      }
    }

    if (uploadThumbnailImage) {
      try {
        const { url, path } = await uploadCoverImage(
          firebase,
          uploadThumbnailImage
        );
        newThumbnailImage = {
          url: url,
          path: path,
        };
      } catch (e) {
        return Promise.reject('Error to upload the thumbnail image');
      }
    }

    if (uploadLogo) {
      try {
        const { url, path } = await uploadCoverImage(firebase, uploadLogo);
        newLogo = {
          url: url,
          path: path,
        };
      } catch (e) {
        return Promise.reject('Error to upload the thumbnail image');
      }
    }

    const configurableModules = formatModules(venue.configurableModules);
    const configurableAboutModules = formatModules(
      venue.configurableAboutModules
    );
    const artworkFilters = formatArtworkFilters(venue.artworkFilters);

    // Create a venue
    const uid = firebase.venuesRef().push().key;
    if (uid) {
      delete venue.uploadImage;
      delete venue.uploadCoverMobileImage;
      delete venue.uploadThumbnailImage;

      const newVenue = {
        ...venue,
        uid: uid,
        coverImage: newCoverImage.path ? newCoverImage : null,
        coverImageMobile: newCoverMobileImage.path ? newCoverMobileImage : null,
        thumbnailImage: newThumbnailImage.path ? newThumbnailImage : null,
        logo: newLogo.path ? newLogo : null,
        published: false,
        configurableModules,
        configurableAboutModules,
        artworkFilters,
      };

      const { error } = await promiseWrapper(
        firebase.db.ref(`venues/${uid}`).set(newVenue)
      );

      if (error) {
        // if error, delete the new cover image file silently
        newCoverImage.path &&
          firebase.storage.ref().child(newCoverImage.path).delete();
        newCoverMobileImage.path &&
          firebase.storage.ref().child(newCoverMobileImage.path).delete();
        newThumbnailImage.path &&
          firebase.storage.ref().child(newThumbnailImage.path).delete();
        return Promise.reject(error);
      }

      return Promise.resolve(true);
    }

    return Promise.reject('Error to get the unique key');
  };
};

export const useEditVenue = () => {
  const firebase = useFirebaseContext();

  return async (venueId: string, venue: IPreVenue) => {
    if (!venueId) {
      return Promise.reject('Missing id params');
    }
    const {
      uploadImage,
      coverImage,
      uploadCoverMobileImage,
      coverImageMobile,
      uploadThumbnailImage,
      thumbnailImage,
      uploadLogo,
      logo,
    } = venue;
    const oldCoverImagePath = coverImage?.path;
    const oldCoverImageMobilePath = coverImageMobile?.path;
    const oldThumbnailImagePath = thumbnailImage?.path;
    const oldLogo = logo?.path;
    let newCoverImage: IVenueCoverImage = { url: '', path: '' };
    let newCoverMobileImage: IVenueCoverImage = { url: '', path: '' };
    let newThumbnailImage: IVenueCoverImage = { url: '', path: '' };
    let newLogo: IVenueCoverImage = { url: '', path: '' };

    // Upload the cover image
    if (uploadImage) {
      try {
        const { url, path } = await uploadCoverImage(firebase, uploadImage);
        newCoverImage = {
          url: url,
          path: path,
        };
      } catch (e) {
        return Promise.reject('Error to upload the cover image');
      }
    }

    if (uploadCoverMobileImage) {
      try {
        const { url, path } = await uploadCoverImage(
          firebase,
          uploadCoverMobileImage
        );
        newCoverMobileImage = {
          url: url,
          path: path,
        };
      } catch (e) {
        return Promise.reject('Error to upload the cover mobile image');
      }
    }

    if (uploadThumbnailImage) {
      try {
        const { url, path } = await uploadCoverImage(
          firebase,
          uploadThumbnailImage
        );
        newThumbnailImage = {
          url: url,
          path: path,
        };
      } catch (e) {
        return Promise.reject('Error to upload the thumbnail image');
      }
    }

    if (uploadLogo) {
      try {
        const { url, path } = await uploadCoverImage(firebase, uploadLogo);
        newLogo = {
          url: url,
          path: path,
        };
      } catch (e) {
        return Promise.reject('Error to upload the thumbnail image');
      }
    }

    const configurableModules = formatModules(venue.configurableModules);
    const artworkFilters = formatArtworkFilters(venue.artworkFilters);
    // Update an venue
    delete venue.uploadImage;
    delete venue.uploadCoverMobileImage;
    delete venue.uploadThumbnailImage;
    delete venue.uploadLogo;

    const updatedVenue = {
      ...venue,
      coverImage: newCoverImage.path ? newCoverImage : venue.coverImage || null,
      coverImageMobile: newCoverMobileImage.path
        ? newCoverMobileImage
        : venue.coverImageMobile || null,
      thumbnailImage: newThumbnailImage.path
        ? newThumbnailImage
        : venue.thumbnailImage || null,
      logo: newLogo.path ? newLogo : venue.logo || null,
      configurableModules,
      artworkFilters,
    };
    const { error } = await promiseWrapper(
      firebase.db.ref(`venues/${venueId}`).update(updatedVenue)
    );
    if (error) {
      // if error, delete the new cover image file silently
      newCoverImage.path &&
        firebase.storage.ref().child(newCoverImage.path).delete();
      newCoverMobileImage.path &&
        firebase.storage.ref().child(newCoverMobileImage.path).delete();
      newThumbnailImage.path &&
        firebase.storage.ref().child(newThumbnailImage.path).delete();
      newLogo.path && firebase.storage.ref().child(newLogo.path).delete();
      return Promise.reject(error);
    }

    // if success, delete the old cover image file silently
    oldCoverImagePath &&
      newCoverImage.path &&
      firebase.storage.ref().child(oldCoverImagePath).delete();
    oldCoverImageMobilePath &&
      newCoverMobileImage.path &&
      firebase.storage.ref().child(oldCoverImageMobilePath).delete();
    oldThumbnailImagePath &&
      newThumbnailImage.path &&
      firebase.storage.ref().child(oldThumbnailImagePath).delete();
    oldLogo && newLogo.path && firebase.storage.ref().child(oldLogo).delete();
    return Promise.resolve(updatedVenue);
  };
};

export const useCheckHostedVenue = () => {
  const firebase = useFirebaseContext();
  const log = useLog();

  return async (): Promise<IVenue | null> => {
    let hostname = '';
    try {
      hostname = window.location.hostname;
    } catch (hostnameError: any) {
      // case couldn't get the hostname
      log({
        type: 'error',
        message: `get hostname error ${hostnameError?.message || ''}`,
      });
      return null;
    }

    const query = firebase
      .venuesRef()
      .orderByChild('domainName')
      .equalTo(hostname);

    const { data, error } = await promiseWrapper(query.once('value'));

    if (error) {
      log({
        type: 'error',
        message:
          error?.message ||
          `CheckHostedVenue error: get venue by hostname ${hostname}`,
      });
      throw error;
    }
    const venuesData = data.val();

    // not hosted venue
    if (!venuesData) {
      return null;
    }

    try {
      // is hosted venue
      const venue = Object.values(venuesData)[0] as IVenue;

      return venue;
    } catch (venueError: any) {
      // case hosted venue but get an error
      log({
        type: 'error',
        message:
          venueError?.message ||
          `CheckHostedVenue error: get venue by venuesData ${JSON.stringify(
            venuesData
          )}`,
      });
      throw venueError;
    }
  };
};

export const useUpdateHostedVenue = () => {
  const firebase = useFirebaseContext();
  const log = useLog();

  return async ({
    allVenues,
    venueId,
    domainName,
  }: {
    allVenues: IVenue[];
    venueId?: string;
    domainName?: string;
  }) => {
    if (!venueId || !allVenues?.length) {
      return;
    }

    const newDomainName = domainName ? domainName.toLowerCase() : null;

    const updateObj = allVenues.reduce((acc: any, venue: IVenue) => {
      if (venue.uid === venueId) {
        return {
          ...acc,
          [`venues/${venue.uid}/domainName`]: newDomainName,
        };
      }

      if (
        newDomainName &&
        venue.domainName === newDomainName &&
        venue.uid !== venueId
      ) {
        return {
          ...acc,
          [`venues/${venue.uid}/domainName`]: null,
        };
      }

      return acc;
    }, {});

    const { error } = await promiseWrapper(firebase.db.ref().update(updateObj));
    if (error) {
      log({
        type: 'error',
        message:
          error?.message ||
          `useUpdateHostedVenue error: venueId: ${venueId}, domainName: ${domainName}`,
      });
      throw error;
    }

    return;
  };
};
