import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { Print as PrintData } from 'constants/data/print.data';
import { ImageData } from 'constants/data/image.data';
import { useLoadUser } from 'utils/requests';
import { UserData } from 'constants/data';

import UIButton from 'ui-components/Button';

interface IPrintItem {
  print: PrintData;
  image: ImageData;
  onClickBuy: () => void;
}

const getPrintOwnerName = (owner: UserData, isAnonymous: boolean) => {
  if (isAnonymous) {
    return 'Anonymous Collector';
  }

  return owner.fullName;
};

const PrintItem = ({ print, image, onClickBuy }: IPrintItem) => {
  const classes = useStyles();
  const {
    price,
    editionIndex,
    owner: printOwner,
    notForSale,
    isAnonymousPurchased,
  } = print;

  const { numberOfPrints, owner: imageOwner } = image;

  const { user: owner } = useLoadUser(printOwner || imageOwner);

  const ownerName =
    owner === undefined
      ? ''
      : getPrintOwnerName(owner, Boolean(isAnonymousPurchased));

  return (
    <ListItem disableGutters ContainerComponent={'div'}>
      <ListItemText
        primary={`Edition #${editionIndex} of ${numberOfPrints}`}
        secondary={`Owned by ${ownerName}`}
        classes={{
          primary: classes.listItemTextPrimary,
          secondary: classes.listItemTextSecondary,
        }}
      />
      <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
        {notForSale ? (
          <UIButton
            variant="primary"
            size="medium"
            disabled
            text="Not for sale"
            rounded
          />
        ) : (
          <UIButton
            variant="primary"
            size="medium"
            onClick={onClickBuy}
            disabled={!price}
            text={Number(price) ? `€${price}` : 'n/a'}
            rounded
          />
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    listItemSecondaryAction: {
      right: 0,
    },
    listItemTextPrimary: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
    listItemTextSecondary: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(12),
    },
  })
);

export default PrintItem;
