import { createContext, useMemo, useContext } from 'react';
import { useLoadUsers, useDisableUser, useEnableUser } from './helpers';
import { UserData } from 'constants/data/user.data';

interface ContextProps {
  allUsers?: UserData[] | null;
  usersMap?: Record<string, UserData> | null;
  disableUser: (uid: string) => Promise<any>;
  enableUser: (uid: string) => Promise<any>;
}
/**
 * This context provides the consumers the data of all users
 */
const AllUsersContext = createContext<ContextProps>({
  allUsers: undefined,
  usersMap: undefined,
  disableUser: () => Promise.resolve(undefined),
  enableUser: () => Promise.resolve(undefined),
});

export const AllUsersProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { usersMap } = useLoadUsers();
  const disableUser = useDisableUser();
  const enableUser = useEnableUser();
  const allUsers = useMemo(
    () =>
      usersMap
        ? Object.keys(usersMap).map(key => ({ ...usersMap[key], uid: key }))
        : usersMap,
    [usersMap]
  );

  const contextValue = useMemo(
    () => ({
      usersMap,
      allUsers,
      disableUser,
      enableUser,
    }),
    [usersMap, allUsers, disableUser, enableUser]
  );

  return (
    <AllUsersContext.Provider value={contextValue}>
      {children}
    </AllUsersContext.Provider>
  );
};

export const useAllUsersContext = () => useContext(AllUsersContext);
