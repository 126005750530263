import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo, useState, useEffect } from 'react';

import {
  Box,
  createStyles,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  makeStyles,
  Theme,
  DialogProps,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'static/images/close-icon.svg';
import Typography from 'modules/typography';
import { Controller, useForm } from 'react-hook-form';
import { TextField } from 'modules/formFields';
import { ImageData } from 'constants/data/image.data';
import { useIsMobile } from 'utils/hooks';
import { mobileMediaQuery } from 'utils/common';
import { getStreamingArtworkValidationSchema } from './helpers';
import {
  useUpdateStreamingArtwork,
  useGetListStreamings,
  StreamingCollections,
} from 'utils/requests/useStreaming';
import { isStreamingUnlimited } from 'views/account/upload/helpers';
import { useModal } from 'modules/modals';
import { useConfirmEditStreamingDialog } from 'modules/dialogs/ConfirmEditStreamingDialog';
import { STREAMING_SLOT_UNLIMITED } from 'constants/data';

import UIButton from 'ui-components/Button';

type ManageStreamingDialogProps = {
  image: ImageData;
} & DialogProps;

interface ManageStreamingField {
  enableStreaming?: boolean;
  isUnlimitedStreamingSlots: boolean;
  numberOfStreamingSlots?: number;
}

const ManageStreamingDialog = ({
  image,
  onClose,
  ...dialogProps
}: ManageStreamingDialogProps) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const updateStreamingArtwork = useUpdateStreamingArtwork();
  const getListStreamings = useGetListStreamings();
  const [streamingCollectionLength, setStreamingCollectionLength] =
    useState<number>(0);
  const {
    render: renderConfirmEditStreamingDialog,
    openDialog: openEditStreamingDialog,
  } = useConfirmEditStreamingDialog();
  const { showSnackbar } = useModal();
  const { enableStreaming, numberOfStreamings } = image;
  const validationSchema = useMemo(
    () => getStreamingArtworkValidationSchema(),
    []
  );
  useEffect(() => {
    if (image.uid) {
      (async () => {
        const value = (await getListStreamings(
          image.uid
        )) as StreamingCollections[];
        if (value) {
          setStreamingCollectionLength(value.length);
        }
      })();
    }
  }, [getListStreamings, image.uid]);

  const defaultValues: ManageStreamingField = useMemo(
    () => ({
      enableStreaming: enableStreaming,
      isUnlimitedStreamingSlots: isStreamingUnlimited(numberOfStreamings || 0),
      numberOfStreamingSlots: isStreamingUnlimited(numberOfStreamings || 0)
        ? 1
        : numberOfStreamings,
    }),
    [enableStreaming, numberOfStreamings]
  );
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    getValues,
    setValue,
  } = useForm<ManageStreamingField>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = async ({
    enableStreaming,
    numberOfStreamingSlots,
    isUnlimitedStreamingSlots,
  }: ManageStreamingField) => {
    if (
      typeof enableStreaming === 'undefined' &&
      typeof numberOfStreamingSlots === 'undefined'
    ) {
      return;
    }

    let confirmChange = true;
    if (
      enableStreaming &&
      numberOfStreamingSlots &&
      numberOfStreamingSlots > STREAMING_SLOT_UNLIMITED &&
      numberOfStreamingSlots < streamingCollectionLength
    ) {
      confirmChange = await openEditStreamingDialog({
        open: true,
        currentStreaming: streamingCollectionLength,
      });
    }

    if (!confirmChange) {
      return;
    }

    updateStreamingArtwork({
      enableStreaming: enableStreaming as boolean,
      numberOfStreamingSlots: numberOfStreamingSlots as number,
      isUnlimitedStreamingSlots,
      image: image,
    })
      .then(() => {
        showSnackbar('Update successfully');
      })
      .catch(error => {
        console.error(error);
        showSnackbar('Update Failed', { severity: 'error' });
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleClose = (reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (!reason && onClose) {
      onClose({}, 'backdropClick');
    }
  };
  return (
    <>
      <Dialog
        fullScreen={isMobile}
        onClose={(event, reason) => handleClose(reason)}
        classes={{ paper: classes.dialog }}
        {...dialogProps}>
        <DialogContent className={classes.content}>
          <IconButton
            onClick={() => handleClose()}
            className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography className={classes.title}>Manage</Typography>
            <Typography className={classes.heading}>
              Manage streaming
            </Typography>
            <Typography className={classes.description}>
              {`Change and set the number of slots of the artwork available for subscribers to stream. Currently, ${
                streamingCollectionLength > 1
                  ? streamingCollectionLength + ' people are'
                  : streamingCollectionLength + ' person is'
              } streaming.`}
            </Typography>
            <Box width={'100%'}>
              <Controller
                name="enableStreaming"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={value}
                        onChange={e => {
                          onChange(e.target.checked);
                        }}
                        color={'primary'}
                      />
                    }
                    label="Enable streaming"
                    labelPlacement="end"
                  />
                )}
              />
              {watch('enableStreaming') && (
                <>
                  <Typography
                    fontSize={'0.6875rem'}
                    lineHeight={1.2}
                    letterSpacing={'0.12em'}>
                    NUMBER OF STREAMING SLOTS
                  </Typography>
                  <Box>
                    <RadioGroup
                      name={'radio-buttons-group'}
                      className={classes.radioGroup}
                      row>
                      <FormControlLabel
                        onChange={() =>
                          setValue('isUnlimitedStreamingSlots', false)
                        }
                        classes={{ label: classes.radioBox }}
                        label={'Set number of streams'}
                        labelPlacement="end"
                        control={
                          <Radio
                            color="primary"
                            checked={!watch('isUnlimitedStreamingSlots')}
                          />
                        }
                      />
                      <FormControlLabel
                        onChange={() =>
                          setValue('isUnlimitedStreamingSlots', true)
                        }
                        classes={{ label: classes.radioBox }}
                        label={'Unlimited streams'}
                        labelPlacement="end"
                        control={
                          <Radio
                            color="primary"
                            checked={watch('isUnlimitedStreamingSlots')}
                          />
                        }
                      />
                    </RadioGroup>
                  </Box>
                  <Controller
                    name="numberOfStreamingSlots"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        className={classes.input}
                        value={value}
                        onChange={onChange}
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                        disabled={getValues('isUnlimitedStreamingSlots')}
                        type="number"
                      />
                    )}
                  />
                </>
              )}
            </Box>
            <DialogActions classes={{ root: classes.buttonWrapper }}>
              <UIButton
                variant="primary"
                size="medium"
                type="submit"
                disabled={isSubmitting}
                text="Save change"
              />
              <UIButton
                variant="text"
                size="medium"
                onClick={() => handleClose()}
                text="Cancel"
                type={'button'}
              />
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      {renderConfirmEditStreamingDialog}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    content: {
      'padding': theme.spacing(6.25),
      'position': 'relative',
      '&:first-child': {
        paddingTop: theme.spacing(6.25),
      },
      [mobile]: {
        padding: theme.spacing(4),
      },
    },
    title: {
      fontFamily: 'Fraunces',
      fontWeight: 300,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1.4,
      color: '#000000',
      paddingBottom: theme.spacing(2),
    },
    heading: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(36),
      lineHeight: 1.2,
      letterSpacing: '-0.02em',
      color: '#000000',
      paddingBottom: theme.spacing(2),
    },
    description: {
      lineHeight: 1.625,
      color: '#000000',
      opacity: 0.8,
      paddingBottom: theme.spacing(5),
    },
    buttonWrapper: {
      justifyContent: 'flex-start',
      padding: theme.spacing(4, 0, 0, 0),
    },
    closeButton: {
      position: 'absolute',
      top: 12,
      right: 20,
      [mobile]: {
        right: 10,
      },
    },
    dialog: {
      width: 500,
      maxWidth: 500,
      [mobile]: {
        maxWidth: '100%',
        width: '100%',
      },
    },
    checkbox: {
      display: 'inline-block',
      marginBlock: theme.spacing(1),
    },
    input: {
      marginBottom: theme.spacing(2),
    },
    radioBox: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: '150%',
    },
    radioGroup: {
      width: '100%',
    },
  });
});

export default ManageStreamingDialog;
