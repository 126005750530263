import { useMemo } from 'react';
import { OptionRenderProps } from '../FilterOption';
import { FilterAutocomplete } from 'modules/filterControls';
import { multiValuesStringToArray } from 'utils/common';
import { useFilterContext } from '../context';
import { ImageData } from 'constants/data/image.data';

export const KeywordOption = ({ values = [], onChange }: OptionRenderProps) => {
  const { originalList } = useFilterContext();

  const keywordOptions = useMemo(
    () => (originalList ? createKeywordOptions(originalList) : []),
    [originalList]
  );

  return (
    <FilterAutocomplete
      options={keywordOptions}
      value={values}
      onChange={onChange}
      renderValue={renderKeywordsLabel}
    />
  );
};

const createKeywordOptions = (images: ImageData[] = []) => {
  const concatedString = images.reduce(
    (acc, image) => acc + ',' + image.associations,
    ''
  );
  const array = multiValuesStringToArray(concatedString).sort();
  return Array.from(new Set(array)).map(keyword => ({
    value: keyword,
    label: keyword,
  }));
};

const renderKeywordsLabel = (value: string[]) =>
  value.length > 0 ? `Keywords: ${value.join(', ')}` : 'Keywords';

export default KeywordOption;
