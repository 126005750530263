import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { UserData } from 'constants/data/user.data';
import * as ROUTES from 'constants/routes';
import { useAuthContext } from 'shell/session';
import { useFirebaseContext } from 'shell/firebase';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FeatureFlag from 'modules/featureFlag';
import { useIsMobile } from 'utils/hooks';
import MenuLink, { MenuItem } from 'modules/menu/MenuLink';
import { mobileMediaQuery } from 'utils/common';
import { ACCOUNT_MENU, IMenuItem, ADMIN_MENU } from 'constants/menu';

import UIButton from 'ui-components/Button';
import { useFeatureFlagContext } from 'utils/contexts/featureFlagContext';

const translations = {
  title: 'Profile',
  menu: {
    profile: 'Profile',
    membership: 'Subscription',
    upload: 'Upload',
    images: 'Images',
    canvas: 'Canvas',
    admin: 'Admin',
    signOut: 'Sign out',
  },
};

const MENU = ACCOUNT_MENU.concat(ADMIN_MENU);

const AccountLayout = ({ children }: React.PropsWithChildren<{}>) => {
  const firebase = useFirebaseContext();
  const { user } = useAuthContext() as any as { user: UserData };
  const isMobile = useIsMobile();
  const classes = useStyles();
  const canUpload = user && user.canUpload;
  const isAdmin = user && user.isAdmin;
  const { membership } = user;

  const { enabled: enableAdminQueryTool } =
    useFeatureFlagContext('queryDatabases');

  const filteredMenus = MENU.filter(m => {
    if (m.path === ROUTES.ACCOUNT_MEMBER_SHIP) {
      return !!membership ? true : false;
    }

    if (m.path === ROUTES.ADMIN_QUERY_TOOL) {
      return !!enableAdminQueryTool;
    }

    return true;
  });

  const handleClickLogout = () => {
    if (firebase !== null) {
      (firebase as any).doSignOut();
    }
  };

  const hideMenuItem = (item: IMenuItem) => {
    const path = item.path;
    return (
      (!canUpload &&
        (path === ROUTES.ACCOUNT_UPLOAD ||
          path === ROUTES.ACCOUNT_UPLOADED_IMAGES ||
          path === ROUTES.ACCOUNT_COMMERCE)) ||
      (!isAdmin && !!ADMIN_MENU.find(menu => path === menu.path))
    );
  };

  return (
    <Box className={classes.container} mx="auto">
      <Box className={classes.leftMenu}>
        <MenuLink
          menu={filteredMenus}
          renderItem={item => {
            if (hideMenuItem(item)) {
              return null;
            }
            if (item.feature) {
              return (
                <FeatureFlag key={item.path} feature={item.feature}>
                  <MenuItem key={item.path} data={item} />
                </FeatureFlag>
              );
            }
            return <MenuItem key={item.path} data={item} />;
          }}
        />
        {!isMobile && (
          <Box mt={4}>
            <UIButton
              variant="secondary"
              size="medium"
              onClick={handleClickLogout}
              text={translations.menu.signOut}
            />
          </Box>
        )}
      </Box>
      <Box className={classes.content}>{children}</Box>
      {isMobile && (
        <Box mb={4} mt={4}>
          <UIButton
            variant="secondary"
            size="medium"
            onClick={handleClickLogout}
            text={translations.menu.signOut}
          />
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    container: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'row',
      [mobile]: {
        flexDirection: 'column',
        padding: theme.spacing(2),
      },
      width: '100%',
      maxWidth: '1440px',
      marginTop: 100,
    },
    content: {
      marginLeft: theme.spacing(6),
      flex: 1,
      overflow: 'hidden',
      [mobile]: {
        marginTop: theme.spacing(4),
        marginLeft: 0,
      },
    },
    leftMenu: {
      width: '300px',
      [mobile]: {
        width: '100%',
      },
    },
  });
});

export default AccountLayout;
