import {
  PublicSignUpParams,
  AnonymousSignUpParams,
} from 'constants/data/user.data';
import { useFirebaseContext } from 'shell/firebase';
import useAnalytics from './useAnalytics';
import promiseWrapper from 'a-promise-wrapper';
import { useDetectInternetConnection } from '../hooks';

export const usePublicSignUp = () => {
  const firebase = useFirebaseContext();
  const { trackSignUp } = useAnalytics();
  const isOnline = useDetectInternetConnection();

  return (params: PublicSignUpParams): Promise<PublicSignUpParams> => {
    const callable = firebase.functions.httpsCallable('publicSignup');
    if (!isOnline) {
      return Promise.reject({ message: 'internet-unavailable' });
    }

    return callable(params).then((response: any) => {
      const { verificationLink } = response || {};

      trackSignUp({
        method: 'Email',
        code: params.code,
      });

      return Promise.resolve({
        ...params,
        ...(verificationLink ? { verificationLink } : {}),
      });
    });
  };
};

export const useResendVerifyEmail = () => {
  const firebase = useFirebaseContext();

  return async (params: {
    email: string;
  }): Promise<firebase.functions.HttpsCallableResult> => {
    const callable = firebase.functions.httpsCallable('sendVerificationEmail');

    return await callable({ ...params, continueUrl: window.location.pathname });
  };
};

export const useAnonymousSignUp = () => {
  const firebase = useFirebaseContext();
  const { trackSignUp } = useAnalytics();

  return async (
    params: AnonymousSignUpParams
  ): Promise<AnonymousSignUpParams> => {
    const { email, password } = params;
    // generate firebase credentials
    const { data: credentials, error: credentialsError } = await promiseWrapper(
      firebase.generateAuthCredentials(email, password)
    );
    if (credentialsError) {
      throw new Error(credentialsError.code || credentialsError.message || '');
    }
    // link the login credentials - it will create an entry in firebase `authorized users`
    const { data: authUser, error: authUserError } = firebase.auth.currentUser
      ? await promiseWrapper(
          firebase.auth.currentUser.linkWithCredential(credentials)
        )
      : { data: null, error: null };
    if (authUserError) {
      throw new Error(authUserError.code || authUserError.message || '');
    }

    if (authUser?.user?.uid) {
      // call cloud function to create a record in realtime-db user collection
      const callable = firebase.functions.httpsCallable(
        'createAnonymousToPermanentUser'
      );
      const { data: signupData, error: signupError } = await promiseWrapper(
        callable(params)
      );

      if (signupError) {
        return Promise.reject(new Error('failed to create user'));
      }

      const { verificationLink } = signupData?.data || {};

      trackSignUp({
        method: 'Email',
      });

      return Promise.resolve({
        ...params,
        ...(verificationLink ? { verificationLink } : {}),
      });
    } else {
      return Promise.reject(new Error('failed to create user'));
    }
  };
};
