import { useEffect, useMemo, useReducer, useRef } from 'react';
import { database } from 'firebase';

export enum QueryStatus {
  idle = 'idle',
  loading = 'loading',
  success = 'success',
  error = 'error',
}

type QueryResultType<T = any, E = any> = {
  status: QueryStatus;
  data: T | null;
  error: E | null;
  loading: boolean;
};

type QueryAction<T = any> = {
  type: T;
  [key: string]: any;
};

type QueryOptions<T> = {
  transform: (snapshot: database.DataSnapshot) => T | null;
};

const constants = {
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_ERROR: 'FETCH_ERROR',
};

const actions = {
  fetch: () => ({
    type: constants.FETCH_REQUEST,
  }),

  success: <T>(data: T) => ({
    type: constants.FETCH_SUCCESS,
    payload: { data },
  }),

  error: <E>(error: E) => ({
    type: constants.FETCH_ERROR,
    payload: { error },
  }),
};

const initialState = {
  status: QueryStatus.idle,
  data: null,
  error: null,
  loading: false,
};

const reducer = (state: QueryResultType, action: QueryAction) => {
  switch (action.type) {
    case constants.FETCH_REQUEST:
      return {
        ...state,
        status: QueryStatus.loading,
      };

    case constants.FETCH_SUCCESS:
      return {
        ...state,
        status: QueryStatus.success,
        data: action.payload.data,
        error: null,
      };

    case constants.FETCH_ERROR:
      return {
        ...state,
        status: QueryStatus.error,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

const useCompareQuery = (query: database.Query, onChange: () => void) => {
  const ref = useRef(query);

  useEffect(() => {
    if (query.isEqual(ref.current)) {
      return;
    }

    ref.current = query;

    onChange();
  }, [query, onChange]);

  return ref.current;
};

const useQuery = <T, E = any>(
  query: database.Query,
  options?: QueryOptions<T>
): QueryResultType<T, E> => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const memoizedQuery = useCompareQuery(query, () => dispatch(actions.fetch()));

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  useEffect(() => {
    const onValueCallback = (snapshot: database.DataSnapshot) => {
      const data: T | null = snapshot.val() ?? null;
      dispatch(actions.success(options ? options.transform(snapshot) : data));
    };

    const onErrorCallback = (error: E) => {
      dispatch(actions.error(error));
    };

    memoizedQuery.on('value', onValueCallback, onErrorCallback);
    return () => {
      memoizedQuery.off('value', onValueCallback);
    };
  }, [memoizedQuery, options]);

  return useMemo(
    () => ({
      ...state,
      loading: [QueryStatus.idle, QueryStatus.loading].includes(state.status),
    }),
    [state]
  );
};

export default useQuery;
