export type ColorType =
  | 'red'
  | 'dark red'
  | 'white'
  | 'blue'
  | 'cream'
  | 'yellow'
  | 'black'
  | 'brown'
  | 'green'
  | 'green2'
  | 'grey'
  | 'orange'
  | 'pink'
  | 'violet'
  | 'light red'
  | 'light blue'
  | 'light green';

export const COLORS_MAP: Record<ColorType, string> = {
  'red': '#DB2525',
  'dark red': '#BB3535',
  'light red': '#E34F4F',
  'white': '#FFFFFF',
  'blue': '#4E79CC',
  'light blue': '#39ACD0',
  'cream': '#ECCBAD',
  'yellow': '#F1DE36',
  'black': '#121212',
  'brown': '#895C52',
  'green': '#3D7312',
  'green2': '#3FBB35',
  'light green': '#8BCB59',
  'grey': '#989898',
  'orange': '#D96E33',
  'pink': '#E169A3',
  'violet': '#813CB7',
};

export const StaticFiltersArray = [
  {
    name: 'theme',
    options: [
      { name: 'Urban', value: 'urban' },
      { name: 'Abstract', value: 'abstract' },
      { name: 'Architecture', value: 'architecture' },
      { name: 'Nature', value: 'nature' },
    ],
  },
  {
    name: 'style',
    options: [
      { name: 'Black & White', value: 'black and white' },
      { name: 'Abstract', value: 'abstract' },
      { name: 'Architecture', value: 'architecture' },
      { name: 'Nature', value: 'nature' },
    ],
  },
  {
    name: 'medium',
    options: [
      { name: 'Photography', value: 'photography' },
      { name: 'Painting', value: 'painting' },
      { name: 'Video', value: 'video' },
      { name: 'Digital art', value: 'digital art' },
    ],
  },
  {
    name: 'place',
    options: [
      { name: 'Berlin', value: 'berlin' },
      { name: 'New York', value: 'new york' },
      { name: 'Los Angeles', value: 'los angeles' },
      { name: 'Copenhagen', value: 'copenhagen' },
      { name: 'Paris', value: 'paris' },
    ],
  },
  {
    name: 'brightness',
    options: [
      { name: '1', value: '1' },
      { name: '2', value: '2' },
      { name: '3', value: '3' },
      { name: '4', value: '4' },
      { name: '5', value: '5' },
    ],
  },
  {
    name: 'abstraction',
    options: [
      { name: '1', value: '1' },
      { name: '2', value: '2' },
      { name: '3', value: '3' },
      { name: '4', value: '4' },
      { name: '5', value: '5' },
    ],
  },
  {
    name: 'color',
    options: [
      { name: '🔴 Red', value: 'red' },
      { name: '🔵 Blue', value: 'blue' },
      { name: '🟢 Green', value: 'green' },
      { name: '🟡 Yellow', value: 'yellow' },
      { name: '🟠 Orange', value: 'orange' },
      { name: '⚫ Black', value: 'black' },
      { name: '🟤 Brown', value: 'brown' },
    ],
  },
];
