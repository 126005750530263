import loadable from 'utils/loadable';

// export { default } from './EditImagesDialog';

export default loadable(
  () =>
    import(
      /* webpackChunkName: "modules.editImagesDialog.EditImagesDialog" */ './EditImagesDialog'
    )
);
