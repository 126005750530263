import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogProps,
  makeStyles,
  createStyles,
  Theme,
  Box,
  IconButton,
} from '@material-ui/core';
import promiseWrapper from 'a-promise-wrapper';
import Typography from 'modules/typography';
import { useMarkPrintAsUnAvailableForSale } from 'utils/requests';
import { useMarkOriginalArtworkAsUnAvailableForSale } from 'utils/requests/useOriginalArtwork';
import { useModal } from 'modules/modals';
import { Print } from 'constants/data';
import { MARK_PRINT_UNAVAILABLE_FOR_SALE } from './helpers';
import { ReactComponent as CloseIcon } from 'static/images/close-icon.svg';
import { mobileMediaQuery } from 'utils/common';
import { useIsMobile } from 'utils/hooks';

import UIButton from 'ui-components/Button';

type Props = {
  id: string;
  isPrint: boolean;
  action: string | undefined;
  printData: null | Print;
  onSuccess?: () => void;
} & DialogProps;

interface ResultHandlerInput {
  error: Error;
  errorMessage: string;
  successMessage: string;
}

const MakeUnavailableDialog = (props: Props) => {
  const styles = useStyles();
  const { showSnackbar } = useModal();
  const markPrintUnAvailableForSale = useMarkPrintAsUnAvailableForSale();
  const markOriginalArtworkUnAvailable =
    useMarkOriginalArtworkAsUnAvailableForSale();
  const isMobile = useIsMobile();
  const actions = [MARK_PRINT_UNAVAILABLE_FOR_SALE];
  const { id, isPrint, action, printData, onClose, onSuccess, ...dialogProps } =
    props;

  const handleResult = (data: ResultHandlerInput) => {
    const { error, errorMessage, successMessage } = data;

    if (error) {
      showSnackbar(errorMessage, {
        severity: 'error',
      });
    } else {
      showSnackbar(successMessage);
      onSuccess && onSuccess();
    }

    closeDialog();
  };

  const closeDialog = () => {
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  };

  const disableSellPrint = async () => {
    const { id, printData } = props;
    if (printData) {
      const { imageId } = printData;

      const { error } = await promiseWrapper(
        markPrintUnAvailableForSale(id, imageId)
      );
      const errorMessage = 'Disable selling print failed';
      const successMessage = 'Disable selling print successfully';

      handleResult({ error, errorMessage, successMessage });
    }
  };

  const disableSellOriginal = async () => {
    const { id } = props;

    const { error } = await promiseWrapper(markOriginalArtworkUnAvailable(id));
    const errorMessage = 'Disable selling original artwork failed';
    const successMessage = 'Disable selling original artwork successfully';

    return handleResult({ error, errorMessage, successMessage });
  };

  const confirmHandler = async () => {
    const { isPrint, action } = props;

    if (!action || actions.indexOf(action) === -1) {
      return;
    }

    let handler;

    if (isPrint && action === 'disableSale') {
      handler = disableSellPrint;
    } else {
      handler = disableSellOriginal;
    }

    handler();
  };

  return (
    <Dialog fullScreen={isMobile} {...dialogProps}>
      <DialogContent className={styles.content}>
        <IconButton onClick={closeDialog} className={styles.closeButton}>
          <CloseIcon />
        </IconButton>
        <Typography className={styles.title}>
          Are you sure you want to take this off the market?
        </Typography>
        <Typography className={styles.description}>
          Once you confirm, the artwork will be made unavailable and taken off
          the market
        </Typography>
        <Box width={150}>
          <UIButton
            fullWidth
            variant="primary"
            size="medium"
            onClick={confirmHandler}
            text="Confirm"
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    content: {
      'padding': theme.spacing(6.25),
      'position': 'relative',
      '&:first-child': {
        paddingTop: theme.spacing(6.25),
      },
      [mobile]: {
        padding: theme.spacing(4),
      },
    },
    title: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(28),
      lineHeight: 1.2,
      color: '#000000',
    },
    description: {
      lineHeight: 1.625,
      color: '#000000',
      opacity: 0.8,
      padding: theme.spacing(3, 0),
    },
    closeButton: {
      position: 'absolute',
      top: 12,
      right: 20,
      [mobile]: {
        right: 10,
      },
    },
  });
});

export default MakeUnavailableDialog;
