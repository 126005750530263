import firebase from 'firebase';
import { EmailTemplateValues } from 'views/admin/queryDatabaseTool/tabs/EmailTemplateTab';

export const FIRESTORE_COLLECTIONS = {
  CUSTOMERS: 'customers',
  EMAIL_TEMPLATES: 'email_templates',
  INVITE_CODE_MAILS: 'invite_code_mails',
  PRODUCTS: 'products',
};

const getEmailTemplates = async (store: firebase.firestore.Firestore) => {
  return store.collection(FIRESTORE_COLLECTIONS.EMAIL_TEMPLATES).get();
};

const getEmailTemplate = (store: firebase.firestore.Firestore, id: string) => {
  return store.collection(FIRESTORE_COLLECTIONS.EMAIL_TEMPLATES).doc(id).get();
};

const setEmailTemplate = (
  store: firebase.firestore.Firestore,
  values: EmailTemplateValues
) => {
  return store
    .collection(FIRESTORE_COLLECTIONS.EMAIL_TEMPLATES)
    .doc(values.id)
    .set({ ...values }, { merge: true });
};

export { getEmailTemplates, getEmailTemplate, setEmailTemplate };
