import { useEffect, useState } from 'react';
import { ImageData } from 'constants/data/image.data';
import { Print } from 'constants/data/print.data';
import { useFirebaseContext } from 'shell/firebase';
import promiseWrapper from 'a-promise-wrapper';

type PlayingImage = ImageData & {
  print: Print | null;
};

export const useLoadPlayingArtworkByCanvas = (canvasId?: string) => {
  const firebase = useFirebaseContext();
  const [image, setImage] = useState<PlayingImage | null>();

  useEffect(() => {
    if (!canvasId) {
      setImage(null);
      return;
    }

    const fetchMedia = async () => {
      let imageId = null;
      let playingPrint: Print | null = null;

      const { data: printsSnapshot } = await promiseWrapper(
        firebase
          .printsRef()
          .orderByChild('canvasId')
          .equalTo(canvasId)
          .once('value')
      );
      const printsObject = printsSnapshot.val();

      // Check if the canvas is playing original Artwork
      if (!printsObject) {
        const { data: canvasSnapshot } = await promiseWrapper(
          firebase.canvasRef(canvasId).once('value')
        );
        const canvas = canvasSnapshot.val();
        if (canvas && canvas.originalArtwork) {
          imageId = canvas.originalArtwork;
        }
      } else {
        const playingPrintId = Object.keys(printsObject)[0];
        playingPrint = {
          ...printsObject[playingPrintId],
          uid: playingPrintId,
        };
        imageId = printsObject[playingPrintId].imageId;
      }

      if (imageId) {
        const { data: imageSnapshot } = await promiseWrapper(
          firebase.imageRef(imageId).once('value')
        );
        const image = imageSnapshot.val();
        if (image) {
          setImage({
            ...image,
            print: playingPrint,
          });
          return;
        }
      }
      setImage(null);
    };
    firebase.canvasToMediaLastChangedRef(canvasId).on('value', fetchMedia);

    return () =>
      firebase.canvasToMediaLastChangedRef(canvasId).off('value', fetchMedia);
  }, [firebase, canvasId]);

  return {
    image,
    isLoading: typeof image === 'undefined',
  };
};
