import loadable from 'utils/loadable';

const AdminExhibitionListPage = loadable(
  () =>
    import(
      /* webpackChunkName: "admin.exhibitions.ExhibitionList" */ './ExhibitionList'
    )
);
const AdminCreateExhibitionPage = loadable(
  () =>
    import(
      /* webpackChunkName: "admin.exhibitions.CreateExhibitionPage" */ './CreateExhibitionPage'
    )
);
const AdminEditExhibitionPage = loadable(
  () =>
    import(
      /* webpackChunkName: "admin.exhibitions.EditExhibitionPage" */ './EditExhibitionPage'
    )
);

export {
  AdminExhibitionListPage,
  AdminCreateExhibitionPage,
  AdminEditExhibitionPage,
};
