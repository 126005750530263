export enum StreamingLogActionEnum {
  OWNER_ENABLE_STREAMING = 'on',
  OWNER_DISABLE_STREAMING = 'off',
  OWNER_CHANGE_NUMBER = 'update',
  MEMBER_JOIN = 'join',
  MEMBER_LEAVE = 'leave',
  EXHIBITION_END_BY_ADMIN = 'end',
  EXHIBITION_END_AUTO = 'systemEnd',
  OWNER_UPDATE = 'update',
  ARCHIVE_STREAMING = 'archive',
}

export interface Streaming {
  exhibitionId?: string;
  timestamp?: number;
  isFreeSlot?: boolean;
  canvasId?: string;
  // additional field for FE use only
  artworkId?: string;
}

export interface StreamingLogs {
  userId: string;
  exhibitionId?: string;
  timestamp: number;
  action: StreamingLogActionEnum;
  metadataUpdate?: Record<string, any>;
}

export interface StreamingArtworkSlotsInfo {
  artworkId: string;
  exhibitionId?: string | null;
  totalSlots: number;
  currentUsersCount: number;
  availableSlots: number;
  isUserJoined?: boolean;
}

export const STREAMING_SLOT_UNLIMITED = -1;
