import { Box, CircularProgress } from '@material-ui/core';
import { useLoadVenueDetails } from 'utils/requests';
import * as ROUTES from 'constants/routes';
import { getSlug } from 'utils/common';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import UILink from 'ui-components/Link';

interface VenueCellProps {
  venueId: string;
}

const VenueCell = (props: VenueCellProps) => {
  const { venueId } = props;
  const { venue, isLoading } = useLoadVenueDetails(venueId);

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  if (!venue) {
    return null;
  }

  const { name } = venue;
  const detailUrl = generatePath(ROUTES.VENUE_DETAILS, {
    id: venueId,
    name: getSlug(name),
  });

  return (
    <Box>
      <UILink component={RouterLink} href={detailUrl} variant={'secondary'}>
        {name}
      </UILink>
    </Box>
  );
};

export default VenueCell;
