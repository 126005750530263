import { ReactNode } from 'react';
import { Box, makeStyles, createStyles } from '@material-ui/core';
import { useModesContext } from 'utils/contexts/modesContext';
import { useModalState } from 'utils/hooks';
import UploadImageDialog from './UploadImageDialog';
import { useLoadEditableImage } from './helpers';
import { IEditableImage } from 'constants/data';
import EditIcon from '@material-ui/icons/Edit';

export interface IRenderProps {
  openUploadDialog: (index: number) => void;
  editableImage: IEditableImage | null | undefined;
}

interface IProps {
  render: (props: IRenderProps) => ReactNode;
  imageKey: string;
  className?: string;
}

const EditableImage = ({ render, className, imageKey }: IProps) => {
  const classes = useStyles();
  const { isEditorMode } = useModesContext();
  const { open, isOpen, close } = useModalState<number>();
  const { editableImage } = useLoadEditableImage(imageKey);
  const openUploadDialog = () => {
    open();
  };
  return (
    <Box className={className} position="relative">
      {render({ editableImage, openUploadDialog })}
      {isOpen && (
        <UploadImageDialog
          imageKey={imageKey}
          editableImage={editableImage}
          open={isOpen}
          onClose={() => close(true)}
        />
      )}
      {isEditorMode && (
        <Box className={classes.placeholder} onClick={openUploadDialog}>
          <EditIcon fontSize="large" />
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    placeholder: {
      'position': 'absolute',
      'border': '3px dashed #aaa',
      'top': 0,
      'left': 0,
      'bottom': 0,
      'right': 0,
      'minWidth': 50,
      'minHeight': 50,
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      // backgroundColor: "rgba(255, 255, 255, 0.4)",
      'background':
        'linear-gradient(90deg, rgba(255,237,0,0.2) 0%, rgba(255,2,2,0.2) 11%, rgba(0,255,147,0.2) 21%, rgba(255,237,0,0.2) 31%, rgba(255,2,2,0.2) 41%, rgba(0,255,147,0.2) 51%, rgba(255,237,0,0.2) 61%, rgba(255,2,2,0.2) 71%, rgba(0,255,147,0.2) 81%), rgba(255,237,0,0.2) 91%',
      'cursor': 'pointer',
      '&:hover': {
        background: 'rgba(45, 74, 83, 0.8)',
      },
    },
  })
);

export default EditableImage;
