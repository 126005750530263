import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';

export type FilterValues = Record<string, Array<any> | any>;

export interface FilterContextProps<T = any> {
  activeFilter: FilterValues;
  clearFilter: () => void;
  originalList: T[];
  createHandleChangeFunc: (
    name: string
  ) => (values: (string | number)[]) => void;
}

const FilterContext = createContext<FilterContextProps>({
  activeFilter: {},
  originalList: [],
  clearFilter: () => {},
  createHandleChangeFunc: () => () => {},
});

interface ProviderProps<T = any> {
  defaultFilterValues: FilterValues;
  originalList: T[];
}

export const FilterContextProvider = ({
  children,
  defaultFilterValues = {},
  originalList = [],
}: React.PropsWithChildren<ProviderProps>) => {
  const [activeFilter, setActiveFilter] =
    useState<FilterValues>(defaultFilterValues);

  const createHandleChangeFunc = useCallback(
    (name: string) => (values: any[]) => {
      setActiveFilter(state => ({
        ...state,
        [name]: values,
      }));
    },
    []
  );

  const clearFilter = useCallback(() => {
    setActiveFilter({ ...defaultFilterValues });
  }, [defaultFilterValues]);

  const contextValue = useMemo(
    () => ({
      activeFilter,
      clearFilter,
      originalList,
      createHandleChangeFunc,
    }),
    [activeFilter, clearFilter, originalList, createHandleChangeFunc]
  );

  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => useContext(FilterContext);
