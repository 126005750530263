import DefaultFooter, { useStyles } from './Footer';
import { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { EnumVenueModuleType } from 'constants/data/venue.data';
import VenueFooter from 'views/client/venueDetails/editableModules/Footer';
import { useLayoutState } from 'utils/contexts/LayoutState';

const Footer = () => {
  const classes = useStyles();
  const { isHostedVenue, venue } = useLayoutState();

  const renderVenueFooter = useCallback(() => {
    if (!venue) {
      return null;
    }

    const { configurableModules, isDisplayVenueProfile25 } = venue;

    if (!isDisplayVenueProfile25) {
      return <DefaultFooter />;
    }

    if (!configurableModules) {
      return null;
    }

    const footerModule = Object.values(configurableModules).find(
      module => module.type === EnumVenueModuleType.Footer
    );

    if (!footerModule?.visible) {
      return null;
    }

    return (
      <Box className={classes.container}>
        <VenueFooter
          moduleId={footerModule.uid}
          venueId={venue.uid}
          isLandingPage={false}
        />
      </Box>
    );
  }, [venue, classes]);

  if (!isHostedVenue) {
    return <DefaultFooter />;
  }

  return renderVenueFooter();
};

export default Footer;
