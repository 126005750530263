import React from 'react';
import { useLocation } from 'react-router';
import {
  makeStyles,
  createStyles,
  Theme,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { mobileMediaQuery } from 'utils/common';
import AuthLayout from 'views/auth/AuthLayout';
import ResetPasswordConfirmationForm from './ResetPasswordConfirmationForm';
import { Headline, Heading } from '../../common/Typography';
import { useVerifyPasswordResetCode } from './helpers';

const ResetPasswordConfirmation = () => {
  const classes = useStyles();
  const { state: locationState } = useLocation<{ actionCode: string }>();
  const { actionCode } = locationState || {};
  const {
    email,
    isLoading,
    error: actionCodeError,
  } = useVerifyPasswordResetCode(actionCode);

  return (
    <AuthLayout>
      <Box className={classes.container}>
        <Headline>Account</Headline>
        <Box mt={2}>
          <Heading>Reset Password</Heading>
        </Box>
        <Box className={classes.resetPasswordForm}>
          {isLoading && <CircularProgress />}
          {(!actionCode || actionCodeError) && (
            <Alert severity="error">{actionCodeError}</Alert>
          )}
          {!!email && <ResetPasswordConfirmationForm actionCode={actionCode} />}
        </Box>
      </Box>
    </AuthLayout>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(11),
      width: '100%',
      [mobile]: {
        marginLeft: 0,
      },
    },
    resetPasswordForm: {
      flex: 1,
      marginTop: theme.spacing(2.5),
    },
  });
});

export default ResetPasswordConfirmation;
