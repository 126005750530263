import { TransactionData, TransactionType } from 'constants/data';
import { useEffect, useState } from 'react';
import { useFirebaseContext } from 'shell/firebase';
import { responseToArray } from 'utils/common';

export const useAcceptGift = () => {
  const firebase = useFirebaseContext();

  return (transactionId: string) => {
    if (!firebase) {
      return Promise.reject('firebase is not defined');
    }

    const callable = firebase.functions.httpsCallable('gift-accept');
    return callable(transactionId);
  };
};

export const useCancelGift = () => {
  const firebase = useFirebaseContext();

  return (transactionId: string) => {
    if (!firebase) {
      return Promise.reject('firebase is not defined');
    }

    const callable = firebase.functions.httpsCallable('gift-cancel');
    return callable(transactionId);
  };
};

export interface GiftTransactionInputs {
  originalArtworkId: string;
  printId: string;
  isPrint: boolean;
  receiverEmail: string;
  assignee?: string;
  otp?: string;
}

export const useSendGift = () => {
  const firebase = useFirebaseContext();
  const debug = false;

  return (data: GiftTransactionInputs) => {
    if (!firebase) {
      return Promise.reject('Firebase is not define');
    }

    if (debug) {
      return Promise.resolve({
        data: `/receive-artwork?otp=some_random_string&txId=tx_uid`,
      });
    }

    const callable = firebase.functions.httpsCallable('gift-send');
    return callable(data);
  };
};

export const useValidateOTPGift = () => {
  const firebase = useFirebaseContext();

  return (id: string, otp?: string) => {
    if (!firebase) {
      return Promise.reject('Firebase is not define');
    }

    const inputs = {
      id,
      otp,
    };

    const callable = firebase.functions.httpsCallable('gift-validateOTP');
    return callable(inputs);
  };
};

export const useResendEmail = () => {
  const firebase = useFirebaseContext();

  return (id: string) => {
    if (!firebase) {
      return Promise.reject('Firebase is not define');
    }

    if (!id) {
      return Promise.reject('id is invalid');
    }

    const callable = firebase.functions.httpsCallable(
      'gift-resendGiftReceiverEmail'
    );
    return callable(id);
  };
};

export const useAdminLoadGifts = (status?: string) => {
  const firebase = useFirebaseContext();
  const [data, setData] = useState<TransactionData[]>();

  useEffect(() => {
    const ref = firebase.transactionsRef();
    const field = 'eventType';
    const fieldValue = TransactionType.TRANSACTION_TYPE_GIFT;
    const query = ref.orderByChild(field).equalTo(fieldValue);
    const listener = query.on('value', snapshot => {
      const data = snapshot.val();

      if (data !== null) {
        const values = responseToArray(data) as TransactionData[];
        if (status) {
          setData(values.filter(trans => trans.status === status));
          return;
        }
        setData(values);

        return;
      }

      setData([]);
    });

    return () => query.off('value', listener);
  }, [firebase, status]);

  return {
    data,
    isLoading: typeof data === 'undefined',
  };
};

export const useAdminCancelGift = () => {
  const firebase = useFirebaseContext();

  return (transactionId: string) => {
    if (!firebase) {
      return Promise.reject('firebase is not defined');
    }

    if (!transactionId) {
      return Promise.reject('transactionId is undefined');
    }

    const callable = firebase.functions.httpsCallable(
      'admin-cancelGiftArtwork'
    );
    return callable(transactionId);
  };
};
