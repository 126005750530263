import Firebase from 'shell/firebase';

export const uploadCoverImage = async (
  firebase: Firebase,
  coverImage: File
): Promise<Record<string, string>> => {
  const uploadCoverImageSnapshot = await firebase.imagesStorageCreate(
    coverImage
  );
  const url = await uploadCoverImageSnapshot.ref.getDownloadURL();
  const path = uploadCoverImageSnapshot.ref.fullPath;

  return {
    url,
    path,
  };
};
