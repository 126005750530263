import { useState } from 'react';
import {
  Dialog,
  DialogProps,
  makeStyles,
  createStyles,
  Theme,
  DialogContent,
  Box,
  Button,
  IconButton,
} from '@material-ui/core';
import Typography from 'modules/typography';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { useIsMobile } from 'utils/hooks';
import { mobileMediaQuery } from 'utils/common';
import { CanvasData } from 'constants/data/canvas.data';
import { ImageData } from 'constants/data/image.data';
import { IPrintTransform } from 'constants/data/print.data';
import SelectionBox from './SelectionBox';

export type ImageSelectionParamsType = {
  image: ImageData;
  printId?: string;
  canvas: CanvasData;
  printTransform: IPrintTransform | null;
};

export type ExtendedImageSelectionParamsType = ImageSelectionParamsType & {
  onConfirm: (params: ImageSelectionParamsType) => void;
};

type Props = Omit<DialogProps, 'onClose'> &
  ExtendedImageSelectionParamsType & {
    onClose: () => void;
    onNext?: () => void;
  };

const ImageSelectionDialog = ({
  image,
  printId,
  canvas,
  onClose,
  onConfirm,
  onNext,
  printTransform: initialTransform,
  ...dialogProps
}: Props) => {
  const [printTransform, setPrintTransform] = useState<IPrintTransform | null>(
    null
  );
  const isMobile = useIsMobile();
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  const handleClickConfirm = () => {
    onConfirm({
      image,
      printId,
      canvas,
      printTransform,
    });
    onNext && onNext();
    handleClose();
  };
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullWidth
      {...dialogProps}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={isMobile}>
      <DialogContent className={classes.content}>
        <Box className={classes.textArea}>
          <Box className={classes.titleWrapper}>
            <Typography
              className={classes.title}
              fontSize="2.25rem"
              lineHeight={1.2}
              mobileFontSize="1.75rem"
              fontWeight={500}>
              Select image crop on your display
            </Typography>
            <IconButton className={classes.buttonClose} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={classes.bodyWrapper}>
            <Typography fontSize="0.875rem">
              Zoom in, out or drag the image within the viewport to your
              preference
            </Typography>
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonConfirm}
              onClick={handleClickConfirm}>
              <CheckIcon />
            </Button>
          </Box>
        </Box>
        <Box className={classes.selectionArea}>
          <SelectionBox
            image={image}
            canvas={canvas}
            onChange={setPrintTransform}
            initialValue={initialTransform}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    root: {},
    paper: {
      height: 600,
      maxWidth: 1000,
      [mobile]: {
        height: '100%',
      },
    },
    content: {
      'display': 'flex',
      'flexDirection': 'row',
      'padding': 0,

      '&:first-child': {
        paddingTop: 0,
      },
      [mobile]: {
        flexDirection: 'column-reverse',
      },
    },
    textArea: {
      position: 'relative',
      flex: '1 1 50%',
      background: '#fff',
      padding: theme.spacing(7, 4, 7, 7),
      display: 'flex',
      flexDirection: 'column',
      [mobile]: {
        padding: theme.spacing(2),
        flex: 0,
        paddingBottom: theme.spacing(3),
      },
    },
    selectionArea: {
      flex: '1 1 50%',
      background: '#CCCCCC',
    },
    titleWrapper: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'flex-start',
      gap: theme.spacing(4) + 'px',
    },
    title: {},
    bodyWrapper: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      paddingRight: theme.spacing(7),
      [mobile]: {
        marginTop: 'auto',
        gap: theme.spacing(2) + 'px',
        flexDirection: 'row',
        paddingRight: 0,
      },
    },
    buttonConfirm: {
      marginTop: 'auto',
      minWidth: 50,
      width: 50,
      height: 50,
      alignSelf: 'flex-start',
      padding: 0,
      [mobile]: {
        marginTop: 0,
      },
    },
    buttonClose: {
      color: '#000',
      marginTop: -theme.spacing(1),
    },
  });
});

export default ImageSelectionDialog;
