import { useLocation } from 'react-router-dom';
import { isSpecialGifting } from 'utils/common/gift';

export const useSpecialGifting = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const giftingType = query.get('type');

  return isSpecialGifting(giftingType);
};
