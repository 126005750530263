import React from 'react';
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Chip,
  Checkbox,
} from '@material-ui/core';
import { Column, CellProps } from 'react-table';
import { UserData } from 'constants/data/user.data';
import { useAllUsersContext } from 'utils/contexts/allUsersContext';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { useModal } from 'modules/modals';
import { ADMIN_USER_DETAILS } from 'constants/routes';
import { getMembershipItem } from 'utils/common';
import UILink from 'ui-components/Link';

import UIButton from 'ui-components/Button';

export const COLUMN_FULLNAME: Column<UserData> = {
  accessor: 'fullName',
  Header: 'Full Name',
  width: 300,
  Cell: ({ row }: CellProps<UserData>) => {
    const user = row.original;
    const classes = useStyles();
    return (
      <Box>
        <span>{user.fullName}</span>
        {user.isAdmin && (
          <Chip
            label="Admin"
            className={classes.chip}
            variant="outlined"
            size="small"
          />
        )}
        {user.canUpload && (
          <Chip
            label="Artist"
            color="secondary"
            className={classes.chip}
            variant="outlined"
            size="small"
          />
        )}
      </Box>
    );
  },
};
export const COLUMN_USERNAME: Column<UserData> = {
  accessor: 'username',
  Header: 'Username',
  width: 200,
};
export const COLUMN_EMAIL: Column<UserData> = {
  accessor: 'email',
  Header: 'Email',
  width: 300,
};

export const COLUMN_MEMBERSHIP: Column<UserData> = {
  accessor: 'membership',
  Header: 'Membership',
  width: 150,
  Cell: ({ row }: CellProps<UserData>) => {
    const user = row.original;
    const selectedMembership = getMembershipItem(user.membership);
    if (!selectedMembership) {
      return null;
    }
    return <Box>{selectedMembership.name}</Box>;
  },
};

export const COLUMN_IS_JOIN_WAITLIST: Column<UserData> = {
  accessor: 'isJoinWaitlist',
  Header: 'Join Waitlist',
  width: 150,
  Cell: ({ row }: CellProps<UserData>) => {
    const user = row.original;
    return (
      <Box>
        <Checkbox
          checked={!!user.isJoinWaitlist}
          color="primary"
          disabled={true}
        />
      </Box>
    );
  },
};

export const COLUMN_ACTION: Column<UserData> = {
  id: 'action',
  Header: '',
  width: 200,
  disableSortBy: true,
  Cell: ({ row }: CellProps<UserData>) => {
    return <ActionsCell user={row.original} />;
  },
};

const ActionsCell = ({ user }: { user: UserData }) => {
  const classes = useStyles();
  const { openDialog: openConfirmDialog, showSnackbar } = useModal();
  const { enableUser, disableUser } = useAllUsersContext();
  const handleDisableClick = (uid: string) => {
    openConfirmDialog({
      title: 'Disable user',
      message:
        "Do you really want to disable this user's access to the site? They will be logged out of their canvases and lose the ability to display content until they are enable again.",
      onConfirm: () => {
        disableUser(uid)
          .then(() => {
            showSnackbar('User has been disabled');
          })
          .catch(error => {
            showSnackbar(error.message, { severity: 'error' });
          });
      },
      labelConfirm: 'Yes, disable',
    });
  };
  const handleEnableClick = (uid: string) => {
    enableUser(uid)
      .then(() => {
        showSnackbar('User has been enabled');
      })
      .catch(error => {
        showSnackbar(error.message, { severity: 'error' });
      });
  };

  const handleClick = () => {
    if (user.active) {
      handleDisableClick(user.uid);
    } else {
      handleEnableClick(user.uid);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Box>
      <UILink
        component={RouterLink}
        href={generatePath(ADMIN_USER_DETAILS, { id: user.uid })}
        variant={'secondary'}>
        Details
      </UILink>
      <UIButton
        onClick={handleClick}
        variant="primary"
        size="medium"
        className={classes.activeButton}
        text={user.active ? 'Disable' : 'Enable'}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    activeButton: {
      marginLeft: theme.spacing(2),
    },
    chip: {
      marginLeft: theme.spacing(1),
    },
  });
});
