import { useEffect, useState } from 'react';
import { useFirebaseContext } from 'shell/firebase';

export const useLoadHeroMedias = () => {
  const [images, setImages] = useState<string[] | null>();
  const firebase = useFirebaseContext();

  useEffect(() => {
    if (firebase !== null) {
      const query = firebase.heroMediaRef();
      const callback = query.on('value', snapshot => {
        const value = snapshot.val();

        if (value === null) {
          setImages(null);
        } else {
          let sorted: string[] = [];
          // use snapshot.forEach to maintain the sorted order turned from firebase
          snapshot.forEach(child => {
            sorted = [child.val(), ...sorted];
          });
          setImages(sorted);
        }
      });

      return () => query.off('value', callback);
    }
  }, [firebase]);
  return { images, isLoading: typeof images === 'undefined' };
};
