import React, { useEffect, useRef, LegacyRef, MouseEvent } from 'react';
import {
  Box,
  makeStyles,
  createStyles,
  Popper,
  Paper,
  Theme,
  ClickAwayListener,
} from '@material-ui/core';
import cs from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { useModalState } from 'utils/hooks';
import {
  useUpdateText,
  useLoadText,
  useLoadLink,
  EditableTextType,
  getLinkToSave,
  useLogEditableTextChange,
} from './helpers';
import { useModesContext } from 'utils/contexts/modesContext';
import TextField from 'modules/formFields/TextField';
import { useModal } from 'modules/modals';
import { EditableTextLog } from 'constants/data';

import UIButton from 'ui-components/Button';

interface ChildrenRenderProps {
  text: string;
  link: string;
}

interface Props {
  textKey: string;
  defaultText: string;
  linkKey?: string;
  defaultLink?: string;
  textType?: EditableTextType;
  render?: (renderProps: ChildrenRenderProps) => React.ReactNode;
}

interface FormValuesInterface {
  text: string;
  link?: string;
}

interface LoadEditableTextDataInputs {
  textKey: string;
  textType?: EditableTextType;
  linkKey?: string;
  defaultText: string;
  defaultLink?: string;
}
interface RawEditableTextProps {
  text: string;
  textKey: string;
  textType: EditableTextType;
  linkKey?: string;
  link?: string;
}

export const useLoadEditableTextData = (inputs: LoadEditableTextDataInputs) => {
  const {
    textKey,
    textType = 'static-text',
    linkKey,
    defaultText,
    defaultLink,
  } = inputs;
  const { text: savedText } = useLoadText({ textKey, textType });
  const { link: savedLink } = useLoadLink({
    linkKey,
  });

  const text = savedText || defaultText;
  const link = savedLink || defaultLink || '#';

  return {
    text,
    link,
  };
};

export const RawEditableText = (props: RawEditableTextProps) => {
  const { text, textKey, textType, link, linkKey } = props;

  return (
    <EditText
      text={text}
      textKey={textKey}
      textType={textType}
      link={link}
      linkKey={linkKey}
    />
  );
};

const EditableText = ({
  textKey,
  defaultText,
  linkKey,
  defaultLink,
  textType = 'static-text',
  render,
}: Props) => {
  const { text: savedText } = useLoadText({ textKey, textType });
  const { link: savedLink } = useLoadLink({
    linkKey,
  });
  const { isEditorMode } = useModesContext();
  const text = savedText || defaultText;
  const link = savedLink || defaultLink || '#';
  const renderedChildren =
    typeof render === 'function' ? render({ text, link }) : text;

  if (!isEditorMode) {
    return <>{renderedChildren}</>;
  }
  return (
    <EditText
      text={text}
      textKey={textKey}
      textType={textType}
      link={link}
      linkKey={linkKey}
    />
  );
};

const EditText = ({
  text,
  textKey,
  link,
  linkKey,
  textType,
}: {
  text: string;
  textKey: string;
  link?: string;
  linkKey?: string;
  textType: EditableTextType;
}) => {
  const { showSnackbar } = useModal();
  const updateText = useUpdateText({ textKey, linkKey, textType });
  const logEditableTextChange = useLogEditableTextChange();
  const classes = useStyles();
  const anchorRef = useRef();
  const { exitEditorMode } = useModesContext();
  const { isOpen, close, open } = useModalState();

  const { handleSubmit, control, reset } = useForm<FormValuesInterface>();
  useEffect(() => {
    reset({ text, link });
  }, [text, link, reset]);

  const handleClose = () => {
    close(false);
    reset();
  };

  const logEventUpdate = (values: FormValuesInterface) => {
    const logData: EditableTextLog = {
      textKey,
      linkKey: linkKey || '',
      oldData: {
        text: text,
        link: link || '',
      },
      newData: {
        text: values.text,
        link: values.link ? getLinkToSave(values.link) : '',
      },
    };
    return logEditableTextChange(logData);
  };

  const onSubmit = (values: FormValuesInterface) => {
    const link = values.link ? getLinkToSave(values.link) : undefined;
    if (!textKey) {
      return;
    }

    updateText({ text: values.text, link })
      .then(() => {
        handleClose();
        logEventUpdate(values);
        exitEditorMode();
        showSnackbar('Text content updated');
      })
      .catch(error => {
        console.error(error);
        showSnackbar('Something went wrong, failed to update text', {
          severity: 'error',
        });
      });
  };

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    open();
  };
  const handleClickPopper = (e: MouseEvent<HTMLElement>) => {
    // Prevent the parent from being click when working on the popper
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };
  const { label, helperText } = getUITexts(textType);
  return (
    <>
      <span
        className={cs(classes.root, {
          [classes.emptyText]: text.length <= 0,
        })}
        onClick={handleClick}
        ref={anchorRef as any as LegacyRef<HTMLSpanElement>}>
        {text}
      </span>
      <Popper open={isOpen} anchorEl={anchorRef.current}>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper
            elevation={2}
            className={classes.popperContent}
            onClick={handleClickPopper}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="text"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={label}
                    type="text"
                    fullWidth
                    autoComplete="off"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : helperText}
                  />
                )}
              />
              {linkKey && (
                <Controller
                  name="link"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Box mt={2}>
                      <TextField
                        label="New link"
                        fullWidth
                        autoComplete="off"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : helperText}
                      />
                    </Box>
                  )}
                />
              )}
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <UIButton
                  variant="primary"
                  size="small"
                  type="submit"
                  text="Save"
                />
              </Box>
            </form>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

const getUITexts = (textType: EditableTextType) => {
  switch (textType) {
    case 'keyword-description': {
      return {
        label: 'New keyword description',
        helperText:
          '* You are editing a keyword description which may also appear on other pages.',
      };
    }
    case 'static-text': {
      return {
        label: 'New Text',
        helperText: '',
      };
    }
    default: {
      throw new Error('Invalid Editable Text Type');
    }
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      'background':
        'linear-gradient(90deg, rgba(255,237,0,0.2) 0%, rgba(255,2,2,0.2) 11%, rgba(0,255,147,0.2) 21%, rgba(255,237,0,0.2) 31%, rgba(255,2,2,0.2) 41%, rgba(0,255,147,0.2) 51%, rgba(255,237,0,0.2) 61%, rgba(255,2,2,0.2) 71%, rgba(0,255,147,0.2) 81%), rgba(255,237,0,0.2) 91%',
      'backgroundColor': '#f0f8ff',
      'cursor': 'pointer',
      '&:hover': {
        background: '#72bcd4',
      },
    },
    emptyText: {
      display: 'inline-block',
      width: 50,
      height: '1rem',
    },
    popperContent: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
    },
  })
);

export default EditableText;
