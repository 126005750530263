import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import {
  Dialog,
  DialogProps,
  makeStyles,
  createStyles,
  Theme,
  DialogContent,
  Box,
  IconButton,
} from '@material-ui/core';
import Typography from 'modules/typography';
import CloseIcon from '@material-ui/icons/Close';
import { useIsMobile } from 'utils/hooks';
import { mobileMediaQuery } from 'utils/common';
import { ImageData } from 'constants/data/image.data';
import { Print as PrintData } from 'constants/data/print.data';
import OptimizedImage from 'modules/OptimizedImage';
import PrintItem from './PrintItem';
import { useLoadUser } from 'utils/requests';
import { SingleFilterSelect } from 'modules/filterControls';
import SortIcon from '@material-ui/icons/Sort';
import Tabs, { Tab } from 'ui-components/Tabs';
import { ReactComponent as ListIcon } from 'static/images/list-icon.svg';
import { useLayoutState } from 'utils/contexts/LayoutState';
import AIPoweredBy from 'modules/aIPoweredBy';

const ROW_HEIGHT = 70;
const contentRef = React.createRef<HTMLElement>();

type ListEditionsProps = {
  prints: PrintData[];
  image: ImageData;
  onSelectPrint: (print: PrintData) => void;
};

export const ListEditions = ({
  prints,
  image,
  onSelectPrint,
}: ListEditionsProps) => {
  const styles = useStyles();
  const filterRef = React.createRef<HTMLDivElement>();
  const [sortType, setSortType] = useState<ISortType>('relevancy');
  const [height, setHeight] = React.useState(0);
  const sortedPrints = useMemo(
    () => sortPrints(prints, sortType),
    [prints, sortType]
  );

  const itemData = useMemo(
    () => ({
      prints: sortedPrints,
      onSelectPrint,
      image,
    }),
    [sortedPrints, onSelectPrint, image]
  );

  useEffect(() => {
    if (contentRef?.current && filterRef?.current) {
      const contentRect = contentRef.current.getBoundingClientRect();
      const filterRect = filterRef.current.getBoundingClientRect();
      const height =
        contentRect.height -
        (filterRect.top - contentRect.top) -
        filterRect.height;

      setHeight(height);
    }
  }, [filterRef]);

  return (
    <Box>
      {sortedPrints.length ? (
        <>
          <div ref={filterRef}>
            <SingleFilterSelect
              options={OPTIONS}
              onChange={type => setSortType(type as ISortType)}
              value={sortType}
              autoWidth
              renderValue={selectedValue => (
                <Box display="flex" alignItems="center">
                  <Box component={SortIcon} mr={1} fontSize={18} />
                  <Typography fontWeight={500}>
                    {getOptionLabel(selectedValue as ISortType)}
                  </Typography>
                </Box>
              )}
            />
          </div>
          <FixedSizeList
            itemData={itemData}
            height={height}
            itemCount={sortedPrints.length}
            itemSize={ROW_HEIGHT}
            width={'100%'}>
            {PrintItemRow}
          </FixedSizeList>
        </>
      ) : (
        <div className={styles.emptyStateWrapper}>
          <ListIcon />
          <Typography className={styles.emptyStateText}>
            There are no offers yet
          </Typography>
        </div>
      )}
    </Box>
  );
};

type PrintItemRowProps = ListChildComponentProps<{
  prints: PrintData[];
  image: ImageData;
  onSelectPrint: (print: PrintData) => void;
}>;

const PrintItemRow = (props: PrintItemRowProps) => {
  const styles = useStyles();
  const { prints, image, onSelectPrint } = props.data;
  const print = prints[props.index];
  const clickBuyHandler = useCallback(
    () => onSelectPrint(print),
    [onSelectPrint, print]
  );

  return (
    <div className={styles.printItem} style={props.style}>
      <PrintItem print={print} image={image} onClickBuy={clickBuyHandler} />
    </div>
  );
};

interface Props extends Omit<DialogProps, 'onClose'> {
  image: ImageData;
  prints: PrintData[];
  onClose: () => void;
  onSelectPrint: (print: PrintData) => void;
}

const SelectPrintDialog = ({
  image,
  prints,
  onClose,
  onSelectPrint,
  ...dialogProps
}: Props) => {
  const isMobile = useIsMobile();
  const classes = useStyles();
  const { user: artist } = useLoadUser(image.artist);
  const { isHostedVenue } = useLayoutState();

  const handleClose = (reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (!reason) {
      onClose();
    }
  };

  const printsOfCreator = useMemo(() => {
    const filtered = prints.filter(
      ({ owner }) => !owner || owner === image.artist
    );
    return filtered;
  }, [prints, image.artist]);

  const printsOfCollectors = useMemo(() => {
    const filtered = prints.filter(
      ({ owner }) => owner && owner !== image.artist
    );
    return filtered;
  }, [prints, image.artist]);

  const activeTabIndex =
    printsOfCreator.length === 0 && printsOfCollectors.length !== 0 ? 1 : 0;

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullWidth
      {...dialogProps}
      onClose={(event, reason) => handleClose(reason)}
      fullScreen={isMobile}>
      <DialogContent className={classes.content} ref={contentRef}>
        <IconButton
          className={classes.buttonClose}
          onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
        <Box className={classes.leftColumn}>
          <Typography className={classes.buyArtwork}>Buy Artwork</Typography>
          <Typography className={classes.selectEdition}>
            Select edition
          </Typography>
          <Typography fontSize="0.875rem" lineHeight={1.6} fontColor="#030303">
            Select the edition number of the artwork you wish to gain ownership
            of.
          </Typography>
          <Box className={classes.imageInfo}>
            <OptimizedImage
              path={image.path}
              sizes="200px"
              className={classes.imagePreview}
            />
            <Box>
              <Typography fontSize="1rem" lineHeight={1.6}>
                {image.name}
              </Typography>
              <Typography
                fontSize="0.875rem"
                lineHeight={1.6}
                fontColor="616161">
                {artist?.fullName}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.rightColumn}>
          <Tabs activeTabIndex={activeTabIndex}>
            <Tab title="From the creator">
              <ListEditions
                prints={printsOfCreator}
                image={image}
                onSelectPrint={onSelectPrint}
              />
            </Tab>
            <Tab title="From collectors">
              <ListEditions
                prints={printsOfCollectors}
                image={image}
                onSelectPrint={onSelectPrint}
              />
            </Tab>
          </Tabs>
        </Box>
      </DialogContent>
      {isHostedVenue && (
        <Box mr={isMobile ? 3 : 5} my={1.5} textAlign="right">
          <AIPoweredBy />
        </Box>
      )}
    </Dialog>
  );
};

const sortPrints = (prints: PrintData[], sortType: ISortType) => {
  let result;
  switch (sortType) {
    case 'edition_asc': {
      result = prints.slice().sort((a, b) => a.editionIndex - b.editionIndex);
      break;
    }
    case 'edition_desc': {
      result = prints.slice().sort((a, b) => b.editionIndex - a.editionIndex);
      break;
    }
    case 'price_asc': {
      result = prints.slice().sort((a, b) => (a.price || 0) - (b.price || 0));
      break;
    }
    case 'price_desc': {
      result = prints.slice().sort((a, b) => (b.price || 0) - (a.price || 0));
      break;
    }
    case 'relevancy': {
      result = prints.slice().sort((a, b) => {
        if (a.notForSale && !b.notForSale) {
          return 1;
        }
        if (!a.notForSale && b.notForSale) {
          return -1;
        }
        return a.editionIndex - b.editionIndex;
      });
      break;
    }
    default: {
      result = prints;
    }
  }
  return result;
};

type ISortType =
  | 'relevancy'
  | 'price_asc'
  | 'price_desc'
  | 'edition_asc'
  | 'edition_desc';
const OPTIONS: Array<{ value: ISortType; label: string }> = [
  { value: 'relevancy', label: 'Relevancy' },
  { value: 'price_asc', label: 'Price: low to high' },
  { value: 'price_desc', label: 'Price: high to low' },
  { value: 'edition_asc', label: 'Lowest edition number' },
  { value: 'edition_desc', label: 'Highest edition number' },
];
const getOptionLabel = (optionValue: ISortType) => {
  const option = OPTIONS.find(({ value }) => value === optionValue);
  return option ? option.label : null;
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    root: {},
    paper: {
      color: '#000',
      height: 600,
      maxWidth: 1000,
      [mobile]: {
        height: '100%',
      },
    },
    content: {
      'display': 'flex',
      'flexDirection': 'row',
      'padding': 0,
      'position': 'relative',
      '&:first-child': {
        paddingTop: 0,
      },
      [mobile]: {
        flexDirection: 'column',
      },
    },
    leftColumn: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: theme.spacing(6, 2, 6, 7),
      [mobile]: {
        padding: theme.spacing(6, 2, 2),
      },
    },
    rightColumn: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(6),
      [mobile]: {
        paddingTop: theme.spacing(2),
        width: '100%',
      },
    },
    imageInfo: {
      display: 'flex',
      gap: theme.spacing(2),
      marginTop: theme.spacing(4),
      alignItems: 'center',
    },
    imagePreview: {
      maxWidth: 100,
      maxHeight: 200,
    },
    buyArtwork: {
      fontFamily: ['Fraunces', 'Times', 'Times New Roman', 'serif'].join(', '),
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1.25,
      fontWeight: 300,
      marginBottom: theme.spacing(1),
    },
    selectEdition: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(36),
      lineHeight: 1.2,
      marginBottom: theme.spacing(2.5),
    },
    divider: {
      marginBottom: theme.spacing(2),
      backgroundColor: '#7A7A7A',
    },
    buttonClose: {
      position: 'absolute',
      top: '8px',
      right: '8px',
    },
    printItem: {
      paddingRight: theme.spacing(2),
    },
    emptyStateWrapper: {
      display: 'flex',
    },
    emptyStateText: {
      paddingLeft: theme.spacing(2),
    },
  });
});

export default SelectPrintDialog;
