import { DEFAULT_CURRENCY, zeroDecimalCurrencies } from 'constants/common';
import { Stripe } from 'stripe';

export const getPriceInCents = (price: Stripe.Price) => {
  const { unit_amount: amount, currency = DEFAULT_CURRENCY } = price;

  if (!amount) {
    return 0;
  }

  if (zeroDecimalCurrencies.indexOf(currency.toUpperCase()) === -1) {
    return amount * 100;
  }

  return amount;
};

export const getPriceFromCents = (price: Stripe.Price) => {
  const { unit_amount: amount, currency = DEFAULT_CURRENCY } = price;

  if (!amount) {
    return 0;
  }

  if (zeroDecimalCurrencies.indexOf(currency.toUpperCase()) === -1) {
    return amount / 100;
  }

  return amount;
};
