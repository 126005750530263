import React, { useRef, useState, useEffect } from 'react';
import cs from 'classnames';
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  styled,
  CircularProgress,
} from '@material-ui/core';
import { spacing, sizing, compose } from '@material-ui/system';
import OptimizedImage, { Sizes } from '../OptimizedImage';
import ExternalRouterLink from '../editableText/ExternalRouterLink';
import { checkIsInternalLink, getFullImagePath } from 'utils/common';
import { useIsMobile } from 'utils/hooks';

/**
 * This component is to wrap an image inside a fixed ratio wrapper
 * The image keeps its aspect ratio and fills the given dimension. The image will be clipped to fit
 * If the image is from our firebase storage, use `path` instead of `src` to make use of the optimization features.
 *
 * @example
 *
 *  const Demo = () => (
 *    <ImageBox ratio="4:3" src='https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg' alt="demo"/>
 *  );
 *
 * @typedef {Object} Props
 * @prop {string} src The `src` attribute of the wrapped `img`
 * @prop {string} path Path to the image in firebase storage. If this prop is present then the `src` will be ignored
 * @prop {string} alt The `alt` attribute of the wrapped `img`
 * @prop {string} ratio The ratio of the wrapper, must be in format `w:h` e.g. `4:3`. Default is `16:9`
 * @prop {string} linkTo If this prop is specified, the component will be wrapped by an anchor with href={linkTo}
 * @prop {number} originalRatio The img's `originalRatio` (width/height) attribute. Default is `16/9`
 * @param {Prop} props
 */

interface ImageBoxProps {
  src?: string;
  path?: string;
  alt: string;
  ratio: string;
  classNames?: { root?: string; img?: string };
  className?: string;
  linkTo?: string;
  originalRatio: number;
  sizes?: Sizes | string;
  previewUrl?: string;
}

const ImageBox = ({
  path,
  src,
  alt,
  ratio,
  classNames: propClasses,
  className,
  linkTo,
  originalRatio,
  sizes,
  previewUrl,
}: ImageBoxProps) => {
  const classes = useStyles();
  const imgRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  // use the minumum size until the correct sizes is calculated
  const [newSizes, setNewSizes] = useState<Sizes | string>('1px');
  const [isHover, setHover] = useState<boolean>(false);
  const [isImageLoaded, setImageLoaded] = useState<boolean>(false);
  const [isVideoCanPlay, setVideoCanPlay] = useState<boolean>(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (sizes) {
      // If pass `sizes` props it will ignore auto calculate new sizes
      setNewSizes(sizes);
      return;
    }
    const clientWidth = imgRef?.current?.clientWidth;
    const clientHeight = imgRef?.current?.clientHeight;
    if (originalRatio && clientWidth && clientHeight) {
      setNewSizes(calculateSizes(originalRatio, clientWidth, clientHeight));
    }
  }, [originalRatio, sizes]);
  const isShowImage = () => {
    return !isHover || !isImageLoaded || !previewUrl || !isVideoCanPlay;
  };

  useEffect(() => {
    if (previewUrl && videoRef.current) {
      (videoRef.current as HTMLVideoElement).load();
    }
  }, [videoRef, previewUrl, isMobile]);

  const handlePointerEnter = () => {
    if (!videoRef?.current) {
      return;
    }

    try {
      videoRef.current.play();
    } catch (e) {
      console.error(e);
    }
  };

  const handlePointerLeave = () => {
    if (!videoRef?.current) {
      return;
    }

    try {
      videoRef.current.pause();
    } catch (e) {
      console.error(e);
    }
  };

  // Use OptimizedImage if the `path` is present
  const renderContent = () => {
    if (path) {
      return (
        <div
          className={classes.imgContainer}
          ref={imgRef}
          style={{
            paddingTop: calculatePaddingTop(ratio),
          }}>
          <OptimizedImage
            path={path}
            alt={alt}
            sizes={newSizes}
            className={cs(classes.img, propClasses?.img)}
            style={{ display: isShowImage() ? 'block' : 'none' }}
            onLoad={() => setImageLoaded(true)}
          />
          {previewUrl && (
            <video
              ref={videoRef}
              playsInline
              autoPlay
              muted
              loop
              className={cs(classes.img, propClasses?.img)}
              style={{ display: !isShowImage() ? 'block' : 'none' }}
              id={path}
              poster={getFullImagePath(path)}
              onContextMenu={event => event.preventDefault()}
              onLoadedMetadata={() => setVideoCanPlay(true)}>
              <source src={previewUrl} />
            </video>
          )}
          <Box
            className={classes.overlay}
            onPointerEnter={() => setHover(true)}
            onPointerLeave={() => setHover(false)}
          />
        </div>
      );
    }

    if (previewUrl) {
      return (
        <div
          className={classes.imgContainer}
          ref={imgRef}
          style={{
            paddingTop: calculatePaddingTop(ratio),
          }}>
          <video
            ref={videoRef}
            playsInline
            muted
            loop
            className={cs(classes.img, propClasses?.img)}
            id={previewUrl}
            onContextMenu={event => event.preventDefault()}
            onLoadedMetadata={() => setVideoCanPlay(true)}>
            <source src={previewUrl} />
          </video>
          {!isVideoCanPlay && (
            <Box className={classes.loading}>
              <CircularProgress />
            </Box>
          )}
          <Box
            className={classes.overlay}
            onPointerEnter={handlePointerEnter}
            onPointerLeave={handlePointerLeave}
          />
        </div>
      );
    }

    return (
      <Box
        className={classes.imgContainer}
        paddingTop={calculatePaddingTop(ratio)}>
        {src && (
          <img
            src={src}
            alt={alt}
            onContextMenu={event => event.preventDefault()}
            className={cs(classes.img, propClasses?.img)}
          />
        )}
        <Box className={classes.overlay} />
      </Box>
    );
  };

  if (linkTo) {
    return (
      <ExternalRouterLink
        to={linkTo}
        className={cs(classes.root, propClasses?.root, className)}
        title={alt}
        rel={checkIsInternalLink(linkTo) ? '' : 'noreferrer'}>
        {renderContent()}
      </ExternalRouterLink>
    );
  }

  return (
    <Box className={cs(classes.root, propClasses?.root, className)}>
      {renderContent()}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      overflow: 'hidden',
    },
    imgContainer: {
      position: 'relative',
      height: '100%',
    },
    loading: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    img: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      objectFit: 'cover',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  })
);

ImageBox.defaultProps = {
  ratio: '16:9',
  originalRatio: 16 / 9,
};

const calculatePaddingTop = (ratio: string) => {
  const [width, height] = ratio.split(':');
  const paddingTop = (parseInt(height) / parseInt(width)) * 100;
  if (Number.isNaN(paddingTop)) {
    // Return the value for 16:9
    return '56.25%';
  }
  return `${paddingTop}%`;
};

const calculateSizes = (
  originalRatio: number,
  clientWidth: number,
  clientHeight: number
): string => {
  const realRatio = clientWidth / clientHeight;
  const newSize = Math.round(
    realRatio > originalRatio
      ? clientWidth
      : (clientWidth * originalRatio) / realRatio
  );
  return newSize + 'px';
};

export default styled(ImageBox)(compose(spacing, sizing));
