import { useMemo } from 'react';
import { OptionRenderProps } from '../FilterOption';
import { FilterAutocomplete } from 'modules/filterControls';
import { useArtistsContext } from 'utils/contexts/artistsContext';
import { UserData } from 'constants/data/user.data';

export const ArtistOption = ({ values, onChange }: OptionRenderProps) => {
  const { artistsMap, getArtistInfo } = useArtistsContext();

  const renderArtistsLabel = createRenderArtistsLabelFunc(getArtistInfo);

  const artistOptions = useMemo(
    () => (artistsMap ? createArtistOptions(artistsMap) : []),
    [artistsMap]
  );
  return (
    <FilterAutocomplete
      options={artistOptions}
      value={values}
      onChange={onChange}
      renderValue={renderArtistsLabel}
    />
  );
};

const createArtistOptions = (artistsMap: Record<string, UserData>) => {
  return Object.entries(artistsMap || {}).map(([uid, artist]) => ({
    label: artist.fullName,
    value: uid,
  }));
};

const createRenderArtistsLabelFunc =
  (getArtistInfo: (artistId: string) => UserData | null | undefined) =>
  (value: string[]) => {
    if (value?.length <= 0) {
      return 'Artists';
    }
    const artistsName = value
      .map(artistId => getArtistInfo(artistId)?.fullName)
      .join(', ');
    return `Artists: ${artistsName}`;
  };
