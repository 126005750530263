import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuthContext } from 'shell/session';
import { SIGN_IN, LANDING as homePath } from 'constants/routes';
import { UserData } from 'constants/data/user.data';

const AdminRoute = (props: RouteProps) => {
  const authContext = useAuthContext();
  const user = authContext.user as UserData | undefined | null;

  // undefined means checking user status
  if (typeof user === 'undefined') {
    return null;
  }

  if (user === null) {
    return (
      <Redirect
        to={{
          pathname: SIGN_IN,
          state: { from: props.location },
        }}
      />
    );
  }

  if (user && !user.isAdmin) {
    return (
      <Redirect
        to={{
          pathname: homePath,
          state: { from: props.location },
        }}
      />
    );
  }

  return <Route {...props} />;
};

export default AdminRoute;
