import { useEffect, useState } from 'react';
import Firebase, { useFirebaseContext } from 'shell/firebase';
import { getMembership } from 'views/client/subscribe/helpers';
import { UserData, SubscribePlatform } from 'constants/data/user.data';
import { SubscribeOptionInterface } from 'constants/subscription';
import { Stripe } from 'stripe';
import useAnalytics from './useAnalytics';
// import { useAuthContext } from "shell/session";

const queryStripeCustomer = async (userId: string, firebase: Firebase) => {
  const store = await firebase.getStore();

  if (!store) {
    return null;
  }

  return store.collection('customers').doc(userId);
};

const getStripeProducts = async (firebase: Firebase) => {
  const store = await firebase.getStore();

  if (!store) {
    return null;
  }

  const snapshot = await store
    .collection('products')
    .where('active', '==', true)
    .get();

  const products = await Promise.all(
    snapshot.docs.map(async doc => {
      const product = doc.data();
      const { name } = product;
      const priceSnapshot = await doc.ref
        .collection('prices')
        .where('active', '==', true)
        .orderBy('unit_amount')
        .get();

      const prices: Stripe.Price[] = [];
      priceSnapshot.docs.forEach(priceDoc => {
        const priceData = priceDoc.data() as Stripe.Price;

        const membership = getMembership(name);
        const priceItem = { ...priceData, uid: priceDoc.id, membership };

        prices.push(priceItem);
      });

      return { uid: doc.id, ...product, prices };
    })
  );

  return products;
};

const useLoadStripeCustomer = (userId: string) => {
  const [customerId, setCustomerId] = useState<string | null>();
  const firebase = useFirebaseContext();

  useEffect(() => {
    if (firebase) {
      queryStripeCustomer(userId, firebase).then(customer => {
        if (!customer) {
          return;
        }

        customer
          .get()
          .then(doc => {
            if (doc.exists) {
              setCustomerId(userId);
              return;
            }

            setCustomerId(null);
          })
          .catch(() => {
            setCustomerId(null);
          });
      });
    }
  }, [firebase, userId]);

  return {
    customerId,
    isLoading: typeof customerId === 'undefined',
  };
};

/**
 * Get user's subscription depend on channel they subscribed (web/app)
 *
 * @param userId
 * @returns
 */
export const useLoadSubscription = (userId: string) => {
  const firebase = useFirebaseContext();
  const [user, setUser] = useState<UserData | null>();
  const [subscription, setSubscription] =
    useState<Stripe.Subscription | null>();
  const { customerId } = useLoadStripeCustomer(userId);
  const subscribedBy = user?.subscribedBy || SubscribePlatform.Web;

  const parseStripeSubscription = (
    querySnapshot: firebase.firestore.QuerySnapshot
  ) => {
    const item = querySnapshot.docs.length ? querySnapshot.docs[0] : null;

    if (item) {
      const subscription = item.data() as Stripe.Subscription;
      if (subscription) {
        setSubscription(subscription);
        return;
      }
    }

    setSubscription(null);
  };

  /**
   * Get Firebase user
   */
  useEffect(() => {
    if (firebase) {
      const query = firebase.userRef(userId);
      query.once('value', snapshot => {
        const data = snapshot.val();

        setUser(data);
      });
    }
  }, [userId, firebase]);

  useEffect(() => {
    if (customerId === null) {
      setSubscription(null);
      return;
    }

    if (firebase && customerId) {
      switch (subscribedBy) {
        case SubscribePlatform.Web:
          queryStripeCustomer(customerId, firebase).then(customer => {
            if (!customer) {
              return;
            }

            const subscriptions = customer.collection('subscriptions');
            const query = subscriptions.where('status', 'in', [
              'trialing',
              'active',
            ]);
            query.onSnapshot(parseStripeSubscription);
          });

          return;
        case SubscribePlatform.App:
          setSubscription(null);
          return;
        default:
          return;
      }
    }
  }, [firebase, customerId, subscribedBy]);

  return {
    subscription,
    isLoading: typeof subscription === 'undefined',
  };
};

/**
 * Get Stripe products, only use for Web subscription
 */
export const useLoadProducts = () => {
  const firebase = useFirebaseContext();
  const [products, setProducts] = useState<any>();

  useEffect(() => {
    const loadProducts = async () => {
      const products = await getStripeProducts(firebase);

      setProducts(products);
    };

    loadProducts();
  }, [firebase]);

  return {
    products,
    isLoading: typeof products === 'undefined',
  };
};

/**
 * Subscribe Stripe product from web
 * @returns
 */
export const useSubscribe = () => {
  const firebase = useFirebaseContext();
  const { trackBeginCheckout } = useAnalytics();

  const subscribe = (userUid: string, options: SubscribeOptionInterface) => {
    const { priceUid, successUrl, cancelUrl, membership } = options;

    return new Promise((resolve, reject) => {
      if (!priceUid) {
        reject('Invalid price data');
      }

      if (!membership) {
        reject('Invalid membership data');
      }

      firebase.getStore().then(store => {
        store
          .collection('customers')
          .doc(userUid)
          .collection('checkout_sessions')
          .add({
            price: priceUid,
            success_url: successUrl || window.location.href,
            cancel_url: cancelUrl || window.location.href,
          })
          .then(doc => {
            const stop = doc.onSnapshot(snap => {
              const sessionData = snap.data();
              const { error, sessionId } = sessionData as any;

              if (error) {
                reject(new Error(`An error occured: ${error.message}`));
              }

              if (sessionId) {
                /**
                 *
                 */
                trackBeginCheckout({
                  membership,
                  userId: userUid,
                  items: [
                    {
                      item_id: priceUid,
                    },
                  ],
                });

                resolve(sessionId);
                stop();
              }
            });
          });
      });
    });
  };

  return subscribe;
};

/**
 * Cancel current subscription from web
 * @returns
 */
export const useCancelSubscription = () => {
  const firebase = useFirebaseContext();

  return (subscriptionId: string) => {
    const callable = firebase.functions.httpsCallable('cancelSubscription');
    return callable({ subscriptionId });
  };
};
