import { useMemo, useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Link,
  Button,
} from '@material-ui/core';
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';
import { ImageData } from 'constants/data/image.data';
import { Print } from 'constants/data/print.data';
import { UserData } from 'constants/data/user.data';
import OptimizedImage from 'modules/OptimizedImage';
import Typography from 'modules/typography';
import { useAuthContext } from 'shell/session';
import { useModalState } from 'utils/hooks';
import {
  useLoadArtist,
  useLoadImage,
  useLoadCanvasById,
  useUpdateCanvasType,
} from 'utils/requests';
import { isAvailableForSale } from 'utils/requests/useOriginalArtwork';
import { useIsAvailableForSale } from 'utils/requests/usePrints';
import MakeUnavailableDialog from './MakeUnavailableDialog';
import PopperMenu from './PopperMenu';
import ResellDialog from './ResellDialog';
import Status from './Status';
import Type from './Type';
import { CanvasData, CanvasType } from 'constants/data';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { useLoadCurrentMedia } from 'views/account/canvases/helpers';
import AssignArtworkStatus from './AssignArtworkStatus';
import ProofOfOwnershipDialog from './ProofOfOwnershipDialog';
import { CanvasDialogProps } from './ImagesList';
import { useMemberLeaveStreaming, useListenCanvasType } from 'utils/requests';
import { useModal } from 'modules/modals';
import { StreamingImageData, useArtwork } from './ArtworksProvider';
import { ReactComponent as TriangleIcon } from 'static/images/icons/active-image-triangle-icon.svg';

type Props = {
  image: ImageData | Print | StreamingImageData;
  imageId: string;
  printId: string;
  editionIndex: number;
  isPrint: boolean;
  print: null | Print;
  onAssign?: (image: ImageData | Print) => void;
  canvas?: CanvasData;
  handleOpenCanvasDialog: (props: CanvasDialogProps) => void;
  handleOpenManageStreamingDialog: (image: ImageData) => void;
  handleOpenGiftDialog: (image: ImageData | Print) => void;
  handleOpenCancelGiftDialog: (image: ImageData | Print) => void;
  customMobileScreen?: boolean;
  setReloadPrint?: (timestamp: number) => void;
};

const ImagesListItem = ({
  image: imageOrPrint,
  imageId,
  printId,
  editionIndex,
  isPrint,
  print,
  onAssign,
  canvas,
  handleOpenCanvasDialog,
  handleOpenManageStreamingDialog,
  handleOpenGiftDialog,
  handleOpenCancelGiftDialog,
  customMobileScreen,
  setReloadPrint,
}: Props) => {
  const authContext = useAuthContext();
  const { showSnackbar } = useModal();
  const user = authContext.user as unknown as UserData;
  const { isEmailVerified, showVerifyDialog } = authContext;
  const { streamingExhibitionsMap } = useArtwork();
  const exhibition = streamingExhibitionsMap
    ? streamingExhibitionsMap[imageId]
    : undefined;

  const {
    isOpen: isOpenResellDialog,
    open: openResellDialog,
    close: closeResellDialog,
  } = useModalState();
  const {
    isOpen: isOpenMakeUnavailableDialog,
    open: openMakeUnavailableDialog,
    close: closeMakeUnavailableDialog,
  } = useModalState();

  const {
    isOpen: isOpenProvenanceDialog,
    open: openProvenanceDialog,
    close: closeProvenanceDialog,
  } = useModalState();

  const { print: playingPrint } = useLoadCurrentMedia(user.uid, canvas?.uid);
  const styles = useStyles();
  const image = useLoadImage(imageId);
  const { artist } = useLoadArtist(image?.artist);
  const { uid: userId, freeSlot } = user || {};
  const isPrintOwner = isPrint && userId === print?.owner;
  const isOriginalOwner = !isPrint && userId === image?.owner;
  const isOwner = isPrintOwner || isOriginalOwner;
  const waitingGiftTx = isPrint
    ? print?.currentWaitingGiftTx
    : image?.currentWaitingGiftTx;
  const isGifted = isPrint ? !!print?.isGifted : !!image?.isGifted;
  const isArtist = userId === image?.artist;
  const artworkIsForSale = isPrint
    ? false
    : isAvailableForSale((image || {}) as ImageData);
  const isPrintAvailableForSale = useIsAvailableForSale();
  const artworkDetailUrl = ROUTES.artworkDetailsUrl({
    imageId: image?.uid || '',
    name: image?.name || '',
  });
  const currentFreeSlot = freeSlot ? freeSlot.current : null;
  const freeSlotArtworkId = currentFreeSlot ? currentFreeSlot.artworkId : '';
  const isFreeSlot = !!freeSlotArtworkId && freeSlotArtworkId === imageId;
  const { canvas: playingCanvas } = useLoadCanvasById(
    isOriginalOwner || !isPrintOwner
      ? { originalArtwork: imageId }
      : { canvasId: print?.canvasId }
  );

  const updateCanvasType = useUpdateCanvasType();
  const canvasType = useListenCanvasType(
    playingCanvas ? playingCanvas.uid : ''
  );
  const hasWatermarks = useMemo(() => {
    if (!image) {
      return false;
    }

    // Check if the canvas is playing print
    if (isPrint) {
      return !!print?.watermarks?.visible;
    }
    return !!image.watermarks?.visible;
  }, [image, print, isPrint]);

  const [disableAction, setDisableAction] = useState<string | undefined>();
  const memberLeaveStreaming = useMemberLeaveStreaming();

  const onOpenManageStreamingDialog = () => {
    handleOpenManageStreamingDialog(image as ImageData);
  };

  const handleOpenCanvasSelector = () => {
    if (!isEmailVerified) {
      return showVerifyDialog();
    }

    const canvasDialogProps = {
      printId: isOriginalOwner || !isPrintOwner ? '' : printId,
      image: image,
      user: user,
    } as CanvasDialogProps;

    handleOpenCanvasDialog(canvasDialogProps);
  };

  const handleDisable = () => {
    if (!isEmailVerified) {
      return showVerifyDialog();
    }

    openMakeUnavailableDialog();
  };

  const handleEnableResell = () => {
    if (!isEmailVerified) {
      return showVerifyDialog();
    }

    openResellDialog();
  };

  const handleOpenProvenance = useCallback(() => {
    openProvenanceDialog();
  }, [openProvenanceDialog]);

  const handleDownloadArtwork = useCallback(() => {
    if (!print?.watermarks?.invisible) {
      return;
    }

    const anchor = document.createElement('a');
    anchor.href = print.watermarks.invisible;
    anchor.target = '_blank';
    // TODO: double check value in here
    // anchor.download = `${image?.name}-edition-${editionIndex}`;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }, [print]);

  const location = useLocation();
  useEffect(() => {
    if (location.search && image) {
      const query = new URLSearchParams(location.search);
      const isOpenViewProvenanceDialog = query.get('openViewProvenanceDialog');
      const artworkId = query.get('artworkId');
      if (
        isOpenViewProvenanceDialog &&
        (isOriginalOwner || isPrintOwner) &&
        printId === artworkId
      ) {
        handleOpenProvenance();
      }
    }
  }, [
    location,
    handleOpenProvenance,
    printId,
    isOriginalOwner,
    isPrintOwner,
    image,
  ]);

  const history = useHistory();
  const handleCloseProvenanceDialog = () => {
    if (location.search) {
      history.replace({ search: '' });
    }
    closeProvenanceDialog(false);
  };

  const handleMemberLeaveStreaming = () => {
    memberLeaveStreaming({
      artworkId: imageId,
      exhibitionId: exhibition?.uid || '',
    })
      .then(() => {
        showSnackbar('Leave streaming successfully');
      })
      .catch(error => {
        showSnackbar(error.message, { severity: 'error' });
        console.error(error);
      });
  };

  const openGiftDialog = () => {
    handleOpenGiftDialog(imageOrPrint);
  };

  const openCancelGiftDialog = () => {
    handleOpenCancelGiftDialog(imageOrPrint);
  };

  const handleShowIRIS = () => {
    if (!!playingCanvas) {
      updateCanvasType({
        canvasId: playingCanvas.uid,
        type: CanvasType.WATERMARKED,
        printId: isPrint ? printId : '',
      });
    }
  };

  const handleHideIRIS = () => {
    if (!!playingCanvas) {
      updateCanvasType({
        canvasId: playingCanvas.uid,
        type: CanvasType.ORIGINAL,
        printId: isPrint ? printId : '',
      });
    }
  };

  const deviceName = canvas?.meta.deviceName;

  return (
    <>
      <Box className={styles.container}>
        <Box className={styles.leftContainer}>
          <Box className={cn(styles.centerBox, styles.innerAction)}>
            {onAssign ? (
              <Button
                variant="outlined"
                color="primary"
                className={styles.assignImageButton}
                onClick={() => onAssign(imageOrPrint)}
                disabled={playingPrint?.uid === printId}>
                {!customMobileScreen ? (
                  <>
                    <PanoramaOutlinedIcon className={styles.buttonIcon} />
                    Assign Artwork
                  </>
                ) : (
                  <>Assign</>
                )}
              </Button>
            ) : (
              <PopperMenu
                onClickPlay={handleOpenCanvasSelector}
                openMakeUnavailableDialog={handleDisable}
                openResellDialog={handleEnableResell}
                enableForSale={
                  isPrint
                    ? isPrintAvailableForSale(print as Print)
                    : artworkIsForSale
                }
                setDisableAction={setDisableAction}
                isOwner={isOwner}
                isPrintOwner={isPrintOwner}
                isOriginOwner={isOriginalOwner}
                isArtist={isArtist}
                hasWaitingGift={!!waitingGiftTx}
                isGifted={!!isGifted}
                handleOpenGiftDialog={openGiftDialog}
                handleOpenCancelGiftDialog={openCancelGiftDialog}
                isStreaming={!!(imageOrPrint as StreamingImageData).isStreaming}
                onOpenProvenance={handleOpenProvenance}
                onOpenManageStreamingDialog={onOpenManageStreamingDialog}
                onMemberLeaveStreaming={handleMemberLeaveStreaming}
                hasWatermak={print?.watermarks ? true : false}
                onDownloadArtwork={handleDownloadArtwork}
                isPlaying={!!playingCanvas}
                hasWatermarks={hasWatermarks}
                isPlayingIRIS={canvasType === CanvasType.WATERMARKED}
                onClickShowIRIS={handleShowIRIS}
                onClickHideIRIS={handleHideIRIS}
                iconButton={<TriangleIcon />}
                className={styles.iconButton}
              />
            )}
          </Box>
          <Box className={styles.imageWrapper}>
            <Box className={styles.imageBox}>
              <Link
                component={RouterLink}
                to={image?.redirectTo || artworkDetailUrl}>
                {image && (
                  <OptimizedImage
                    className={styles.image}
                    path={image.path}
                    alt={image.name}
                    sizes="192px"
                  />
                )}
                <Box className={styles.overlay} />
              </Link>
            </Box>
            <Box className={cn(styles.centerBox, styles.imageInfo)}>
              <Typography
                fontSize="0.875rem"
                fontColor="#000000"
                className={styles.truncate}>
                {image?.name}
              </Typography>
              <Typography
                fontSize="0.75rem"
                fontColor="#616161"
                className={styles.truncate}>
                {artist?.fullName}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={styles.rightContainer}>
          {!customMobileScreen && (
            <Box className={cn(styles.centerBox, styles.innerRight)}>
              <Type
                isOwner={isOwner}
                isOriginalArtwork={editionIndex === -1}
                editionIndex={editionIndex}
                numberOfPrints={image?.numberOfPrints || 0}
                exhibition={exhibition}
                isFreeSlot={isFreeSlot}
              />
            </Box>
          )}
          <Box
            className={cn(
              styles.centerBox,
              styles.innerRight,
              styles.statusWrapper,
              { [styles.horizontalCenter]: onAssign && deviceName }
            )}>
            {onAssign && deviceName && playingPrint?.uid === printId && (
              <AssignArtworkStatus deviceName={deviceName} />
            )}
            {!onAssign && !deviceName && (
              <Status
                enableForSale={
                  isPrint
                    ? isPrintAvailableForSale(print as Print)
                    : artworkIsForSale
                }
                playingCanvas={playingCanvas}
                exhibition={exhibition}
                isFreeSlot={isFreeSlot}
                waitingGiftTx={waitingGiftTx}
              />
            )}
          </Box>
        </Box>
      </Box>
      {isOpenMakeUnavailableDialog && (
        <MakeUnavailableDialog
          id={isPrint ? printId : imageId}
          isPrint={isPrint}
          action={disableAction}
          open={isOpenMakeUnavailableDialog}
          printData={print}
          onClose={closeMakeUnavailableDialog}
          onSuccess={() => setReloadPrint && setReloadPrint(Date.now())}
        />
      )}
      {isOpenResellDialog && (
        <ResellDialog
          isPrint={isPrint}
          printId={printId}
          imageId={imageId}
          open={isOpenResellDialog}
          onClose={closeResellDialog}
          onSuccess={() => setReloadPrint && setReloadPrint(Date.now())}
          lastSoldPrice={isPrint ? print?.lastSoldPrice : image?.lastSoldPrice}
        />
      )}
      {isOpenProvenanceDialog && (
        <ProofOfOwnershipDialog
          image={image}
          print={print}
          printId={printId}
          originId={imageId}
          isPrintOwner={isPrintOwner}
          isOriginOwner={isOriginalOwner}
          open={isOpenProvenanceDialog}
          onClose={handleCloseProvenanceDialog}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(1.25, 0),
      borderTop: '1px solid #D8D8D8',
    },
    leftContainer: {
      display: 'flex',
      width: '55%',
    },
    rightContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '45%',
    },
    centerBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    imageInfo: {
      width: 'calc(100% - 80px)',
      paddingLeft: theme.spacing(2),
    },
    imageBox: {
      position: 'relative',
      width: 80,
      height: 80,
    },
    statusWrapper: {
      paddingLeft: theme.spacing(1),
    },
    image: {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
    innerAction: {
      flex: 1.5,
    },
    imageWrapper: {
      display: 'flex',
      flex: 8.5,
    },
    innerRight: {
      display: 'flex',
      flex: 3,
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 3,
    },
    truncate: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '300px',
    },
    assignImageButton: {
      fontSize: '0.75rem',
      height: 35,
      borderRadius: 30,
    },
    buttonIcon: {
      height: 18,
      marginRight: theme.spacing(1),
    },
    horizontalCenter: {
      alignItems: 'center',
    },
    iconButton: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  });
});

export default ImagesListItem;
