import { useFirebaseContext } from 'shell/firebase';
import { useLocation } from 'react-router-dom';

type LogType = 'warn' | 'error';

type LogData = {
  type: LogType;
  message: string;
};

export const useLog = () => {
  const firebase = useFirebaseContext();
  const location = useLocation();

  return async (payload: LogData) => {
    const callable = firebase.functions.httpsCallable('log');
    let appInfo = {};
    try {
      const userAgent = window.navigator.userAgent;
      appInfo = {
        ...location,
        userAgent,
      };
    } catch {}
    callable({
      ...payload,
      app: 'Web',
      appInfo: JSON.stringify(appInfo),
    });
  };
};
