import {
  createStyles,
  makeStyles,
  Theme,
  Tooltip as MuiTooltip,
  TooltipProps,
} from '@material-ui/core';

const Tooltip = ({ title, children, placement = 'top' }: TooltipProps) => {
  const classes = useStyles();
  return (
    <MuiTooltip
      title={title}
      arrow
      placement={placement}
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.tooltipArrow,
      }}>
      {children}
    </MuiTooltip>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    tooltip: {
      background: 'white',
      width: 350,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: '150%',
      color: '#000',
      padding: theme.spacing(2),
      fontWeight: 400,
      boxShadow: '0px 4px 25px rgba(109, 109, 109, 0.25)',
      borderRadius: '3px',
    },
    tooltipArrow: {
      '&:before': {
        backgroundColor: 'white',
      },
    },
  });
});

export default Tooltip;
