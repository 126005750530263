import { UserData } from 'constants/data';
import { createContext, useContext } from 'react';
import { useAuthContext } from 'shell/session';
import { useGetAllFeatureFlag } from 'utils/requests';

interface FeatureFlagItem {
  users: string[];
  global: boolean;
}

interface ContextProps {
  isLoading: boolean;
  value: {
    [key: string]: FeatureFlagItem;
  };
}

const defaultValue = {
  isLoading: true,
  value: {},
};
const FeatureFlagContext = createContext<ContextProps>(defaultValue);

const FeatureFlagsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { featureData, isLoading } = useGetAllFeatureFlag();
  const value = isLoading ? {} : { ...featureData };

  return (
    <FeatureFlagContext.Provider value={{ isLoading, value }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

const useFeatureFlagContext = (key: string) => {
  const authContext = useAuthContext();
  const user = authContext.user as UserData | null | undefined;
  const { isLoading: isLoadingFeatureFlag, value } =
    useContext(FeatureFlagContext);

  const isLoadingUser = user === undefined;
  const isLoading = isLoadingFeatureFlag || isLoadingUser;

  if (isLoading || !value[key]) {
    return { isLoading, enabled: false };
  }

  const email = user ? user.email : null;
  const { users = [], global: isGlobal } = value[key];
  const hasUser =
    email !== null &&
    users.findIndex(
      userEmail => userEmail.toLowerCase() === email.toLowerCase()
    ) > -1;

  const enabled = isGlobal || hasUser;

  return { isLoading, enabled };
};

export { FeatureFlagsProvider, useFeatureFlagContext };
