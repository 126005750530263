import React from 'react';
import {
  Typography as MuiTypography,
  makeStyles,
  createStyles,
  Theme,
  TypographyProps,
} from '@material-ui/core';
import { TypographyProps as SystemTypographyProps } from '@material-ui/system';
import { mobileMediaQuery } from 'utils/common';
import cs from 'classnames';

interface Props extends TypographyProps, SystemTypographyProps {
  className?: string;
  component?: string | React.ElementType;
  mobileFontSize?: string | number;
  fontColor?: string;
  lineClamp?: number;
}

/**
 * This custom Typography wraps the original @material-ui/core/Typography,
 * accept more properties to control the style. Support the props below:
 * - fontWeight
 * - fontSize
 * - mobileFontSize
 * - fontColor
 * - lineHeight
 *
 * @typedef {Object} Props
 * @prop {string | number} fontWeight
 * @prop {string | number} fontSize
 * @prop {string | number} mobileFontSize font-size to apply in mobile screen size
 * @prop {string} fontColor
 * @param {Props} props
 */
const Typography = (props: Props) => {
  const {
    className,
    fontWeight,
    fontSize,
    mobileFontSize,
    fontColor,
    lineHeight,
    lineClamp,
    fontFamily,
    letterSpacing,
    ...rest
  } = props;
  const classes = useStyles({
    fontWeight,
    fontSize,
    mobileFontSize,
    fontColor,
    lineHeight,
    lineClamp,
    fontFamily,
    letterSpacing,
  });
  return (
    <MuiTypography
      {...rest}
      className={cs(
        classes.root,
        { [classes.multiLineEllipsis]: typeof props.lineClamp === 'number' },
        className
      )}
    />
  );
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    root: {
      fontWeight: ({ fontWeight }) => fontWeight,
      fontSize: ({ fontSize }) => fontSize,
      [mobile]: {
        fontSize: ({ mobileFontSize }) => mobileFontSize,
      },
      color: ({ fontColor }) => fontColor,
      lineHeight: ({ lineHeight }) => lineHeight,
      fontFamily: ({ fontFamily }) => fontFamily,
      letterSpacing: ({ letterSpacing }) => letterSpacing,
    },
    multiLineEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: ({ lineClamp }) => lineClamp,
      WebkitBoxOrient: 'vertical',
    },
  });
});

export default Typography;
