import React, { useMemo } from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
  IconButton,
} from '@material-ui/core';
import { useIsMobile } from 'utils/hooks';
import { ReactComponent as CloseIcon } from 'static/images/close-icon.svg';

interface Props extends DialogProps {
  totalPrintsCount: number;
  availablePrintsCount: number;
  isRent: boolean;
}

const EditionsInfoDialog = (props: Props) => {
  const isMobile = useIsMobile();
  const classes = useStyles();
  const { totalPrintsCount, availablePrintsCount, isRent, ...dialogProps } =
    props;

  const handleClose = (reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (!reason && dialogProps.onClose) {
      dialogProps.onClose({}, 'escapeKeyDown');
    }
  };

  const availableMessage = useMemo(() => {
    const lastText = isRent ? 'rent/view' : 'buy';
    return `${availablePrintsCount} of ${totalPrintsCount} are available to ${lastText}`;
  }, [isRent, availablePrintsCount, totalPrintsCount]);

  return (
    <Dialog
      {...dialogProps}
      fullScreen={isMobile}
      maxWidth="xs"
      onClose={(event, reason) => handleClose(reason)}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography className={classes.dialogTitleText}>Editions</Typography>

        <IconButton
          onClick={() => handleClose()}
          className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>
          Editions are unique renditions of an artwork that are offered in
          defined quantity. The editions are offered in a specific numbered
          series.
        </Typography>
        <Box mt={3.5}>
          <Typography variant="body2" className={classes.textBold}>
            Unique Limited Editions: 1-9 prints
          </Typography>
          <Typography variant="body2">
            The works of art in this edition level are unique limited and only
            available in the amount of 1 to 9 to buy or rent.
          </Typography>
          <Typography variant="body2" className={classes.textBold}>
            Limited Editions: 10-99 prints
          </Typography>
          <Typography variant="body2">
            The works of art in this edition level are limited and only
            available in the amount of 10 to 99 to buy or rent.
          </Typography>
          <Typography variant="body2" className={classes.textBold}>
            Editions: 100 prints or more
          </Typography>
          <Typography variant="body2">
            The works of art in this edition level are available in the amount
            of 100 or more to buy or rent.
          </Typography>
        </Box>

        <Box mt={4.5}>
          <Typography className={classes.dialogContentTitle}>
            Availability
          </Typography>
          <Typography className={classes.availableText}>
            {availableMessage}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    dialogTitle: {
      position: 'relative',
      padding: theme.spacing(7, 7, 3, 7),
    },
    dialogTitleText: {
      fontSize: '1.75rem',
      fontWeight: 500,
      maxWidth: 350,
    },
    dialogContentTitle: {
      fontSize: '1.25rem',
      fontWeight: 500,
      marginBottom: theme.spacing(1),
    },
    closeButton: {
      position: 'absolute',
      right: 40,
      top: 40,
    },
    dialogContent: {
      padding: theme.spacing(0, 7, 7, 7),
    },
    availableText: {
      color: '#30A035',
      fontSize: '0.75rem',
    },
    textBold: {
      fontWeight: 500,
      marginTop: theme.spacing(3.5),
    },
  });
});

export default EditionsInfoDialog;
