import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { ResetPasswordSchema } from 'constants/validationSchema';
import { TextField } from 'modules/formFields';
import { yupResolver } from '@hookform/resolvers/yup';

import UIButton from 'ui-components/Button';

interface Props {
  onSubmit: (values: IResetPasswordForm) => void;
  loading: boolean;
}

const ResetPasswordForm = ({ onSubmit, loading }: Props) => {
  const classes = useStyles();
  const { control, handleSubmit } = useForm<IResetPasswordForm>({
    defaultValues: getFormDefaultValues(),
    resolver: yupResolver(ResetPasswordSchema),
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Box className={classes.fields}>
          {FIELDS.map(field => {
            return (
              <Controller
                key={field.name}
                name={field.name}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Box mb={3} className={classes.field}>
                      <TextField
                        onChange={onChange}
                        value={value}
                        error={!!error}
                        helperText={error?.message || ''}
                        fullWidth
                        type={field.type}
                        label={field.label}
                        disabled={!!field.disabled}
                        autoFocus={!!field.autoFocus}
                        placeholder={field.placeholder}
                      />
                    </Box>
                  );
                }}
              />
            );
          })}
        </Box>
        <Box className={classes.submitWrapper}>
          <UIButton
            type="submit"
            variant="primary"
            size="medium"
            loading={loading}
            disabled={loading}
            text="Reset password"
          />
        </Box>
      </form>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    fields: {},
    field: {
      position: 'relative',
      maxWidth: 584,
    },
    submitWrapper: {
      marginTop: theme.spacing(2),
    },
  })
);

interface IResetPasswordForm {
  email: string;
}

const getFormDefaultValues = (): IResetPasswordForm => {
  return {
    email: '',
  };
};

interface IFormField {
  name: keyof IResetPasswordForm;
  label: string;
  autoFocus?: boolean;
  type?: string;
  disabled?: boolean;
  placeholder: string;
}

const FIELDS: IFormField[] = [
  {
    name: 'email',
    label: 'EMAIL',
    type: 'email',
    autoFocus: true,
    placeholder: 'Your email address',
  },
];

export default ResetPasswordForm;
