import React from 'react';
import {
  Box,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'static/images/close-icon.svg';
import Typography from 'modules/typography';
import ProofOfOwnership from 'modules/purchaseSuccess/proofOfOwnership';
import { mobileMediaQuery } from 'utils/common';
import { useIsMobile } from 'utils/hooks';
import { ImageData, Print } from 'constants/data';
import ImageBox from 'modules/imageBox';
import useGetTransactionById from 'utils/requests/useGetTransactionById';

import UIButton from 'ui-components/Button';

interface Props extends Omit<DialogProps, 'onClose'> {
  image?: ImageData | null;
  print?: Print | null;
  printId?: string | null;
  originId?: string | null;
  isPrintOwner: boolean;
  isOriginOwner: boolean;
  onClose: () => void;
}

const ProofOfOwnershipDialog = ({
  image,
  printId,
  originId,
  isPrintOwner,
  isOriginOwner,
  print,
  ...dialogProps
}: Props) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { latestTransaction: transaction, isLoading } = useGetTransactionById({
    value: isPrintOwner ? printId : originId,
    isPrint: isPrintOwner,
    transactionId: isPrintOwner
      ? print?.currentTransferTx
      : image?.currentTransferTx,
  });

  if (isLoading) {
    return null;
  }
  const handleClose = () => {
    dialogProps.onClose();
  };
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullWidth
      scroll={'body'}
      {...dialogProps}
      disableEscapeKeyDown
      fullScreen={isMobile}>
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        {/* Title Section */}
        <Box mb={isMobile ? 3 : 2}>
          <Typography component="span" className={classes.title}>
            Proof of Ownership
          </Typography>
        </Box>

        <Box className={classes.content}>
          {/* Proof of ownership section */}
          <Box className={classes.proofOfOwnership}>
            {image && transaction && printId ? (
              <ProofOfOwnership
                image={image}
                transactionId={transaction.key}
                printId={printId}
                hideTitle={true}
              />
            ) : transaction === null ? (
              `This item has not been transferred yet`
            ) : (
              <CircularProgress />
            )}
          </Box>

          {/* Image Section */}
          <Box className={classes.image}>
            {image ? (
              <ImageBox
                path={image.path}
                alt={image.name}
                originalRatio={image.width / image.height}
                classNames={{ img: classes.img, root: classes.imgRoot }}
              />
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Box>
        {/* Footer section */}
        <Box className={classes.footer}>
          <UIButton
            fullWidth={isMobile}
            variant="secondary"
            size="medium"
            onClick={handleClose}
            className={classes.buttonDone}
            text="Done"
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    root: {},
    paper: {
      color: '#000',
      maxWidth: 1000,
      padding: theme.spacing(7, 6),
      [mobile]: {
        maxWidth: '100%',
        padding: theme.spacing(5, 2),
        height: '80vh',
      },
    },
    dialogContent: {
      padding: 0,
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      [mobile]: {
        flexDirection: 'column-reverse',
      },
    },
    title: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(36),
      lineHeight: '125%',
      letterSpacing: '-0.02em',
      [mobile]: {
        fontSize: theme.typography.pxToRem(28),
      },
    },
    proofOfOwnership: {
      maxWidth: 500,
      minWidth: 500,
      [mobile]: {
        maxWidth: '100%',
        minWidth: '100%',
      },
    },
    image: {
      paddingLeft: theme.spacing(5),
      width: '100%',
      [mobile]: {
        paddingLeft: 0,
      },
    },
    buttonDone: {
      alignSelf: 'flex-start',
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(3.5),
      right: theme.spacing(3.5),
      zIndex: 2,
      [mobile]: {
        top: theme.spacing(1),
        right: theme.spacing(1),
      },
    },
    img: {
      objectFit: 'contain',
    },
    imgRoot: {
      width: '100%',
      height: 450,
      paddingTop: theme.spacing(1.5),
      marginBottom: theme.spacing(0),
      [mobile]: {
        width: '100%',
        height: 'auto',
        paddingTop: theme.spacing(1.5),
        marginBottom: theme.spacing(3),
      },
    },
    footer: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [mobile]: {
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
      },
    },
  });
});

export default ProofOfOwnershipDialog;
