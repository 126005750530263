import { useMemo, useState, useEffect } from 'react';
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Checkbox,
  Radio,
  Chip,
  Tooltip,
} from '@material-ui/core';
import { Column, CellProps, HeaderProps } from 'react-table';
import ErrorIcon from '@material-ui/icons/Error';
import { ImageData } from 'constants/data/image.data';
import { ColorType, COLORS_MAP } from 'constants/colors';
import { getSlug, multiValuesStringToArray } from 'utils/common';
import { useIsMounted } from 'utils/hooks';
import { useAllImagesContext } from 'utils/contexts/allImagesContext';
import { useUpdateFeaturedImage } from 'utils/contexts/artistsContext/helpers';
import { useArtistsContext } from 'utils/contexts/artistsContext';
import { useImageFieldsContext } from 'utils/contexts/imageFieldsContext';
import { useModal } from 'modules/modals';
import OptimizedImage from 'modules/OptimizedImage';
import ImageBox from 'modules/imageBox';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { useArchiveImage, useUnarchiveImage } from 'utils/requests';
import UILink from 'ui-components/Link';

import UIButton from 'ui-components/Button';

export const COLUMN_CHECKBOX: Column<ImageData> = {
  id: 'selection',
  width: 55,
  disableSortBy: true,
  Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<ImageData>) => (
    <Checkbox size="small" {...getToggleAllRowsSelectedProps()} />
  ),
  Cell: ({ row }: CellProps<ImageData>) => (
    <Checkbox size="small" {...row.getToggleRowSelectedProps()} />
  ),
};
export const COLUMN_THUMBNAIL: Column<ImageData> = {
  accessor: 'path',
  width: 80,
  disableSortBy: true,
  Cell: ({ row }: CellProps<ImageData>) => {
    return <ThumbnailCell image={row.original} />;
  },
};
export const COLUMN_NAME: Column<ImageData> = {
  accessor: 'name',
  Header: 'Name',
  width: 200,
  Cell: (cell: CellProps<ImageData>) => {
    const { uid, name } = cell.row.original;
    return (
      <UILink
        component={RouterLink}
        href={
          uid
            ? generatePath(ROUTES.ARTWORK_DETAILS, {
                imageId: uid,
                name: getSlug(name || 'untitled'),
              })
            : '#'
        }
        variant={'secondary'}>
        {name}
      </UILink>
    );
  },
};
export const COLUMN_PRINTS: Column<ImageData> = {
  accessor: 'numberOfPrints',
  Header: 'Prints',
  width: 100,
  disableSortBy: true,
  Cell: (cell: CellProps<ImageData>) => {
    const { uid, numberOfPrints } = cell.row.original;
    return <PrintCell imageId={uid} totalPrints={numberOfPrints} />;
  },
};
export const COLUMN_ARTIST: Column<ImageData> = {
  accessor: 'artist',
  Header: 'Artist',
  width: 175,
  disableSortBy: true,
  Cell: ({ value }: CellProps<ImageData>) => {
    return <AritistCell userId={value} />;
  },
};
export const COLUMN_CATALOG: Column<ImageData> = {
  accessor: 'catalog',
  Header: 'Collections',
  width: 200,
};
export const COLUMN_BRIGHTNESS: Column<ImageData> = {
  accessor: 'brightness',
  Header: 'Bright­ness',
  width: 100,
};
export const COLUMN_ABSTRACTION: Column<ImageData> = {
  accessor: 'abstraction',
  Header: 'Abstr­action',
  width: 110,
};
export const COLUMN_MEDIUM: Column<ImageData> = {
  accessor: 'medium',
  Header: 'Medium',
  width: 75,
};
export const COLUMN_DATE: Column<ImageData> = {
  accessor: 'date',
  Header: 'Year',
  width: 75,
};
export const COLUMN_COLOR: Column<ImageData> = {
  accessor: 'color',
  Header: 'Colors',
  width: 150,
  disableSortBy: true,
  Cell: ({ value }: CellProps<ImageData>) => {
    return <ColorsCell colorsStr={value} />;
  },
};
export const COLUMN_SIZE: Column<ImageData> = {
  id: 'size',
  accessor: (row: any) => `${row.width} x ${row.height}`,
  Header: 'Size',
  width: 150,
};
export const COLUMN_WIDTH: Column<ImageData> = {
  accessor: 'width',
  Header: 'Width',
  width: 100,
};
export const COLUMN_HEIGHT: Column<ImageData> = {
  accessor: 'height',
  Header: 'Height',
  width: 100,
};
export const COLUMN_ASPECT_RATIO: Column<ImageData> = {
  id: 'ratio',
  accessor: (row: any) =>
    (Math.round((row.width / row.height) * 100) / 100).toFixed(2),
  Header: 'Aspect ratio',
  width: 120,
};
export const COLUMN_KEYWORDS: Column<ImageData> = {
  accessor: 'associations',
  Header: 'Keywords',
  width: 500,
};
export const COLUMN_FEATURED: Column<ImageData> = {
  id: 'featured',
  width: 100,
  disableSortBy: true,
  Header: 'Featured',
  Cell: ({ row }: CellProps<ImageData>) => {
    return <FeaturedCell image={row.original} />;
  },
};
export const COLUMN_STATUS: Column<ImageData> = {
  Header: 'Status',

  Cell: ({ row }: CellProps<ImageData>) => {
    return <StatusCell image={row.original} />;
  },
};
export const COLUMN_MINT_NFT_STATUS: Column<ImageData> = {
  Header: 'NFT Status',

  Cell: ({ row }: CellProps<ImageData>) => {
    return <NFTStatusCell image={row.original} />;
  },
};
export const COLUMN_ACTIONS: Column<ImageData> = {
  id: 'actions',
  width: 150,
  disableSortBy: true,
  Header: 'Actions',
  Cell: ({ row }: CellProps<ImageData>) => {
    return <ActionsCell image={row.original} />;
  },
};

const AritistCell = ({ userId }: { userId: string }) => {
  const { getArtistInfo } = useArtistsContext();
  const artist = getArtistInfo(userId);
  return <span>{artist?.fullName || userId}</span>;
};

const PrintCell = ({
  imageId,
  totalPrints,
}: {
  imageId: string;
  totalPrints: number;
}) => {
  const isMounted = useIsMounted();
  const [count, setCount] = useState<number>();
  const { getNumberOfAvailablePrints } = useAllImagesContext();
  useEffect(() => {
    getNumberOfAvailablePrints(imageId).then(newCount => {
      if (isMounted() === true) {
        setCount(newCount);
      }
    });
  }, [imageId, getNumberOfAvailablePrints, isMounted]);
  return <span>{`${count ?? '--'} of ${totalPrints}`}</span>;
};

const ColorsCell = ({ colorsStr }: { colorsStr: string }) => {
  const classes = useStyles();
  const sorted = useMemo(() => {
    const colors = multiValuesStringToArray(colorsStr || '') as ColorType[];
    return colors.slice().sort();
  }, [colorsStr]);
  return (
    <Box className={classes.colorsCell}>
      {sorted.map(color => (
        <Box
          key={color}
          title={color}
          style={{ color: COLORS_MAP[color] }}
          className={classes.colorItem}></Box>
      ))}
    </Box>
  );
};

const FeaturedCell = ({ image }: { image: ImageData }) => {
  const { uid: imageId, artist: artistId, published } = image;
  const [loading, setLoading] = useState<boolean>();
  const updateFeaturedImage = useUpdateFeaturedImage();
  const { getArtistInfo } = useArtistsContext();
  const { showSnackbar } = useModal();
  const artist = getArtistInfo(artistId);
  const featuredImage = artist?.featuredImage;

  const onFeaturedClick = () => {
    if (!published) {
      showSnackbar('Only published image can be set as featured image', {
        severity: 'error',
      });
      return;
    }

    if (featuredImage === imageId) {
      return;
    }
    setLoading(true);
    updateFeaturedImage(imageId, artistId)
      .then(() => {
        showSnackbar('Featured image was updated');
        setLoading(false);
      })
      .catch((error: any) => {
        showSnackbar(error.message, { severity: 'error' });
        setLoading(false);
      });
  };
  return (
    <Radio
      size="small"
      checked={imageId === artist?.featuredImage}
      onClick={onFeaturedClick}
      disabled={loading}
    />
  );
};

const MAX_PREVIEW_THUMBNAIL_WIDTH = 500;
const MAX_PREVIEW_THUMBNAIL_HEIGHT = 300;
const ThumbnailCell = ({ image }: { image: ImageData }) => {
  const classes = useStyles();
  const { path, name, width, height } = image;
  const thumnailStyle = {
    width,
    height,
  };
  const calculatedWidth = (MAX_PREVIEW_THUMBNAIL_HEIGHT / height) * width;
  if (calculatedWidth > MAX_PREVIEW_THUMBNAIL_WIDTH) {
    thumnailStyle.width = MAX_PREVIEW_THUMBNAIL_WIDTH;
    thumnailStyle.height = (MAX_PREVIEW_THUMBNAIL_WIDTH / width) * height;
  } else {
    thumnailStyle.width = calculatedWidth;
    thumnailStyle.height = MAX_PREVIEW_THUMBNAIL_HEIGHT;
  }
  return (
    <Tooltip
      placement="right-end"
      title={
        <Box>
          <OptimizedImage
            path={path}
            alt={name}
            sizes={thumnailStyle.width + 'px'}
            className={classes.thumbnailPreview}
            style={thumnailStyle}
          />
        </Box>
      }
      classes={{ tooltip: classes.thumbnailTooltip }}>
      <Box>
        <ImageBox
          path={path}
          alt={name}
          originalRatio={width / height}
          classNames={{ img: classes.thumbnail }}
          height={50}
          width={50}
        />
      </Box>
    </Tooltip>
  );
};

const StatusCell = ({ image }: { image: ImageData }) => {
  const classes = useStyles();
  const { checkIsPublishable } = useImageFieldsContext();
  if (image.archived === true) {
    return <Chip size="small" label="Archived" color="secondary" />;
  }
  if (image.published === true) {
    return <Chip size="small" label="Published" />;
  }
  const { errors } = checkIsPublishable(image);
  if (errors.length <= 0) {
    return <Chip size="small" label="Ready to publish" color="primary" />;
  }
  return (
    <Tooltip
      arrow
      title={errors.map(message => `- ${message}\n`)}
      classes={{ tooltip: classes.tooltip }}>
      <Chip
        size="small"
        variant="outlined"
        label="Incomplete"
        color="secondary"
        icon={<ErrorIcon />}
      />
    </Tooltip>
  );
};

const NFTStatusCell = ({ image }: { image: ImageData }) => {
  const { published, udId } = image;

  if (!published) {
    return null;
  }

  if (udId) {
    return <Chip size="small" label="Minted" color="primary" />;
  }

  return <Chip size="small" label="Ready to mint" color="secondary" />;
};

const ActionsCell = ({ image }: { image: ImageData }) => {
  const { uid, archived } = image;
  if (!image) {
    return null;
  }
  return <ArchiveButton imageId={uid} archived={archived} />;
};

interface ArchiveButtonProps {
  archived?: boolean;
  imageId: string;
}
const ArchiveButton = ({ archived, imageId }: ArchiveButtonProps) => {
  const { openDialog: openConfirmDialog, showSnackbar } = useModal();
  const archiveImage = useArchiveImage();
  const unarchiveImage = useUnarchiveImage();

  const [loading, setLoading] = useState<boolean>(false);

  const onArchiveImage = async () => {
    setLoading(true);
    const confirm = await openConfirmDialog({
      title: 'Archive an image',
      message:
        'Are you sure you want to archive this image? Archived images will disappear immediately from the site and from all canvases where they are currently displayed. The archived image can be unarchived later, but these prints and displays cannot be restored.',
      labelConfirm: 'Yes, archive',
      labelCancel: "No, I don't want to archive this image",
    });

    if (!confirm) {
      setLoading(false);
      return;
    }

    archiveImage(imageId)
      .then(() => {
        setLoading(false);
        showSnackbar('Archive the image successfully!');
      })
      .catch(error => {
        console.warn(error);
        setLoading(false);
        showSnackbar('Fail to archive the image!', { severity: 'error' });
      });
  };

  const onUnarchiveImage = async () => {
    setLoading(true);
    const confirm = await openConfirmDialog({
      title: 'Unarchive an image',
      message:
        'Unarchive this image? It will immediately become visible on the site and rentable by subscribed users.',
      labelConfirm: 'Yes, unarchive',
      labelCancel: 'No',
    });

    if (!confirm) {
      setLoading(false);
      return;
    }

    unarchiveImage(imageId)
      .then(() => {
        setLoading(false);
        showSnackbar('Unarchive the image successfully!');
      })
      .catch(error => {
        console.warn(error);
        setLoading(false);
        showSnackbar('Fail to unarchive the image!', { severity: 'error' });
      });
  };

  if (!imageId) {
    return null;
  }

  if (archived) {
    return (
      <UIButton
        variant="primary"
        size="medium"
        onClick={onUnarchiveImage}
        disabled={loading}
        text="Unarchive"
      />
    );
  }

  return (
    <UIButton
      variant="secondary"
      size="medium"
      onClick={onArchiveImage}
      disabled={loading}
      text="Archive"
    />
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorsCell: {
      display: 'flex',
      gap: 4,
    },
    colorItem: {
      height: theme.spacing(2),
      width: theme.spacing(2),
      backgroundColor: 'currentColor',
      borderRadius: '50%',
      border: '1px solid #999',
    },
    editButton: {
      marginRight: theme.spacing(2),
      minWidth: 180,
    },
    tableWrapper: {
      overflowX: 'scroll',
      width: '100%',
    },
    thumbnail: {
      objectFit: 'contain',
    },
    thumbnailPreview: {
      objectFit: 'contain',
      maxWidth: MAX_PREVIEW_THUMBNAIL_WIDTH + 'px',
      maxHeight: MAX_PREVIEW_THUMBNAIL_HEIGHT + 'px',
      zIndex: 10,
    },
    thumbnailTooltip: {
      backgroundColor: 'transparent',
      padding: 0,
      margin: '0 0 0 5px',
    },
    tooltip: {
      whiteSpace: 'pre-wrap',
      fontSize: '0.875rem',
    },
  })
);
