import { Box, IconButton } from '@material-ui/core';
import BlackInfoIcon from 'static/images/black-info-icon.svg';
import Tooltip from 'modules/tooltip';
import Typography from 'modules/typography';

interface HeaderWithTooltipProps {
  title: string;
  tooltip?: string;
}

const TooltipWithIconInfo = ({ title }: { title: string }) => {
  return (
    <Tooltip title={title}>
      <IconButton>
        <img src={BlackInfoIcon} alt="More info" width="14px" height="14px" />
      </IconButton>
    </Tooltip>
  );
};

const HeaderWithTooptip = (props: HeaderWithTooltipProps) => {
  const { title, tooltip } = props;

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start">
      <Typography fontWeight={500}>{title}</Typography>
      {!!tooltip && <TooltipWithIconInfo title={tooltip} />}
    </Box>
  );
};

export default HeaderWithTooptip;
