import {
  Select,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
  SelectProps,
  ListItemText,
} from '@material-ui/core';
import { SelectOption } from './FilterSelect';
import { ReactComponent as DropdownIcon } from 'static/images/dropdown.svg';
import { mobileMediaQuery } from 'utils/common';

interface SingleFilterSelectProps
  extends Omit<SelectProps, 'onChange' | 'name'> {
  options: SelectOption[];
  onChange: (values: string | number) => void;
}

const SingleFilterSelect = ({
  options,
  onChange,
  value,
  renderValue,
  ...rest
}: SingleFilterSelectProps) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValues = event.target.value as string | number;
    onChange(newValues);
  };

  return (
    <Select
      displayEmpty
      value={value}
      onChange={handleChange}
      classes={{ root: classes.selectRoot, icon: classes.icon }}
      renderValue={renderValue}
      disableUnderline
      IconComponent={DropdownIcon}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      {...rest}>
      {options.map((option: SelectOption) => (
        <MenuItem
          value={option.value}
          key={option.value}
          className={classes.capitalize}>
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </Select>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const mobile = mobileMediaQuery(theme);
  return createStyles({
    filter: {},
    options: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: theme.spacing(4) + 'px',
      rowGap: theme.spacing(1) + 'px',
      [mobile]: {
        flexDirection: 'column',
      },
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    selectRoot: {
      whiteSpace: 'pre-wrap',
      color: '#000000',
      fontSize: '1rem',
      fontWeight: 500,
      textTransform: 'capitalize',
      maxWidth: 250,
      [mobile]: {
        maxWidth: 'unset',
      },
    },
    icon: {
      color: '#000000',
      top: '50%',
      right: theme.spacing(1),
      transform: 'translateY(-50%)',
    },
  });
});

export default SingleFilterSelect;
