import { Print as PrintData } from 'constants/data';

export const getLowestPriceFromPrintForSale = (
  prints: PrintData[],
  artistId: string
) => {
  const artistPrints = prints.filter(print => print.owner === artistId);
  if (artistPrints.length !== 0) {
    const sortPrints = artistPrints.sort(
      (a, b) => Number(a.price) - Number(b.price)
    );
    return sortPrints[0];
  }
  const sortPrints = prints.sort((a, b) => Number(a.price) - Number(b.price));
  return sortPrints[0];
};
